import React, {useState} from 'react'
import './ServiceCalculate.scss'

import {Redirect, useHistory} from "react-router-dom";
import {connect, useDispatch, useSelector} from "react-redux";
import Table from "react-bootstrap/Table";
import GreenButton from "../../../../components/UI/GreenButton/GreenButton";
import BackBtn from "../../../../components/UI/BackBtn/BackBtn";
import ServiceCalculateTabs from "./components/ServiceCalculateTabs/ServiceCalculateTabs";
import ServiceCalculateHead from "./components/ServiceCalculateHead/ServiceCalculateHead";
import ServiceCalculateRows from "./components/ServiceCalculateRows/ServiceCalculateRows";
import ServiceCalculateBottom from "./components/ServiceCalculateBottom/ServiceCalculateBottom";
import ServiceCalculateTotal from "./components/ServiceCalculateTotal/ServiceCalculateTotal";
import ServiceCalculateMaterialsRows from "./components/ServiceCalculateMaterialsRows/ServiceCalculateMaterialsRows";
import {setActiveNavAnchor} from "../../../../store/actions/navActions";
import {CONFIRM_ORDER, SERVICE_SELECT_ANCHOR} from "../../../../config";
import DefaultLayout from '../../../../app/layout/DefaultLayout/DefaultLayout';
import ServiceWorkerSelect from '../ServiceWorkerSelect/ServiceWorkerSelect';


const ServiceCalculate = (props) => {
	const [currentTab, setCurrentTab] = useState(1);
	const {tabs, service, order} = props;
	const {servicesTableHead, materialTableHead} = useSelector(state => state.serviceCalc);

	const history = useHistory();
	const dispatch = useDispatch();

	const addLink = (anchor, url) => {
		history.push(url);
		dispatch(setActiveNavAnchor(anchor))
	};

	const withMaterials = order.needMaterials === 2 && !!order.materials.length;

	return (
		<DefaultLayout>
			{
				!!service
					?
					<>
						<BackBtn onClick={() => addLink(SERVICE_SELECT_ANCHOR, '/')}/>

						<div className='d-flex justify-content-between'>
							<ServiceCalculateTabs
								tabs={tabs}
								setCurrentTab={setCurrentTab}
								currentTab={currentTab}
								withMaterials={withMaterials}
							/>
						</div>


						<div
							className={`ServiceCalc_container px-4 pb-4 pt-3 rounded-bottom`}>

							{currentTab === 1
								?
								<Table responsive borderless>
									<ServiceCalculateHead head={servicesTableHead}/>
									<ServiceCalculateRows/>
								</Table>
								:
								<>
									<Table responsive borderless>
										<ServiceCalculateHead head={materialTableHead}/>
										<ServiceCalculateMaterialsRows/>
									</Table>
									{/*<ServiceCalculateMaterialsTotal/>*/}
								</>
							}
						</div>

						{currentTab === 1 && <ServiceCalculateBottom/>}


						<div className='d-flex justify-content-between flex-column flex-sm-row p-3'>
							<div
								className={`d-flex flex-column flex-sm-row  align-items-center`}>
								<ServiceCalculateTotal/>
							</div>


							<div
								className='d-flex flex-column flex-sm-row justify-content-end align-items-center'>
								<GreenButton
									className='mt-2 mt-sm-0'
									onClick={() => addLink(CONFIRM_ORDER, '/confirm')}
									//to={'/confirm'}
								>
									Продолжить
								</GreenButton>
							</div>
						</div>

					</>
					:
					<Redirect to='/'/>
			}
			<ServiceWorkerSelect/>
		</DefaultLayout>
	)

};

function mapState(state) {
	return {
		tabs: state.serviceCalc.tabs,
		order: state.order,
		service: state.order.service,
	}
}


export default connect(mapState)(ServiceCalculate)
