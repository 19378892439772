import React from 'react';
import styled from 'styled-components';
import { LEFT_WIDTH } from '../config';

const StyledTableLeft = styled.div`
  display: flex;
  width: ${LEFT_WIDTH};
  flex-direction: column;
  overflow-x: auto;
  overflow-y: hidden;
  background: #fff;
`;

const ChartsTableLeft = ({ children }) => {
    return (
        <StyledTableLeft>
            {children}
        </StyledTableLeft>
    );
};

export default ChartsTableLeft;
