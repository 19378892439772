import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";

import AdminHeader from "./AdminHeader";
import AdminServiceSelect from "./AdminServiceSelect";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import SaveIcon from '@material-ui/icons/Save';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {
    fetchCategories, fetchGoods,
    fetchMaterials,
    fetchProfessions,
    fetchServices,
    saveAdminChanges, setAdminServiceNormProperty
} from "../../../store/actions/adminServiceActions";
import {setCurrentTab} from "../../../store/actions/adminActions";
import LoaderAdmin from "../../../components/UI/LoaderAdmin/Loader";
import AdminPopup from "./AdminUI/AdminPopup";
import AdminSendLoader from "./AdminUI/AdminSendLoader";
import {TITLE_CATEGORY} from '../../../config';


class AdminWrapper extends Component {

    state = {
        value: 1,
        currentCategoryTab: 0,
        currentCategoryId: null,
        expanded: false,
    }

    categoryTabHandler = (event) => {

        const currentCategoryId = parseInt(event.target.closest('.category_tab__js').id)

        if (currentCategoryId) {
            let currentCategoryTab = this.props.categories.findIndex(category => {
                return category.id === currentCategoryId
            })

            this.setState({
                currentCategoryTab,
                currentCategoryId
            })
        }

    };

    serviceTabHandler = (event, newValue) => {
        this.setState({
            value: newValue
        })

    };

    clearResultHandler = () => {
        this.props.setAdminServiceNormProperty({}, 'saveResult')
    }

    componentDidMount = async () => {
        if (!this.props.categories.length) {
            await this.props.fetchServices()
            await this.props.fetchCategories()
            await this.props.fetchMaterials()
            await this.props.fetchProfessions()
            await this.props.fetchGoods()
        } else {
            const currentCategoryId = this.props.categories[0]['id']
            this.setState({
                currentCategoryId
            })
        }
    }


    render() {
        const {
            navTabs,
            categories,
            saveResult,
            saveInProcess
        } = this.props


        const tabPanels = navTabs.map((tab) => {
            return (
                <Fragment key={tab.id}>
                    {
                        !!categories.length &&

                        <TabPanel value={this.state.value} index={tab.id}>
                            <AdminHeader tabId={tab.id}  currentTab={this.state.value}/>
                            <AdminServiceSelect
                                tabId={tab.id}
                                currentTab={this.state.value}
                                serviceTabHandler={this.serviceTabHandler}
                                currentCategory={this.state.currentCategoryId || categories[0]['id']}
                            />

                        </TabPanel>
                    }
                </Fragment>


            )
        })

        const {value} = this.state

        return (
            <Fragment>
                {
                    this.props.loading ?
                        <LoaderAdmin/>
                        :
                        <Fragment>
                            <Typography variant="button" display="block"
                                        className="pt-4 pb-1 text-center border-bottom border-blue">
                                {TITLE_CATEGORY}
                            </Typography>
                            <Paper className="mt-2" elevation={2}>
                                <Tabs
                                    value={this.state.currentCategoryTab}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    onChange={this.categoryTabHandler}
                                    variant="scrollable"
                                >
                                    {
                                        categories.length > 0 &&
                                        categories.map((category) => {
                                            return (
                                                <Tab key={category.id} label={category.name}
                                                     className='category_tab__js'
                                                     id={category.id}/>
                                            )
                                        })
                                    }
                                </Tabs>
                            </Paper>
                            <AppBar position="static" className='mt-3'>
                                <Tabs
                                    value={value}
                                    variant="fullWidth"
                                    indicatorColor="secondary"
                                    onChange={this.serviceTabHandler}
                                >
                                    {
                                        navTabs.map((tab) => <Tab key={tab.id} value={tab.id} disabled={tab.disabled}
                                                                  label={tab.name} {...a11yProps(tab.id)} />)
                                    }
                                </Tabs>
                            </AppBar>
                            {tabPanels}
                            <Paper className="mt-2">
                                <div className="d-flex p-3 justify-content-end">
                                    <Button
                                        className="Admin_btn text mr-2"
                                        variant="contained"
                                        color="primary"
                                        onClick={this.props.saveAdminChanges}
                                    >
                                        <SaveIcon
                                            className='Admin_btn-icon Admin_btn-icon-create mr-1'/>
                                        <Typography variant="overline" display="block">
                                            Сохранить нормы
                                        </Typography>
                                    </Button>
                                </div>
                            </Paper>

                            {
                                saveResult.title &&
                                <AdminPopup saveResult={saveResult} clearResultHandler={this.clearResultHandler}/>
                            }
                            {
                                saveInProcess &&
                                <AdminSendLoader/>
                            }

                        </Fragment>
                }
            </Fragment>

        )


    }
}
const mapState = (state) => {

    return {
        navTabs: state.admin.navTabs,
        categories: state.adminService.categories,
        loading: state.adminService.loading,
        saveInProcess: state.adminService.saveInProcess,
        saveResult: state.adminService.saveResult,
        // currentCategoryId: state.admin
    }
}
const actions = {
    saveAdminChanges,
    setCurrentTab,
    fetchServices,
    fetchMaterials,
    fetchProfessions,
    fetchCategories,
    fetchGoods,
    setAdminServiceNormProperty
}

export default connect(mapState, actions)(AdminWrapper);


function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Fragment>{children}</Fragment>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



