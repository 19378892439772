import React, { useEffect, useRef } from 'react';
import './CountSlider.scss';
import Slider from 'react-slick';
import { useSelector } from 'react-redux';
import CountSliderArrow from './CountSliderArrow';
import CountSliderItem from './CountSliderItem';

const CountSlider = () => {
    const { services } = useSelector(state => state.serviceSelect);
    const { onlineTable: { goodsCount, workers } } = useSelector(state => state.homePageAbout);

    const settings = {
        dots: true,
        infinite: true,
        autoplaySpeed: 5000,
        speed: 1000,
        arrows: false,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        // dotsClass: 'CountSlider__dots',
    };
    const slider = useRef();
    const next = () => {
        slider.current.slickNext();
    };

    const previous = () => {
        slider.current.slickPrev();
    };


    return (
        <div className="CountSlider">
            {
                !!services && (
                    <>
                        <Slider {...settings} ref={slider}>
                            <CountSliderItem title="Услуг" num={!!services.length ? services.length : 118} />
                            <CountSliderItem title="Строителей" num={goodsCount} />
                            <CountSliderItem title="Материалов" num={workers.busy + workers.free} />
                        </Slider>
                        <div className="CountSlider__arrow CountSlider__arrow-previous">
                            <CountSliderArrow onClick={previous} position="previous" />
                        </div>
                        <br />
                        <div className="CountSlider__arrow CountSlider__arrow-next">
                            <CountSliderArrow onClick={next} />
                        </div>
                    </>
                )
            }
        </div>
    );
};

export default CountSlider;