import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import WorkerListHeader from "../WorkerPersonalList/WorkerListHeader";
import WorkerListLine from "../WorkerPersonalList/WorkerListLine";
import DraggableScroll from "../../../../components/UI/DraggableScroll/DraggableScroll";

const WorkerPersonalTeam = ({user}) => {
	const [team, setTeam] = useState([])
	useEffect(() => {
		console.log(user)
		const workers = user.workers;
		const master = workers.find((worker) => worker.isMaster);
		if (master) {
			setTeam([master, ...user.workers.filter((worker) => !worker.isMaster)])
		} else {
			setTeam([...user.workers.filter((worker) => !worker.isMaster)])
		}
	}, [user]);

	return (
		<>
			{
				!!team.length &&
				<DraggableScroll>
					<WorkerListHeader user={user}/>
					{
						team.map((worker, idx) => (
							<WorkerListLine
								key={worker.id}
								idx={idx + 1}
								master={worker}
								user={user}
							/>)
						)
					}
				</DraggableScroll>
			}
		</>
	);
};

const mapState = state => {
	return {
		user: state.workerPersonal.user
	}
};


export default connect(mapState)(WorkerPersonalTeam);