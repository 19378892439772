import {arrayUnique} from '../../utils/arrayFunctions';

export function getSectionsMap(categoryId, services) {
	// получаем все услуги текущей катгории
	const activeServices = services.filter(service => {
		return service.category.id === categoryId
	});
	//  получаем все разделы из этой категории
	const sections = activeServices.map((service) => service.category.subCategory)
		.sort((s1, s2) => {
			return s1.sort - s2.sort;
		});
	// получаем уникальный массив из id разделов
	const sectionsId = arrayUnique(sections.map((section) => section.id));
	return sectionsId.map(sectionId => {
		const section = {...sections.find(s => s.id === sectionId)};
		section.services = [...activeServices.filter(service => service.category.subCategory.id === section.id)];
		return section;
	})
}

export function getTotalSum(service) {
	let sum = 0;

	service.materials.map((materialItem) => {
	const pack = typeof materialItem.goods.pack !== 'undefined' ? materialItem.goods.pack : materialItem.pack;
	let packageCoefficient = typeof materialItem.goods.pack !== 'undefined' ? materialItem.goods.package_coefficient : materialItem.package_coefficient ? materialItem.package_coefficient : 1;
		sum += ((materialItem.goods.price || materialItem.price) / pack) * packageCoefficient
	});
	return sum.toFixed(2);
}