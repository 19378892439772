import React from 'react';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";


const WorkerListHeaderRequest = () => {
    return (
        <Paper className='wp__line my-2 p-3'>

            <Typography variant="overline"
                        className="col p-0 m-0 font-weight-bold"># / ID</Typography>

            <Typography variant="overline"
                        className="col-2 p-0 m-0 font-weight-bold">ФИО</Typography>

            <Typography variant="overline"
                        className="col-2 p-0 m-0 font-weight-bold">
                Категории
            </Typography>

            <Typography variant="overline"
                        className="col p-0 m-0 font-weight-bold">
                Профессия
            </Typography>

            <Typography variant="overline"
                        className="col p-0 m-0 font-weight-bold">
                Телефон
            </Typography>
            {/*<Typography variant="overline"*/}
            {/*            className="col-1 p-0 m-0 font-weight-bold">*/}
            {/*    Модерация*/}
            {/*</Typography>*/}
            <div className='col-3'/>

        </Paper>
    );
};

export default WorkerListHeaderRequest;