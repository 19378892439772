import './Works.scss'

import React from "react";
import Work from "./Work/Work";

function Works({ works, times }) {
    return(
        <div className="works-wrapper">
            <div className="works">
                {works.map((work, index) => {
                    return <Work work={work} times={times} index={index} worksCount={works.length} key={index} />
                })}
            </div>
        </div>
    )
}

export default Works;
