import React from 'react';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const AdminOrderListHeader = ({header}) => {
    return (

        <Paper className='w-100 d-flex bg-blue-header my-2'>
            <div className='w-100 d-flex py-2 px-3 mx-0 row'>
                {
                    header.map(item => {
                        return (

                            <Typography variant="overline"
                                        key={item.id}
                                        className={`Admin_head-item d-flex  p-0  ${item.col ? 'col-' + item.col : 'col'}`}>
                                <div className='d-flex align-items-center justify-content-center flex-column d-flex'>
                                    <span className='text-center'> {item.title}</span>
                                    {
                                        !!item.subtitle &&
                                        <span className='text-center'>{item.subtitle}</span>
                                    }
                                </div>
                            </Typography>
                        )
                    })
                }
            </div>
            <div style={{width: '50px', height: '100%'}}/>
        </Paper>

    );
};

export default AdminOrderListHeader;