import React from "react";
import { connect } from "react-redux";
import {
  nextStep,
  setValue,
} from "../../../../../../../store/actions/estimateActions";
import "./Step1.scss";
import build from "../../../../../../../img/build.jpg";
import reconstruction from "../../../../../../../img/reconstruction.jpg";
import repair from "../../../../../../../img/repair.jpg";
import WorkKindItem from "./WorkKindItem";
import EstimateButton from "../../../UI/EstimateButton";
import EstimateProcess from "../../Process/EstimateProcess";

export const Step1 = ({ nextStep, setValue, selectedWorkKind }) => {
  const kindsOfWork = {
    build: {
      id: "build",
      label: "Строительство",
      image: build,
    },
    repair: {
      id: "repair",
      label: "Ремонт",
      image: repair,
    },
    reconstruction: {
      id: "reconstruction",
      label: "Реконструкция",
      image: reconstruction,
    },
  };

  const selectWorkKind = (id) => {
    setValue("workKind", id);
  };

  return (
    <>
    <EstimateProcess/>
      <div className="complex-body">
        <div className="row">
          {Object.values(kindsOfWork).map(({ id, label, image }) => (
            <WorkKindItem
              key={id}
              onClick={selectWorkKind}
              selectedWorkKind={selectedWorkKind}
              id={id}
              label={label}
              image={image}
            />
          ))}
        </div>
      </div>
      <div className="complex-footer mt-5">
        <div className="row">
          <div className="col-12 estimate-footer-item">
            <EstimateButton
              onClick={nextStep}
              disabled={!selectedWorkKind}
              text="Следующий шаг"
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedWorkKind: state.estimateOrder.workKind,
});

const mapDispatchToProps = {
  nextStep,
  setValue,
};

export default connect(mapStateToProps, mapDispatchToProps)(Step1);
