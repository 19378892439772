import React, {memo, useCallback, useEffect, useState} from 'react';
import './AdminGoodsDetail.scss';
import {useDispatch, useSelector} from "react-redux";
import {
	AppBar, TextField, Tabs, Typography, Paper,
	Tab, MenuItem, Select, FormControl, Button, ListItem, List
} from "@material-ui/core";
import parse from 'html-react-parser';
import {useDropzone} from "react-dropzone";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import cuid from 'cuid';
import {
	adminGoodsFetch, adminGoodsFetchCategories,
	saveAdminGoodsGood, setAdminGoodsGoodActivity,
	setAdminGoodsGoodProperty
} from "../../../../../store/actions/adminGoodsActions";
import {fetchAdminMaterialListUnits} from "../../../../../store/actions/adminMaterialListActions";
import AdminBtn from "../../AdminUI/AdminBtn";
import AdminSendLoader from "../../AdminUI/AdminSendLoader";
import ph from '../../../../../img/no-photos.jpg'

function TabPanel(props) {
	const {children, value, index, ...other} = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`wrapped-tabpanel-${index}`}
			aria-labelledby={`wrapped-tab-${index}`}
			{...other}
		>
			{value === index && (
				<div>{children}</div>
			)}
		</div>
	);
}

function a11yProps(index) {
	return {
		id: `wrapped-tab-${index}`,
		'aria-controls': `wrapped-tabpanel-${index}`,
	};
}

const AdminGoodsDetail = (props) => {
		const [good, setGood] = useState(null);
		const {history} = props;
		const {goods, categories, loading} = useSelector(state => state.adminGoods);
		const {units} = useSelector(state => state.adminMaterialList);
		const dispatch = useDispatch();

		const [tab, setTab] = React.useState(0);
		const handleChange = (event, newValue) => {
			setTab(newValue);
		};


		const id = props.match.params.id;

		useEffect(() => {
			if (!goods.length) {
				dispatch(adminGoodsFetch())
			} else {
				let good = goods.find(good => {
					return good.id === +id || good.id === id
				});
				if (good) {
					setGood(good)
				} else {
					history.push('/admin/goods')
				}
			}
		}, [goods, dispatch, id, history]);

		useEffect(() => {
			if (!units.length) {
				dispatch(fetchAdminMaterialListUnits())
			}
		}, [units, dispatch]);


		const editGood = () => {
			dispatch(setAdminGoodsGoodProperty(good.id, true, 'edit'));
			dispatch(setAdminGoodsGoodProperty(good.id, true, 'edited'));
		};

		const saveGood = () => {
			dispatch(setAdminGoodsGoodProperty(good.id, false, 'edit'));
			dispatch(saveAdminGoodsGood(good.id))
		};
		const restoreGood = () => {
			dispatch(setAdminGoodsGoodProperty(good.id, false, 'deleted'));
			dispatch(setAdminGoodsGoodProperty(good.id, false, 'edit'));
		};

		const deleteGood = () => {
			// dispatch(setAdminGoodsGoodProperty(good.id, false, 'edit'));
			dispatch(setAdminGoodsGoodProperty(good.id, true, 'edited'));
			dispatch(setAdminGoodsGoodProperty(good.id, true, 'deleted'));
		};

		const deletePicture = (id) => {
			if (good) {
				let pictures = good.pictures.filter(picture => picture.id !== id);
				dispatch(setAdminGoodsGoodProperty(good.id, true, 'edit'));
				dispatch(setAdminGoodsGoodProperty(good.id, pictures, 'pictures'));
				dispatch(setAdminGoodsGoodProperty(good.id, true, 'edited'));
			}
		}

		const goBackHandler = () => {
			dispatch(setAdminGoodsGoodProperty(good.id, false, 'edit'));
			props.history.goBack()
		};

		const onDrop = useCallback(acceptedFiles => {
			acceptedFiles.forEach((file) => {
				const id = cuid()
				const reader = new FileReader();
				reader.onload = () => {
					if (reader.result) {
						let value = {
							id,
							name: file.name,
							src: reader.result,
							encoding: "base64"
						};
						if (good) {
							dispatch(setAdminGoodsGoodProperty(good.id, true, 'edit'));
							dispatch(setAdminGoodsGoodProperty(good.id, [...good.pictures, value], 'pictures'));
							dispatch(setAdminGoodsGoodProperty(good.id, true, 'edited'));
						}
					}
				};
				reader.readAsDataURL(file)
			})


		}, [good, dispatch]);
		const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

		useEffect(() => {
			if (!categories.length) {
				dispatch(adminGoodsFetchCategories())
			}
		}, [categories])


		if (!good) {
			return <AdminSendLoader/>
		}
		return (
			<>
				{
					loading && <AdminSendLoader/>
				}
				{
					!!good &&
					<>
						<Button className='mb-3' variant='contained' color='primary'
								onClick={goBackHandler}>Назад</Button>
						<div className='row'>
							<div className="col-3 pl-0">
								<Paper className='p-3'>
									<img src={!!good.pictures.length ? good.pictures[0].src : ph} alt={good.name}
										 className='img-fluid'/>
								</Paper>

								<div className='AdminGoodsDetail__thumbs'>
									{
										good.pictures.map(pic => {
											return (
												<div key={pic.id} className='AdminGoodsDetail__thumb'>
                                        <span className='AdminGoodsDetail__delete'
											  onClick={() => deletePicture(pic.id)}><HighlightOffIcon/></span>
													<img key={pic.id} src={pic.src} className='img-fluid mx-1'
														 alt="thumb-good"/>

												</div>
											)
										})
									}
								</div>

								<div
									className={`AdminGoodsDetail__dropzone ${isDragActive ? 'active' : '2'}`}
									{...getRootProps()}>
									<input {...getInputProps()} />
									{
										isDragActive ?
											<p>Бросьте файл ...</p> :
											<p>Для загрузки перетащите файлы сюда или кликните по этом полю
												Допустимые форматы: jpg, png. Размеры файла до 5 МБ
											</p>
									}
								</div>
							</div>
							<div className="col-9 px-0">

								{
									!good.edit
									&& <Paper className='p-3 mt-2 w-100'>
										<div className="d-flex flex-column">
											<Typography variant='subtitle1' className='mb-2'>Название
												товара</Typography>
											<Typography variant='h6' className='mb-2'>{good.name}</Typography>
										</div>
									</Paper>
								}

								<Paper className="d-flex align-items-center justify-content-between mt-2">
									{

										<div className='px-3 d-flex align-items-center'>
											{
												!good.isNew &&
												<>
													<Typography variant='subtitle1' className='mr-3'>ID:</Typography>
													<Typography variant='h6'>{good.id}</Typography>
												</>
											}
										</div>
									}
									<div className='px-3 p-2 d-flex align-items-center justify-content-between'>
										<div className='d-flex align-items-center'>
											<Typography variant='subtitle1' className='mr-3'>Активность:</Typography>
											<Typography variant='h6'
														className={good.active ? 'text-success' : 'text-danger'}>{good.active ? 'Активен' : 'Неактивен'}</Typography>
										</div>
										<div className='d-flex'>
											{
												good.active
													? <AdminBtn tip='Деактивировать' type='clear'
																onClick={() => dispatch(setAdminGoodsGoodActivity(good.id, 'N'))}/>
													: <AdminBtn tip='Активировать' type='check'
																onClick={() => dispatch(setAdminGoodsGoodActivity(good.id, 'Y'))}/>
											}
										</div>
									</div>
								</Paper>

								{
									good.edit && !good.deleted
										?
										<>
											<Paper className='p-3 mt-2 w-100'>

												<div className="d-flex flex-column">
													<Typography variant='subtitle1'
																className='mr-3 w-20'>Название:</Typography>
													<TextField value={good.name || ''}
															   variant='outlined'
															   className='w-100'
															   onChange={(evt) => dispatch(setAdminGoodsGoodProperty(good.id, evt.target.value, 'name'))}/>
												</div>

												<div className="d-flex flex-column">
													<Typography variant='subtitle1'
																className='mr-3 w-20'>Раздел:</Typography>

													{
														!!categories.length &&
														<FormControl variant="outlined" className='w-100'>
															<Select
																size={5}
																value={good.section && good.section.map(section => section.id) || []}
																multiple
																onChange={(evt) => dispatch(setAdminGoodsGoodProperty(good.id, evt.target.value.map(id => ({id})), 'section'))}
															>
																{categories.map((category) => {
																	return (
																		<MenuItem
																			key={category.id}
																			value={category.id}>
																			{category.name}
																		</MenuItem>
																	)
																})}
															</Select>
														</FormControl>
													}
												</div>

												<div className="d-flex flex-column">
													<Typography variant='subtitle1'
																className='mr-3 mt-2'>Описание:</Typography>
													<TextField value={good.fullName || ''}
															   variant='outlined'
															   className='w-100'
															   multiline
															   rowsMax={4}
															   onChange={(evt) => dispatch(setAdminGoodsGoodProperty(good.id, evt.target.value, 'fullName'))}/>
												</div>

												<div className="d-flex flex-column">
													<Typography variant='subtitle1'
																className='mr-3 mt-2'>Характеристики:</Typography>
													<TextField value={good.description || ''}
															   variant='outlined'
															   className='w-100'
															   multiline
															   rowsMax={4}
															   onChange={(evt) => dispatch(setAdminGoodsGoodProperty(good.id, evt.target.value.replace('&lt;br /&gt;', ''), 'description'))}/>
												</div>

												<div className="d-flex align-items-center">
													<div className="d-flex flex-column w-25 pr-3">
														<Typography variant='subtitle1'
																	className='mr-3 mt-2'>Цена поставщика:</Typography>
														<TextField value={good.provider_price || ''}
																   variant='outlined'
																   className='w-100'
																   multiline
																   onChange={(evt) => dispatch(setAdminGoodsGoodProperty(good.id, evt.target.value.replace('&lt;br /&gt;', ''), 'provider_price'))}/>
													</div>
													<div className="d-flex flex-column w-75">
														<Typography variant='subtitle1'
																	className='mr-3 mt-2'>Поставщик:</Typography>
														<TextField value={good.add_description || ''}
																   variant='outlined'
																   className='w-100'
																   multiline
																   onChange={(evt) => dispatch(setAdminGoodsGoodProperty(good.id, evt.target.value.replace('&lt;br /&gt;', ''), 'add_description'))}/>
													</div>
												</div>

											</Paper>
											<Paper className='px-3 p-2 mt-2 d-flex align-items-center'>
												<Typography variant='subtitle1' className='mr-2'>Цена:</Typography>


												<TextField value={good.price}
														   variant='outlined'
														   className='w-15 mr-3'
														   type={'number'}
														   onChange={(evt) => dispatch(setAdminGoodsGoodProperty(good.id, evt.target.value, 'price'))}/>
												<Typography variant='subtitle1' className='mr-3'>руб</Typography>
												<Typography variant='subtitle1' className='mr-3'>за</Typography>
												<TextField value={good.quantity_for_price || ''}
														   variant='outlined'
														   className='w-15 mr-3'
														   onChange={(evt) => dispatch(setAdminGoodsGoodProperty(good.id, evt.target.value, 'quantity_for_price'))}/>


												{
													units.length &&
													<FormControl variant="outlined" className='w-15'>
														<Select
															size={5}
															value={good.unit.trim() || 'Кг'}
															onChange={(evt) => dispatch(setAdminGoodsGoodProperty(good.id, evt.target.value, 'unit'))}
														>
															{units.map(unit => {
																return (
																	<MenuItem
																		key={unit.id}
																		value={unit.web_name}>
																		{parse(unit.web_name)}
																	</MenuItem>
																)
															})}
														</Select>
													</FormControl>
												}


											</Paper>
											<Paper className='px-3 p-2 mt-2 d-flex align-items-center'>
												<Typography variant='subtitle1' className='mr-3 w-20'>Вес (упаковки),
													кг:</Typography>
												<TextField value={good.weight || ''}
														   variant='outlined'
														   className='w-20'
														   onChange={(evt) => dispatch(setAdminGoodsGoodProperty(good.id, evt.target.value, 'weight'))}/>

											</Paper>
											<Paper className='px-3 p-2 mt-2 d-flex align-items-center'>
												<Typography variant='subtitle1' className='mr-3 w-20'>Объем (упаковки),
													м<sup>3</sup>:</Typography>
												<TextField value={good.volume || ''}
														   variant='outlined'
														   className='w-20'
														   onChange={(evt) => dispatch(setAdminGoodsGoodProperty(good.id, evt.target.value, 'volume'))}/>
											</Paper>
											<Paper className='px-3 p-2 mt-2 d-flex align-items-center'>
												<Typography variant='subtitle1' className='mr-3 w-20'>Поправочный
													коэффициент:</Typography>
												<TextField value={good.package_coefficient}
														   variant='outlined'
														   className='w-20'
														   onChange={(evt) => dispatch(setAdminGoodsGoodProperty(good.id, evt.target.value, 'package_coefficient'))}/>
											</Paper>
											<Paper className='px-3 p-2 mt-2 d-flex align-items-center'>
												<Typography variant='subtitle1' className='mr-3 w-20'>Измерение товара в
													ед.изм. нормы:</Typography>
												<TextField value={good.pack}
														   variant='outlined'
														   className='w-20'
														   onChange={(evt) => dispatch(setAdminGoodsGoodProperty(good.id, evt.target.value, 'pack'))}/>
											</Paper>
											<Paper className='px-3 p-2 mt-2 d-flex align-items-center'>
												<Typography variant='subtitle1' className='mr-3 w-20'>Количество товара в
													одной упаковке:</Typography>
												<TextField value={good.quantity_in_pack}
														   variant='outlined'
														   className='w-20'
														   onChange={(evt) => dispatch(setAdminGoodsGoodProperty(good.id, evt.target.value, 'quantity_in_pack'))}/>
											</Paper>
										</>
										:
										<>
											<Paper className='px-3 p-2 mt-2 d-flex align-items-center'>
												<Typography variant='subtitle1' className='mr-3'>Раздел:</Typography>
												<List className="d-flex">

													{
														!!good.section && good.section.map(section => (
															<ListItem key={section.id}>
																{section.name}
															</ListItem>))
													}
												</List>
											</Paper>

											<Paper className='p-3 mt-2 w-100'>
												<div className="d-flex flex-column">
													<Typography variant='subtitle1' className='mb-2'>Описание:</Typography>
													<Typography variant='subtitle1'
																className='mb-3'>{good.fullName}</Typography>
												</div>
											</Paper>

											<Paper className='p-3 mt-2 w-100'>
												<div className="d-flex flex-column">
													<Typography variant='subtitle1'
																className='mb-2'>Характеристики:</Typography>
													<Typography variant='subtitle1'
																className='mb-3'>{good.description || ''}</Typography>
												</div>
											</Paper>

											<Paper className='p-3 mt-2 w-100'>
												<div className="d-flex">
													<div className="w-25">
														<Typography variant='subtitle1'
																	className='mb-2'>Цена поставщика:</Typography>
														<Typography variant='subtitle1'
																	className='mb-3'>{good.provider_price || ''}</Typography>
													</div>
													<div className="w-75">
														<Typography variant='subtitle1'
																	className='mb-2'>Поставщик:</Typography>
														<Typography variant='subtitle1'
																	className='mb-3'>{good.add_description || ''}</Typography>
													</div>
												</div>
											</Paper>


											<Paper className='px-3 p-2 my-2 d-flex align-items-center'>
												<Typography variant='subtitle1' className='mr-2'>Цена:</Typography>
												<Typography variant='h6'
															className="mr-2">{(+good.price).toFixed(2)} руб</Typography>
												<Typography variant='h6'
															className="mr-1">за {good.quantity_for_price}</Typography>
												<Typography variant='h6'>{parse(good.unit.trim())}</Typography>

											</Paper>


											<AppBar position="static" className='rounded'>
												<Tabs value={tab} onChange={handleChange} aria-label="simple tabs example">
													<Tab label="Характеристики" {...a11yProps(0)} />
													<Tab label="Рассчетные данные" {...a11yProps(1)} />
												</Tabs>
											</AppBar>
											<TabPanel value={tab} index={0}>
												<Paper className='px-3 p-2 mt-2 d-flex align-items-center'>
													<Typography variant='subtitle1' className='mr-3 w-20'>Вес
														(упаковки):</Typography>
													<Typography variant='h6'>{good.weight} кг</Typography>
												</Paper>
												<Paper className='px-3 p-2 mt-2 d-flex align-items-center'>
													<Typography variant='subtitle1'
																className='mr-3 w-20'>Объем (упаковки):</Typography>
													<Typography variant='h6'>{good.volume} м<sup>3</sup> </Typography>
												</Paper>
											</TabPanel>
											<TabPanel value={tab} index={1}>
												<Paper className='px-3 p-2 mt-2 d-flex align-items-center'>
													<Typography variant='subtitle1'
																className='mr-3 w-20'>Поправочный коэффициент:</Typography>
													<Typography variant='h6'>{good.package_coefficient}</Typography>
												</Paper>
												<Paper className='px-3 p-2 mt-2 d-flex align-items-center'>
													<Typography variant='subtitle1'
																className='mr-3 w-20'>
														Измерение товара в ед.изм. нормы:</Typography>
													<Typography
														variant='h6'>{good.pack}</Typography>
												</Paper>
												<Paper className='px-3 p-2 mt-2 d-flex align-items-center'>
													<Typography variant='subtitle1'
																className='mr-3 w-20'>
														Количество в одной упаковке:</Typography>
													<Typography
														variant='h6'>{good.quantity_in_pack}</Typography>
												</Paper>
											</TabPanel>
										</>
								}
								<Paper className='px-3 p-2 mt-2 d-flex align-items-center'>
									{good.edit && <AdminBtn type='save' text='Сохранить' onClick={saveGood}/>}
									{!good.edit && !good.deleted &&
									<AdminBtn type='edit' text='Редактировать' onClick={editGood}/>}
									{
										good.deleted
											? (
												<>
													<AdminBtn type='restore' text='Восстановить' onClick={restoreGood}/>
													<AdminBtn type='check' text='Подтвердить удаление' color='secondary'
															  onClick={saveGood}/>
												</>
											)
											: <AdminBtn type='delete' text='Удалить' onClick={deleteGood}/>
									}
								</Paper>
							</div>
						</div>
					</>
				}

			</>
		);
	}
;

export default memo(AdminGoodsDetail);

