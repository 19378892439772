import React from 'react';
import styled from 'styled-components';

const StyledTable = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 100%;
  padding: 15px 0;
`;

const ChartsTable = ({ children }) => {
    return (
        <StyledTable>
            {children}
        </StyledTable>
    );
};

export default ChartsTable;
