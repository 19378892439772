import React from 'react';

export const adminUserListIcons = {
	master: <svg className='ml-2' enableBackground="new 0 0 512 512" height="22" viewBox="0 0 512 512"
				 width="22"
	>
		<path
			d="m135.5 190.667h51.945v-190.667h-92.111c-8.285 0-15 6.716-15 15v120.5c0 30.419 24.747 55.167 55.166 55.167z"/>
		<path
			d="m256 130.666c14.924 0 28.548 6.36 38.557 17.546v-148.212h-77.111v148.21c10.007-11.185 23.63-17.544 38.554-17.544z"/>
		<path
			d="m431.666 135.5v-120.5c0-8.284-6.717-15-15-15h-92.109v190.667h51.943c30.418 0 55.166-24.748 55.166-55.167z"/>
		<path
			d="m431.218 317.732c7.399-7.213 10.102-16.098 7.412-24.376s-10.097-13.878-20.323-15.364l-88.879-12.913c-4.294-.625-10.867-5.4-12.787-9.291l-39.748-80.538c-4.572-9.269-12.188-14.584-20.893-14.584-8.706 0-16.321 5.315-20.893 14.582l-39.75 80.542c-1.918 3.89-8.49 8.664-12.784 9.289l-88.88 12.913c-10.225 1.485-17.633 7.085-20.322 15.362-2.69 8.277.01 17.162 7.41 24.378l64.312 62.691c3.107 3.029 5.617 10.754 4.883 15.031l-15.182 88.52c-1.388 8.098.293 15.457 4.732 20.723 3.971 4.71 9.672 7.304 16.052 7.304 4.265 0 8.645-1.167 13.021-3.468l79.494-41.791c1.385-.728 4.189-1.506 7.905-1.506s6.52.777 7.902 1.505l79.496 41.792c4.377 2.301 8.758 3.468 13.021 3.468 6.381 0 12.08-2.594 16.052-7.304 4.439-5.266 6.12-12.625 4.731-20.724l-15.182-88.518c-.732-4.276 1.778-12.004 4.884-15.032z"/>

	</svg>
};