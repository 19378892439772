import React from 'react';

const RadioTabs = ({tabs, onclick, active, title = '', variant = '', className = ''}) => {
	return (
		<div className={`UI__RadioTabs ${className}`}>
			<div className={`UI__RadioTabs-title ${variant ? 'UI__RadioTabs-title_' + variant : ''}`}>
				{title}
			</div>
			<div className="UI__RadioTabs-tabs">
				{
					tabs.map((tab) => {
						return <div key={tab.id}
									onClick={() => onclick(tab.id)}
									className={`UI__RadioTabs-tab ${tab.id === active ? 'UI__RadioTabs-tab_active' : ''}`}>
							{tab.title}
						</div>
					})
				}
			</div>
		</div>
	);
};

export default RadioTabs;