import React from 'react';
import {ListItem} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import parse from "html-react-parser";
import AdminActive from "../AdminUI/AdminActive";
import AdminBtn from "../AdminUI/AdminBtn";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";


const AdminMaterialListItem = ({material, num, saveHandler, deleteHandler, categories, units}) => {

    const [state, setState] = React.useState({...material})

    const inputHandler = (value, property) => {
        state[property] = value
        setState({...state})
    }

    const saveMaterialHandler = () => {
        state.edit = false
        state.edited = true
        saveHandler(state)

    }

    const editMaterialHandler = () => {
        state.edit = true
        saveHandler(state)
    }

    const activeMaterialHandler = (active = true) => {
        state.edited = true
        state.active = active
        saveHandler(state)
    }
    const deleteMaterialHandler = () => {
        deleteHandler(state)
    }

    const restoreMaterialHandler = () => {
        state.deleted = false
        saveHandler(state)
    }

    // console.log(state)

    return (
        <ListItem className="Admin_material-list-item">
            <Paper className='w-100 h-100 py-1'>
                <div
                    className="Admin_material-list-content d-flex align-items-center border-top border-bottom border-gray">
                    {/*  1  */}
                    <Typography variant="subtitle1"
                                className="col p-0 m-0 d-flex justify-content-center">
                        {num}. {!material.isNew && <> [{material.id}]</>}
                    </Typography>
                    {/*  3  */}
                    {
                        !!material.edit ?
                            <TextField
                                variant="outlined"
                                className='Admin_material-list-item__input col-3 edit_btn__js mr-1'
                                multiline
                                onChange={evt => inputHandler(evt.target.value, 'name')}
                                value={state.name || ''}
                            />
                            :
                            <Typography variant="subtitle1"
                                        className="col-3 p-0 m-0 Admin_material-list-item__name">{state.name}</Typography>
                    }
                    {/*  1  */}
                    {
                        !!material.edit && units ?
                            <FormControl variant="outlined"
                                         className='Admin_material-list-item__select col mx-1'>
                                <Select
                                    className=""
                                    size={5}
                                    value={state.unit}
                                    onChange={evt => inputHandler(evt.target.value, 'unit')}
                                >
                                    {units.map(unit => {
                                        return (

                                            <MenuItem
                                                key={unit.id}
                                                value={unit.web_name}>
                                                {parse(unit.web_name)}
                                            </MenuItem>
                                        )
                                    })}

                                </Select>
                            </FormControl>
                            :
                            <Typography variant="subtitle1"
                                        className="col p-0 m-0 d-flex justify-content-center">{parse(state.unit)}</Typography>
                    }
                    {/*  1  */}
                    {
                        !!material.edit ?
                            <TextField
                                variant="outlined"
                                type="number"
                                className='Admin_material-list-item__input col edit_btn__js mx-1'
                                onChange={evt => inputHandler(evt.target.value, 'pack')}
                                value={state.pack || ''}
                            />
                            :
                            <Typography variant="subtitle1"
                                        className="col p-0 m-0 d-flex justify-content-center">{state.pack}</Typography>
                    }
                    {
                        !!material.edit ?
                            <TextField
                                variant="outlined"
                                type="number"
                                className='Admin_material-list-item__input col edit_btn__js mx-1'
                                onChange={evt => inputHandler(evt.target.value, 'price')}
                                value={state.price || ''}
                            />
                            :
                            <Typography variant="subtitle1"
                                        className="col p-0 m-0 d-flex justify-content-center">{state.price}</Typography>
                    }
                    {/*  1  */}

                    {/*  3  */}
                    {
                        !!material.edit ?
                            <FormControl variant="outlined"
                                         className='Admin_material-list-item__select col-3 mx-1'>
                                <Select
                                    className=""
                                    size={5}
                                    multiple
                                    value={state.categories}
                                    onChange={evt => inputHandler(evt.target.value, 'categories')}
                                >
                                    {categories.map(category => {
                                        return (

                                            <MenuItem
                                                key={category.id}
                                                value={category.id}>
                                                {category.name}
                                            </MenuItem>
                                        )
                                    })}

                                </Select>
                            </FormControl>
                            :
                            <Typography variant="subtitle1"
                                        className="col-3 p-0 m-0 d-flex justify-content-center flex-column">
                                {state.categories.map((categoryId) => {
                                    let category = categories.find(category => category.id === categoryId)
                                    if (category) {
                                        return (<span key={category.id}>{category.name}</span>)
                                    }
                                    return null
                                })}
                            </Typography>
                    }
                    {/*   2  */}
                    <div className="col-2  d-flex justify-content-end">

                        <AdminActive active={state.active} edited={state.edited}/>

                        {
                            material.edit
                                ?
                                <AdminBtn
                                    tip="Сохранить материал"
                                    type="save"
                                    onClick={saveMaterialHandler}
                                />
                                :
                                <AdminBtn
                                    tip="Редактировать материал"
                                    type="edit"
                                    onClick={editMaterialHandler}
                                />
                        }


                        {
                            material.active ?
                                <AdminBtn tip="Деактивировать материал" type="clear"
                                          onClick={() => activeMaterialHandler(false)}
                                />
                                :
                                <AdminBtn tip="Активировать материал" type="check"
                                          onClick={() => activeMaterialHandler(true)}
                                />
                        }

                        {
                            material.deleted
                                ?
                                <AdminBtn tip="Восстановить специальность" type="restore"
                                          onClick={restoreMaterialHandler}
                                />
                                :
                                <AdminBtn tip="Удалить специальность" type="delete"
                                          onClick={deleteMaterialHandler}
                                />
                        }

                    </div>
                </div>
            </Paper>
        </ListItem>
    );
};

export default AdminMaterialListItem;