import {
	ADD_ADMIN_GOODS_CATEGORY,
	ADD_ADMIN_GOODS_GOOD,
	ADMIN_GOODS_FETCH_CATEGORIES_SUCCESS,
	ADMIN_GOODS_FETCH_ERROR,
	ADMIN_GOODS_FETCH_START,
	ADMIN_GOODS_FETCH_SUCCESS,
	DELETE_ADMIN_GOODS_CATEGORY,
	SET_ADMIN_GOODS_CATEGORY_PROPERTY,
	SET_ADMIN_GOODS_GOOD_PROPERTY, SET_ADMIN_GOODS_PROPERTY
} from "./actionTypes";
import axios from "../../rest/axios";
import qs from 'qs'
import {store} from '../../index'


export function adminGoodsFetch() {
	return async dispatch => {
		let token = localStorage.getItem('token')
		if (token) {
			try {
				dispatch(adminGoodsFetchStart());
				const goods = await axios.post('/admin/goods/', qs.stringify({token}));
				dispatch(adminGoodsFetchSuccess(goods.data.data))
			} catch (e) {
				dispatch(adminGoodsFetchError(e))
			}
		}
	}
}

export function adminGoodsFetchCategories() {
	return async dispatch => {
		let token = localStorage.getItem('token')
		if (token) {
			try {
				dispatch(adminGoodsFetchStart());
				const categories = await axios.post('/admin/goodsCategories/', qs.stringify({token}));
				dispatch(adminGoodsFetchCategoriesSuccess(categories.data.data))
			} catch (e) {
				dispatch(adminGoodsFetchError(e))
			}
		}
	}
}

function adminGoodsFetchStart() {
	return {
		type: ADMIN_GOODS_FETCH_START
	}
}

function adminGoodsFetchSuccess(goods) {
	return {
		type: ADMIN_GOODS_FETCH_SUCCESS,
		payload: {
			goods
		}
	}
}

function adminGoodsFetchCategoriesSuccess(categories) {
	return {
		type: ADMIN_GOODS_FETCH_CATEGORIES_SUCCESS,
		payload: {
			categories
		}
	}
}

function adminGoodsFetchError(error) {
	return {
		type: ADMIN_GOODS_FETCH_ERROR,
		payload: {
			error
		}
	}
}

export function setAdminGoodsGoodProperty(goodId, value, property) {

	return {
		type: SET_ADMIN_GOODS_GOOD_PROPERTY,
		payload: {
			goodId, value, property
		}
	}
}

export function setAdminGoodsProperty(value, property) {

	return {
		type: SET_ADMIN_GOODS_PROPERTY,
		payload: {
			value, property
		}
	}
}

export function addAdminGoodsGood(sectionId) {
	return {
		type: ADD_ADMIN_GOODS_GOOD,
		payload: {
			sectionId
		}
	}
}

export function addAdminGoodsCategory(id) {
	let category = {
		id,
		name: '',
		isNew: true,
		edit: true,
		active: true,
	};

	return {
		type: ADD_ADMIN_GOODS_CATEGORY,
		payload: {
			category
		}
	}
}

export function setAdminGoodsCategoryProperty(categoryId, value, property) {
	return {
		type: SET_ADMIN_GOODS_CATEGORY_PROPERTY,
		payload: {
			categoryId, value, property
		}
	}
}

export function deleteAdminGoodsCategory(categoryId) {
	return {
		type: DELETE_ADMIN_GOODS_CATEGORY,
		payload: {
			categoryId
		}
	}
}

export function saveAdminGoodsCategory(id) {
	return async dispatch => {
		const categories = store.getState().adminGoods.categories;
		let category = categories.find(category => category.id === id);
		// category = {...category};
		let token = localStorage.getItem('token');
		if (token) {
			try {
				dispatch(adminGoodsFetchStart());
				await axios.post('/admin/categories/collection/', qs.stringify({
					token,
					items: [category]
				}));
				dispatch(adminGoodsFetchCategories())
			} catch (e) {
				dispatch(adminGoodsFetchError(e))
			}
		}

	}
}


export function saveAdminGoodsGood(goodId) {
	return async dispatch => {
		const goods = store.getState().adminGoods.goods
		let good = goods.find(good => good.id === goodId);
		let prices = [
			{
				code: 'BASE',
				value: good.price
			}
		];

		good = {...good, prices, sectionId: good.section.map(section => section.id)};
		let token = localStorage.getItem('token');
		if (token) {
			try {
				dispatch(adminGoodsFetchStart());
				await axios.post('/admin/products/collection/', qs.stringify({
					token,
					items: [good]
				}));
				dispatch(adminGoodsFetch())
			} catch (e) {
				dispatch(adminGoodsFetchError(e))
			}
		}

	}
}

export function setAdminGoodsGoodActivity(goodId, active) {
	return async dispatch => {
		const goods = store.getState().adminGoods.goods
		const good = goods.find(good => good.id === goodId);
		let token = localStorage.getItem('token')
		if (token) {
			try {
				dispatch(adminGoodsFetchStart());
				await axios.post('/admin/products/setActivity/', qs.stringify({
					active,
					token,
					id: good.id
				}));
				dispatch(adminGoodsFetch())
			} catch (e) {
				dispatch(adminGoodsFetchError(e))
			}
		}

	}
}