import React from 'react'
import { connect, useDispatch } from 'react-redux'
import GreenButton from '../../../components/UI/GreenButton/GreenButton'
import InstantComplete from './InstanteComplete/InstantComplete'
import ScheduleAndSupport from './ScheduleAndSupport/ScheduleAndSupport'
import WorkControl from './WorkControl/WorkControl'
import BgBlot from '../../ui/BgBlot/BgBlot'
import blot from '../../../img/advantagesbg.png'
import cn from './Advantages.module.scss'
import { modalOpenModal } from '../../../store/reducers/modalReducer'
import yandexGoal from '../../../utils/yandexGoal'
import { setActiveNavAnchor } from '../../../store/actions/navActions'
import { CALCULATOR_ANCHOR } from '../../../config'

const Advantages = (props) => {
  const dispatch = useDispatch();
  const openModal = () => {
    yandexGoal('GET_MORE_BUTTON')
    props.modalOpenModal({
      title: 'Узнать подробнее',
      buttonText: 'Узнать подробнее',
      activeFields: ['name', 'phone', 'comment'],
      goal: 'GET_MORE_FORM'
    })
  }

  const goToAnchor = (anchor) => {
    console.log(1);
		dispatch(setActiveNavAnchor(anchor))
	};
  return (
    <>
      <InstantComplete/>
      <WorkControl/>
		  <BgBlot left={0} bottom='200px' image={blot} width='391px' height='486px'/>
      <ScheduleAndSupport/>
      <GreenButton className={cn.button} onClick={() => goToAnchor(CALCULATOR_ANCHOR)}>узнать подробнее</GreenButton>
    </>
  )
}

const mapStateToProps = (state) => ({
  
})
const mapDispatchToprops = {
  modalOpenModal
}
export default connect(mapStateToProps, mapDispatchToprops)(Advantages)