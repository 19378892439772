import React from 'react';

const CountSliderArrow = ({position, onClick}) => {
	return (
		<div onClick={onClick}>
			{
				position === 'previous'
					? (
						<svg width="11" height="21" viewBox="0 0 11 21" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M10.5 19.9976L1 10.4976L10.5 0.997558" stroke="#5D20C1" strokeLinecap="round"
								  strokeLinejoin="round"/>
						</svg>
					)
					: (
						<svg width="11" height="21" viewBox="0 0 11 21" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M0.5 0.997559L10 10.4976L0.5 19.9976" stroke="#5D20C1" strokeLinecap="round"
								  strokeLinejoin="round"/>
						</svg>
					)
			}
		</div>
	);
};

export default CountSliderArrow;