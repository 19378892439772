import React from 'react';
import './ActPDFViewer.scss'
import html2canvas from "html2canvas";
import jsPDF from 'jspdf'
import {Button, Form} from "semantic-ui-react";
import ReactToPrint from 'react-to-print';
import * as moment from 'moment'
import ActKS from "../ActKS/ActKS";
import GetAppIcon from '@material-ui/icons/GetApp';
import PrintIcon from '@material-ui/icons/Print';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';


class ActPDFViewer extends React.Component {

	state = {
		period: {
			startPeriod: "",
			endPeriod: "",
		}
	}

	ActRef = React.createRef();

	printDocument = async () => {
		const input = document.getElementById('divToPrint');

		html2canvas(input)
			.then((canvas) => {
				const imgData = canvas.toDataURL('image/png');
				// const pdf = new jsPDF('landscape');
				const pdf = new jsPDF('portrait');
				pdf.addImage(imgData, 'JPEG', 0, 0);
				// pdf.output('dataurlnewwindow');
				pdf.save("download.pdf");
			})
	}

	closeAct = (e) => {
		if (e.target.classList.contains('ActPDFViewer')) {
			this.props.ksHandler(this.props.ks)
		}
	}


	formSubmitHandler = (evt) => {
		evt.preventDefault();
		// console.log(evt.target.startPeriod.value)
		// console.dir(evt.target.endPeriod.value)
	}

	inputHandler = ({target: {name, value}}) => {

		const period = this.state.period
		period[name] = value

		this.setState({
			period
		})
	}

	componentDidMount = () => {
		this.setState({
			period: {
				startPeriod: moment(this.props.order.startDate, "DD.MM.YYYY").format("YYYY-MM-DD"),
				endPeriod: moment().format("YYYY-MM-DD"),
			}
		})
	}

	render() {
		const {order, ksHandler, ks} = this.props
		const {printDocument, closeAct, formSubmitHandler, inputHandler} = this
		const {period: {startPeriod, endPeriod}, period} = this.state

		// console.log("startPeriod", startPeriod)
		// console.log("endPeriod", endPeriod)
		// console.log("order", order)

		// console.log('ks', ks)


		return (
			<div className="ActPDFViewer" onClick={closeAct}>
				<div className="ActPDFViewer_btns">


					<Form onSubmit={formSubmitHandler}>
						<Form.Field>
							<label>Отчетный период (с)</label>
							<input
								type="date"
								onChange={inputHandler}
								name="startPeriod"
								value={startPeriod}/>
						</Form.Field>
						<Form.Field>
							<label>Отчетный период (по)</label>
							<input
								type="date"
								onChange={inputHandler}
								name="endPeriod"
								value={endPeriod}/>
						</Form.Field>
					</Form>
					<Button className="ActPDFViewer_btn"
							animated='vertical'
							basic inverted color='blue'
							onClick={printDocument}>
						<Button.Content hidden>Скачать</Button.Content>
						<Button.Content visible>
							<GetAppIcon/>
						</Button.Content>
					</Button>
					<ReactToPrint
						trigger={() =>

							<Button className="ActPDFViewer_btn"
									animated='vertical'
									basic inverted color='violet'
							>
								<Button.Content hidden>Распечатать</Button.Content>
								<Button.Content visible>
									<PrintIcon/>
								</Button.Content>
							</Button>

						}
						content={() => this.ActRef.current}
					/>
					<Button className="ActPDFViewer_btn"
							animated='vertical'
							basic inverted color='purple'
							onClick={() => ksHandler(ks)}>
						<Button.Content hidden>Закрыть</Button.Content>
						<Button.Content visible>
							<CancelPresentationIcon/>
						</Button.Content>
					</Button>


				</div>
				<div id="divToPrint" ref={this.ActRef}>
					<ActKS order={order} period={period} ks={ks}/>
				</div>
			</div>
		)
	}

}

export default ActPDFViewer