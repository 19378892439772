import React, {useState} from 'react';
import './ApiDocs.scss';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import logo from '../../img/fwlogo.jpg';
import EndPoints from './ad-components/EndPoints';
import UserStory from './ad-components/UserStory';
import ErrorList from './ad-components/ErrorList';
import ApiStory from './ad-components/ApiStory';
import Page from '../../hoc/Page/Page';

const ApiDocs = ({nav}) => {
    const [page, setPage] = useState(1);

    return (
        <Page title="Документация по API">
            <div className="ApiDocs container-fluid pb-5">

                <div className="row py-3">
                    <div className="col-4 logo ">
                        <Link to="/" target="_blank">
                            <img className="mx-3" src={logo} alt="logo"/>
                        </Link>
                    </div>
                    <div className="col-8 d-flex align-items-center justify-content-end flex-wrap">
                        {
                            nav.map((link) => (
                                <div key={link.id} className="ApiDocs_tab-btn m-0 mx-3 mb-2">
                                    <button
                                        type="button"
                                        className={`btn btn-outline${link.id === page ? '-info' : '-primary'}`}
                                        onClick={() => setPage(link.id)}
                                    >
                                        {link.name}
                                    </button>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <hr className="border-info"/>
                {/* <div className="row d-flex  justify-content-center" > */}
                {
                    page === 1 && <EndPoints/>
                }
                {
                    page === 2 && <ApiStory/>
                }
                {
                    page === 3 && <ErrorList/>
                }
                {
                    page === 4 && <UserStory/>
                }
                {/* </div> */}
            </div>
        </Page>
    );
};

ApiDocs.propTypes = {
    nav: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapState = (state) => (
    {nav: state.apiDocs.nav}
);

export default connect(mapState)(ApiDocs);
