import React from 'react';
import './ActKSTableHeading.scss'
import parse from 'html-react-parser'

export default ({order, ks}) => {

	let title = ks === 'ks2' ? 'АКТ<br/>О ПРИЕМКЕ ВЫПОЛНЕННЫХ РАБОТ' : 'СПРАВКА<br/>О СТОИМОСТИ ВЫПОЛНЕННЫХ РАБОТ'

	const withMaterials = !!order.materials.length;
	const serviceCost = (order.unitPrice * order.serviceValue).toFixed(2)
	const materialsPrice = ((order.totalPrice - order.servicePrice) / order.serviceValue).toFixed(2);
	const materialsCost = (materialsPrice * order.serviceValue).toFixed(2)
	let totalCost = withMaterials ? Number(serviceCost) + Number(materialsCost) : Number(serviceCost)
	totalCost = new Intl.NumberFormat('ru-RU').format(totalCost)
	return (
		<div className="ActKSTableHeading">

			<div className="text-center font-weight-bold w-100 mt-3">
				{parse(title)}
			</div>
			{
				ks === 'ks2' &&
				<div className="row mt-2">
					<div className="col-6">
						Сметная (договорная) стоимость в соответствии с договором подряда (субподряда)
					</div>
					<div className="col-6 row">
						<div className="ActKSTableHeading_sum col-8 text-center border-bottom border-dark">
							<strong>{totalCost}</strong>
						</div>
						<div>
							руб.
						</div>
					</div>
				</div>
			}

		</div>
	);

}

