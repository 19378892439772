import React, {useEffect} from 'react'
import './Worker.scss'
import './WorkerComponents/WorkerComponents.scss'
import {useSelector} from "react-redux"
import {Redirect, useHistory} from "react-router-dom";

import Loader from "../../components/UI/Loader/Loader";
import {getIsLogin, getPersonalLoading, getUser, getUserRole} from "../../store/selectors";
import {ROLE_CLIENT} from "../../config";


const Worker = () => {

    const user = useSelector(getUser);
    const isLogin = useSelector(getIsLogin);
    const loading = useSelector(getPersonalLoading);
    const role = useSelector(getUserRole);
    const history = useHistory();
    // const dispatch = useDispatch();



    useEffect(() => {
        if (isLogin && !loading) {
            if (user.name) {
                history.push('/worker/personal/data')
                console.log('worker histoty push personal')
            } else if (user.id && !user.name) {
                history.push('/worker/create')
                console.log('worker histoty push create')
            }else{
                history.push('/')
            }

        }
    }, [history, user, isLogin, loading]);




    if (role === ROLE_CLIENT) {
        return (
            <Redirect to={"/personal/orders"}/>
        )
    }
    if (!isLogin) {
        return (
            <Redirect to={'/personal'}/>
        )
    }

    return null

};

export default Worker