import React from 'react';

const IndividualWorksCategoriesItem = ({category, subCategories, onClick}) => {
	let categoryName = category.name.trim().split(' ');
	const categoryLastWord = categoryName[categoryName.length -1];
	categoryName = categoryName.slice(0, -1).join(' ');
	return (
		<div className="IndividualWorks__categories-item col-6 col-md-4 col-lg-3"
			 onClick={onClick(category.id, 'category')}>
			<img src={category.picture} alt={category.name}/>
			<div className="IndividualWorks__categories-item-name">
				{categoryName}
				<div className="IndividualWorks__categories-item-name-with-arrow">
					{categoryLastWord}&nbsp;({subCategories.length})
				</div>
			</div>
		</div>
	);
};

export default IndividualWorksCategoriesItem;