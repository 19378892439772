import React from "react";
import { connect } from "react-redux";
import {
  nextStep,
  prevStep, setFormItemValue
} from "../../../../../../../store/actions/estimateActions";
import EstimateButton from "../../../UI/EstimateButton";
import EstimateProcess from "../../Process/EstimateProcess";
import "./Estimate-Step2.scss";
import { useHistory } from 'react-router-dom';



const Step2 = (props) => {
  const { setFormItemValue, formcontrols, nextStep, prevStep } = props;

  const { push } = useHistory();

  const createOrderHandler = () => {
    push('/complex-confirm');
  };

  const stepIsValid =
    formcontrols.startDate.value !== "" && formcontrols.termOfWork.value !== "";

  return (
    <>
    <EstimateProcess/>
      <div className="complex-body">
      </div>
      <div className="complex-footer mt-5">
        <div className="row">
          <div className="col-2 estimate-footer-item pr-0">
            <EstimateButton
              className="back"
              back={true}
              onClick={prevStep}
              text="Назад"
            />
          </div>
          {/*<div className="col-10 estimate-footer-item pl-0">*/}
          {/*  <EstimateButton*/}
          {/*    onClick={createOrderHandler}*/}
          {/*    noIcon*/}
          {/*    // disabled={!stepIsValid}*/}
          {/*    text="Оформить заказ"*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  formcontrols: state.estimateOrder.formControls,
});

const actions = {
  setFormItemValue,
  nextStep,
  prevStep,
};

export default connect(mapStateToProps, actions)(Step2);
