import React, {useEffect, useState} from 'react';
import './WorkerAuthForm.scss'
import {connect} from "react-redux";
import {validateControl} from "../../../form/formFramework";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {Typography} from "@material-ui/core";
import {
	setWorkerPersonalProperty,
	workerPersonalLogin,
	workerPersonalReg
} from "../../../store/actions/workerPersonalActions";
import {WorkerHomeLink, WorkerAuthFooter} from "../WorkerComponents";


import authBG from "../../../img/auth_bg.jpg";
import personalBG from "../../../img/remont.jpg";
import Switch from "@material-ui/core/Switch";
import {ROLE_CLIENT, ROLE_MASTER} from "../../../config";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const WorkerAuthForm = (props) => {
	const {
		formControls,
		workerPersonalReg,
		setWorkerPersonalProperty,
		authType,
		workerPersonalLogin,
		token,
		error
	} = props;
	const [role, setRole] = useState(ROLE_MASTER);
	const [policy, setPolicy] = useState(true);
	const [currentTab, setCurrentTab] = useState(1);
	const [controls, setControls] = useState({...formControls});


	// useEffect(() => {
	//     setCurrentTab(authType)
	//     console.log(au)
	// }, [authType]);

	useEffect(() => {
		return () => {
			setWorkerPersonalProperty(null, 'error')
		}
	}, [setWorkerPersonalProperty]);

	useEffect(() => {
		if (!token) {
			document.querySelector('body').classList.add('auth')
		}
		return () => {
			document.querySelector('body').classList.remove('auth')
		}
	}, [token])

	const tabHandler = () => {
		setCurrentTab(currentTab === 1 ? 2 : 1)
	}

	const inputHandler = (value, property) => {
		//убираем лишние символы
		if (property !== 'name') {
			value = value.replace(/[А-ЯЁа-яё\s]/gi, '')
		}

		let error = validateControl(value, controls[property].validation, currentTab === 1 ? controls.password.value : null)
		controls[property].error = error
		controls[property].valid = !error
		controls[property].value = value
		setControls({...controls})
	}

	const formHandler = (evt) => {
		evt.preventDefault()

		if (currentTab === 1) {
			let formValid = true
			Object.keys(controls).forEach(property => {
				if (role === ROLE_CLIENT) {
					if (property !== 'passwordAuth') {
						let error = validateControl(controls[property].value, controls[property].validation, controls.password.value)
						controls[property].error = error
						controls[property].valid = !error
						formValid = formValid && controls[property].valid
					}
				} else {
					const exclude = ['passwordAuth', 'name', 'phone'];
					if (exclude.indexOf(property) === -1) {
						let error = validateControl(controls[property].value, controls[property].validation, controls.password.value)
						controls[property].error = error
						controls[property].valid = !error
						formValid = formValid && controls[property].valid
					}
				}
			})

			if (formValid) {
				workerPersonalReg(controls.email.value, controls.password.value, role, controls.name.value, controls.phone.value)
			} else {
				setControls({...controls})
			}
		} else {
			let formValid = true
			Object.keys(controls).forEach(property => {
				const exclude = ['password', 'passwordConfirm', 'name', 'phone'];
				if (exclude.indexOf(property) === -1) {
					let error = validateControl(controls[property].value, controls[property].validation, controls.password.value)
					controls[property].error = error
					controls[property].valid = !error
					formValid = formValid && controls[property].valid
				}
			})

			if (formValid) {
				workerPersonalLogin(controls.email.value, controls.passwordAuth.value, role)
			} else {
				setControls({...controls})
			}
		}
	};

	const policyChange = (event) => {
		setPolicy(event.target.checked)
	};

	const roleChange = (event) => {
		setRole(event.target.checked ? ROLE_CLIENT : ROLE_MASTER)
	};

	return (
		<div className='WorkerAuthForm' style={{backgroundImage: `url(${role === ROLE_CLIENT ? authBG : personalBG})`}}>
			<WorkerHomeLink/>
			<div className='WorkerAuthForm_container'>

				<div className='WorkerAuthForm_tabs'>
					<div
						onClick={tabHandler}
						className={currentTab === 1 ? 'active' : ''}>
						Зарегистрироваться
					</div>
					<div
						onClick={tabHandler}
						className={currentTab === 2 ? 'active' : ''}>
						Войти
					</div>
				</div>

				{currentTab === 1
					?
					<form onSubmit={formHandler} autoComplete={'on'}>
						<div className='WorkerAuthForm_name mb-2'>Регистрация</div>
						{
							error && authType === 1 &&
							<Typography className='d-flex justify-content-center ' variant='button'
										color='secondary'>{error}</Typography>
						}

						<div className='d-flex justify-content-center align-items-center'>
							<div className='text-main-4'>Исполнитель</div>
							<Switch
								checked={role === ROLE_CLIENT}
								onChange={roleChange}
								color="primary"
								inputProps={{'aria-label': 'primary checkbox'}}
							/>
							<div className='text-main-4'> Заказчик</div>
						</div>
						{
							role === ROLE_CLIENT
							&& (
								<>
									<TextField
										error={!controls.name.valid}
										label={controls.name.label}
										className='mb-4'
										value={controls.name.value}
										onChange={e => inputHandler(e.target.value, 'name')}
										helperText={`${!controls.name.valid ? controls.name.errorMessage[controls.name.error] : ''}`}
										variant="outlined"
									/>

									<TextField
										error={!controls.phone.valid}
										label={controls.phone.label}
										className='mb-4'
										value={controls.phone.value}
										onChange={e => inputHandler(e.target.value, 'phone')}
										helperText={`${!controls.phone.valid ? controls.phone.errorMessage[controls.phone.error] : ''}`}
										variant="outlined"
									/>
								</>
							)
						}

						<TextField
							error={!controls.email.valid}
							label={controls.email.label}
							className='mb-4'
							value={controls.email.value}
							onChange={e => inputHandler(e.target.value, 'email')}
							helperText={`${!controls.email.valid ? controls.email.errorMessage[controls.email.error] : ''}`}
							variant="outlined"
						/>


						<TextField
							error={!controls.password.valid}
							label="Пароль"
							className='mb-4'
							type='password'
							value={controls.password.value}
							onChange={e => inputHandler(e.target.value, 'password')}
							helperText={`${!controls.password.valid ? controls.password.errorMessage[controls.password.error] : ''}`}
							variant="outlined"
						/>


						{/*{*/}
						{/*    controls.password.valid && controls.password.value &&*/}
						<TextField
							error={!controls.passwordConfirm.valid}
							label="Повторите пароль"
							className='mb-4'
							type='password'
							value={controls.passwordConfirm.value}
							onChange={e => inputHandler(e.target.value, 'passwordConfirm')}
							helperText={`${!controls.passwordConfirm.valid ? controls.passwordConfirm.errorMessage[controls.passwordConfirm.error] : ''}`}
							variant="outlined"
						/>
						{/*}*/}
						<FormControlLabel
							control={
								<Switch
									checked={policy}
									onChange={policyChange}
									color="primary"
									inputProps={{'aria-label': 'primary checkbox'}}
								/>}
							label="Согласен на обработку перс. данных"
							// labelPlacement="bottom"
						/>

						<Button type='submit' variant="contained" color="primary" className='mt-2' disabled={!policy}>
							Зарегистрироваться
						</Button>


					</form>
					:
					<form onSubmit={formHandler} autoComplete='on'>
						<div className='WorkerAuthForm_name mb-2'>Авторизация</div>
						{
							error && authType === 2 &&
							<Typography className='d-flex justify-content-center ' variant='button'
										color='secondary'>{error}</Typography>
						}


						<TextField
							error={!controls.email.valid}
							label="EMAIL"
							className='mb-4'
							value={controls.email.value}
							onChange={e => inputHandler(e.target.value, 'email')}
							helperText={`${!controls.email.valid ? controls.email.errorMessage[controls.email.error] : ''}`}
							variant="outlined"
						/>

						<TextField
							error={!controls.passwordAuth.valid}
							label="Пароль"
							className='mb-4'
							type='password'
							value={controls.passwordAuth.value}
							onChange={e => inputHandler(e.target.value, 'passwordAuth')}
							helperText={`${!controls.passwordAuth.valid ? controls.passwordAuth.errorMessage[controls.passwordAuth.error] : ''}`}
							variant="outlined"
						/>


						<Button type='submit' variant="contained" color="primary" className='mt-2'>
							Войти
						</Button>


					</form>
				}

			</div>
			<WorkerAuthFooter/>
		</div>
	);
};
const mapState = (state) => {
	return {
		formControls: state.auth.formControls,
		token: state.workerPersonal.token,
		error: state.workerPersonal.error,
		authType: state.workerPersonal.authType,
	}
}
const actions = {
	workerPersonalReg,
	workerPersonalLogin,
	setWorkerPersonalProperty
}

export default connect(mapState, actions)(WorkerAuthForm)