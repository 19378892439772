import React from 'react';
import styled from 'styled-components';
import { DATE_WIDTH } from '../config';

const StyledRowContainer = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 1rem 0;
`;

const StyledColumn = styled.div`
  height: 300px;
  width: ${DATE_WIDTH};
  min-width: ${DATE_WIDTH};
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  > div {
    height: ${({ height }) => height}%;
    width: 40px;
    background: #E2E4F1;
    text-align: center;
  }
`;

const ChartsFinanceColumns = ({ total }) => {
    const max = Math.max(...total);
    return (
        <StyledRowContainer>
            {
                total.map((value, idx) => <StyledColumn key={idx} height={(value / max) * 100}>
                    {/*<div>{value}</div>*/}
                    <div />
                </StyledColumn>)
            }
        </StyledRowContainer>
    );
};

export default ChartsFinanceColumns;
