import React from "react";
import cn from "./StepsHowTo.module.css";

const OneStep = ({ item }) => {
  const toTop = item.index % 2 === 0;

  return (
    <li className={`${cn.item} ${toTop ? cn.toTop : ''}`}>
      {toTop && (
        <div className={`${cn.textWrapper} ${cn.textWrapperTop}`}>
          <div className={`${cn.number} ${cn.numberToTop}`}>
          0{item.index}
          </div>
          <div className={cn.text}>{item.text}</div>
          <div className={`${cn.line} ${cn.lineToTop}`}>
            <svg
              width="8"
              height="52"
              viewBox="0 0 8 52"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="4.00003"
                cy="4.00003"
                r="4"
                transform="rotate(-180 4.00003 4.00003)"
                fill="#7B53DA"
              />
              <line
                x1="4.00003"
                y1="5.00003"
                x2="4.00003"
                y2="52"
                stroke="#7B53DA"
                strokeWidth="2"
              />
            </svg>
          </div>
        </div>
      )}
      <div className={cn.center}>
        <div className={cn.numberMobile}>
          0{item.index}
        </div>
      <div
        className={cn.iconWrapper}
        dangerouslySetInnerHTML={{ __html: item.icon }}
      />
      </div>
      {!toTop && (
        <div className={`${cn.textWrapper} ${cn.textWrapperBottom}`}>
          <div className={`${cn.number} ${cn.numberToBottom}`}>
           0{item.index}
          </div>
          <div className={`${cn.line} ${cn.lineToBottom}`}>
            <svg
              width="8"
              height="52"
              viewBox="0 0 8 52"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="4" cy="48" r="4" fill="#7B53DA" />
              <line
                x1="4"
                y1="47"
                x2="4"
                y2="-1.52588e-05"
                stroke="#7B53DA"
                strokeWidth="2"
              />
            </svg>
          </div>
          <div className={cn.text}>{item.text}</div>
        </div>
      )}
    </li>
  );
};

export default OneStep;
