import {
  ESTIMATE_NEXT_STEP,
  ESTIMATE_PREV_STEP,
  ESTIMATE_SET_VALUE,
  ESTIMATE_FILTER_SUBCATEGORIES,
  ESTIMATE_FILTER_CATEGORIES,
  ESTIMATE_FILTER_SERVICES,
  ESTIMATE_CHANGE_SERVICE_ANIMATE_ITEMS,
  ESTIMATE_RESET_VALUE,
} from "../actions/actionTypes";

const initialState = {
  activeCategories: [],
  activeSubCategories: [],
  activeServices: [],
  animateServicesItems: [],
  step: 0,
  selectedCategoryId: null,
};

export default function estimateReducer(state = initialState, action) {
  let step = state.step;
  switch (action.type) {
    case ESTIMATE_SET_VALUE:
      let temp = { ...state };
      temp[action.payload.id] = action.payload.value;
      return { ...temp };
    case ESTIMATE_NEXT_STEP:
      step++;
      return { ...state, step };
    case ESTIMATE_PREV_STEP:
      step--;
      return { ...state, step };
    case ESTIMATE_FILTER_SUBCATEGORIES:
      return {
        ...state,
        activeSubCategories: action.payload.activeSubCategories,
      };
    case ESTIMATE_FILTER_CATEGORIES:
      return {
        ...state,
        activeCategories: action.payload.activeCategories,
      };
    case ESTIMATE_FILTER_SERVICES:
      return {
        ...state,
        activeServices: action.payload.activeServices,
      };
    case ESTIMATE_CHANGE_SERVICE_ANIMATE_ITEMS:
      let tempAnimateServicesItems = state.animateServicesItems;
      const itemIndex = tempAnimateServicesItems.findIndex(
        (t) => t.id === action.payload.item.id
      );
      if (itemIndex === -1) {
        tempAnimateServicesItems.push(action.payload.item);
      } else {
        tempAnimateServicesItems.splice(itemIndex, 1);
      }
      return {
        ...state,
        animateServicesItems: tempAnimateServicesItems,
      };
    default:
      return { ...state };
  }
}
