import { GANTT_CHANGE_POSITION } from '../actions/actionTypes'
import transfromGamttData from '../../helpers/transformGanttData'

const testData = [
  {
    id: 1,
    name: "Раскопка траншеи",
    quantity: 20,
    norm: 1.15,
    time: 10,
    workers: 2,
    in: [],
    out: [2]
  },
  {
    id: 2,
    name: "Заливка бетона",
    quantity: 20,
    norm: 1.3,
    workers: 3,
    time: 15,
    in: [1],
    out: []
  },
]

const initialState = transfromGamttData(testData);

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GANTT_CHANGE_POSITION:
      const item = state.works.find(item => item.id === payload.work);
      return state
    default:
      return state;
  }
}
