import React from 'react';
import styled, { css } from 'styled-components';
import { DATE_WIDTH, ROW_HEIGHT } from '../config';

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  max-width: 100%;
`;
const StyledCell = styled.div`
  width: ${DATE_WIDTH};
  min-width: ${DATE_WIDTH};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F1F2FA;
  height: inherit;
  ${({ total }) => total && css`
    font-weight: 600;
  `}
`;

const ChartsFinanceRow = ({ values, total = false, ...props }) => {
    return (
        <StyledRow {...props}>
            {
                values.map((value, idx) => (
                    <StyledCell
                        key={idx}
                        total={total}
                    >
                        {new Intl.NumberFormat('ru-RU').format(value)}
                    </StyledCell>)
                )
            }
        </StyledRow>
    );
};

export default ChartsFinanceRow;
