import React, {Fragment} from 'react';
import {connect} from "react-redux";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from "@material-ui/core/Button";
import AddIcon from '@material-ui/icons/Add';

import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import cuid from 'cuid'
import {
	addServiceListCategory,
	addServiceListService,
	addServiceListSubCategory,
	deleteServiceListCategory,
	deleteServiceListSubCategory,
	fetchCategoriesList,
	fetchServicesList,
	saveAdminServiceListChanges,
	setServiceListCategoryProperty,
	setServiceListServiceProperty,
	setServiceListSubCategoryProperty
} from "../../../../store/actions/adminServiceListActions";
import LoaderAdmin from "../../../../components/UI/LoaderAdmin/Loader";
import SaveIcon from "@material-ui/icons/Save";
import AdminServiceListCategories from './AdminServiceListCategories';
import AdminServiceListSubCategory from './AdminServiceListSubCategory';

/*
компонент вывода списка работ
*/

class AdminServiceList extends React.Component {

	state = {
		expanded: false,
		expanded2: false,
		expanded3: false,
	}

	handleChange = (panel, level) => (event, isExpanded) => {

		if (!!event.target.tagName) {
			const isEditBtn = event.target.closest('.edit_btn__js')
			if (!isEditBtn) {
				if (level === 1) {
					this.setState({
						expanded: isExpanded ? panel : false
					})
				}
				if (level === 2) {
					this.setState({
						expanded2: isExpanded ? panel : false
					})
				}
				if (level === 3) {
					this.setState({
						expanded3: isExpanded ? panel : false
					})
				}
			}
		}
	}

	editHandler = () => {
		return true
	}

	addCategoryHandler = () => {
		const category = {
			id: cuid(),
			name: '',
			active: false,
			edit: true,
			edited: true,
			isNew: true,
			subCategory: []
		}
		this.props.addServiceListCategory(category)
	}

	addSubCategoryHandler = (categoryId) => {
		const {categories} = this.props;
		let currentCategory = categories.find(category => category.id === categoryId);
		if (currentCategory) {
			const subCategoriesNewAndNoName = currentCategory.subCategory.find(subCategory => !subCategory.name);
			if (!subCategoriesNewAndNoName) {
				const subCategory = {
					id: cuid(),
					name: '',
					active: false,
					edit: true,
					edited: true,
					isNew: true,
				}
				this.props.addServiceListSubCategory(categoryId, subCategory)
				// this.props.setServiceListCategoryProperty(categoryId, true, 'edited')
			}
			// this.setState({
			// 	expanded: categoryId
			// })
			this.setExpandedHandler(categoryId)
		}
	}

	setExpandedHandler = (id, level = '') => {
		this.setState({
			[`expanded${level}`]: id
		})
	}


	componentDidMount = () => {
		if (!this.props.services.length) {
			this.props.fetchServicesList()
		}
		if (!this.props.categories.length) {
			this.props.fetchCategoriesList()
		}
	};


	render() {
		const {expanded, expanded2, expanded3} = this.state
		const {handleChange} = this
		const {categories, services, loading} = this.props
		return (
			<Fragment>
				{
					loading && services.length
						? <LoaderAdmin/>
						: <div className='w-100'>
							<Paper className="mt-2">
								<div className="d-flex p-3 justify-content-end">
									<Button
										className="Admin_btn text mr-2"
										variant="contained"
										color="primary"
										onClick={this.addCategoryHandler}
									>
										<AddIcon
											className='Admin_btn-icon Admin_btn-icon-create mr-1'/>
										<Typography variant="overline" display="block">
											Добавить вид работ
										</Typography>
									</Button>
									<Button
										className="Admin_btn text "
										variant="contained"
										color="primary"
										onClick={this.props.saveAdminServiceListChanges}
									>
										<SaveIcon
											className='Admin_btn-icon Admin_btn-icon-create mr-1'/>
										<Typography variant="overline" display="block">
											Сохранить изменения
										</Typography>
									</Button>
								</div>
							</Paper>

							{
								categories.length > 0 &&
								categories.map((category, category_idx) => {
									return (
										<Accordion key={category.id}
												   TransitionProps={{unmountOnExit: true}}
												   expanded={expanded === category.id}
												   className="mt-2"
												   onChange={handleChange(category.id, 1)}>
											<AccordionSummary
												expandIcon={<ExpandMoreIcon/>}
												aria-controls="panel1bh-content"
												id={category.id}
												className='Admin_categories'
											>
												<AdminServiceListCategories
													category={category}
													idx={category_idx + 1}
													addSubCategoryHandler={this.addSubCategoryHandler}
												/>
											</AccordionSummary>
											<AccordionDetails className=" pl-2 pr-0 py-2 ">
												<AdminServiceListSubCategory category={category}
																			 services={services}
																			 setExpandedHandler={this.setExpandedHandler}
																			 handleChange={this.handleChange}
																			 idx={category_idx + 1}
																			 expanded2={this.state.expanded2}
																			 expanded3={this.state.expanded3}
												/>
											</AccordionDetails>
										</Accordion>
									)
								})
							}

						</div>
				}
			</Fragment>
		);
	}
}

const mapState = (state) => {
	return {
		services: state.adminServiceList.services,
		categories: state.adminServiceList.categories,
		loading: state.adminServiceList.loading,
	}
}

const actions = {
	fetchServicesList,
	fetchCategoriesList,
	addServiceListService,
	setServiceListServiceProperty,
	addServiceListCategory,
	setServiceListCategoryProperty,
	deleteServiceListCategory,
	addServiceListSubCategory,
	setServiceListSubCategoryProperty,
	deleteServiceListSubCategory,
	saveAdminServiceListChanges
}

export default connect(mapState, actions)(AdminServiceList)
