import {
    CLEAR_ORDER,
    GET_CLASSIFIERS_ADMIN_ERROR,
    GET_CLASSIFIERS_ADMIN_START,
    GET_CLASSIFIERS_ADMIN_SUCCESS, GET_LOCATION_OBJECT_CONDITIONS_ERROR,
    GET_LOCATION_OBJECT_CONDITIONS_START, GET_LOCATION_OBJECT_CONDITIONS_SUCCESS,
    ORDER_SET_MATERIAL_QUANTITY,
    ORDER_SET_MATERIALS,
    SET_DEFAULT_MATERIAL_QUANTITY,
    SET_ORDER_FORM_CONTROL_VALUE,
    SET_ORDER_PROPERTY,
    SET_ORDER_TRANCHE,
    VALIDATE_FORM_CONTROLS_ORDER
} from "./actionTypes";
import {store} from "../../index";
import axios from '../../rest/axios';

export function clearOrder() {

    return {
        type: CLEAR_ORDER,
    }
}

export function setOrderProperty(value, property) {
    return {
        type: SET_ORDER_PROPERTY,
        payload: {
            value,
            property
        }
    }
}


export function setOrderFormControlValue(value, property, error = false) {
    return {
        type: SET_ORDER_FORM_CONTROL_VALUE,
        payload: {
            value,
            property,
            error,

        }
    }
}

export function materialQuantityHandler(materialId, quantity) {

    return {
        type: ORDER_SET_MATERIAL_QUANTITY,
        payload: {
            materialId,
            quantity,
        }
    }
}

export function setDefaultMaterialQuantity() {

    return {
        type: SET_DEFAULT_MATERIAL_QUANTITY
    }
}


export function setTranche(count, id) {
    return {
        type: SET_ORDER_TRANCHE,
        count,
        id
    }
}


export function setMaterials(serviceId) {
    let services = store.getState().serviceSelect.services;
    let service = services.find(service => service.id === serviceId);
    let materials = [];
    if (service) {
        if (!!service.items.length) {
            service.items.forEach(item => {
                item.materials.forEach(material => {
                    materials = [...materials, material]
                })
            })
        } else {
            service.materials.forEach(material => {
                materials = [...materials, material]
            })
        }
    }

    return {
        type: ORDER_SET_MATERIALS,
        payload: {
            materials
        }
    }
}


export function validateFormControls(formControlId, error) {
    return {
        type: VALIDATE_FORM_CONTROLS_ORDER,
        formControlId,
        error,
        valid: !error
    }
}


