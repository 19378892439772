import React from 'react';
import './ActKSTopLineLittle.scss'

const ActKSTopLineLittle = (
    {
        name,
        middle,
        cell,
        height,
        topMiddle,
        topCell,
        bottomMiddle,
        bottomCell,
        borderBottomWidth,
        borderTopWidth
    }
) => {
    return (
        <div className="ActKSTopLineLittle" style={{
            height
        }}>
            <div>
                {name}
            </div>
            {
                middle ?
                    <div style={{
                        borderTop: topMiddle ? null : 'none',
                        borderBottom: bottomMiddle ? null : 'none'
                    }}>
                        {middle}
                    </div>
                    : null
            }
            <div style={{
                borderTop: topCell ? null : 'none',
                borderBottom: bottomCell ? null : 'none',
                borderBottomWidth: borderBottomWidth ? '2px' : '1px',
                borderTopWidth: borderTopWidth ? '2px' : '1px'
            }}>
                {cell}
            </div>
        </div>
    );
}

export default ActKSTopLineLittle;