import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { RIGHT_WIDTH } from '../config';
import { useChartsContext } from '../ChartsContext';

const StyledTableRight = styled.div`
  display: flex;
  width: ${RIGHT_WIDTH};
  flex-direction: column;
  overflow-x: auto;
  overflow-y: hidden;
  background: #fff;
`;

const ChartsTableRight = ({ children, table }) => {
    const scrollRef = useRef();
    const { scroll, setScroll } = useChartsContext();
    const scrollHandler = useCallback((evt) => {
        if (table === scroll.table || !scroll.table) {
            setScroll({
                table,
                scrollLeft: evt.target.scrollLeft
            });
        }
    }, [table, scroll]);

    const onLeaveHandler = () => {
        setScroll({
            ...scroll,
            table: null
        });
    };

    useEffect(() => {
        if (scrollRef.current && scroll.table !== table) {
            scrollRef.current.scrollLeft = scroll.scrollLeft;
        }
    }, [scroll, table]);


    return (
        <StyledTableRight
            ref={scrollRef}
            onScroll={scrollHandler}
            onMouseLeave={onLeaveHandler}
        >
            {children}
        </StyledTableRight>
    );
};

export default ChartsTableRight;
