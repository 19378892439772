import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import WorkerPersonalTeam from "../WorkerPersonalTeam/WorkerPersonalTeam";
import WorkerPersonalData from "../WorkerPersonalData/WorkerPersonalData";
import WorkerPersonalRequest from "../WorkerPersonalRequest/WorkerPersonalRequest";
import WorkerPersonalSettings from "../WorkerPersonalSettings/WorkerPersonalSettings";
import WorkerPersonalRequestMasters from "../WorkerPersonalRequestMasters/WorkerPersonalRequestMasters";
import WorkerPersonalDates from "../WorkerPersonalDates/WorkerPersonalDates";
import {WorkerFooter} from "../../WorkerComponents";
import is from "is_js";
import WorkerPersonalOrdersCurrent from '../WorkerPersonalOrders/WorkerPersonalOrdersCurrent';
import WorkerPersonalPayments from '../WorkerPersonalPayments/WorkerPersonalPayments';
import WorkerPersonalPaymentDetail from '../WorkerPersonalPayments/WorkerPersonalPaymentDetail';
import WorkerPersonalOrdersCurrentDetail from '../WorkerPersonalOrders/WorkerPersonalOrdersCurrentDetail';
import WorkerPersonalReports from '../WorkerPersonalReports/WorkerPersonalReports';
import WorkerPersonalReportsDetail from '../WorkerPersonalReports/WorkerPersonalReportsDetail';

const WorkerPersonalContent = () => {

    const isMobile = is.mobile() || is.tablet();
    return (
        <div className='wp__content'>
            <Switch>
                <Route path={'/worker/personal/data'} exact component={WorkerPersonalData}/>
                <Route path={'/worker/personal/team'} component={WorkerPersonalTeam}/>
                <Route path={'/worker/personal/request'} component={WorkerPersonalRequest}/>
                <Route path={'/worker/personal/masters'} component={WorkerPersonalRequest}/>
                <Route path={'/worker/personal/request-masters'} component={WorkerPersonalRequestMasters}/>
                <Route path={'/worker/personal/settings'} component={WorkerPersonalSettings}/>
                <Route path={'/worker/personal/reports/:id'} component={WorkerPersonalReportsDetail}/>
                <Route path={'/worker/personal/reports'} component={WorkerPersonalReports}/>
                <Route path={'/worker/personal/orders-current/:id'} component={WorkerPersonalOrdersCurrentDetail}/>
                <Route path={'/worker/personal/orders-current'} component={WorkerPersonalOrdersCurrent}/>
                <Route path={'/worker/personal/dates'} component={WorkerPersonalDates}/>
                <Route path={'/worker/personal/payment/:id'} component={WorkerPersonalPaymentDetail}/>
                <Route path={'/worker/personal/payment'} component={WorkerPersonalPayments}/>
                <Redirect to={'/worker/personal/data'}/>
            </Switch>
            {
                isMobile && <WorkerFooter/>
            }
        </div>
    );
};

export default WorkerPersonalContent;