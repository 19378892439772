import React, {Fragment} from 'react'
import './ChatMessages.scss'
import * as moment from "moment";

export default ({messages, profile}) => {

    let lastMsg = 'msg-in' // чье сообщение последние, по умолчанию оппонента

    return (
        <Fragment>
            {
                Object.keys(messages).map((key, idxKey) => {
                    const group = messages[key]
                    const lastGroup = Object.keys(messages).length === idxKey + 1

                    return (
                        <Fragment key={key}>
                            <div
                                className="ChatMessages">{moment(key).format('DD.MM.YYYY')}</div>
                            {
                                group.map((msg, idx) => {
                                    // console.log(msg)

                                    const classes = ['ChatMessages_msg']

                                    let msgClass = profile.user.id === msg.uid ? 'msg-out' : 'msg-in'
                                    classes.push(msgClass)

                                    if (lastMsg !== msgClass) {
                                        classes.push('msg-new') // сообщение опонента, следующее за своим
                                    }

                                    if (lastGroup && !group[idx + 1]) {
                                        classes.push('msg-last') // самое последнее сообщение
                                    }

                                    if (msg.unread) {
                                        classes.push('msg-urd')
                                    }

                                    lastMsg = msgClass

                                    return (
                                        <div
                                            className={classes.join(' ')}
                                            key={msg.timestamp}>
                                            <div>
                                                {
                                                    msg.photo ?
                                                        <img src={msg.photo} alt="message"/>
                                                        : null
                                                }
                                                <div className="d-flex justify-content-end">
                                                    {msg.text}
                                                    {
                                                        lastMsg === 'msg-out'
                                                            ?
                                                            <svg enableBackground="new 0 0 512 512"
                                                                 viewBox="0 0 512 512">
                                                                <path
                                                                    d="m473.074 120.729-203.295 203.296-21.212-21.212 153.765-153.764-38.926-38.926-153.765 153.765-61.047-61.047-38.926 38.926 61.047 61.047-21.211 21.211-110.578-110.577-38.926 38.926 149.503 149.503 60.138-60.137 60.137 60.137 242.222-242.221z"/>
                                                            </svg>
                                                            : null
                                                    }
                                                </div>
                                                <span>{moment.unix(msg.timestamp).format('HH:mm')}</span>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </Fragment>
                    )
                })
            }
        </Fragment>
    )
}