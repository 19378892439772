export function getAdminOrderListState(state) {
	return state.adminOrderList
}

export function getAdminOrderListOrder(state) {
	return getAdminOrderListState(state).order
}

export function getAdminOrderListTabsFinance(state) {
	return getAdminOrderListState(state).navTabsFinance
}

export function getAdminOrderListEmployersTabsFinance(state) {
	return getAdminOrderListState(state).employersTabsFinance
}

export function getAdminOrderListOnlineTabsFinance(state) {
	return getAdminOrderListState(state).onlineTabsFinance
}

export function getAdminOrderListTasksState(state) {
	return state.adminOrderListTasks
}



