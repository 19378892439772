import React from "react";
import EstimateOrderSubcategoryRow from "./EstimateOrderSubcategoryRow";
import NumberFormat from "react-number-format";
import {
    getTotalCategoryServicesPrice,
    getTotalCategoryMaterialsPrice,
} from "../calculateFunctions";

export default function EstimateOrderCategoryRow({
                                                     category,
                                                     subCategories,
                                                     categoryServices,
                                                     options,
                                                     clearPrintContent,
                                                 }) {
    const totalWorkPrice = getTotalCategoryServicesPrice(
        categoryServices,
        options.workType.value,
        options
    );
    const totalMaterialPrice = getTotalCategoryMaterialsPrice(categoryServices);
    const totalPrice =
        options.supplyMaterials.value === "customer"
            ? totalWorkPrice
            : totalWorkPrice + totalMaterialPrice;
    return (
        <>
            <tr>
                <th className="text-left" colSpan="7">
                    <h5 className="m-0">
                        <b>{category.name}</b>
                    </h5>
                </th>
            </tr>
            {subCategories.map((subCategory) => {
                const subcategoryServices = categoryServices.filter(
                    (service) => service.category.subCategory.id === subCategory.id
                );
                return (
                    <EstimateOrderSubcategoryRow
                        key={subCategory.id}
                        subCategory={subCategory}
                        services={subcategoryServices}
                        options={options}
                        clearPrintContent={clearPrintContent}
                    />
                );
            })}
            <tr>
                <td></td>
                <td className="text-left" colSpan="3">
                    <b style={{ fontSize: "1.2rem" }}>{`ИТОГО за ${category.name}`}</b>
                </td>
                {options.supplyMaterials.value === "performer" && (
                    <td>
                        <b>
                            {
                                <NumberFormat
                                    value={totalMaterialPrice.toFixed(2)}
                                    displayType={"text"}
                                    thousandSeparator={" "}
                                />
                            }
                        </b>
                    </td>
                )}
                <td>
                    <b>
                        {
                            <NumberFormat
                                value={totalWorkPrice.toFixed(2)}
                                displayType={"text"}
                                thousandSeparator={" "}
                            />
                        }
                    </b>
                </td>
                <td>
                    <b>
                        {
                            <NumberFormat
                                value={totalPrice.toFixed(2)}
                                displayType={"text"}
                                thousandSeparator={" "}
                            />
                        }
                    </b>
                </td>
            </tr>
        </>
    );
}
