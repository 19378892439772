import React, {Component} from 'react';
import './ActKSTableBody.scss'
import ActKsTableColumn from "../ActKSTableColumn/ActKSTableColumn";
import moment from "moment";

class ActKSTableBody extends Component {

	state = {
		services: [],
		totalConfirmedValue: null
	}

	getServices = () => {
		const {reports, services} = this.props.order
		const {period} = this.props
		const formatedServices = []

		if (reports.length > 0) {
			services.forEach(service => {
				const serviceItem = {...service}
				serviceItem.value = 0
				reports.forEach(report => {

					const reportDate = moment(report.date, "DD.MM.YYYY").format("YYYY-MM-DD")
					//    console.log(moment(reportDate))

					if (moment(reportDate).isSameOrAfter(period.startPeriod) && moment(reportDate)
						.isSameOrBefore(period.endPeriod)) {
						report.services.forEach(item => {
							if (service.id === item.id) {
								serviceItem.value += item.value
							}
						})
					}

				})

				formatedServices.push(serviceItem)
			})
		}


		return formatedServices;
	}

	getConfirmedValue = (services) => {
		if (services.length > 0) {

			const values = services.map(service => service.value)

			return values.reduce((min, current) => {
				return Math.min(min, current)
			})
		}
		return null
	}


	render() {

		const services = this.getServices();
		const {order} = this.props;
		const withMaterials = !!order.materials.length;


		return (
			<div className="ActKSTableBody border-top border-dark border-bottom-0">
				<div
					className="ActKSTableBody_column border-dark d-flex justify-content-center flex-column flex-grow-1">
					<div
						className={`border-bottom border-left border-right border-dark num ${withMaterials ? 'material' : ''}`}>
						Номер
					</div>
					<div className="d-flex">
						<div className="d-flex justify-content-center flex-column flex-grow-1">
							<div
								className={`border-bottom border-left border-right border-dark num ${withMaterials ? 'material' : ''}`}>по
								порядку
							</div>
							<div className="border-bottom border-left border-right border-dark num"><strong>1</strong>
							</div>
							<ActKsTableColumn services={services} column={1} order={order}/>
						</div>
						<div>
							<div
								className={`border-bottom border-right border-dark num ${withMaterials ? 'material' : ''}`}>позиции
								по смете
							</div>
							<div className="border-bottom border-right border-dark num"><strong>2</strong></div>
							<ActKsTableColumn services={services} column={2} order={order}/>
						</div>
					</div>
				</div>
				<div
					className="ActKSTableBody_column   d-flex justify-content-between flex-column flex-grow-1">
					<div className={`border-bottom border-right border-dark name ${withMaterials ? 'material' : ''}`}>
						Наименование работ
					</div>
					<div className="border-bottom  border-right border-dark num"><strong>3</strong></div>
					<ActKsTableColumn services={services} column={3} order={order}/>
				</div>
				<div
					className="ActKSTableBody_column d-flex justify-content-between flex-column flex-grow-0">
					<div className={`border-bottom  border-right border-dark name ${withMaterials ? 'material' : ''}`}>
						Номер еденичной <br/> разценки
					</div>
					<div className="border-bottom  border-right border-dark num"><strong>4</strong></div>
					<ActKsTableColumn services={services} column={4} order={order}/>
				</div>
				<div
					className="ActKSTableBody_column d-flex justify-content-between flex-column flex-grow-0">
					<div className={`border-bottom  border-right border-dark name ${withMaterials ? 'material' : ''}`}>
						Еденица измерения
					</div>
					<div className="border-bottom  border-right border-dark num"><strong>5</strong></div>
					<ActKsTableColumn services={services} column={5} order={order}/>
				</div>
				<div
					className="ActKSTableBody_column d-flex justify-content-center flex-column flex-grow-1">
					<div className="border-bottom border-right border-dark num">
						Выполнено работ
					</div>

					<div className="d-flex">
						<div className="d-flex justify-content-center flex-column flex-grow-1">
							<div
								className={`border-bottom  border-right border-dark num ${withMaterials ? 'material-num' : ''}`}>количество
							</div>
							<div className="border-bottom  border-right border-dark num"><strong>6</strong></div>
							<ActKsTableColumn services={services} column={6} order={order}/>
						</div>
						<div className="d-flex justify-content-center flex-column flex-grow-1">
							<div className="border-bottom  border-right border-dark num">цена за еденицу, руб.</div>
							{
								withMaterials && <div className="border-bottom  border-right border-dark num p-0">
									<div className="d-flex h-100 w-100">
										<div
											className="w-50 border-right border-dark h-100  d-flex align-items-center justify-content-center">
											Материалы
										</div>
										<div
											className="h-100 w-50 d-flex align-items-center justify-content-center">
											Работы
										</div>
									</div>
								</div>
							}
							<div className="border-bottom  border-right border-dark num"><strong>7</strong></div>
							<ActKsTableColumn services={services} column={7} order={order}/>
						</div>
						<div className="d-flex justify-content-center flex-column flex-grow-1">
							<div
								className={`border-bottom  border-right border-dark num ${withMaterials ? 'material-num' : ''}`}>стоимость,
								руб.
							</div>
							<div className="border-bottom  border-right border-dark num"><strong>8</strong></div>
							<ActKsTableColumn services={services} column={8} order={order}/>
						</div>
					</div>

				</div>

			</div>
		);
	}
}

export default ActKSTableBody;