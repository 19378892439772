import React, { useEffect } from "react";
import { connect } from "react-redux";
import parse from "html-react-parser";
import { Table } from "react-bootstrap";
import cn from "../Advantages.module.scss";
import { getMaterials } from "./instanteCompleteFunctions";
import { countSliderSetMaterials } from "../../../../store/actions/countSliderAxtions";

const AdvantagesServiceMaterials = (props) => {
	useEffect(() => {
		props.countSliderSetMaterials(getMaterials(props.service, props.count));
	}, [props.service, props.count]);

	return (
		<div className={cn.materialsWrapper}>
			{props.materials.length === 0 ? (
				<div>Материалы не требуются</div>
			) : (
				<div className={cn.tableWrapper}>
					<Table striped>
						<thead>
							<tr>
								<th>№</th>
								<th>Название</th>
								<th>Ед. измерения</th>
								<th>Количество</th>
							</tr>
						</thead>
						<tbody>
							{props.materials.map((material, index) => (
								<tr key={material.name + index}>
									<td>{index + 1}</td>
									<td>{material.name}</td>
									<td>
										{material.unit ? (
											parse(material.unit)
										) : (
											<h1>НЕТ ЕД ИЗМЕРЕНИЯ</h1>
										)}
									</td>
									<td>{material.count.toFixed(2)}</td>
								</tr>
							))}
						</tbody>
					</Table>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	service: state.categorySlider.activeService,
	count: +state.categorySlider.workLoad,
	materials: state.categorySlider.materials,
});
const mapDispatchToprops = {
	countSliderSetMaterials,
};
export default connect(
	mapStateToProps,
	mapDispatchToprops
)(AdvantagesServiceMaterials);
