import React from 'react';
import NumberFormat from 'react-number-format';

const AdminOrderListFinanceMastersReportsRows = ({reports, order}) => {

	return (
		<div className="w-100">
			<div className="d-flex align-items-center justify-content-between">
				<div className="col-6">Аванс</div>
				<div className="col-3">Дата</div>
				<div className="col-3">Сумма, р.</div>
			</div>
			{
				reports.map((report) => {
					return (
						<div key={report.id} className="d-flex align-items-center justify-content-between mt-2">
							<div className="col-6 px-2">
								<div className="p-2 pl-3 bg-light">Акт №{report.id}</div>
							</div>
							<div className="col-3 px-2">
								<div className="p-2 pl-3 bg-light">От {report.date}</div>
							</div>
							<div className="col-3 px-2">
								<div className="p-2 pl-3 bg-light">
									<NumberFormat
										value={(report.value * order.masterPrice).toFixed()}
										displayType={'text'}
										thousandSeparator={" "}/>
								</div>
							</div>
						</div>
					)
				})
			}
		</div>
	);
};

export default AdminOrderListFinanceMastersReportsRows;