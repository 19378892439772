export const filterSubCategories = (subCategories, services) => {
	// оставляем только те, у которых есть услуги и активны
	if (!!services.length) {
		return subCategories.filter(subCategory => {
			const subCategoriesServices = services.filter(service => {
				if (service.category.subCategory) {
					if (service.category.subCategory.id === subCategory.id) {
						if (!!service.items && service.items.length) {
							// if (service.items) {
							let norms = true;
							service.items.forEach(item => norms = norms && !!item.norms.length)
							return norms;
						} else {
							return !!service.norms.length;
						}
					}
				}
				return false;
			})
			return subCategory.active && !!subCategoriesServices.length;
		})
	}
	return []
}

export const getBasePrice = (service, workType = 1) => {
	let price = 0;
	if (service) {
		let customerCoefficient = workType === 1 ? service.customerCoefficient.standard : service.customerCoefficient.premium;
		if (!!service.items && !!service.items.length) {
			service.items.forEach(item => {
				item.norms.forEach(norm => {
					price += norm.cc * norm.norm * (workType === 1 ? norm.costHourStandard : norm.costHourPremium)
				})
			})
		} else {
			service.norms.forEach(norm => {
				price += norm.cc * norm.norm * (workType === 1 ? norm.costHourStandard : norm.costHourPremium)
			})
		}
		price *= customerCoefficient;
	}
	return price;
}
