import React, {useEffect, useState} from 'react';
import WorkerMainInfo from "../../WorkerCreate/WorkerMainInfo/WorkerMainInfo";
import WorkerPassport from "../../WorkerCreate/WorkerPassport/WorkerPassport";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import latinize from "latinize";
import {validateControl} from "../../../../form/formFramework";
import {fetchCategories} from "../../../../store/actions/workerCreateActions";
import WorkerCategories from "../../WorkerCreate/WorkerCategories/WorkerCategories";
import WorkerPersonalCard from "../WorkerPersonalCard/WorkerPersonalCard";
import {workerPersonalUpdate} from "../../../../store/actions/workerPersonalActions";
import AdminSendLoader from "../../../Admin/AdminComponents/AdminUI/AdminSendLoader";
import WorkerPersonalAbout from "../WorkerPersonalAbout/WorkerPersonalAbout";

const WorkerPersonalSettings = ({formControls, fetchCategories, categories, user, workerPersonalUpdate, loading}) => {

	const [formTouched, setFormTouched] = useState(false);
	const [userCategories, setUserCategories] = useState([]);
	const [controls, setControls] = useState({...formControls});
	const [formData, setFormData] = useState({});


	const formSettings = React.createRef();

	const submitHandler = (evt) => {
		evt.preventDefault();
		setFormTouched(true);

		const formControls = {...controls};
		const data = {...formData};
		let valid = true;

		Object.values(formControls).forEach(formControlGroup => {
			Object.keys(formControlGroup).forEach(property => {
				const control = formControlGroup[property];
				const value = control.value;
				const error = validateControl(value, control.validation);
				valid = valid && !error;
				control.error = error;
				control.value = value;
				formData[property] = value;
			})
		});

		if (valid) {
			workerPersonalUpdate({...formData, categories: userCategories})
		}
		setFormData(data);
		setControls(controls);
	};

	const formControlHandler = (value, propertyId) => {

		const formControls = {...controls};

		if (propertyId === 'owner') {
			value = latinize(value).toUpperCase()
		}
		Object.values(formControls).forEach(formControlGroup => {
			const control = Object.keys(formControlGroup).find(key => key === propertyId);
			if (control) {
				if (formTouched) {
					formControlGroup[control].error = validateControl(value, formControlGroup[control].validation);
				}
				formControlGroup[control].value = value;
			}
		});
		setControls(formControls);
	};


	useEffect(() => {
		if (!categories.length) {
			fetchCategories();
		}
		return () => {
		};
	}, [categories, fetchCategories]);

	useEffect(() => {
		if (user.categories) {
			setUserCategories(user.categories.map(category => category.id));
		}
	}, [user]);

	useEffect(() => {
		if (user.id) {
			let formControls = {...controls};
			Object.keys(formControls).forEach(key => {
				Object.keys(formControls[key]).forEach(property => {
					if (property !== 'photo') {
						let control = formControls[key][property];
						if (!control.value) {
							control.value = typeof user[property] !== 'undefined' ? user[property] : typeof user[key] !== 'undefined' ? user[key][property] : '';
							control.error = ''
						}
					}
				});
			});
		}
	}, [user, controls]);

	const checkBoxHandler = (value) => {
		let changedItemId = +value;
		let changedItemIdx = userCategories.findIndex(id => id === changedItemId)
		if (changedItemIdx === -1) {
			setUserCategories([...userCategories, changedItemId])
		} else {
			setUserCategories([
				...userCategories.slice(0, changedItemIdx),
				...userCategories.slice(changedItemIdx + 1)
			])
		}
	};

	return (
		<div className='container'>
			{
				loading && <AdminSendLoader/>
			}
			<form ref={formSettings}
				  onSubmit={submitHandler}>


				<WorkerMainInfo controls={controls.user}
								picture={user.picture}
								title={null}
								formControlHandler={formControlHandler}/>

				<WorkerPassport controls={controls.passport}
								formControlHandler={formControlHandler}/>

				<WorkerPersonalCard controls={controls.card} formControlHandler={formControlHandler}/>
				{
					user.role === 5
					&&
					<WorkerCategories categories={categories}
									  checkBoxHandler={checkBoxHandler} formData={{categories: userCategories}}/>
				}
				{/*<WorkerTypes checkBoxHandler={this.checkBoxHandler} formData={this.state.formData}/>*/}
				<WorkerPersonalAbout control={controls.user.description} formControlHandler={formControlHandler}/>


				<div className="w-100 mt-2 d-flex p-3 justify-content-end">

					<Button
						variant="contained"
						color='primary'
						component='button'
						type='submit'
						className='col-12 col-sm-3'>
						<div>Сохранить</div>
					</Button>

				</div>


			</form>
		</div>
	);
};

const mapState = state => {
	return {
		formControls: state.workerPersonal.formControls,
		categories: state.workerCreate.categories,
		user: state.workerPersonal.user,
		loading: state.workerPersonal.loading,
	}
}

const actions = {
	fetchCategories,
	workerPersonalUpdate
}

export default connect(mapState, actions)(WorkerPersonalSettings);