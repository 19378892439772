import React, { useEffect } from "react";
import { connect } from "react-redux";
import SyncLoader from 'react-spinners/SyncLoader';
import { countSliderChangeCategory, countSliderSetCategories, countSliderSetServices } from "../../../store/actions/countSliderAxtions";
import cn from "./CategoryList.module.scss";
import CategoryListSlider from "./CategoryListSlider";
import CategoryListTextItem from "./CategoryListTextItem";
import tick from "../../../tick.svg";

const CategoryList = (props) => {
	const {
		categories,
	} = props.categorySlider;

	const { fetchedServices, fetchedCategories } = props;

	useEffect(() => {
		//Фильтрация и диспатч категорий и услуг
		if (!fetchedCategories.length || !fetchedServices.length) return;
		const activeServices = fetchedServices.filter((service) => {
			let active = false;
			if (!!service.items.length) {
				service.items.forEach((i) => {
					active = active || !!i.norms.length;
				});
			}
			return active || !!service.norms.length;
		});
		const activeCategories = fetchedCategories.filter((c) => {
			return activeServices.some((s) => s.category.id === c.id);
		});

		activeCategories.sort((a, b) => a.sort - b.sort);
		activeServices.sort((a, b) => a.sort - b.sort);

		props.countSliderSetCategories(activeCategories);
		props.countSliderSetServices(activeServices);
		props.countSliderChangeCategory(activeCategories[0].id);
	}, [fetchedServices, fetchedCategories]);

	const textData = [
		{
			id: 0,
			title: "Отдельные виды работ",
			text: "Выберите актуальный вид работ, одну или несколько — по необходимости. Или позвоните нам, если нужно помочь оформить актуальный заказ!",
		},
		{
			id: 1,
			title: "Комплексное строительство",
			text: "Вы можете выбрать сразу все работы, в комплексе, составив с помощью нашего сервиса самостоятельно смету, или доверить ее составление нам, и заказать весь комплекс работ.",
		},
	];

	return (
		<section className={cn.main}>
			<h3 className={cn.mainTitle}>Доступен заказ работ через сервис</h3>
			<div>
				<ul className={cn.list} style={{ textAlign: 'center' }}>
					<li className={cn.textBlockText}><img src={tick} width={10} height={10} alt="tick"/> Перечень работ уже заполнен по 12 разделам по 400+ позициям основных работ.</li>
					<li className={cn.textBlockText}><img src={tick} width={10} height={10} alt="tick"/> База материалов с актуальными ценами содержит порядка 400 наименований.</li>
					<li className={cn.textBlockText}><img src={tick} width={10} height={10} alt="tick"/> У нас более 500 мастеров разных специальностей.</li>
				</ul>
				<div className={cn.textBlockText} style={{ textAlign: 'center', marginBottom: '30px' }}>
					Сервис моментально по нормам рассчитает, исходя из сроков и объема, необходимое количество мастеров и материалов.<br/>
					Работы нормированы по видам работ и материалам. Вам нужно лишь выбрать работу и ее объем и срок выполнения.<br/>
					По вашему звонку мы можем создать, составить и включить в перечень новые необходимые позиции.<br/>
					Мы даем онлайн-доступ ко всем процессам.
				</div>
			</div>
			<div className={`${cn.mainWrapper} row`}>
				<div className={`${cn.mainTextBlock} col-12 col-lg-6`}>
					{textData.map((item) => (
						<CategoryListTextItem key={item.id} text={item.text} title={item.title} />
					))}
				</div>
				<div className={`${cn.mainSliderBlock} col-12 col-lg-6`}>
					<div className={cn.mainSliderWrapper}>
						<div className={cn.mainSliderHead}>
							<b>Виды работ</b>
						</div>
						<div className={cn.mainSliderContent}>
							{!!categories.length ? (
								<CategoryListSlider
									categories={categories}
									beforeChange={props.countSliderChangeCategory}
								/>
							) : (
								<SyncLoader className='SyncLoader' color="#7B53DA"/>
							)}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

const mapStateToProps = (state) => ({
	categorySlider: state.categorySlider,
	fetchedServices: state.serviceSelect.services,
	fetchedCategories: state.serviceSelect.categories,
});
const mapDispatchToprops = {
	countSliderChangeCategory,
	countSliderSetServices,
	countSliderSetCategories,
};
export default connect(mapStateToProps, mapDispatchToprops)(CategoryList);
