import React from 'react';
import {List} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import {useSelector} from 'react-redux';
import {getAdminOrderListState} from './selectors';

const AdminOrderListInfo = () => {
	const {order} = useSelector(getAdminOrderListState)

	return (
		<>
			{
				order && <List>
					{
						order.customer
						&& <ListItem
							className='d-flex align-items-center justify-content-between border-bottom py-3'>
							<div className='d-flex align-items-center'>
								<Typography variant='body2'
											className='mr-3'>Заказчик:</Typography>
								<Typography variant='subtitle2'>{order.customer.name} {order.customer.surname}</Typography>
							</div>
							<div className='d-flex justify-content-between align-items-center w-50'>
								<Typography variant='subtitle2'
											className='w-50 text-right'>Тел: {order.customer.phone}</Typography>
								<Typography
									variant='subtitle2'
									className='ml-3'>Email: {order.customer.email}</Typography>
							</div>
						</ListItem>
					}
					<ListItem className='d-flex align-items-center border-bottom py-3'>
						<Typography className='mr-3' variant='body2'>Место
							работы:</Typography>
						<Typography variant='subtitle2'>{order.objectPlace}</Typography>
					</ListItem>
					<ListItem className='d-flex align-items-center border-bottom py-3'>
						<Typography className='mr-3' variant='body2'>Адрес
							объекта:</Typography>
						<Typography variant='subtitle2'>{order.location && order.location.address}</Typography>
					</ListItem>
					<ListItem className='d-flex align-items-center border-bottom py-3'>
						<Typography className='mr-3' variant='body2'>Объект:</Typography>
						<Typography variant='subtitle2'>{order.objectName}</Typography>
					</ListItem>
					<ListItem className='d-flex align-items-center border-bottom py-3'>
						<Typography className='mr-3' variant='body2'>Требования:</Typography>
						<Typography variant='subtitle2'>{order.requirments}</Typography>
					</ListItem>
					<ListItem className='d-flex align-items-center border-bottom py-3'>
						<Typography className='mr-3' variant='body2'>Количество траншей:</Typography>
						<Typography variant='subtitle2'>{order.tranches && order.tranches.length}</Typography>
					</ListItem>
					<ListItem className='d-flex align-items-center border-bottom py-3'>
						<Typography className='mr-3' variant='body2'>Дата начала:</Typography>
						<Typography variant='subtitle2'>{order.startDate}</Typography>
					</ListItem>
					<ListItem className='d-flex align-items-center border-bottom py-3'>
						<Typography className='mr-3' variant='body2'>Дата окончания:</Typography>
						<Typography variant='subtitle2'>{order.finishDate}</Typography>
					</ListItem>

				</List>
			}
		</>
	);
};

export default AdminOrderListInfo;