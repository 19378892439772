import React, {Fragment} from 'react'
import './Dialog.scss'
import {connect} from "react-redux";
import {fetchDialogs, getProfile, sendMessage, setDialogProperty} from "../../store/actions/dialogActions";
import firebase from "../../rest/firebase";
import 'firebase/database';
// import firebase from 'firebase/app';
import Loader from "../UI/Loader/Loader";
import ChatList from "./ChatList/ChatList";
import Chat from "./Chat/Chat";
import {withRouter} from "react-router-dom";


class Dialog extends React.Component {

    state = {
        order: {},
        chats: {},
        currentChat: null,
        limit: 50
    }

    chatPickHandler = (dialogId) => {
        const state = {...this.state}
        state.currentChat = dialogId
        this.setState(state)
    }

    sendMessageHandler = (e) => {
        e.preventDefault()
        let text = e.target.msg.value

        let orderId = this.state.order.id
        const dialogId = this.state.currentChat
        const {currentImage, dialogs} = this.props.dialog


        const currentDialog = dialogs[dialogId]
        orderId = currentDialog.opponent.role === 8 || currentDialog.opponent.role === 1 ? 'admin' : orderId

        console.log(orderId)

        this.props.sendMessage(orderId, dialogId, text, currentImage)

        e.target.msg.value = ''
        this.props.setDialogProperty({}, 'currentImage')

    }

    closeDialog = (e) => {
        if (e.target.classList.contains('Dialog')) {
            this.props.dialogHandler()
        }
    }

    componentDidMount = async () => {
        // console.log(this.props)

        const {order, chats} = this.props
        // let {dialogs} =
        // const {limit, chats} = this.state

        // await this.props.getProfile()
        //
        // let dialogs = []
        // dialogs.push(this.props.dialog.profile.adminDialog)
        //
        // if(this.props.order.dialogs){
        //     dialogs = [...dialogs, ...this.props.order.dialogs]
        // }
        //
        // if (dialogs) {
        //     dialogs.forEach(async dialog => {
        //         let messages = []
        //         let orderId = dialog.opponent.role === 8 || dialog.opponent.role === 1 ? 'admin' : order.id
        //
        //         const db = await firebase.database()
        //             .ref(`dialogs/${orderId}/${dialog.id}`)
        //             .orderByChild('timestamp')
        //             .limitToLast(limit);
        //
        //         db.on("value", snapShot => {
        //             messages = snapShot.val() || []
        //             chats[dialog.id] = {
        //                 opponent: dialog.opponent,
        //                 messages,
        //             }
        //             this.props.fetchDialogs(orderId, dialog.id, dialog.opponent)
        //         })
        //     })
        // }


        this.setState({
            chats,
            order,
        })

    }


    render() {

        const {id} = this.state.order
        const {dialogs, profile} = this.props.dialog
        const {chats, currentChat} = this.state

        let showChat = false
        if (!!Object.values(dialogs).length && !!Object.values(chats).length) {
            if (Object.values(dialogs).length === Object.values(chats).length) {
                showChat = true
            }
        }

        // console.log(this.props)

        return (
            <Fragment>
                {
                    !!dialogs
                        ?
                        <div className="Dialog" onClick={this.closeDialog}>

                            {
                                showChat ?
                                    <div className="container">

                                        <div className={`Dialog_head ${currentChat ? 'active' : ''} row p-2`}>
                                            <div className="col-12 col-sm-4 d-flex  align-items-center">
                                                Заказ №{id}
                                            </div>
                                            <div
                                                className="col-12 col-sm-8 d-flex justify-content-center align-items-center">
                                                Диалоги
                                                <svg viewBox="0 0 329.26933 329" onClick={this.props.dialogHandler}>
                                                    <path
                                                        d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"/>
                                                </svg>
                                            </div>
                                        </div>

                                        <div className="Dialog_body row">
                                            <div className="Dialog_body__left col-12 col-sm-4">
                                                <ChatList chats={chats}
                                                          dialogs={dialogs}
                                                          profile={profile}
                                                          currentChat={currentChat}
                                                          onClickHandler={this.chatPickHandler}/>
                                            </div>
                                            <div
                                                className="Dialog_body__right col-12 col-sm-8">
                                                <Chat
                                                    orderId={id}
                                                    dialogs={dialogs}
                                                    profile={profile}
                                                    currentChat={currentChat}
                                                    sendMessageHandler={this.sendMessageHandler}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                    :
                                    <Loader/>
                            }

                        </div>
                        :
                        null

                }
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        dialog: state.dialog
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchDialogs: (orderId, dialogId, opponent) => dispatch(fetchDialogs(orderId, dialogId, opponent)),
        getProfile: () => dispatch(getProfile()),
        sendMessage: (orderId, dialogId, text, picture) => dispatch(sendMessage(orderId, dialogId, text, picture)),
        setDialogProperty: (value, property) => dispatch(setDialogProperty(value, property)),
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dialog))