import React from 'react';
import {withMaterials} from '../functions';

const PersonalOrderItemTranchesLeftSide = ({order}) => {
	return (
		<>
			<div
				className='d-flex align-content-center justify-content-end h60'>
				<div className="w-10"/>
				<div className='w-70 d-flex align-items-center px-3'>
					Работы
				</div>
				<div
					className='p-3 w-20 d-flex justify-content-center align-items-center'>Ед. Изм
				</div>
			</div>


			<div
				className='PersonalOrderItemTranches_LeftBlock__block d-flex align-items-center w-100 position-relative'>
				<div
					className='w-10 PersonalOrderItemTranches_LeftBlock-leftHeadingRotate top '>
					<div>
						<div>П</div>
						<div>л</div>
						<div>а</div>
						<div>н</div>
					</div>
				</div>

				<div className='w-100 pl-3'>
					<div
						className='border-bottom border-main-1-3 d-flex align-items-center h60'>
						<div className="w-75 pt-3">Объемы</div>
						<div className="w-25 pt-3 d-flex justify-content-center">{order.unit}</div>
					</div>
					<div
						className={`border-bottom ${withMaterials(order) ? 'border-main-1-3' : 'border-main-2-1'} d-flex align-items-center h40`}>
						<div className="w-75">Фин. выполнение (Работа)</div>
						<div className="w-25 d-flex justify-content-center">руб.</div>
					</div>
					{
						withMaterials(order) &&
						<div>
							<div
								className='border-bottom border-main-1-3 d-flex align-items-center h40'>
								<div className="w-75 d-flex align-items-center">Фин. выполнение (Материалы)</div>
								<div
									className="w-25 d-flex align-items-center justify-content-center">руб.
								</div>
							</div>
							<div
								className='border-bottom border-main-2-1 d-flex align-items-center h40'>
								<div className="w-75 d-flex align-items-center">
									Фин. выполнение (Работа + Материалы)
								</div>
								<div
									className="w-25 d-flex align-items-center justify-content-center">руб.
								</div>
							</div>
						</div>

					}
				</div>

			</div>


			<div
				className='PersonalOrderItemTranches_LeftBlock__block d-flex align-items-center bg-white w-100 position-relative'>

				<div
					className='PersonalOrderItemTranches_LeftBlock-leftHeadingRotate w-10 bottom'>
					<div>
						<div>Ф</div>
						<div>а</div>
						<div>к</div>
						<div>т</div>
					</div>
				</div>

				<div className='w-100  pl-3'>

					{
						order &&
						<div
							className='border-bottom border-main-1-3 d-flex align-items-center h40'>
							<div
								className="w-75 d-flex align-items-center">
								{/*{order.serviceName || ''}*/}
								Объемы
							</div>
							<div
								className="w-25 d-flex align-items-center justify-content-center">{order.unit}</div>
						</div>

					}

					<div className='border-bottom border-main-1-3 d-flex align-items-center h40'>
						<div className="w-75 d-flex align-items-center">Фин. выполнение (Работа)</div>
						<div className="w-25 d-flex align-items-center justify-content-center">руб.</div>
					</div>

					{
						withMaterials(order) &&
						<div>
							<div
								className='border-bottom border-main-1-3 d-flex align-items-center h40'>
								<div className="w-75 d-flex align-items-center">Фин. выполнение (Материалы)</div>
								<div
									className="w-25 d-flex align-items-center justify-content-center">руб.
								</div>
							</div>
							<div
								className='border-bottom border-main-1-3 d-flex align-items-center h40'>
								<div className="w-75 d-flex align-items-center">
									Фин. выполнение (Работа + Материалы)
								</div>
								<div
									className="w-25 d-flex align-items-center justify-content-center">руб.
								</div>
							</div>
						</div>
					}

				</div>

			</div>
		</>
	)
};

export default PersonalOrderItemTranchesLeftSide;