import React from 'react';
import styled from 'styled-components';
import ChartsTableHeadTitleCol from './ChartsTableHeadTitleCol';
import { LEFT_WIDTH } from '../config';

const StyledHeadTitle = styled.div`
  display: flex;
  background: #7B53DA;
  color: #fff;
  height: 50px;
  border-right: 1px solid #7251C3;
  font-size: 12px;
  font-weight: 500;
  padding: 0 15px;
  min-width: ${LEFT_WIDTH};
`;

const ChartsTableHeadTitle = ({ titleCols }) => {
    return (
        <StyledHeadTitle>
            {
                titleCols.map(({ id, title, col }) => <ChartsTableHeadTitleCol key={id} col={col} title={title} />)
            }
        </StyledHeadTitle>
    );
};

export default ChartsTableHeadTitle;
