import React, { useEffect, useState, useRef } from "react";
import buttonClasses from "../../../components/UI/GreenButton/GreenButton.module.scss";
import { renderFormControl, validateControl } from "../../../form/formFramework";
import yandexGoal from "../../../utils/yandexGoal";
import cn from "./Modal.module.scss";

const Modal = (props) => {
	const [formTouched, setFormTouched] = useState(false)
	const [formValid, setFormValid] = useState(true)
	const form = useRef();
	const closeModalOnWrapperClick = (e) => {
		if (e.target.id == "modal-wrapper") {
			props.closeModal();
		}
	};
	const closeModalOnEsc = (e) => {
		if (e.key === "Escape") {
			props.closeModal();
		}
	};
  const formValidHandler = () => {
		let formValid = true
		props.activeFields.forEach(property => {
			const formControl = props.formControls[property];
			const error = validateControl(formControl.value, formControl.validation, property);
			const valid = !error;
      if (error) props.modalSetFieldError(error, property);
			formValid = valid && formValid;
		});
		return formValid;
	};
  const submitFormHandler = (e) => {
    e.preventDefault();
		setFormTouched(false)
		setFormValid(formValidHandler())
    if (formValidHandler()) {
			let formData = new FormData();
			props.activeFields.forEach(fieldId => {
				if (props.formControls[fieldId].value !== '') formData.append(fieldId, props.formControls[fieldId].value)
			});
			props.sendmessage(formData)
			yandexGoal(props.goal)
    } else {
      console.log('false')
		}
  }
	const setFormFieldValue = (value, id) => {
		setFormTouched(true)
		props.modalSetFieldError(false, id);
		props.changeFieldValue(value, id)
	}
	useEffect(() => {
		document.addEventListener("keydown", closeModalOnEsc);
		return () => {
			document.removeEventListener("keydown", closeModalOnEsc);
		};
	});

	if (props.success) return (
		<div
			className={cn.wrapper}
			onClick={closeModalOnWrapperClick}
			id="modal-wrapper"
		>
			<div className={cn.content}>
				<div className={cn.inner}>
					<div className={cn.icon}>
					<svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M34.9998 3.88892C28.8466 3.88892 22.8316 5.71355 17.7154 9.13208C12.5992 12.5506 8.6116 17.4095 6.25688 23.0943C3.90215 28.7791 3.28605 35.0345 4.48648 41.0695C5.68691 47.1045 8.64995 52.6479 13.0009 56.9989C17.3519 61.3499 22.8954 64.3129 28.9303 65.5133C34.9653 66.7138 41.2207 66.0977 46.9055 63.7429C52.5903 61.3882 57.4492 57.4006 60.8677 52.2844C64.2863 47.1682 66.1109 41.1532 66.1109 35C66.1109 26.7488 62.8331 18.8356 56.9987 13.0011C51.1642 7.16668 43.251 3.88892 34.9998 3.88892V3.88892ZM34.9998 62.2222C29.6157 62.2222 24.3526 60.6257 19.8759 57.6345C15.3993 54.6433 11.9101 50.3917 9.84974 45.4175C7.78936 40.4433 7.25027 34.9698 8.30064 29.6892C9.35102 24.4086 11.9437 19.5581 15.7508 15.751C19.5579 11.9439 24.4084 9.35125 29.689 8.30087C34.9696 7.2505 40.4431 7.78959 45.4173 9.84997C50.3915 11.9104 54.643 15.3995 57.6342 19.8762C60.6255 24.3528 62.222 29.616 62.222 35C62.222 42.2198 59.354 49.1439 54.2488 54.249C49.1437 59.3542 42.2196 62.2222 34.9998 62.2222Z" fill="#5D20C1"/>
						<path d="M54.4445 23.5278C54.0802 23.1657 53.5874 22.9624 53.0737 22.9624C52.56 22.9624 52.0672 23.1657 51.7029 23.5278L30.1195 45.0139L18.4529 33.3473C18.097 32.9631 17.6032 32.736 17.0799 32.7159C16.5566 32.6959 16.0468 32.8845 15.6626 33.2403C15.2784 33.5962 15.0513 34.09 15.0312 34.6133C15.0112 35.1366 15.1998 35.6464 15.5556 36.0306L30.1195 50.5556L54.4445 26.2889C54.6268 26.1082 54.7714 25.8931 54.8702 25.6562C54.9689 25.4192 55.0197 25.1651 55.0197 24.9084C55.0197 24.6517 54.9689 24.3975 54.8702 24.1606C54.7714 23.9237 54.6268 23.7086 54.4445 23.5278Z" fill="#5D20C1"/>
					</svg>
					</div>
					<h3 className={cn.title}>Успешно!</h3>
					<p className={cn.text}>Ваша заявка отправлена.<br/>Мы свяжемся с Вами в ближайшее время.</p>
				</div>
			</div>
		</div>
	)

	return (
		<div
			className={cn.wrapper}
			onClick={closeModalOnWrapperClick}
			id="modal-wrapper"
		>
			<div className={cn.content}>
				<div className={cn.inner}>
					<h3 className={cn.title}>{props.title}</h3>
					<form onSubmit={submitFormHandler} className={cn.form} ref={form}>
						{props.activeFields.map((fieldId) => (
							<div key={fieldId} className={cn.inputWrapper}>
								{renderFormControl(
									props.formControls[fieldId],
									setFormFieldValue
								)}
							</div>
						))}
						<button disabled={!formTouched && !formValid} type='submit' className={`${cn.button} ${buttonClasses.GreenButton}`}>
							{props.buttonText}
						</button>
						<span className={cn.policy}>
							Согласен на политику конфиденциальности
						</span>
					</form>
				</div>
			</div>
		</div>
	);
};

export default Modal;