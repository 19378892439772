import axios from "../../rest/axios";
import * as moment from 'moment';
// import {store} from '../../index'
import {
    CHANGE_PAYMENT_SYSTEM,
    FETCH_ORDER_DETAIL_START,
    FETCH_ORDER_DETAIL_SUCCESS,

    FETCH_ORDER_LIST_ERROR,
    FETCH_ORDER_LIST_MATERIALS_ERROR,
    FETCH_ORDER_LIST_MATERIALS_START,
    FETCH_ORDER_LIST_MATERIALS_SUCCESS,
    FETCH_ORDER_LIST_START,
    FETCH_ORDER_LIST_SUCCESS, FETCH_PAYMENT_LIST, FETCH_PAYMENT_URL, ORDER_LIST_CANCEL_ORDER,
    ORDER_LIST_ITEM_SWITCH_TAB,
    ORDER_LIST_ITEM_SWITCH_TAB_SMALL,
    SET_ORDER_LIST_PROPERTY,
} from "./actionTypes";
import {store} from "../../index";

import qs from "qs";

export function setOrderListProperty(value, propertyName) {
    return {
        type: SET_ORDER_LIST_PROPERTY,
        value,
        propertyName
    }
}

export function fetchOrderList(params = {}) {
    return async dispatch => {
        const token = localStorage.getItem('token') || store.getState().workerPersonal.token;

        if (token) {
            dispatch(fetchStart())
            try {
                let {data} = await axios.get('/mobile/cabinet/orders/getList/', {
                    params: {
                        token
                    }
                });
                if (data) {
                     const orders = data.data.items.sort((a, b) => moment(b.dateInsert, 'DD.MM.YYYY hh:mm:ss').unix() - moment(a.dateInsert, 'DD.MM.YYYY hh:mm:ss').unix())
                    dispatch(fetchSuccess(orders))
                }
            } catch (e) {
                dispatch(fetchError(e))
            }
        }
    }
}

function fetchStart() {
    return {
        type: FETCH_ORDER_LIST_START
    }
}

function fetchSuccess(orders) {
    return {
        type: FETCH_ORDER_LIST_SUCCESS,
        orders
    }
}

function fetchError(error) {
    return {
        type: FETCH_ORDER_LIST_ERROR,
        error
    }
}

export function fetchOrderDetail(orderId) {
    return async dispatch => {
        const token = localStorage.getItem('token') || store.getState().workerPersonal.token

        if (token) {
            dispatch(fetchOrderDetailStart())
            try {
                let response = await axios.post('/mobile/orders/getDetail/', qs.stringify({
                    token, orderId
                }));
                if (response.data) {
                    dispatch(fetchOrderDetailSuccess(response.data.data))
                }
            } catch (e) {
                dispatch(fetchError(e))
            }
        }
    }
}

const fetchOrderDetailStart = () => {
    return {
        type: FETCH_ORDER_DETAIL_START
    }
}

const fetchOrderDetailSuccess = (order) => {
    return {
        type: FETCH_ORDER_DETAIL_SUCCESS,
        order
    }
}


export function switchTab(id) {
    return {
        type: ORDER_LIST_ITEM_SWITCH_TAB,
        payload: id
    }
}

export function switchTabSmall(id) {
    return {
        type: ORDER_LIST_ITEM_SWITCH_TAB_SMALL,
        payload: id
    }
}

export function fetchMaterials(materialsId = []) {

    return async dispatch => {
        dispatch(fetchMaterialStart())
        try {
            const materials = await axios.get('/get/materials.php', {
                params: {
                    id: encodeURI(materialsId.join().trim())
                }
            })

            dispatch(fetchMaterialSuccess(materials.data))
        } catch (e) {
            dispatch(fetchMaterialError(e))
        }
    }
}

function fetchMaterialStart() {
    return {
        type: FETCH_ORDER_LIST_MATERIALS_START
    }
}

function fetchMaterialSuccess(materials) {
    return {
        type: FETCH_ORDER_LIST_MATERIALS_SUCCESS,
        materials
    }
}

function fetchMaterialError(error) {
    return {
        type: FETCH_ORDER_LIST_MATERIALS_ERROR,
        error
    }
}

export function fetchPaymentUrl({paymentId, orderId, psId = 3}) {
    return dispatch => {
        dispatch(fetchStart());
        console.log(psId)
        let paysystem = psId === 3 ? 'Alfabank' : 'Invoice';

        axios.get(`/web/orders/payment/getResult/${paysystem}/`, {
            params: {
                paymentId,
                orderId,
                token: localStorage.getItem('token'),
            },
            // headers: {
            //     'Authorization': 'Bearer: ' + localStorage.getItem('token')
            // }
        }).then(response => {

            dispatch({
                type: FETCH_PAYMENT_URL,
                payload: {paymentUrl: response.data.data.paymentUrl}
            })
        }).catch(e => {
            dispatch(fetchError(e.message))
            console.warn(e)
        })
    }
}

export function fetchPaymentList(orderId, paymentId) {
    return dispatch => {
        dispatch(fetchStart());
        axios.get('/web/orders/payment/getChangeList/', {
            params: {
                orderId,
                paymentId,
                token: localStorage.getItem('token'),
            },
        }).then(response => {
            dispatch({
                type: FETCH_PAYMENT_LIST,
                payload: {paymentSystems: response.data.data.items}
            })
        }).catch(e => {
            dispatch(fetchError(e.message))
            console.warn(e)
        })
    }
}

export function changePaymentSystem(orderId, paymentId, psId) {
    return dispatch => {
        dispatch(fetchStart());
        axios.get('/web/orders/payment/change/', {
            params: {
                orderId,
                paymentId,
                psId,
                token: localStorage.getItem('token'),
            },
        }).then(response => {
            dispatch({
                type: CHANGE_PAYMENT_SYSTEM,
            })
            dispatch(fetchPaymentList(orderId, paymentId))
        }).catch(e => {
            dispatch(fetchError(e.message))
            console.warn(e)
        })
    }
}

export function cancelOrder(orderId) {
    return dispatch => {
        dispatch(fetchStart());
        axios.get('/web/orders/cancel/', {
            params: {
                orderId,
                token: localStorage.getItem('token'),
            },
        }).then(response => {
            dispatch({
                type: ORDER_LIST_CANCEL_ORDER,
            });
            dispatch(fetchOrderList())
        }).catch(e => {
            dispatch(fetchError(e.message))
            console.warn(e)
        })
    }
}