import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import AdminSpecialistHeader from './AdminSpecialistHeader';
import AdminSpecialistLine from './AdminSpecialistLine';
import {getAdminMasterListUsers} from '../selectors';
import {adminMastersFetch} from '../../../../../store/actions/adminMastersListActions';
import {fetchAdminProfessionalListProfessions} from '../../../../../store/actions/adminProfessionalListActions';
import {ROLE_SPECIALIST} from '../../../../../config';

const AdminSpecialistList = () => {
	const dispatch = useDispatch();
	const [counts, setCounts] = useState({})
	const [total, setTotal] = useState(0)
	const [expanded, setExpanded] = useState(null)
	const stateUsers = useSelector(getAdminMasterListUsers);
	const {professions} = useSelector(state => state.adminProfessionalList)
	useEffect(() => {
		dispatch(fetchAdminProfessionalListProfessions())
		dispatch(adminMastersFetch(ROLE_SPECIALIST, 'all', 'all', 'all', 'all'))
	}, [])
	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false)
	};

	useEffect(() => {
		if (!!stateUsers['specialists'].length) {
			const count = {}
			professions.forEach((profession) => {
				count[profession.id] = stateUsers['specialists'].filter((specialist) => {
					const workerProfessions = [specialist.professionMain.id, specialist.professionAdd.id, specialist.professionAdd_2.id];
					return workerProfessions.indexOf(profession.id) !== -1
				} ).length;
			})
			setCounts(count)
			setTotal(stateUsers['specialists'].length)
		}
	}, [stateUsers])

	return (
		<>
			<Typography variant="h5" className="my-3">Специалисты</Typography>
			<Typography variant="button" className="my-3">Всего специалистов: {total}</Typography>

			{
				!!professions.length && <>
					{
						professions.map((profession, idx) => <Accordion
							key={profession.id}
							TransitionProps={{unmountOnExit: true}}
							expanded={expanded === profession.id}
							className="mt-2"
							onChange={handleChange(profession.id)}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon/>}
								aria-controls="panel1bh-content"
								id="panel1bh-header"
								className='Admin_categories'
							>
								<div
									className="d-flex align-items-center justify-content-between w-100">
									<div className="d-flex align-items-center justify-content-between text-white">
										<Typography variant="button" gutterBottom
													className="m-0">
											{idx + 1}. {profession.name} &nbsp;
										</Typography>
										<Typography variant="overline" display="block">
											{`[ рабочих: ${counts[profession.id] || 0} ]`}
										</Typography>
									</div>
								</div>
							</AccordionSummary>
							<AccordionDetails className="d-flex flex-column px-2 py-2 ">
								<AdminSpecialistHeader/>
								<List className='w-100 py-0'>

									{
										stateUsers['specialists'].filter((worker)=> {
											const workerProfessions = [worker.professionMain.id, worker.professionAdd.id, worker.professionAdd_2.id];
											return workerProfessions.indexOf(profession.id) !== -1
										}).map((worker, worker_idx) => (<ListItem key={worker.id}
															className="px-1 py-0"
												>
													<AdminSpecialistLine worker={worker} idx={worker_idx + 1}/>
												</ListItem>
										))

									}
								</List>
							</AccordionDetails>
						</Accordion>)
					}
				</>

			}
		</>

	);
};

export default AdminSpecialistList;