import React, {Component} from 'react';
import './ActKS3TableBody.scss'
import moment from "moment";
import ActKS3TableColumn from "../ActKS3TableColumn/ActKS3TableColumn";

class ActKS3TableBody extends Component {

    state = {
        services: [],
        totalConfirmedValue: null
    }

    getServices = () => {
        const {reports, services} = this.props.order
        const {period} = this.props
        const formatedServices = []

        if (reports.length > 0) {
            services.forEach(service => {
                const serviceItem = {...service}
                serviceItem.value = 0
                reports.forEach(report => {

                    const reportDate = moment(report.date, "DD.MM.YYYY").format("YYYY-MM-DD")
                    //    console.log(moment(reportDate))

                    if (moment(reportDate).isSameOrAfter(period.startPeriod) && moment(reportDate).isSameOrBefore(period.endPeriod)) {
                        report.services.forEach(item => {
                            if (service.id === item.id) {
                                serviceItem.value += item.value
                            }
                        })
                    }

                })

                formatedServices.push(serviceItem)
            })
        }


        return formatedServices;
    }

    getConfirmedValue = (services) => {
        if (services.length > 0) {

            const values = services.map(service => service.value)

            return values.reduce((min, current) => {
                return Math.min(min, current)
            })
        }
        return null
    }


    render() {
        const services = this.getServices();
        const {order} = this.props;

        return (
            <div className="ActKS3TableBody border-top border-dark border-bottom-0">

                <div
                    className="ActKS3TableBody_column d-flex justify-content-center flex-column flex-grow-0">
                    <div className="border-bottom border-left border-right border-dark big wl">
                        Номер по порядку
                    </div>
                    <div className="border-bottom border-left border-right border-dark num"><strong>1</strong></div>
                    <ActKS3TableColumn services={services} column={1} order={order}/>
                </div>

                <div
                    className="ActKS3TableBody_column  d-flex justify-content-between flex-column flex-grow-1">
                    <div className="border-bottom  border-right border-dark big">
                        Наименование пусковых комплексов, этапов, объектов, видов выполненных работ, оборудования,
                        затрат
                    </div>
                    <div className="border-bottom  border-right border-dark num"><strong>2</strong></div>
                    <ActKS3TableColumn services={services} column={2} order={order}/>
                </div>
                <div
                    className="ActKS3TableBody_column d-flex justify-content-between flex-column flex-grow-0">
                    <div className="border-bottom  border-right border-dark big wl">
                        Код
                    </div>
                    <div className="border-bottom  border-right border-dark num"><strong>3</strong></div>
                    <ActKS3TableColumn services={services} column={3} order={order}/>
                </div>

                <div
                    className="ActKS3TableBody_column d-flex justify-content-center flex-column flex-grow-1">
                    <div className="border-bottom  border-right border-dark name">
                        Стоимость выполненных работ и затрат, руб.
                    </div>

                    <div className="d-flex p-0">
                        <div className="d-flex justify-content-center flex-column flex-grow-1 wm">
                            <div className="border-bottom  border-right border-dark medium">с начала проведения работ
                            </div>
                            <div className="border-bottom  border-right border-dark num"><strong>4</strong></div>
                            <ActKS3TableColumn services={services} column={4} order={order}/>
                        </div>
                        <div className="d-flex justify-content-center flex-column flex-grow-1 wm">
                            <div className="border-bottom  border-right border-dark medium">с начала года</div>
                            <div className="border-bottom  border-right border-dark num"><strong>5</strong></div>
                            <ActKS3TableColumn services={services} column={5} order={order}/>
                        </div>
                        <div className="d-flex justify-content-center flex-column flex-grow-1 wm">
                            <div className="border-bottom  border-right border-dark medium">в том числе за отчетный
                                период
                            </div>
                            <div className="border-bottom  border-right border-dark num"><strong>6</strong></div>
                            <ActKS3TableColumn services={services} column={6} order={order}/>
                        </div>
                    </div>

                </div>

            </div>
        );
    }
}

export default ActKS3TableBody;