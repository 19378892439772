import {
	ADD_DATES_DAY, DELETE_ADDRESS, DELETE_DATES_DAY,
	FETCH_DATES_ERROR,
	FETCH_DATES_START,
	FETCH_DATES_SUCCESS,
	SAVE_ADMIN_DATES_ERROR, SAVE_ADMIN_DATES_NOTHING,
	SAVE_ADMIN_DATES_START, SAVE_ADMIN_DATES_SUCCESS, SET_ADDRESS, SET_DATE_ADDRESS, SET_DATES_ADDRESS_PROPERTY,
	SET_DATES_DAY,
	SET_DATES_PROPERTY
} from "./actionTypes";
import axios from "../../rest/axios";
import {store} from "../../index";
import qs from "qs";

export function fetchDates() {
	return async dispatch => {
		let token = localStorage.getItem('token')
		if (token) {
			try {
				dispatch(fetchDatesStart())
				const dates = await axios.get('/admin/dates/', {
					params: {
						token
					}
				});
				dispatch(fetchDatesSuccess(dates.data.data))
			} catch (e) {
				dispatch(fetchDatesError(e))
			}
		}
	}
}

function fetchDatesStart() {
	return {
		type: FETCH_DATES_START
	}
}

function fetchDatesSuccess(date) {
	return {
		type: FETCH_DATES_SUCCESS,
		payload: {
			dates: date.dates,
			addresses: date.addresses,
		}
	}
}

function fetchDatesError(error) {
	return {
		type: FETCH_DATES_ERROR,
		payload: {
			error
		}
	}
}

export function setDatesDay(id, day) {
	return {
		type: SET_DATES_DAY,
		payload: {
			id,
			day
		}
	}
}

export function addDatesDay(day) {
	return {
		type: ADD_DATES_DAY,
		payload: {
			day
		}
	}
}

export function deleteDatesDay(id) {
	return {
		type: DELETE_DATES_DAY,
		payload: {id}
	}
}

export function setDatesProperty(value, property) {
	return {
		type: SET_DATES_PROPERTY,
		payload: {
			value,
			property
		}
	}
}

export function saveAdminDates() {
	return async dispatch => {
		let token = localStorage.getItem('token')
		if (token) {
			dispatch(saveStart())
			try {
				const dates = store.getState().adminDates.dates

				const datesEdited = dates.filter(date => date.edited)

				if (!!datesEdited.length) {
					let response = await axios.post('/admin/saveDates/', qs.stringify({dates: datesEdited, token}))

					await dispatch(saveSuccess(response.data.data))

					let errors = response.data.data.filter(data => {
						return !data.result
					})

					if (!errors.length) {
						dispatch(fetchDates())
					}

				} else {
					dispatch(saveNothing())
				}


			} catch (e) {
				dispatch(saveError(e))
			}
		}
	}
}


function saveNothing() {
	return {
		type: SAVE_ADMIN_DATES_NOTHING
	}
}

function saveStart() {
	return {
		type: SAVE_ADMIN_DATES_START
	}
}

function saveSuccess(saveResult) {
	return {
		type: SAVE_ADMIN_DATES_SUCCESS,
		payload: {
			saveResult
		}
	}
}

function saveError(error) {
	return {
		type: SAVE_ADMIN_DATES_ERROR,
		payload: {
			error
		}
	}
}

export function setAddressProperty(id, value, property) {
	return {
		type: SET_DATES_ADDRESS_PROPERTY,
		payload: {
			id, value, property
		}
	}
}

export function addAddress(address) {
	return {
		type: SET_ADDRESS,
		payload: {
			address
		}
	}
}

export function deleteAddress(id) {
	return {
		type: DELETE_ADDRESS,
		payload: {
			id
		}
	}
}


export function saveAdminAddresses() {
	return async dispatch => {
		let token = localStorage.getItem('token')
		if (token) {
			dispatch(saveStart())
			try {
				const addresses = store.getState().adminDates.addresses

				const addressesEdited = addresses.filter(address => address.edited)
				if (!!addressesEdited.length) {
					let response = await axios.post('/admin/dates/address/collection/', qs.stringify({
						items: addressesEdited,
						token
					}))
					await dispatch(saveSuccess(response.data.data))

					dispatch(fetchDates())

					// let errors = response.data.data.filter(data => {
					//     return !data.result
					// })
					//
					// if (!errors.length) {
					//     console.log(response)
					//     dispatch(fetchDates())
					// }

				} else {
					dispatch(saveNothing())
				}


			} catch (e) {
				dispatch(saveError(e))
			}
		}
	}
}

export function setDateAddress(dateId, addressId) {
	return {type: SET_DATE_ADDRESS, payload: {dateId, addressId}}
}
