import React, {useEffect, useState} from 'react';
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import {getSpecialization} from '../../../../utils/roleFunctions';

const WorkerPersonalAvatar = ({user}) => {
	const {picture, name, role, professionMain = {}} = user;
	const [userName, setUserName] = useState('');
	useEffect(() => {
		if (role) {
			setUserName(getSpecialization(role).name);
		}
	}, [role])

	return (
		<div className='wp__avatar'>
			<Avatar alt={name || ''} src={picture} className={'wp__avatar_medium'}/>
			<Typography
				color="textPrimary"
				variant="h6"
				className="px-4 text-center"
			>
				{name || ''}
			</Typography>
			<Typography
				color="textSecondary"
				variant="body1"
			>
				{userName} - {professionMain && professionMain.name}
			</Typography>

		</div>
	);
};

export default WorkerPersonalAvatar;