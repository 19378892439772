import React from 'react';
import styled, { css } from 'styled-components';
import { ROW_HEIGHT } from '../config';

const StyledTableLeftRow = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px 0 5px;
  width: 100%;
  height: ${ROW_HEIGHT};
  background: #fff;
  border-bottom: 1px solid #E2E4F1;
  border-right: 1px solid #E2E4F1;
  color: #292929;
  transition: background 300ms;

  ${({ onClick }) => onClick && css`
    cursor: pointer;
  `}
  &:hover {
    background: #F3F0FC;
  }
`;

const ChartsTableLeftRow = ({ children, onClick }) => {
    return (
        <StyledTableLeftRow onClick={onClick}>
            {children}
        </StyledTableLeftRow>
    );
};

export default ChartsTableLeftRow;
