import React from 'react';
import ChartsTableRange from './ChartsTableRange';
import { useSelector } from 'react-redux';
import { COLORS } from '../config';

const ChartsTableRanges = ({ serviceOpen }) => {
    const { changedData } = useSelector(state => state.estimateOrder);
    return (
        <div>
            {
                changedData.map((service, idx) => {
                    const color = COLORS[idx%10]
                    return (
                        <ChartsTableRange
                            key={service.id}
                            service={service}
                            color={color}
                            serviceOpen={serviceOpen}
                        />
                    )
                })
            }
        </div>
    );
};

export default ChartsTableRanges;
