import React, {Component} from 'react';
import './ActKSBottom.scss'
import ActKsBottomItem from "./ActKsBottomItem/ActKsBottomItem";

class ActKsBottom extends Component {
    render() {
        return (
            <div className="ActKsBottom">
                <div className='row'>
                    <div className="col-1 d-flex justify-content-start align-items-end">Сдал</div>
                    <div className="col-4">
                        <ActKsBottomItem
                            text="Генеральный директор"
                            underLineDesc="(должность)"
                        />
                    </div>
                    <div className="col-3">
                        <ActKsBottomItem underLineDesc="(подпись)"/>
                    </div>
                    <div className="col-4">
                        <ActKsBottomItem
                            text="Ратко"
                            underLineDesc="(Расшифровка подписи)"/>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-1 d-flex justify-content-start align-items-end">Принял</div>
                    <div className="col-4">
                        <ActKsBottomItem
                            underLineDesc="(должность)"
                        />
                    </div>
                    <div className="col-3">
                        <ActKsBottomItem underLineDesc="(подпись)"/>
                    </div>
                    <div className="col-4">
                        <ActKsBottomItem
                            underLineDesc="(Расшифровка подписи)"/>
                    </div>
                </div>

            </div>
        );
    }
}

export default ActKsBottom;