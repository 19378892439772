import React, {useEffect, useState} from 'react';
import {Paper} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import {useDispatch, useSelector} from 'react-redux';
import {setOrderClassifier} from '../../../../../store/actions/adminOrderListActions';
import {getAdminOrderListTasksState} from '../selectors';
// import * as moment from 'moment';


const AdminOrderListDetailModal = ({onClose, order, classifiers}) => {
	const {workers} = useSelector(getAdminOrderListTasksState)
	const [activeClassifier, setActiveClassifier] = useState('');
	const [acceptedClassifiers, setAcceptedClassifiers] = useState([])

	const dispatch = useDispatch();
	const setClassifierHandler = (e) => {
		setActiveClassifier(e.target.value)
	}
	const setOrderClassifierHandler = () => {
		// console.log(activeClassifier)
		if (!!workers.length && !!activeClassifier) {
			let zones = [];
			workers.forEach((worker) => {
				worker.tasks.forEach((task) => {
					task.items.forEach(item => {
						zones.push(item.id)
					})
				})
			})
			zones = zones.filter((value, index, self) => self.indexOf(value) === index)
			dispatch(setOrderClassifier(order.id, activeClassifier, 0, zones))
			onClose()
		}
	}

	useEffect(() => {
		if (!!acceptedClassifiers.length) {
			setActiveClassifier(acceptedClassifiers[0]['id'])
		}
	}, [acceptedClassifiers])

	useEffect(() => {
		setAcceptedClassifiers(classifiers.filter(c => order.classifierTypes.map(t => t.id).indexOf(c.id) === -1))
	}, [classifiers, order])

	return (
		<div className={`AdminOrderList__modal js-close`}
			 onClick={onClose}>
			<Paper className="p-3">
				<form>
					<Typography>Выберите классификатор</Typography>
					<select className="AdminOrderList__select classifier__select" onChange={setClassifierHandler}
							value={activeClassifier || ''}>
						{
							acceptedClassifiers.map(classifier => {
								return <option key={classifier.id} value={classifier.id}>{classifier.name}</option>
							})
						}
					</select>
					<CloseIcon className="AdminOrderList__modal-close js-close"/>
					<Button variant="contained"
							color="primary"
							onClick={setOrderClassifierHandler}
							className="mt-3">
						Подтвердить
					</Button>
				</form>
			</Paper>
		</div>
	);
};

export default AdminOrderListDetailModal;