import React, { useEffect, useState } from 'react';
import './Charts.scss';
import DefaultLayout from '../../../../layout/DefaultLayout/DefaultLayout';
import ChartsContextProvider from './ChartsContext';
import ChartsCalculation from './ChartsCalculation';
import ComplexConfirm from '../Confirm/ComplexConfirm';
import { useSelector } from 'react-redux';
import ScrollPage from '../../../../layout/ScrollPage/ScrollPage';
import ChartsTune from './ChartsTune';

const Сharts = () => {
    const [confirmPage, setConfirmPage] = useState(1);
    const { services } = useSelector(state => state.estimateOrder);

    return (
        <ChartsContextProvider>
            <DefaultLayout noWrap>
                {
                    confirmPage === 1 && <ChartsTune setPage={setConfirmPage} />
                }
                {
                    confirmPage === 2 && <ComplexConfirm setPage={setConfirmPage} />
                }
                {
                    !!services.length && <ChartsCalculation />
                }
                <ScrollPage />
            </DefaultLayout>
        </ChartsContextProvider>
    );
};

export default Сharts;
