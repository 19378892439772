import React, {useEffect, useState} from 'react';
import './AdminOrderSettings.scss';
import {useDispatch, useSelector} from 'react-redux';
import {fetchOrderConditions} from '../../../../store/actions/serviceSelectActions';
import {getServiceSelectState} from '../../../../store/selectors';
import {TextField, Typography} from '@material-ui/core';
import AdminBtn from '../AdminUI/AdminBtn';
import {updateOrderSettings} from '../../../../store/actions/adminClassifiersActions';

const AdminOrderSettings = () => {
		const dispatch = useDispatch()
		const {conditions} = useSelector(getServiceSelectState);
		const [localConditions, setLocalConditions] = useState({});

		const editHandler = (id, value, property, conditionName) => {
			const conditions = {...localConditions};
			const condition = conditions[conditionName].find((condition) => condition.id === id);
			if (condition) {
				condition[property] = value;
			}
			setLocalConditions(conditions);
		}

		const saveHandler = (id, conditionName) => {
			const conditions = {...localConditions};
			const condition = conditions[conditionName].find((condition) => condition.id === id);
			if (condition) {
				condition.edit = false;
				console.log(condition)
				dispatch(updateOrderSettings(condition, conditionName))
			}
			setLocalConditions(conditions);
		}

		useEffect(() => {
			dispatch(fetchOrderConditions())
		}, []);

		useEffect(() => {
			setLocalConditions(conditions)
		}, [conditions]);

		return (
			<div>
				<h3 className='text-center'>Настройки заказа</h3>


				<div className="d-flex align-items-center w-100 mt-3">
					<Typography variant="h6" className="w-70 pl-3">
						Название
					</Typography>
					<Typography variant="h6" className="pl-5">
						Процент, %
					</Typography>
				</div>
				<hr/>
				<Typography variant="subtitle1" className="pl-3">
					<strong>Расположение объекта</strong>
				</Typography>

				{
					localConditions.locationObjectConditions && !!localConditions.locationObjectConditions.length
					&& <>
						{
							localConditions.locationObjectConditions.map((location) => {
								return <div key={location.id} className="d-flex w-100 mb-1">
									{
										location.edit
											?
											<div className="d-flex w-100">
												<TextField
													className="AdminOrderSettings__title"
													onChange={(e) => editHandler(location.id, e.target.value, 'title', 'locationObjectConditions')}
													value={location.title}
												/>

												<TextField
													className="AdminOrderSettings__percent"
													onChange={(e) => editHandler(location.id, e.target.value, 'percent', 'locationObjectConditions')}
													value={location.percent}
												/>
											</div>
											: <div className="d-flex w-100">
												<Typography
													className="AdminOrderSettings__title">{location.title}</Typography>
												<Typography
													className="AdminOrderSettings__percent">{location.percent}</Typography>
											</div>
									}


									<Typography>
										{
											location.edit
												? <AdminBtn type="save"
															tip="Сохранить"
															onClick={() => saveHandler(location.id, 'locationObjectConditions')}
												/>
												: <AdminBtn type="edit"
															tip="Редактировать"
															onClick={() => editHandler(location.id, true, 'edit', 'locationObjectConditions')}
												/>
										}
									</Typography>

								</div>
							})
						}
					</>
				}

				<div className="mt-3">
					<Typography variant="subtitle1" className="pl-3">
						<strong>Этажность</strong>
					</Typography>

					{
						localConditions.floorConditions && !!localConditions.floorConditions.length
						&& <>
							{
								localConditions.floorConditions.map((location) => {
									return <div key={location.id} className="d-flex w-100 mb-1">
										{
											location.edit
												?
												<div className="d-flex w-100">
													<TextField
														className="AdminOrderSettings__title"
														onChange={(e) => editHandler(location.id, e.target.value, 'title', 'floorConditions')}
														value={location.title}
													/>

													<TextField
														className="AdminOrderSettings__percent"
														onChange={(e) => editHandler(location.id, e.target.value, 'percent', 'floorConditions')}
														value={location.percent}
													/>
												</div>
												: <div className="d-flex w-100">
													<Typography
														className="AdminOrderSettings__title">{location.title}</Typography>
													<Typography
														className="AdminOrderSettings__percent">{location.percent}</Typography>
												</div>
										}


										<Typography>
											{
												location.edit
													? <AdminBtn type="save"
																tip="Сохранить"
																onClick={() => saveHandler(location.id, 'floorConditions')}
													/>
													: <AdminBtn type="edit"
																tip="Редактировать"
																onClick={() => editHandler(location.id, true, 'edit', 'floorConditions')}
													/>
											}
										</Typography>

									</div>
								})
							}
						</>
					}
				</div>

				<div className="mt-3">
					<Typography variant="subtitle1" className="pl-3">
						<strong>Инструменты</strong>
					</Typography>

					{
						localConditions.instrumentConditions && !!localConditions.instrumentConditions.length
						&& <>
							{
								localConditions.instrumentConditions.map((location) => {
									return <div key={location.id} className="d-flex w-100 mb-1">
										{
											location.edit
												?
												<div className="d-flex w-100">
													<TextField
														className="AdminOrderSettings__title"
														onChange={(e) => editHandler(location.id, e.target.value, 'title', 'instrumentConditions')}
														value={location.title}
													/>

													<TextField
														className="AdminOrderSettings__percent"
														onChange={(e) => editHandler(location.id, e.target.value, 'percent', 'instrumentConditions')}
														value={location.percent}
													/>
												</div>
												: <div className="d-flex w-100">
													<Typography
														className="AdminOrderSettings__title">{location.title}</Typography>
													<Typography
														className="AdminOrderSettings__percent">{location.percent}</Typography>
												</div>
										}


										<Typography>
											{
												location.edit
													? <AdminBtn type="save"
																tip="Сохранить"
																onClick={() => saveHandler(location.id, 'instrumentConditions')}
													/>
													: <AdminBtn type="edit"
																tip="Редактировать"
																onClick={() => editHandler(location.id, true, 'edit', 'instrumentConditions')}
													/>
											}
										</Typography>

									</div>
								})
							}
						</>
					}
				</div>

				<div className="mt-3">
					<Typography variant="subtitle1" className="pl-3">
						<strong>Оборудование</strong>
					</Typography>

					{
						localConditions.equipmentConditions && !!localConditions.equipmentConditions.length
						&& <>
							{
								localConditions.equipmentConditions.map((location) => {
									return <div key={location.id} className="d-flex w-100 mb-1">
										{
											location.edit
												?
												<div className="d-flex w-100">
													<TextField
														className="AdminOrderSettings__title"
														onChange={(e) => editHandler(location.id, e.target.value, 'title', 'equipmentConditions')}
														value={location.title}
													/>

													<TextField
														className="AdminOrderSettings__percent"
														onChange={(e) => editHandler(location.id, e.target.value, 'percent', 'equipmentConditions')}
														value={location.percent}
													/>
												</div>
												: <div className="d-flex w-100">
													<Typography
														className="AdminOrderSettings__title">{location.title}</Typography>
													<Typography
														className="AdminOrderSettings__percent">{location.percent}</Typography>
												</div>
										}


										<Typography>
											{
												location.edit
													? <AdminBtn type="save"
																tip="Сохранить"
																onClick={() => saveHandler(location.id, 'equipmentConditions')}
													/>
													: <AdminBtn type="edit"
																tip="Редактировать"
																onClick={() => editHandler(location.id, true, 'edit', 'equipmentConditions')}
													/>
											}
										</Typography>

									</div>
								})
							}
						</>
					}
				</div>

				<div className="mt-3">
					<Typography variant="subtitle1" className="pl-3">
						<strong>Жилье</strong>
					</Typography>

					{
						localConditions.housingConditions && !!localConditions.housingConditions.length
						&& <>
							{
								localConditions.housingConditions.map((location) => {
									return <div key={location.id} className="d-flex w-100 mb-1">
										{
											location.edit
												?
												<div className="d-flex w-100">
													<TextField
														className="AdminOrderSettings__title"
														onChange={(e) => editHandler(location.id, e.target.value, 'title', 'housingConditions')}
														value={location.title}
													/>

													<TextField
														className="AdminOrderSettings__percent"
														onChange={(e) => editHandler(location.id, e.target.value, 'percent', 'housingConditions')}
														value={location.percent}
													/>
												</div>
												: <div className="d-flex w-100">
													<Typography
														className="AdminOrderSettings__title">{location.title}</Typography>
													<Typography
														className="AdminOrderSettings__percent">{location.percent}</Typography>
												</div>
										}


										<Typography>
											{
												location.edit
													? <AdminBtn type="save"
																tip="Сохранить"
																onClick={() => saveHandler(location.id, 'housingConditions')}
													/>
													: <AdminBtn type="edit"
																tip="Редактировать"
																onClick={() => editHandler(location.id, true, 'edit', 'housingConditions')}
													/>
											}
										</Typography>

									</div>
								})
							}
						</>
					}
				</div>

				<div className="mt-3">
					<Typography variant="subtitle1" className="pl-3">
						<strong>Расположение материала</strong>
					</Typography>

					{
						localConditions.materialPlaceConditions && !!localConditions.materialPlaceConditions.length
						&& <>
							{
								localConditions.materialPlaceConditions.map((location) => {
									return <div key={location.id} className="d-flex w-100 mb-1">
										{
											location.edit
												?
												<div className="d-flex w-100">
													<TextField
														className="AdminOrderSettings__title"
														onChange={(e) => editHandler(location.id, e.target.value, 'title', 'materialPlaceConditions')}
														value={location.title}
													/>

													<TextField
														className="AdminOrderSettings__percent"
														onChange={(e) => editHandler(location.id, e.target.value, 'percent', 'materialPlaceConditions')}
														value={location.percent}
													/>
												</div>
												: <div className="d-flex w-100">
													<Typography
														className="AdminOrderSettings__title">{location.title}</Typography>
													<Typography
														className="AdminOrderSettings__percent">{location.percent}</Typography>
												</div>
										}


										<Typography>
											{
												location.edit
													? <AdminBtn type="save"
																tip="Сохранить"
																onClick={() => saveHandler(location.id, 'materialPlaceConditions')}
													/>
													: <AdminBtn type="edit"
																tip="Редактировать"
																onClick={() => editHandler(location.id, true, 'edit', 'materialPlaceConditions')}
													/>
											}
										</Typography>

									</div>
								})
							}
						</>
					}
				</div>

				<div className="mt-3">
					<Typography variant="subtitle1" className="pl-3">
						<strong>Классификаторы</strong>
					</Typography>

					{
						localConditions.classifiersConditions && !!localConditions.classifiersConditions.length
						&& <>
							{
								localConditions.classifiersConditions.map((location) => {
									return <div key={location.id} className="d-flex w-100 mb-1">
										{
											location.edit
												?
												<div className="d-flex w-100">
													<TextField
														className="AdminOrderSettings__title"
														onChange={(e) => editHandler(location.id, e.target.value, 'title', 'classifiersConditions')}
														value={location.title}
													/>

													<TextField
														className="AdminOrderSettings__percent"
														onChange={(e) => editHandler(location.id, e.target.value, 'percent', 'classifiersConditions')}
														value={location.percent}
													/>
												</div>
												: <div className="d-flex w-100">
													<Typography
														className="AdminOrderSettings__title">{location.title}</Typography>
													<Typography
														className="AdminOrderSettings__percent">{location.percent}</Typography>
												</div>
										}


										<Typography>
											{
												location.edit
													? <AdminBtn type="save"
																tip="Сохранить"
																onClick={() => saveHandler(location.id, 'classifiersConditions')}
													/>
													: <AdminBtn type="edit"
																tip="Редактировать"
																onClick={() => editHandler(location.id, true, 'edit', 'classifiersConditions')}
													/>
											}
										</Typography>

									</div>
								})
							}
						</>
					}
				</div>

			</div>
		);
	}
;

export default AdminOrderSettings;