import React, { forwardRef, useEffect, useMemo } from "react";
import NumberFormat from "react-number-format";
import {
    getTotalCategoryMaterialsPrice,
    getTotalCategoryServicesPrice,
} from "./calculateFunctions";
import EstimateOrderHead from "./EstimateOrderHead";
import EstimateOrderCategoryRow from "./Rows/EstimateOrderCategoryRow";
import { useDispatch, useSelector } from 'react-redux';
import { removeCategory, removeSubCategory } from '../../../../../../store/actions/estimateActions';

const EstimateOrderToPrint = forwardRef((props, ref) => {
    const {
        services,
        categories: estimateCategories,
        subCategories: estimateSubCategories,
        formControls: options
    } = useSelector(state => state.estimateOrder);
    const dispatch = useDispatch();
    const { categories, clearPrintContent } = props;
    const totalMaterialsPrice = useMemo(() => getTotalCategoryMaterialsPrice(services), [services]);
    const totalWorkPrice = useMemo(() => getTotalCategoryServicesPrice(
        services,
        options.workType.value,
        options
    ), [services]);
    const totalPrice =
        options.supplyMaterials.value === "customer"
            ? totalWorkPrice
            : totalWorkPrice + totalMaterialsPrice;

    useEffect(() => {
        const categoriesId = [];
        const subCategoriesId = [];
        services.forEach(({ category, subCategoryId }) => {
            categoriesId.push(category.id);
            subCategoriesId.push(subCategoryId);
        });
        estimateCategories.forEach(({ id }) => {
            if (!categoriesId.includes(id)) {
                dispatch(removeCategory(id));
            }
        });
        estimateSubCategories.forEach(({ id }) => {
            if (!subCategoriesId.includes(id)) {
                dispatch(removeSubCategory(id));
            }
        });

    }, [services]);


    return (
        <div
            ref={ref}
            className="table-wrapper text-center overflow-auto">
            <EstimateOrderHead />
            <table className="table table-bordered table-hover">
                <thead>
                <tr className="align-middle">
                    <th className="align-middle" rowSpan="2">
                        №
                    </th>
                    <th style={{ width: "40%" }} className="align-middle" rowSpan="2">
                        Наименование работ
                    </th>
                    <th style={{ width: "3%" }} className="align-middle" rowSpan="2">
                        Ед.изм.
                    </th>
                    <th style={{ width: "10%" }} className="align-middle" rowSpan="2">
                        Кол-во
                    </th>
                    <th
                        className="align-middle"
                        colSpan={
                            options.supplyMaterials.value === "performer" ? "2" : "1"
                        }
                    >
                        Цена
                    </th>
                    <th style={{ width: "12%" }} className="align-middle" rowSpan="2">
                        Сумма
                    </th>
                    {
                        !clearPrintContent &&
                        <th style={{ width: "6%" }} className="align-middle" rowSpan="2">Действия</th>
                    }
                </tr>
                <tr>
                    {options.supplyMaterials.value === "performer" && (
                        <th>Материалы</th>
                    )}
                    <th>Работа</th>
                </tr>
                </thead>
                <tbody>
                {estimateCategories.map((orderCategory) => {
                    const category = categories.find(
                        (cat) => cat.id === orderCategory.id
                    );
                    const subCategories = category.subCategory.filter((subC) =>
                        estimateSubCategories.find((e) => e.id === subC.id)
                    );
                    const categoryServices = services.filter(
                        (service) => service.category.id === orderCategory.id
                    );
                    return (
                        <EstimateOrderCategoryRow
                            key={category.id}
                            category={category}
                            subCategories={subCategories}
                            categoryServices={categoryServices}
                            options={options}
                            clearPrintContent={clearPrintContent}
                        />
                    );
                })}
                <tr>
                    <td />
                    <td colSpan="3" className="text-left">
                        <h5>
                            <b style={{ fontSize: "1.3rem" }}>ВСЕГО: </b>
                        </h5>
                    </td>
                    {options.supplyMaterials.value === "performer" && (
                        <td>
                            <b>
                                <NumberFormat
                                    value={totalMaterialsPrice.toFixed(2)}
                                    displayType="text"
                                    thousandSeparator={" "}
                                />
                            </b>
                        </td>
                    )}
                    <td>
                        <b>
                            <NumberFormat
                                value={totalWorkPrice.toFixed(2)}
                                displayType="text"
                                thousandSeparator={" "}
                            />
                        </b>
                    </td>
                    <td>
                        <b>
                            <NumberFormat
                                value={totalPrice.toFixed(2)}
                                displayType="text"
                                thousandSeparator={" "}
                            />
                        </b>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    );
});
export default EstimateOrderToPrint;

