export const ROLE_ADMINISTRATORS = 1;
export const ROLE_MASTER = 5;
export const ROLE_MANAGER = 6;
export const ROLE_SPECIALIST = 7;
export const ROLE_ADMINS = 8;
export const ROLE_CLIENT = 9;
export const ROLE_ANCILLARY = 10;
export const ROLE_INDEPENDENT_MASTER = 11;
export const ROLE_PROJECT_DIRECTOR = 12;
export const ROLE_GEODESIST = 13;
export const ROLE_SUPPLIER = 14;
export const ROLE_ENGINEER_PTO = 15;

export const HOME_ANCHOR = 'header';
export const ABOUT_ANCHOR = 'about';
export const COTNACTS_ANCHOR = 'contacts';
export const SERVICE_SELECT_ANCHOR = 'service_select';
export const CALCULATOR_ANCHOR = 'calculator';
export const CHARTS_ANCHOR = 'charts';
export const CONFIRM_COMPLEX_ANCHOR = 'confirm_complex';
export const CONFIRM_ORDER = 'confirm_order';
export const ONE_CLICK_ESTIMATE = 'one-click-estimate';

export const TITLE_CATEGORY = 'Вид работ';
export const TITLE_SUB_CATEGORY = 'Раздел';
export const TITLE_SERVICE = 'Наименование работ';

