import React from 'react';
import styled from 'styled-components';
import { DATE_WIDTH, ROW_HEIGHT, WORKER_COLORS } from '../config';
import 'react-tippy/dist/tippy.css';
import {
    Tooltip,
} from 'react-tippy';

const StyledWorkerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledWorker = styled.div`
  height: ${ROW_HEIGHT};
  width: ${DATE_WIDTH};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ color }) => color};
`;

const ChartsWorkerCountRowItem = ({ workers }) => {
    return (
        <StyledWorkerContainer>
            {
                !!workers && workers.map((worker, idx) => {
                    return (
                        <Tooltip
                            title= {worker.name}
                            position="top"
                            interactive
                            key={idx}
                        >
                        <StyledWorker color={WORKER_COLORS[worker.id]}>
                            {worker.count}
                        </StyledWorker>
                        </Tooltip>
                    );
                })
            }
        </StyledWorkerContainer>
    );
};

export default ChartsWorkerCountRowItem;
