import React from 'react'
import './Chat.scss'

import {connect} from "react-redux";
import {readMsgs, setDialogProperty} from "../../../store/actions/dialogActions";
import ChatInput from "./ChatInput/ChatInput";
import ChatCurrentImage from "./ChatCurrentImage/ChatCurrentImage";
import ChatMessages from "./ChatMessages/ChatMessages";
import ChatLoader from "../ChatLoader/ChatLoader";
import ChatBtnDown from "./ChatBtnDown/ChatBtnDown";


class Chat extends React.Component {

    input = React.createRef()

    state = {
        showScrollBtn: false,
        firstScrolled: [],
        read: false
    }

    dialogs = React.createRef()

    componentDidUpdate = async (prevProps, prevState, snapshot) => {

        let {currentChat, dialog, dialogs, orderId} = this.props

        let input = null
        if (this.input.current !== null) {
            input = this.input.current.querySelector('input[name="msg"]')
        }

        if (dialog.currentImage.src || currentChat) {
            if (input) {

                // console.log(input.value)

                // if (input.value) {
                //     input.classList.add('focus')
                // } else {
                //     input.classList.remove('focus')
                // }
                // input.addEventListener('focus', () => {
                //     input.classList.add('focus')
                // })
                // input.addEventListener('blur', () => {
                //     input.classList.remove('focus')
                // })
                input.focus()
            }


            const dialogContainer = this.dialogs.current

            const showScrollBtn = this.state.showScrollBtn
            if (!showScrollBtn) {
                const last = dialogContainer.querySelector('.msg-last')
                if (!!last) {
                    last.scrollIntoView({block: "start", behavior: "smooth"})
                }

            }

            // console.log(currentChat)
            const firstScrolled = [...this.state.firstScrolled]

            if (firstScrolled.indexOf(currentChat) === -1) {
                // dialogContainer.scrollTo({
                //     top: dialogContainer.scrollHeight,
                //     behavior: "smooth"
                // });

                dialogContainer.scrollTop = dialogContainer.scrollHeight

                firstScrolled.push(currentChat)
                this.setState({
                    firstScrolled
                })
                // console.log(firstScrolled)
            }


        }

        if (!prevState.read)
            if (currentChat && !this.state.read) {
                const currentDialog = dialogs[currentChat]
                const read = currentDialog.unreads

                orderId = currentDialog.opponent.role === 8 || currentDialog.opponent.role === 1 ? 'admin' : orderId

                if (read && read.length > 0) {
                    await this.props.readMsgs(orderId, currentChat, read)
                    this.setState({read: true})
                }
            }

    }

    closeCurrentImage = () => {
        this.props.setDialogProperty({}, 'currentImage')
    }

    scrollHandler = (element) => {
        // console.dir(e.target)

        const scrollHeight = element.scrollHeight
        const scrollTop = element.scrollTop
        const offsetHeight = element.offsetHeight
        let diff = scrollHeight - scrollTop - offsetHeight

        const showScrollBtn = this.state.showScrollBtn

        // console.log(showScrollBtn, diff)
        if (diff > 600 && !showScrollBtn) {
            this.setState({
                showScrollBtn: true
            })
        } else if (diff <= 600 && showScrollBtn) {
            this.setState({
                showScrollBtn: false
            })
        }
    }

    btnDownHandler = () => {
        // console.dir(this.dialogs.current)
        // this.dialogs.current.scrollTop = this.dialogs.current.scrollHeight
        this.dialogs.current.scrollTo({
            top: this.dialogs.current.scrollHeight,
            behavior: "smooth"
        });
    }

    componentDidMount() {
        // console.log(this.dialogs.current)
        // this.scrollHandler(this.dialogs.current)
    }


    render() {

        const {dialogs, currentChat, profile, sendMessageHandler} = this.props
        const {currentImage, loading} = this.props.dialog
        const {showScrollBtn} = this.state

        let messages = []
        if (currentChat) {
            messages = dialogs[currentChat]['messages']
        }

        return (
            <div className="Chat">
                {
                    currentChat ?
                        <div className="d-flex flex-column w-100 position-relative">
                            <div className="Chat_dialogs"
                                 onScroll={(e) => this.scrollHandler(e.target)}
                                 ref={this.dialogs}>
                                <ChatMessages messages={messages} profile={profile}/>
                            </div>
                            {
                                // Подгружаемое фото
                                currentImage.src ?
                                    <ChatCurrentImage onClose={this.closeCurrentImage} src={currentImage.src}/>
                                    : null
                            }
                            {
                                showScrollBtn ?
                                    <ChatBtnDown onClick={this.btnDownHandler}/>
                                    : null
                            }
                            <div className="Chat_dialogs_input" ref={this.input}>
                                <ChatInput onSubmit={(e) => sendMessageHandler(e)}/>
                            </div>
                        </div>
                        :
                        <div className="Chat_empty">
                            Выберите, кому вы бы хотели написать
                        </div>
                }
                {
                    loading
                        ?
                        <ChatLoader/>
                        : null
                }
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        dialog: state.dialog
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setDialogProperty: (value, property) => dispatch(setDialogProperty(value, property)),
        readMsgs: (orderId, dialogId, read) => dispatch(readMsgs(orderId, dialogId, read))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Chat)