export const getConfirmedValue = (values) => {
    if (values.length > 0) {
        return values.reduce((min, current) => {
            return Math.min(min, current);
        });
    }
    return 0;
};

export const withMaterials = (order) => {
    if (!order.materials) {
        order.materials = [];
    }
    return order && !!order.materials.length;
};

export const getReports = (order) => {
    // определяем основную услугу, пока что она одна в массиве
    const service = order.services[0];
    // определяем подработы
    const subServices = service.items;
    const reports = order.reports;
    const acceptedReportValues = {};
    const remainsReportValues = {};
    let acceptedValue = 0;
    subServices.forEach((subService) => {
        remainsReportValues[subService.id] = 0;
    })

    reports.forEach((report, idx) => {
        report.services.forEach((service) => {
            remainsReportValues[service.id] += service.value;
        })
        const minValue = Math.min(...Object.values(remainsReportValues));
        let value = minValue - acceptedValue;
        acceptedValue += value;
        acceptedReportValues[report.date] = value;
    })
    return acceptedReportValues;
}
