import React from 'react';
import { Collapse } from 'react-collapse';
import { Typography } from '@material-ui/core';
import ChartsTableLeftRow from './ChartsTableLeftRow';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { LEFT_WIDTH } from '../config';


const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.875rem !important;
  font-weight: 600 !important;
  line-height: 1.1 !important;
  letter-spacing: 0.00714em !important;
  flex: 1 0 auto;
  ${({ right }) => right && css`
    justify-content: flex-end;
  `}
`;
const StyledArrow = styled.span`
  position: absolute;
  top: 50%;
  right: -5px;
  transform: translateY(-50%);
`;

const ChartsTableLeftServices = ({ serviceOpen, setServiceOpen }) => {
    const { changedData } = useSelector(state => state.estimateOrder);
    const collapseHandler = (id) => {
        setServiceOpen(state => state === id ? null : id);
    };
    return (
        <>
            {
                changedData.map((service, idx) => {
                    const withItems = !!service.items.length;
                    const { daysCount, workersCount, startDate, endDate } = service;
                    return (
                        <div key={service.id} style={{ minWidth: LEFT_WIDTH }}>
                            <ChartsTableLeftRow onClick={
                                withItems
                                    ? () => collapseHandler(service.id)
                                    : null
                            }

                            >
                                <StyledTitle className="mx-2" style={{ width: 10 }}>
                                    {idx + 1}.
                                </StyledTitle>
                                <StyledTitle className="col-6" style={{ width: 325 }}>
                                    {service.name} [{service.id}]
                                </StyledTitle>
                                <StyledTitle className="col-auto" right style={{ width: 100 }}>
                                    {startDate}
                                </StyledTitle>
                                <StyledTitle className="col-auto" right style={{ width: 100 }}>
                                    {endDate}
                                </StyledTitle>
                                <StyledTitle className="col-1" right style={{ width: 50 }}>
                                    {daysCount}
                                </StyledTitle>
                                <StyledTitle className="col-1" right style={{ width: 50 }}>
                                    {!service.items.length ? workersCount : ''}
                                    {
                                        withItems && (
                                            <StyledArrow>
                                                {
                                                    service.id === serviceOpen
                                                        ? <IoIosArrowUp />
                                                        : <IoIosArrowDown />
                                                }
                                            </StyledArrow>
                                        )
                                    }
                                </StyledTitle>
                            </ChartsTableLeftRow>
                            <Collapse isOpened={service.id === serviceOpen}>
                                {
                                    service.items.map((item, item_idx) => {
                                            return (
                                                <ChartsTableLeftRow key={item.id}>
                                                    <Typography variant="body2" className="ml-3 mr-2">
                                                        {idx + 1}.{item_idx + 1}
                                                    </Typography>
                                                    <Typography variant="body2" className="col-auto" style={{ width: 315 }}>
                                                        {item.name}
                                                    </Typography>
                                                    <Typography variant="body2" className="col-auto" style={{ width: 100 }}>
                                                        {item.startDate || startDate}
                                                    </Typography>
                                                    <Typography variant="body2" className="col-auto" style={{ width: 100 }}>
                                                        {item.endDate || endDate}
                                                    </Typography>
                                                    <Typography variant="body2" className="col-1" style={{ width: 50 }}>
                                                        {item.daysCount || daysCount}
                                                    </Typography>
                                                    <Typography variant="body2" className="col-1" style={{ width: 50 }}>
                                                        {item.workersCount || workersCount}
                                                    </Typography>
                                                </ChartsTableLeftRow>
                                            );
                                        }
                                    )
                                }
                            </Collapse>
                        </div>
                    );
                })
            }
        </>
    );
};

export default ChartsTableLeftServices;
