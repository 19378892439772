import React from 'react';
import './OnlineTablo.scss';
import {useSelector} from 'react-redux';
import OnlineTabloDigits from './OnlineTabloDigits';

const OnlineTablo = () => {
	const {onlineTable: {orders, workers}} = useSelector(state => state.homePageAbout)

	return (
		<div className="OnlineTablo">
			<div className="OnlineTablo__title">
				Онлайн Табло
			</div>
			{
				!!workers && !!orders
				&& (<>
						<div>
							<div className="OnlineTablo__row-title">
								<div className="OnlineTablo__row-title-line mr-3"/>
								<svg width="24" height="30" viewBox="0 0 24 30" fill="none">
									<path
										d="M21.3515 20.9365C20.6784 20.1998 19.8673 19.84 18.8317 19.84H16.6497L15.2172 18.4694C14.1644 19.3261 12.8873 19.8229 11.5065 19.8229C10.212 19.8229 8.98665 19.3775 7.98563 18.6065L6.7084 19.8229H4.43754C3.28118 19.8229 2.38371 20.217 1.67608 21.0223C-0.377745 23.3866 -0.0325634 28.4409 0.122768 30H23.1118C23.2328 28.4237 23.4399 23.2495 21.3515 20.9365Z"
										fill="#8594A2"/>
									<path
										d="M5.52435 10.2113C5.11013 10.5539 4.83399 11.1022 4.83399 11.7361C4.83399 12.6784 5.45531 13.4494 6.24923 13.5865C6.42182 15.0085 7.00863 16.2592 7.88884 17.1844C8.07863 17.3729 8.26848 17.5613 8.47559 17.7155C8.73454 17.9211 9.01062 18.0925 9.30403 18.2466C9.95987 18.5893 10.6847 18.7777 11.4614 18.7777C12.2381 18.7777 12.963 18.5893 13.6188 18.2466C13.9122 18.0925 14.1883 17.9211 14.4472 17.7155C14.6544 17.5613 14.8442 17.3729 15.034 17.1844C15.897 16.2593 16.5011 15.0256 16.6564 13.6036C16.6737 13.6036 16.6909 13.6036 16.7082 13.6036C17.623 13.6036 18.3478 12.7641 18.3478 11.7361C18.3478 11.1022 18.0716 10.5539 17.6574 10.2113H5.52435Z"
										fill="#8594A2"/>
									<path
										d="M17.7673 6.61334C17.2323 4.84864 15.8171 3.04967 14.0221 2.12449V6.699C14.0221 6.73327 14.0221 6.7504 14.0221 6.76754C14.0049 6.956 13.9703 7.12733 13.8841 7.29866C13.6597 7.72698 13.211 8.03538 12.6932 8.03538H10.5186C10.0353 8.03538 9.60381 7.77838 9.37949 7.40146C9.27594 7.24726 9.22416 7.07599 9.18964 6.88747C9.17239 6.81894 9.17239 6.76754 9.17239 6.699V2.10736C7.37744 3.04967 5.9622 4.84864 5.42717 6.61334L5.30635 6.99027H4.32259V9.13189H18.8595V6.99027H17.8883L17.7673 6.61334Z"
										fill="#8594A2"/>
									<path
										d="M10.5122 7.50426H12.6696C12.9803 7.50426 13.2392 7.33293 13.3772 7.07593C13.4463 6.956 13.4808 6.83607 13.4808 6.699V1.06225C13.4808 0.925182 13.4463 0.805251 13.4117 0.68532C13.2565 0.291261 12.8595 0 12.4108 0H10.5122C10.098 0 9.75282 0.308394 9.7183 0.702453C9.7183 0.736719 9.70104 0.770985 9.70104 0.805251V6.699C9.70104 6.87033 9.75282 7.02453 9.83912 7.1616C9.9945 7.36719 10.2361 7.50426 10.5122 7.50426Z"
										fill="#8594A2"/>
								</svg>
								<div className="ml-3">Мастера</div>
								<div className="OnlineTablo__row-title-line ml-3"/>
							</div>
							<div className="d-flex justify-content-between align-items-center flex-wrap">
								<OnlineTabloDigits title="Всего" num={workers.free + workers.busy}/>
								<OnlineTabloDigits title="Заняты" variant="danger" num={workers.busy}/>
								<OnlineTabloDigits title="Свободны" variant="success" num={workers.free}/>
							</div>
						</div>
						<div className="mt-4">
							<div className="OnlineTablo__row-title">
								<div className="OnlineTablo__row-title-line mr-3"/>
								<svg width="28" height="28" viewBox="0 0 28 28" fill="none">
									<path
										d="M22.366 16.7968C21.8083 16.7968 21.3901 16.9367 20.9718 16.9367L16.9287 12.8813L20.2747 9.52507L24.5967 7.98681L27.8033 2.81268C27.9427 2.53299 27.9427 2.11347 27.8033 1.97363L26.1303 0.295529C25.8515 0.0158459 25.4332 0.0158459 25.1544 0.155687L19.8565 3.2322L18.1834 7.70713L14.8374 11.0633L11.0731 7.00792C11.0731 6.5884 11.2125 6.16887 11.2125 5.60951C11.2125 1.97363 7.72705 -0.683362 4.24158 0.155687L6.33286 2.25331C7.44821 3.37204 7.44821 5.05014 6.33286 6.16887C5.21751 7.28761 3.54449 7.28761 2.42914 6.16887L0.198446 4.21109C-0.916902 8.2665 2.8474 12.1821 7.02996 10.9235L11.0731 14.9789L9.40007 16.657C8.42414 16.3773 7.44821 16.657 6.75112 17.3562L1.03496 23.0897C-0.0803908 24.2084 -0.219809 25.8865 0.756121 27.0053C1.87147 28.2638 3.68391 28.2638 4.79926 27.1451L10.6548 21.2718C11.3519 20.5726 11.6308 19.4538 11.3519 18.6148L13.025 16.9367L17.0681 20.9921C16.9287 21.4116 16.9287 21.971 16.9287 22.3905C16.9287 26.0264 20.4141 28.6834 23.8996 27.8443L21.8083 25.7467C20.693 24.628 20.693 22.9499 21.8083 21.8311C22.9237 20.7124 24.5967 20.7124 25.712 21.8311L27.8033 23.9288C28.7793 20.2929 25.9909 16.7968 22.366 16.7968Z"
										fill="#8594A2"/>
								</svg>
								<div className="ml-3">Работы</div>
								<div className="OnlineTablo__row-title-line ml-3"/>
							</div>
							<div className="d-flex justify-content-between align-items-center flex-wrap">
								<OnlineTabloDigits title="Предзаказ" num={orders.new}/>
								<OnlineTabloDigits title="В работе" variant="danger" num={orders.in_progress}/>
								<OnlineTabloDigits title="Выполнены" variant="success" num={orders.done}/>
							</div>
						</div>
					</>
				)

			}


		</div>
	);
};

export default OnlineTablo;