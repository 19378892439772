import React, {useCallback, useEffect, useState} from 'react';
import {
	Typography,
	Button,
	Paper,
	Accordion,
	AccordionSummary,
	AccordionDetails
} from '@material-ui/core';
import ClimbingBoxLoader
	from "react-spinners/ClimbingBoxLoader";

import AddIcon from '@material-ui/icons/Add';

import {TITLE_CATEGORY, TITLE_SERVICE, TITLE_SUB_CATEGORY} from '../../../../../config';
import {getActiveServices} from '../../../../../store/selectors';
import {
	fetchServiceSelectCategories,
	fetchServiceSelectServices, setServiceSelectProperty
} from '../../../../../store/actions/serviceSelectActions';
import {
	setDefaultMaterialQuantity,
	setMaterials,
	setOrderFormControlValue,
	setOrderProperty
} from '../../../../../store/actions/orderActions';
import {clearSelectedWorkers} from '../../../../../store/actions/workerSelectActions';
import {connect, useDispatch} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AdminClientListIndividual from './AdminClientListIndividual';
import AdminSendLoader from '../../AdminUI/AdminSendLoader';
import is from 'is_js';
import _ from 'lodash';
import {saveAdminIndividualService} from '../../../../../store/actions/adminServiceListActions';
import DeleteIcon from '@material-ui/icons/Delete';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import {getConditionPrice} from '../functions';
import Loader from '../../../../../components/UI/Loader/Loader';


const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	backButton: {
		marginRight: theme.spacing(1),
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
}));

const AdminClientListDetail = (props) => {
		const {
			fetchServiceSelectCategories, fetchServiceSelectServices,
			categories, setOrderProperty, service, formTouched,
			subCategory, category, services, clearSelectedWorkers,
			client, loading, servicesLoading,
		} = props;

		const [activeCategories, setActiveCategories] = useState([]);
		const [activeSubCategories, setActiveSubCategories] = useState([]);
		const [activeServices, setActiveServices] = useState([]);
		const [individualServices, setIndividualServices] = useState([]);

		const [currentCategoryId, setCurrentCategoryId] = useState(category);
		const [currentSubCategoryId, setCurrentSubCategoryId] = useState(subCategory);
		const [currentServiceId, setCurrentServiceId] = useState(service);

		const [steps, setSteps] = useState([TITLE_CATEGORY, TITLE_SUB_CATEGORY, TITLE_SERVICE]);
		const [step, setStep] = useState(0);
		const [discount, setDiscount] = useState(false);
		const [expanded, setExpanded] = useState(false);
		const [scrollTop, setScrollTop] = useState(0);

		const dispatch = useDispatch();

		const classes = useStyles();

		const filterSubCategories = useCallback((subCategories) => {
			// оставляем только те, у которых есть услуги и активны
			if (!!services.length) {
				return subCategories.filter(subCategory => {
					return subCategory.active && !!services.filter(service => service.category.subCategory.id === subCategory.id && service.active).length
				})
			}
			return []
		}, [services],);

		const currentService = activeServices.find(service => service.id === currentServiceId);

		const backToSubCategory = () => {
			setStep(1);
			setSteps([steps[0], TITLE_SUB_CATEGORY, TITLE_SERVICE]);
			setCurrentServiceId('');
			setCurrentSubCategoryId('');
			//setOrderProperty('', 'subCategory');
			setOrderProperty('', 'service');
			setScrollTop(0);
		};

		const backToCategory = () => {
			setStep(0);
			setSteps([TITLE_CATEGORY, TITLE_SUB_CATEGORY, TITLE_SERVICE]);
			setCurrentCategoryId('');
			setOrderProperty('', 'category');
		};

		const setService = (serviceId) => {
			setCurrentServiceId(serviceId);
			if (serviceId) {
				setScrollTop(9999);
			}
		};

		const handleChange = (panel) => (event, isExpanded) => {
			if (!!event.target.tagName) {
				const isEditBtn = event.target.closest('.edit_btn__js')
				if (!isEditBtn) {
					setExpanded(isExpanded ? panel : false)
				}
			}
		}

		const deleteIndividualService = (service) => {
			let conditions = [...service.individual_conditions.filter(condition => parseInt(condition.toString()
				.split(':')[0]) !== client.id)];
			let masterPrices = [...service.individual_master_price.filter(price => parseInt(price.toString()
				.split(':')[0]) !== client.id)];
			const editedService = _.cloneDeep(service);
			editedService.individual_conditions = conditions;
			editedService.individual_master_price = masterPrices;
			editedService.edited = true;
			dispatch(saveAdminIndividualService(editedService))
		}

		const setIndividualDiscount = () => {
			setDiscount(true);
			setExpanded(false);
		}

		useEffect(() => {
			if (!categories.length) {
				fetchServiceSelectCategories();
			}
		}, [fetchServiceSelectCategories, categories]);

		useEffect(() => {
			if (!services.length) {
				fetchServiceSelectServices();
			}
		}, [fetchServiceSelectServices, services]);

		useEffect(() => {
			if (!!categories.length && !!services.length) {
				let filtredCategories = categories.filter(category => !!category.subCategory.length && category.active);
				if (!!filtredCategories.length) {
					filtredCategories = filtredCategories.filter(filtredCategory => {
						return !!filterSubCategories(filtredCategory.subCategory).length
					});
					if (!!filtredCategories.length) {
						setActiveCategories(filtredCategories);
						clearSelectedWorkers();
					}
				}
			}
		}, [categories, services, filterSubCategories]);

		useEffect(() => {
			let currentCategory = categories.find(category => category.id === currentCategoryId);
			if (currentCategory) {
				let filtredSubCategories = filterSubCategories(currentCategory.subCategory);
				if (!!filtredSubCategories.length) {
					setActiveSubCategories(filtredSubCategories)
				}
			}
		}, [currentCategoryId, categories, services, filterSubCategories]);

		useEffect(() => {
			if (currentSubCategoryId) {
				let filtredServices = services.filter(service => currentSubCategoryId === service.category.subCategory.id && service.active)
					.filter(value => -1 === individualServices.findIndex(i => i.id === value.id));
				if (!!filtredServices.length) {
					setActiveServices(filtredServices);
				}
			}
		}, [categories, services, currentSubCategoryId, individualServices]);

		useEffect(() => {
			if (!!categories.length && !!currentCategoryId) {
				const currentCategory = categories.find(category => category.id === currentCategoryId)

				if (steps[0] !== currentCategory['name']) {
					setSteps([currentCategory['name'], TITLE_SUB_CATEGORY, TITLE_SERVICE]);
					setStep(1);
				}
			}
		}, [categories, currentCategoryId, steps, setOrderProperty]);

		useEffect(() => {
			if (currentSubCategoryId) {
				let currentCategory = categories.find(category => category.id === currentCategoryId);
				if (currentCategory) {
					let filtredSubCategories = filterSubCategories(currentCategory.subCategory);
					if (!!filtredSubCategories.length) {
						const subCategory = filtredSubCategories.find(sub => sub.id === currentSubCategoryId);
						if (subCategory) {

							if (steps[1] !== subCategory['name']) {
								setSteps([steps[0], subCategory['name'], TITLE_SERVICE]);
								setStep(2);
							}
						}
					}

				}
			}
		}, [categories, currentSubCategoryId, filterSubCategories, currentCategoryId, steps, setOrderProperty]);

		useEffect(() => {
			if (currentServiceId) {
				let filtredServices = services.filter(service => currentSubCategoryId === service.category.subCategory.id && service.active);
				if (!!filtredServices.length) {
					const service = filtredServices.find(service => service.id === currentServiceId);
					if (!!service) {
						if (steps[2] !== service['name']) {
							setSteps([steps[0], steps[1], service['name']])
						}
					}
				}
			}

		}, [currentServiceId, currentSubCategoryId, services, steps, formTouched]);

		useEffect(() => {
			const filterConditionsByUser = (conditions) => {
				return conditions.map(condition => parseInt(condition.split(':')[0])).indexOf(client.id) !== -1;
			}
			const iServices = services.filter((service) => !!service.individual_conditions.length)
				.filter((service) => filterConditionsByUser(service.individual_conditions))
			setIndividualServices(iServices);

		}, [services])

		useEffect(() => {
			if (scrollTop > 0) {
				const timeout = setTimeout(() => {
					document.querySelector('.AdminOrderList__detail-modal-scroll').scrollTo({
						top: scrollTop,
						behavior: 'smooth'
					});
				}, 500)
				// document.querySelector('.AdminOrderList__detail-modal-scroll').scrollTop = clientIndividualServiceScrollTop
				return () => {
					clearTimeout(timeout)
				}
			}
		}, [scrollTop]);

		return (
			<>
				<Paper className="m-1 p-3">
					{
						servicesLoading
						&& <AdminSendLoader/>
					}

					<div className="AdminClientList__detail">
						<div className="d-flex justify-content-between align-items-center">
							<Typography variant="button" className='p-0'>
								Список работ с индивидуальными условиями:
							</Typography>
							{
								discount
									? (
										<Button
											className="Admin_btn text"
											variant="contained"
											color="primary"
											onClick={() => setDiscount(false)}
										>
											<ClearIcon
												className='Admin_btn-icon Admin_btn-icon-create mr-1'/>
											<Typography variant="overline" display="block">
												Отменить добавление индивидуальных условий
											</Typography>
										</Button>
									)
									: (
										<Button
											className="Admin_btn text"
											variant="contained"
											color="primary"
											onClick={setIndividualDiscount}
										>
											<AddIcon
												className='Admin_btn-icon Admin_btn-icon-create mr-1'/>
											<Typography variant="overline" display="block">
												Добавить индивидуальные условия
											</Typography>
										</Button>
									)
							}
						</div>
						{
							!!individualServices.length
							&& (
								<div className="mt-3">
									{
										individualServices.map((service, service_idx) => {
											return (
												<Accordion
													key={service.id}
													TransitionProps={{unmountOnExit: true}}
													expanded={expanded === service.id}
													onChange={handleChange(service.id)}
												>
													<AccordionSummary
														expandIcon={<ExpandMoreIcon/>}
													>
														<div
															className="w-100 d-flex justify-content-between align-items-center">
															<Typography className={classes.heading}>
																{service_idx + 1}. {service.name} [id:{service.id}]</Typography>
															<div
																className="d-flex align-items-center w-25 justify-content-end">
																<div className="d-flex flex-column w-50 mr-3">
																	<Typography className="AdminClientList__column-text"
																				variant="overline" display="block">
																		Новая цена:
																	</Typography>
																	<Typography className="AdminClientList__column-text"
																				variant="button" display="block">
																		{getConditionPrice(service.individual_conditions, client.id)} руб.
																	</Typography>
																</div>
																<Button
																	className="Admin_btn edit_btn__js text mr-2"
																	variant="contained"
																	color="secondary"
																	onClick={() => deleteIndividualService(service)}
																>
																	<DeleteIcon
																		className='Admin_btn-icon Admin_btn-icon-create mr-1'/>
																	<Typography variant="overline" display="block">
																		Удалить
																	</Typography>
																</Button>
															</div>
														</div>

													</AccordionSummary>
													<AccordionDetails>
														<AdminClientListIndividual client={client} service={service}/>
													</AccordionDetails>
												</Accordion>
											)
										})
									}
								</div>
							)
						}
					</div>

					{
						!!services.length && !!categories.length && discount &&
						<div className="AdminClientList__detail mt-2">
							{
								step === 0 && !!activeCategories.length &&
								<div className='d-flex flex-column align-items-start'>
									<Typography variant="button" className='pb-3 pl-0 pt-0'>
										Выберите {TITLE_CATEGORY}
									</Typography>
									<ul className="AdminClientList__service-list ServicesSelect__list px-0 w-100">
										{
											activeCategories.map((category, cat_idx) => {
												return (
													<li key={category.id} onClick={() => setCurrentCategoryId(category.id)}
														className={`${category.id === currentCategoryId ? 'active' : ''}`}>
														<Paper className='px-3 py-2'>
															{cat_idx + 1}. {category.name}
														</Paper>
													</li>
												)
											})
										}
									</ul>
								</div>
							}
							{
								step === 1 && !!activeSubCategories.length &&
								<div className='d-flex flex-column align-items-start pt-3'>
									<div className='w-100 d-flex align-items-center justify-content-between'>
										<Typography variant="button" className='p-3 pl-0'>
											Выберите {TITLE_SUB_CATEGORY}
										</Typography>
										<Button
											variant="contained"
											color="primary"
											className='Admin_btn text'
											onClick={backToCategory}>
											<ArrowLeftIcon
												className='Admin_btn-icon Admin_btn-icon-create mr-1'/>
											<Typography variant="overline" display="block">
												Назад к выбору вида работ
											</Typography>
										</Button>
									</div>
									<ul className="AdminClientList__service-list ServicesSelect__list px-0 w-100">
										{
											activeSubCategories.map((subCategory, subCategory_idx) => {
												return (
													<li key={subCategory.id}
														onClick={() => setCurrentSubCategoryId(subCategory.id)}
														className={`${subCategory.id === currentSubCategoryId ? 'active' : ''}`}>
														<Paper className='px-3 py-2'>
															{subCategory_idx + 1}. {subCategory.name}
														</Paper>
													</li>
												)
											})
										}
									</ul>
								</div>
							}
							{
								step === 2 && !!activeServices.length &&
								<div className='d-flex flex-column align-items-start pt-3'>
									<div className='w-100 d-flex align-items-center justify-content-between'>
										<Typography variant="button" className='p-3 pl-0'>
											Выберите {TITLE_SERVICE}
										</Typography>
										<Button
											variant="contained"
											color="primary"
											className='Admin_btn text'
											onClick={backToSubCategory}>
											<ArrowLeftIcon
												className='Admin_btn-icon Admin_btn-icon-create mr-1'/>
											<Typography variant="overline" display="block">
												Назад к выбору раздела
											</Typography>
										</Button>
									</div>

									<ul className="AdminClientList__service-list ServicesSelect__list px-0 w-100">
										{
											activeServices.map((activeService, service_idx) => {
												return (
													<li key={activeService.id}
														onClick={() => setService(activeService.id)}
														className={`${activeService.id === currentServiceId ? 'active' : ''}`}>
														<Paper className='px-3 py-2'>
															{service_idx + 1}. {activeService.name}
														</Paper>
													</li>
												)
											})
										}
									</ul>
								</div>
							}

							{
								currentService
								&& (
									<AdminClientListIndividual
										client={client}
										service={currentService}
										setDiscount={setDiscount}
										isNew
									/>
								)
							}

						</div>
					}

				</Paper>
				{
					// servicesLoading &&
					// <div className="d-flex p-5 m-5 justify-content-center align-items-center">
					// 	<ClimbingBoxLoader color="#9171DE" loading={true}/>
					// </div>
				}</>
		);
	}
;


function mapStateToProps(state) {
	return {
		services: getActiveServices(state),
		categories: state.serviceSelect.categories,
		needMaterialsTabs: state.serviceSelect.needMaterialsTabs,
		loading: state.serviceSelect.loading,
		servicesLoading: state.serviceSelect.servicesLoading,
		error: state.serviceSelect.error,
		workTypeTabs: state.serviceSelect.workTypeTabs,
		formTouched: state.serviceSelect.formTouched,
		needMaterials: state.order.needMaterials,
		formControls: state.order.formControls,
		service: state.order.service,
		subCategory: state.order.subCategory,
		category: state.order.category,
		workType: state.order.workType,
	}
}

const actions =
	{
		fetchServiceSelectServices,
		fetchServiceSelectCategories,
		setOrderProperty,
		setMaterials,
		setOrderFormControlValue,
		setDefaultMaterialQuantity,
		setServiceSelectProperty,
		clearSelectedWorkers
	}
;

export default connect(mapStateToProps, actions)(AdminClientListDetail);