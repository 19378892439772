import React, { useEffect, useState } from "react";
import Steps from "./Steps/Steps";
import "./Estimate.scss";
import {
    estimateFetchConditions,
    setFilterCategories,
    setFilterServices,
    setFilterSubCategories,
} from "../../../../../store/actions/estimateActions";
import { connect } from "react-redux";
import EstimateOrder from "./Order/EstimateOrder";
import { useHistory } from 'react-router-dom';
import ChartsContextProvider from '../Сharts/ChartsContext';

const Estimate = (props) => {
    const {
        services,
        categories,
        setFilterServices,
        setFilterCategories,
        setFilterSubCategories,
    } = props;
    const { push } = useHistory();
    const [isLoaded, setIsLoaded] = useState(false);

    const createOrderHandler = () => {
        push('/complex-confirm');
    };

    useEffect(() => {
        if (!categories.length || !services.length) return;
        const activeServices = services.filter((service) => {
            let active = false;
            if (!!service.items.length) {
                service.items.forEach((i) => {
                    active = active || !!i.norms.length;
                });
            }
            return active || !!service.norms.length;
        });
        const activeCategories = categories.filter((c) => {
            return activeServices.some((s) => s.category.id === c.id);
        });
        let activeSubCategories = activeServices.map(
            (s) => s.category.subCategory.id
        );
        setFilterSubCategories(activeSubCategories);
        setFilterCategories(activeCategories);
        setFilterServices(activeServices);
    }, [services, categories]);

    useEffect(() => {
        props.estimateFetchConditions();
    }, []);

    useEffect(() => {
        setIsLoaded(!!services.length && !!categories.length);
    }, [categories, services]);

    return (
        <>
            <Steps />
            {!!props.estimateOrder.services.length &&
                <EstimateOrder isLoaded={isLoaded} createOrderHandler={createOrderHandler} />}
        </>
    );
};

const mapStateToProps = (state) => ({
    services: state.serviceSelect.services,
    categories: state.serviceSelect.categories,
    estimateOrder: state.estimateOrder,
});

const actions = {
    setFilterSubCategories,
    setFilterCategories,
    setFilterServices,
    estimateFetchConditions,
};

export default connect(mapStateToProps, actions)(Estimate);
