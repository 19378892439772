import React, {Fragment, useEffect, useState} from 'react';
// import AdminOrderListDetailScoreboardTable from './AdminOrderListDetailScoreboardTable';
// import * as moment from 'moment';
import {useSelector} from 'react-redux';
import {getAdminOrderListTasksState} from '../selectors';
import {Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography} from '@material-ui/core';
import AdminOrderListDetailScoreboardThreeRow from './AdminOrderListDetailScoreboardThreeRow';
import AdminOrderListDetailScoreboardTwoRow from './AdminOrderListDetailScoreboardTwoRow';

const AdminOrderListDetailScoreboardThree = ({order, currentDay}) => {
    const {workers} = useSelector(getAdminOrderListTasksState);
    const [tableVisible, setTableVisible] = useState(false);
    const [workerOpen, setWorkerOpen] = useState({})
    useEffect(() => {
        setTableVisible(false);
    }, [currentDay])
    return (
        <div className="mt-4">
            <div className="mt-3">
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        {
                            tableVisible
                            && <Paper component={'thead'}>
                                <TableRow className="AdminOrderList__table-head">
                                    <TableCell className="AdminOrderList__cell px-3 py-2 border-right">
                                        <Typography align='center'>№ п/п</Typography>
                                    </TableCell>
                                    <TableCell className="AdminOrderList__cell px-3 py-2 border-right">
                                        <Typography align='center'>№ ТЗ</Typography>
                                    </TableCell>
                                    <TableCell align="left" className="AdminOrderList__cell p-3 large border-right">
                                        <Typography>Наименование работ</Typography>
                                    </TableCell>
                                    <TableCell align="left" className="AdminOrderList__cell p-3 border-right">
                                        <Typography>Ед. изм.</Typography>
                                    </TableCell>
                                    <TableCell align="left" className="AdminOrderList__cell p-3 border-right">
                                        <Typography>Кол-во</Typography>
                                    </TableCell>
                                    <TableCell align="left" className="AdminOrderList__cell p-3 border-right">
                                        <Typography>Цена</Typography>
                                    </TableCell>
                                    <TableCell align="left" className="AdminOrderList__cell p-3">
                                        <Typography>Сумма, руб.</Typography>
                                    </TableCell>
                                    <TableCell className="AdminOrderList__cell p-3"/>
                                </TableRow>
                            </Paper>

                        }

                        <TableBody>
                            {workers.map((worker) => {
                                return (<Fragment key={worker.id}>
                                    {
                                        !!workerOpen[worker.id] && (
                                            <TableRow>
                                                <TableCell align="justify"
                                                           className="AdminOrderList__row-title p-2 bg-light"
                                                           colSpan={12}
                                                >
                                                    <Typography>{worker.name}</Typography>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                    {
                                        worker.tasks.map((task, idx) => (
                                            <AdminOrderListDetailScoreboardThreeRow key={task.id || idx}
                                                                                    idx={idx}
                                                                                    task={task}
                                                                                    order={order}
                                                                                    worker={worker}
                                                                                    currentDay={currentDay}
                                                                                    tableVisible={tableVisible}
                                                                                    setTableVisible={setTableVisible}
                                                                                    setWorkerOpen={setWorkerOpen}
                                                                                    workerOpen={workerOpen}
                                            />

                                        ))
                                    }
                                </Fragment>)
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                {
                    !tableVisible && <Typography>
                        На <strong>{currentDay}</strong> в этом заказе нет текущих заданий, принятых менеджером,
                        по зонам которых
                        приняты все классификаторы.
                    </Typography>
                }
            </div>
        </div>
    );
};

export default AdminOrderListDetailScoreboardThree;