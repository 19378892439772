import React, {Fragment} from 'react';

const SwitchCondition = ({switchers, title, active, onClick, className = ''}) => {

	return (
		<div className={`UI__SwitchCondition ${className}`}>
			<div className="UI__SwitchCondition-title">
				{title}
			</div>
			<div className="d-flex align-items-center">
				{
					switchers.map((item, idx) => {
						return (
							<Fragment key={item.id}>
								<div
									className={`UI__SwitchCondition-name ${item.id === active ? 'UI__SwitchCondition-name_active' : ''}`}
								>
									{item.title}
								</div>
								{
									idx === 0
									&& (
										<div
											onClick={() => onClick(item.id === active ? switchers[1].id : item.id)}
											className={`UI__SwitchCondition-switch ${item.id === active ? 'UI__SwitchCondition-switch_active' : ''}`}>
											<div
												className={`UI__SwitchCondition-switch-circle ${active === item.id ? 'UI__SwitchCondition-switch-circle_left' : ''}`}/>
										</div>
									)
								}
							</Fragment>

						)
					})
				}
			</div>
		</div>
	);
};

export default SwitchCondition;