import React, {
    createContext,
    useContext,
    useEffect,
    useState,
} from 'react';
import { eachDayOfInterval, format, isAfter, parse } from 'date-fns';
import { useSelector } from 'react-redux';

export const ChartsContext = createContext(null);

export const useChartsContext = () => {
    return useContext(ChartsContext);
};


const ChartsContextProvider = ({ children }) => {
    const [scroll, setScroll] = useState({});
    const [counts, setCounts] = useState({});
    const [works, setWorks] = useState([]);
    const [materials, setMaterials] = useState([]);
    const [total, setTotal] = useState([]);
    const { formControls, changedData } = useSelector(state => state.estimateOrder);
    const { startDate, endDate } = formControls;
    const dates = eachDayOfInterval({
        start: new Date(parse(startDate.value, 'dd.MM.yyyy', new Date())),
        end: new Date(parse(endDate.value, 'dd.MM.yyyy', new Date()))
    });

    useEffect(() => {
        const counts = {};
        changedData.forEach((service) => {
            const start = new Date(parse(service.startDate, 'dd.MM.yyyy', new Date()));
            const end = new Date(parse(service.endDate, 'dd.MM.yyyy', new Date()));
            let serviceDates = [];
            if (isAfter(end, start)) {
                serviceDates = eachDayOfInterval({
                    start,
                    end
                });
            } else {
                serviceDates.push(start);
            }
            // console.log('serviceDates', serviceDates);
            const worker = {};
            let currentWorker = null;

            if (service.norms && !!service.norms.length) {
                currentWorker = service.norms[0];
            } else if (!!service.items.length) {
                currentWorker = service.items[0].norms[0];
            }

            if (currentWorker) {
                worker.id = currentWorker.id;
                worker.name = currentWorker.name;
                worker.count = service.workersCount;
                worker.daysCount = service.daysCount;
                worker.startDate = service.startDate;
            }


            serviceDates.forEach((date) => {
                const d = format(new Date(date), 'dd.MM.yyyy', new Date());
                if (!counts[d]) {
                    counts[d] = {
                        value: 0,
                        workers: [],
                        workPrice: 0,
                        materialPrice: 0,
                    };
                }
                counts[d].value += service.workersCount;
                counts[d].workPrice += +(service.workPrice * service.count / service.daysCount).toFixed();
                counts[d].materialPrice += +(service.materialPrice / service.daysCount).toFixed();
                counts[d].workers.push(worker);
            });
        });
        setCounts(counts);

    }, [changedData]);

    useEffect(() => {
        const works = Object.values(counts).map((count) => count.workPrice);
        const materials = Object.values(counts).map((count) => count.materialPrice);
        const total = works.map((work, idx) => +work + +materials[idx]);
        setWorks(works);
        setMaterials(materials);
        setTotal(total);
    }, [counts]);
    return (
        <ChartsContext.Provider value={{
            scroll,
            setScroll,
            dates,
            counts,
            works,
            materials,
            total,
        }}>
            {children}
        </ChartsContext.Provider>
    );
};

export default ChartsContextProvider;
