import React, {Fragment} from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {Collapse} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import is from 'is_js';
import {getFormatDate} from '../functions';


const AdminOrderListDetailTasksRow = ({task, idx, worker, openRoomHandler, order}) => {
	const [open, setOpen] = React.useState(false);

	const getTotalQuantity = () => {
		return !!task.items
			? task.items
				.map((item) => is.number(+item.volume) ? item.volume : item.measureText)
				.reduce((acc, val) => acc + val)
			: 0
	}

	const getTaskStatus = () => {
		const status = !!task.items && task.items.map((item) => item.status.code).indexOf("WK") === -1
			?
			{
				text: "Принято",
				code: "A"
			}
			:
			{
				text: "В работе",
				code: "WK"
			}
		return <span className={`AdminOrderList__task-status ${status.code}`}>{status.text}</span>
	}
	console.log('order,', order)

	return (
		<Fragment key={task.id}>
			<Paper component="tr" className="AdminOrderList__row" onClick={() => setOpen(!open)}>

				<TableCell className="AdminOrderList__cell-row"><Typography
					align='center'>{idx + 1}</Typography></TableCell>
				<TableCell className="AdminOrderList__cell-row" align="left"><Typography
					align='left'>{task.name}</Typography></TableCell>

				<TableCell className="AdminOrderList__cell-row" align="left"><Typography
					align='left'>{getFormatDate(task.activeFrom)}</Typography></TableCell>
				<TableCell className="AdminOrderList__cell-row" align="left"/>

				<TableCell className="AdminOrderList__cell-row" align="left"><Typography
					align='left'>{getTotalQuantity()}</Typography></TableCell>
				<TableCell className="AdminOrderList__cell-row" align="left"><Typography
					align='left'>{order.unit}</Typography></TableCell>

				<TableCell className="AdminOrderList__cell-row" align="left"><Typography
					align='left'>{worker.name}</Typography></TableCell>
				<TableCell className="AdminOrderList__cell-row" align="left"><Typography
					align='left'>{getTaskStatus()}</Typography></TableCell>

				<TableCell className="AdminOrderList__cell-row" align="center">
					<IconButton aria-label="expand row" size="small">
						{open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
					</IconButton>
				</TableCell>

			</Paper>
			<TableRow className="AdminOrderList__detail-modal-row">
				<TableCell style={{padding: 0}} colSpan={12} className="border-0 w-100">
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box margin={0} className="AdminOrderList__detail-modal-box">
							<Typography variant="button" gutterBottom component="div" className="p-2 m-0">
								Зоны работ
							</Typography>
							<Table className="w-100">
								<Paper component={'thead'} className="AdminOrderList__hidden">
									<TableRow className="AdminOrderList__table-head">
										<TableCell className="AdminOrderList__cell border-right">№ ТЗ</TableCell>
										<TableCell align="left" className="AdminOrderList__cell large border-right">Наименование
											ТЗ</TableCell>
										<TableCell align="left" className="AdminOrderList__cell border-right">Дата
											оформления</TableCell>
										<TableCell align="left" className="AdminOrderList__cell border-right">Срок
											выполнения</TableCell>
										<TableCell align="left"
												   className="AdminOrderList__cell border-right">Кол-во</TableCell>
										<TableCell align="left" className="AdminOrderList__cell border-right">Ед.
											изм.</TableCell>
										<TableCell align="left"
												   className="AdminOrderList__cell large border-right">Бригада</TableCell>
										<TableCell align="left"
												   className="AdminOrderList__cell border-right">Статус</TableCell>
										<TableCell className="AdminOrderList__cell"/>
									</TableRow>
								</Paper>
								<TableBody>
									{task.items && task.items.map((item, idx) => {
										console.log('item', item)
										return (
											<TableRow key={item.id} className="AdminOrderList__detail-modal-link"
													  onClick={() => openRoomHandler(item)}>

												<TableCell className="border-right p-2"
														   align="left"><Typography
													align='center'>{idx + 1}</Typography></TableCell>

												<TableCell className="border-right p-2"
														   align="left"><Typography
													align='left'>{item.place}</Typography></TableCell>

												<TableCell className="border-right p-2"
														   align="left"><Typography
													align='left'>{getFormatDate(item.termDatetime)}</Typography></TableCell>

												<TableCell align="left" className="p-2"/>

												<TableCell className="border-right p-2"
														   align="left"><Typography
													align='left'>{is.number(+item.volume) ? item.volume : item.measureText}</Typography></TableCell>

												<TableCell className="border-right p-2"
														   align="left"><Typography
													align='left'>{order.unit}</Typography></TableCell>

												<TableCell className="p-2"/>

												<TableCell align="left" className="p-2">
													<div className={`AdminOrderList__task-status ${item.status.code}`}>
														<Typography
															align='left'>{item.status.value}</Typography>
													</div>
												</TableCell>

												<TableCell className="p-2">
													<div className='AdminOrderList__task-icon'><ArrowForwardIosIcon/>
													</div>
												</TableCell>
											</TableRow>
										)
									})}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
				{/*<TableCell style={{padding: 0}} className="border-0"/>*/}
			</TableRow>
		</Fragment>
	);
};

export default AdminOrderListDetailTasksRow;