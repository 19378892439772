import React from "react";

export const PrevArrow = ({ onClick }) => (
	<div className="WorkersSlider__arrow prev" onClick={onClick}>
		<svg
			width="15"
			height="24"
			viewBox="0 0 15 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12.1457 0L15 2.8162L5.72873 11.9838L15 21.1515L12.1457 23.9677L-3.17254e-08 11.9838L12.1457 0Z"
				fill="black"
			/>
		</svg>
	</div>
);