import React from 'react';
import './ActKSTopHead.scss'

export default ({ks}) => {

    // console.log(ks)

    return (
        <div className="ActKSTopHead">
            <div>
                Унифицированная форма № КС-{ks === 'ks2' ? 2 : 3} <br/>
                Утверждена постановлением Госкомстата России<br/>
                от 11.11.99 № 100
            </div>
        </div>
    );

}

