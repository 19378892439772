import {createSelector} from 'reselect'

// USER

export function getWorkerPersonalState(state) {
	return state.workerPersonal
}

export function getWorkerPersonalOrders(state) {
	return getWorkerPersonalState(state).orders
}

export function getWorkerPersonalOrder(state) {
	return getWorkerPersonalState(state).order
}


export function getWorkerPersonalReports(state) {
	return getWorkerPersonalState(state).reports
}

export function getWorkerPersonalLoading(state) {
	return getWorkerPersonalState(state).loading
}

export function getWorkerPersonalPhotos(state) {
	return getWorkerPersonalState(state).localPhotos
}

export function getWorkerPersonalLocalReports(state) {
	return getWorkerPersonalState(state).localReports
}


export function getUser(state) {
	return getWorkerPersonalState(state).user
}

export function getUserId(state) {
	return getUser(state).id
}

export function getUserToken(state) {
	return getUser(state).token || localStorage.getItem('token')
}

export function getUserRole(state) {
	return getWorkerPersonalState(state).role || getUser(state).role
}

export function getIsLogin(state) {
	return getWorkerPersonalState(state).isLogin
}

export function getPersonalLoading(state) {
	return getWorkerPersonalState(state).loading
}

// ORDER

export function getConfirmOrderState(state) {
	return state.confirmOrder
}

export function getOrderId(state) {
	return getConfirmOrderState(state).orderId
}


// services
export function getServiceSelectState(state) {
	return state.serviceSelect
}

export function getServices(state) {
	return state.serviceSelect.services
}

export function getCategories(state) {
	return state.serviceSelect.categories
}

export function getContactsState(state) {
	return state.contacts
}

export function getNavState(state) {
	return state.nav
}

export function getContacts(state) {
	return getContactsState(state).contacts
}

export function getNav(state) {
	return getNavState(state).nav
}

export function getNavAnchor(state) {
	return getNavState(state).activeAnchor
}

// order
export function getOrderState(state) {
	return state.order
}

export function getOrderMaterials(state) {
	return getOrderState(state).materials
}

// admin

export const getActiveServices = createSelector(getServices, services => {
	return services ? services.filter(service => service.active)
		.filter(service => !!service.norms.length || !!service.items.length) : []
});

export const getActiveCategories = createSelector(getCategories, categories => {
	return categories.filter(category => !category.subCategory.length && category.active)
});


