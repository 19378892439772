export const getRandBetween = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

function delay(timeDelay) {
  return new Promise(resolve => setTimeout(resolve, timeDelay))
}

async function addValueToInput(value, callback, timeDelay) {
  await delay(timeDelay);
  callback(value)
}

async function animateArrayitem(arr, callback, itemFunc, timeDelay) {
  for (const item of arr) {
    await callback(item, itemFunc, timeDelay)
  }
}

async function animateNumberValue(array, setFunc, timeDelay) {

  await delay(2000);

  const arrValue = array.toString().split("");

  const valuesToAnim = arrValue.map((item, index, arr) => {
    let result = [];
    result.push(item);
    for (let i = index - 1; i >= 0; i--) {
      result.unshift(arr[i]);
    }
    return result.join("");
  });

  await animateArrayitem(valuesToAnim, addValueToInput, setFunc, timeDelay)
}

export async function animateInput(array, callback, timeDelay) {
  await animateArrayitem(array, animateNumberValue, callback, timeDelay)
}

export const getBuildersCount = (service, workload, termOfWork) => {
  let workerNorm = 0;
  if (!!service.items && !!service.items.length) {
    service.items.forEach((item) => {
      item.norms.forEach((norm) => {
        workerNorm += norm.norm * norm.cc;
      });
    });
  } else {
    service.norms.forEach((norm) => {
      workerNorm += norm.norm * norm.cc;
    });
  }

  let days = +termOfWork;
  const count = Math.ceil((workload * workerNorm) / (days * 8));
  return(count || 0);
}

export const getMaterials = (service, count) => {
  let materials = [];
  if (!!service.items.length) {
    service.items.forEach(item => {
      materials = [...materials, ...item.materials]
    })
  } else {
    materials = [...service.materials]
  }

  return materials.map(m => ({
    ...m,
    count: m.norms * count
  }))
}