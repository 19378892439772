import React, {memo, useEffect} from 'react';
import {connect, useDispatch} from "react-redux";
import {
    adminGoodsFetch,
    adminGoodsFetchCategories,
    setAdminGoodsProperty
} from "../../../../store/actions/adminGoodsActions";
import AdminGoodsLeft from "./AdminGoodsLeft/AdminGoodsLeft";
import AdminGoodsRight from "./AdminGoodsRight/AdminGoodsRight";
import AdminSendLoader from "../AdminUI/AdminSendLoader";
import {withRouter} from "react-router-dom";

const AdminGoods = memo((props) => {

    const {goods, categories, currentCategory, adminGoodsFetch, adminGoodsFetchCategories, loading} = props;
    const dispatch = useDispatch();

    useEffect(() => {
        if (!goods.length) {
            adminGoodsFetch();
        }
    }, [goods, adminGoodsFetch]);

    useEffect(() => {
        if (!categories.length) {
            adminGoodsFetchCategories();
        }
    }, [categories, adminGoodsFetchCategories]);

    useEffect(() => {
        if (!currentCategory && !!categories.length) {
            dispatch(setAdminGoodsProperty(categories[0].id, 'currentCategory'))
        }
    }, [currentCategory, categories]);

    return (
        <div className='row'>
            {
                loading && <AdminSendLoader/>
            }
            {
                !!categories.length &&
                <AdminGoodsLeft />
            }
            {
                !!goods.length &&
                <AdminGoodsRight />
            }
        </div>
    );

})

const mapState = state => {
    return {
        goods: state.adminGoods.goods,
        categories: state.adminGoods.categories,
        currentCategory: state.adminGoods.currentCategory,
        loading: state.adminGoods.loading,
    }
};

const actions = {
    adminGoodsFetch,
    adminGoodsFetchCategories
};

export default withRouter(connect(mapState, actions)(AdminGoods));