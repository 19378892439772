import React, {useEffect, useState} from 'react';
import AdminOrderListFinanceAccordion from '../AdminOrderListFinanceAccordion';
import {useDispatch, useSelector} from 'react-redux';
import {getAdminOrderListState} from '../../selectors';
import {fetchAdminOrderPays} from '../../../../../../store/actions/adminOrderListActions';
import AdminOrderListFinanceMastersPaysRows from './AdminOrderListFinanceMastersPaysRows';

const AdminOrderListFinanceMastersTotalPayed = ({order, masterId}) => {
	const {pays} = useSelector(getAdminOrderListState);
	const [totalPaid, setTotalPaid] = useState(0)
	const dispatch = useDispatch();

	useEffect(()=>{
		let total = 0;
		if (!!pays.length) {
			pays.forEach((pay) => {
				total += pay.sum
			})
			if (total) {
				setTotalPaid(total)
			}
		}

	}, [pays])

	useEffect(() => {
		dispatch(fetchAdminOrderPays(order.id))
	}, [order])

	useEffect(()=>{
		let sum = 0;
		if (pays[masterId]) {
			pays[masterId].forEach(pay => {
				sum += pay.sum;
			})
			setTotalPaid(sum)
		}
	}, [pays])

	return (
		<div className="px-0">
			<AdminOrderListFinanceAccordion
				name="Всего оплачено"
				sum={totalPaid}>
				<AdminOrderListFinanceMastersPaysRows pays={pays[masterId]}/>
			</AdminOrderListFinanceAccordion>
		</div>
	);
};

export default AdminOrderListFinanceMastersTotalPayed;