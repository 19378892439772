import React from 'react';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {renderFormControl} from "../../../../form/formFramework";

const WorkerPersonalAbout = ({formControlHandler, control}) => {
    return (
        <Paper className='p-3 mt-2'>
            <Typography variant="button" className='py-3'>
                О себе:
            </Typography>
            <div className='row px-3'>
                {renderFormControl(control, formControlHandler, {multiline: true, rows: 5, className: 'wp__textarea'})}
            </div>
        </Paper>
    );
};

export default WorkerPersonalAbout;