import React from 'react'
import {NavLink} from 'react-router-dom'
import logo from "../../../../../img/logo.png";


export default () => {
    return (
        <div
            className="Header__logo">
            <NavLink to="/">
                <img src={logo} alt="logotype"/>
            </NavLink>
        </div>
    )
}