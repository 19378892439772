import {
	FETCH_ORDER_CONDITIONS_SUCCESS,
	FETCH_SERVICE_SELECT_CATEGORIES_SUCCESS,
	FETCH_SERVICES_ERROR,
	FETCH_SERVICES_START,
	FETCH_SERVICES_SUCCESS, SET_SERVICE_SELECT_PROPERTY,
} from "../actions/actionTypes";

const initialState = {
	heading: 'Отдельные виды услуг',
	formTouched: false,
	tabs: [
		{
			id: 1,
			name: 'ОТДЕЛЬНЫЕ ВИДЫ РАБОТ',
			url: '/'
		},
		{
			id: 2,
			name: 'КОМЛЕКС РАБОТ “ПОД КЛЮЧ”',
			url: '/complex-works'
		},
		{
			id: 3,
			name: 'МАСТЕР ПОЧАСОВОЙ',
			url: '/master-hourly'
		}
	],
	needMaterialsTabs: [
		{
			id: 1,
			name: 'Материалы предоставлены заказчиком',
			tooltip: 'Расчет ведется с учетом поставки материалов',
		},
		{
			id: 2,
			name: 'Материалы поставляются исполнителем',
			tooltip: 'Расчет ведется без учета поставки материалов',
		}
	],
	workTypeTabs: [
		{
			id: 1,
			name: 'Работа уровня Стандарт',
			tooltip: 'Расчет ведется по ценам стандартных материалов и работ'
		},
		{
			id: 2,
			name: 'Работа уровня Премиум',
			tooltip: 'Расчет ведется по ценам премиальных материалов и работ'
		}
	],
	services: [],
	categories: [],
	docsTabs: [
		{id: 1, title: 'Исполнителя'},
		{id: 2, title: 'Заказчика'},
	],
	conditions: {
		locationObjectConditions: [],
		floorConditions: [],
		instrumentConditions: [],
		equipmentConditions: [],
		housingConditions: [],
		materialPlaceConditions: [],
		classifiersConditions: [],
	},
	loading: false,
	servicesLoading: false,
	error: null
}

export default function serviceSelectReducer(state = initialState, {type, payload}) {

	switch (type) {
		case FETCH_SERVICES_START:
			return {
				...state,
				// loading: true,
				servicesLoading: true,
			}
		case FETCH_SERVICES_SUCCESS:
			return {
				...state,
				services: !!payload.services ? payload.services : [],
				// loading: false,
				servicesLoading: false,
			}
		case FETCH_SERVICES_ERROR:
			return {
				...state,
				error: payload.error,
				loading: false,
				servicesLoading: false,
			}
		case FETCH_SERVICE_SELECT_CATEGORIES_SUCCESS:
			return {
				...state,
				categories: payload.categories,
				loading: false
			};
		case SET_SERVICE_SELECT_PROPERTY:
			return {
				...state,
				[payload.property]: payload.value
			}
		case FETCH_ORDER_CONDITIONS_SUCCESS:
			return {
				...state,
				conditions: payload.data.data,
			}
		default:
			return state
	}

}

