import React from 'react';
import Button from "@material-ui/core/Button";
import {useDispatch} from "react-redux";
import {removeSelectedMaster} from "../../../../store/actions/workerSelectActions";
import ph from '../../../../img/nophoto.jpg'
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import {ListItem} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import * as moment from 'moment';

function getYears(age) {
    let num = age;
    if (age > 10 && age < 21) {
        return ' лет';
    } else {
        num = +String(age).slice(-1);
        switch (num) {
            case 1:
                return ' год';
            case 2:
            case 3:
            case 4:
                return ' года';
            default:
                return ' лет';
        }
    }
}

const ServiceWorkerSelectItem = ({master}) => {
    const dispatch = useDispatch();
    let masterAge = moment().diff(moment(master.birthday, 'dd.mm.YYYY'), 'years');
    masterAge += getYears(masterAge);

    return (
        <div className='ServiceWorkerSelectItem row'>
            <div className='d-flex flex-column col-12 col-sm-2'>
                {/*<img className={'ServiceWorkerSelectItem__picture'} src={master.picture || ph} alt={master.name}/>*/}
                <Avatar src={master.picture || ph} className='ServiceWorkerSelect__avatar'/>
                <Typography variant='subtitle1' className={'w-100'}>
                    {master.name}
                </Typography>
                <Typography variant='subtitle1' className={'w-100'}>
                    {masterAge}
                </Typography>
            </div>
            <div className='d-flex flex-column col-12 col-sm-10'>
                <div>
                    <Typography variant='subtitle1'>
                        {master.professionMain}
                    </Typography>
                </div>
                <div className='d-flex row'>
                    <div className='col-8 d-flex flex-column '>
                        {
                            master.description &&
                            <>
                                <Typography variant='subtitle1'>
                                    О мастере
                                </Typography>
                                <Typography variant='caption'>
                                    {master.description}
                                </Typography>
                            </>
                        }
                        <Typography variant='subtitle1'>
                            Сводобных мастеров: {master.countWorkersFree}
                        </Typography>
                    </div>
                    <div className='col-4 d-flex flex-column '>
                        <Typography variant='subtitle1'>
                            Категории
                        </Typography>
                        <List>

                            {
                                master.categories.map(category => {
                                    return (
                                        <ListItem className='p-0' key={category.id}>
                                            <Typography variant='caption'>
                                                {category.name}
                                            </Typography>
                                        </ListItem>
                                    )
                                })
                            }
                        </List>
                    </div>
                </div>
                <div className='d-flex justify-content-end'>
                    <Button size="small" variant='outlined' onClick={() => dispatch(removeSelectedMaster(master.id))}>
                        <Typography variant='subtitle1'>Удалить</Typography>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ServiceWorkerSelectItem;