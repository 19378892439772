import React from 'react';
import AdminCommunication from "./AdminCommunication";
import AdminMaterialsNorms from "./AdminNorms/AdminMaterialsNorms";
import AdminNormWorks from "./AdminNorms/AdminNormWorks";
import AdminWorkerPrices from "./AdminNorms/AdminWorkerPrices";
import AdminPrices from "./AdminPrices";
import AdminMaterialsPrices from "./AdminNorms/AdminMaterialsPrices";


const AdminSideComponent = ({tabId, serviceId, serviceTabHandler, height}) => {
    switch (tabId) {
        case 1:
            return <AdminCommunication
                serviceId={serviceId}
            />
        case 2:
            return <AdminMaterialsNorms
                serviceId={serviceId}
            />
        case 3:
            return <AdminNormWorks
                serviceId={serviceId}
            />
        case 4:
            return <AdminWorkerPrices
                serviceId={serviceId}
            />
        case 5:
            return <AdminPrices
                serviceId={serviceId}
                height={height}
            />
        case 6:
            return <AdminMaterialsPrices
                serviceTabHandler={serviceTabHandler}
                serviceId={serviceId}
            />
        default:
            return <AdminCommunication
                serviceId={serviceId}/>
    }

};


export default AdminSideComponent;