import React from "react";
import { connect } from "react-redux";
import estimateProcessSteps from "./estimateProcessSteps";
import './EstimateProcess.scss'

const EstimateProcess = ({currentStep}) => {
  return (
    <div className="estimate-process-steps">
      {estimateProcessSteps.map(({ id, label }) => {
        return (
          <div
            key={id}
            className={`estimate-process-steps-step ${
              currentStep === id ? "estimate-process-steps-step_active" : ""
            } ${currentStep > id ? "estimate-process-steps-step_done" : ""}`}
          >
            <div className="estimate-process-steps-step-title">{label}</div>
            <div
              className={`estimate-process-steps-step-success ${
                currentStep > id
                  ? "estimate-process-steps-step-success_done"
                  : ""
              }`}
            >
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
                <path
                  d="M1 7.66667L6.33333 13L13 1"
                  stroke="#C9D6E1"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentStep: state.estimate.step
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EstimateProcess);
