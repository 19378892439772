import React from "react";
import { connect } from "react-redux";
import SyncLoader from "react-spinners/SyncLoader";
import { countSliderChangeService, countSliderSetTermofwork, countSliderSetWorkload } from "../../../../store/actions/countSliderAxtions";
import cn from "../Advantages.module.scss";
import AdvantagesServiceForm from "./AdvantagesServiceForm";
import AdvantagesServiceMaterials from "./AdvantagesServiceMaterials";
import AdvantagesServicesSlider from "./AdvantagesServicesSlider";

const InstantComplete = (props) => {
	const listDataFirst = [
		"рассчитает вам необходимое количество специалистов по нормам для выполнения вашей работы в срок;",
		"моментально даст вам необходимое количество людей и будет управлять ими через мобильное приложение.",
	];

	const listDataSecond = [
		"рассчитает вам по нормам необходимое количество материала;",
		"при выборе работы с нашими материалами, предоставит их вам по ценам поставщика.",
	];

	return (
		<section className={cn.main}>
			<div className={`${cn.mainWrapper} row`}>
				<div className={`${cn.mainLeftBlock} col-12 col-lg-5`}>
					<h2 className={cn.mainTitleText} style={{ textAlign: 'center' }}>Возможности сервиса</h2>
					<h3 className={cn.mainTitle}>
						<span className={cn.mainTitleNumber}>01 </span>
						<span className={cn.mainTitleText}>Моментальная комплектация</span>
					</h3>
					<div className={cn.mainTextBlock}>
						<span className={cn.mainSubtitle}>Строителями</span>
						<p className={cn.mainParagraph}>
							Сервис, исходя из указанного объема работ и сроков выполнения:
						</p>
						<ul className={cn.mainList}>
							{listDataFirst.map((item, index) => (
								<li key={index} className={cn.mainListItem}>
									{item}
								</li>
							))}
						</ul>
						<p className={cn.mainParagraph}>
							Все специалисты проверены нами на качество работ, имеют
							необходимую квалификацию и опыт.
						</p>
					</div>
					<div className={cn.mainTextBlock}>
						<span className={cn.mainSubtitle}>Материалами</span>
						<p className={cn.mainParagraph}>
							Сервис, исходя из указанного объема работ:
						</p>
						<ul className={cn.mainList}>
							{listDataSecond.map((item, index) => (
								<li key={index} className={cn.mainListItem}>
									{item}
								</li>
							))}
						</ul>
						<p className={cn.mainParagraph}>
							Вы также можете выполнять работу со своими материалами.
						</p>
					</div>
				</div>
				<div className={`${cn.mainRightBlock} col-12 col-lg-7`}>
					<div className={`${cn.builder} ${cn.calculatorWrapper}`}>
						<h2 className={cn.mainTitleText}>Интерактивный пример расчетов</h2>
						<div className={cn.calculatorHead}><b>Название работы</b></div>
							{props.service ? (
								<div className={cn.builderCalculator}>
									<AdvantagesServicesSlider
										countSliderChangeService={props.countSliderChangeService}
										services={props.services}
									/>
									<p>
										При указанном объёме работ и сроках
									</p>
									<AdvantagesServiceForm
										workLoad={props.workLoad}
										termOfWork={props.termOfWork}
										setWorkLoad={props.countSliderSetWorkload}
										setTermOfWork={props.countSliderSetTermofwork}
										service={props.service}
									/>
								</div>
							) : (
								<div className={cn.loaderWrapper}>
									<SyncLoader color="#7B53DA" />
								</div>
							)}
					</div>
					<div className={`${cn.materials} ${cn.calculatorWrapper}`}>
						<div className={cn.calculatorHead}><b>Необходимые материалы</b></div>
						{props.service ? (
								<AdvantagesServiceMaterials />
						) : (
							<div className={cn.loaderWrapper}>
								<SyncLoader color="#7B53DA" />
							</div>
						)}
					</div>
				</div>
			</div>
		</section>
	);
};

const mapStateToProps = (state) => ({
	service: state.categorySlider.activeService,
	services: state.categorySlider.selectedServices,
	workLoad: state.categorySlider.workLoad,
	termOfWork: state.categorySlider.termOfWork,
});
const mapDispatchToprops = {
	countSliderChangeService,
	countSliderSetWorkload,
	countSliderSetTermofwork,
};
export default connect(mapStateToProps, mapDispatchToprops)(InstantComplete);
