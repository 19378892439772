import React, {Component, Fragment} from 'react'
import ph from "../../../img/ph.jpg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileUpload} from "@fortawesome/free-solid-svg-icons";


export default class WorkerImageUploader extends Component {

    fileUploadInput = React.createRef()

    state = {
        // id: this.props.id,
        src: this.props.picture || ph,
        // src: ph,
        delete: false,
        error: ''
    }

    fileLoaderClick = (evt) => {
        let input = this.fileUploadInput.current
        // console.dir(input)
        if (evt.target.tagName !== 'INPUT') {
            input.click()
        }
    }

    fileLoader = () => {
        let file = this.fileUploadInput.current.files[0]

        if (!file) {
            return
        }
        if (!file.type.startsWith('image/')) {
            this.fileUploadInput.current.value = ""
            this.setState({
                error: 'Допустимые расширения jpg, png, gif'
            })
            setTimeout(() => {
                this.setState({
                    error: null
                })
            }, 5000)

            return

        } else if (file.size / 1024 > this.props.maxSize) {
            this.fileUploadInput.current.value = ""
            this.setState({
                error: `Максимальный размер файла ${(this.props.maxSize / 1000).toFixed(2)} МБ`
            })
            setTimeout(() => {
                this.setState({
                    error: null
                })
            }, 5000)

            return
        }

        this.setState({
            error: null
        })

        let reader = new FileReader()

        reader.onload = () => {

            let src = URL.createObjectURL(file)
            this.setState({
                src,
                delete: true
            })

            if (reader.result) {
                let value = {
                    name: file.name,
                    src: reader.result,
                    encoding: "base64"
                }
                this.props.handler(value, 'photo')
            }

            // console.log(src)
        }
        reader.readAsDataURL(file)

    }

    deleteImage = () => {
        this.props.handler({}, 'photo')
        this.setState({
            src: this.props.picture || ph,
            delete: false
        })
    }


    render() {
        // console.log(this.state)

        return (
            <Fragment>
                <div className="w-photo position-relative">

                    <img src={this.state.src} alt="specialist"/>

                    <div onClick={this.fileLoaderClick}
                         className="w-photo__upload d-flex justify-content-center align-items-center">

                        <FontAwesomeIcon
                            icon={faFileUpload}
                            className='file-upload-icon'
                            size={'lg'}
                        />

                        <span>Загрузить фото</span>

                    </div>


                    <input ref={this.fileUploadInput} className='d-none' onChange={this.fileLoader} type="file"/>
                </div>
                {
                    this.state.error
                        ?
                        <small className='text-danger text-center d-block'>{this.state.error}</small>
                        : null
                }
                {
                    this.state.delete
                        ?
                        <small className='text-secondary text-center d-block'
                               style={{cursor: "pointer"}}
                               onClick={this.deleteImage}>Удалить</small>
                        : null
                }

            </Fragment>
        )
    }
}

