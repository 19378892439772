import {
    FETCH_CONTACTS_ERROR, FETCH_CONTACTS_START, FETCH_CONTACTS_SUCCESS,
} from "../actions/actionTypes";

const initialState = {
    contacts: {

    },
    loading: false,
    error: false
}

export default function contactsReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_CONTACTS_START:
            return {
                ...state,
                loading: true
            }
        case FETCH_CONTACTS_SUCCESS:
            return {
                ...state,
                contacts: action.contacts,
                loading: false
            }
        case FETCH_CONTACTS_ERROR:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        default:
            return state
    }
}