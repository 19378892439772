//actions
export const SWITCH_TAB_WORK = 'SWITCH_TAB_WORK';

export const ADMIN_LOGIN_START = 'ADMIN_LOGIN_START';
export const ADMIN_LOGIN_SUCCESS = 'ADMIN_LOGIN_SUCCESS';
export const ADMIN_LOGIN_ERROR = 'ADMIN_LOGIN_ERROR';
export const ADMIN_LOGOUT = 'ADMIN_LOGOUT';
export const SET_ADMIN_PROPERTY = 'SET_ADMIN_PROPERTY';

//orderActions
export const CLEAR_ORDER = 'CLEAR_ORDER';
export const SET_ORDER_PROPERTY = 'SET_ORDER_PROPERTY';
export const SET_ORDER_FORM_CONTROL_VALUE = 'SET_ORDER_FORM_CONTROL_VALUE';

export const ORDER_SET_PROPERTY = 'ORDER_SET_PROPERTY';
export const ORDER_SET_MATERIALS = 'ORDER_SET_MATERIALS';
export const ORDER_SET_MATERIAL_QUANTITY = 'ORDER_SET_MATERIAL_QUANTITY';
export const VALIDATE_FORM_CONTROLS_ORDER = 'VALIDATE_FORM_CONTROLS_ORDER';
export const SET_ORDER_TRANCHE = 'SET_ORDER_TRANCHE';
export const SET_DEFAULT_MATERIAL_QUANTITY = 'SET_DEFAULT_MATERIAL_QUANTITY';


//serviceSelectActions
export const FETCH_SERVICES_START = 'FETCH_SERVICES_START';
export const FETCH_SERVICES_SUCCESS = 'FETCH_SERVICES_SUCCESS';
export const FETCH_SERVICES_ERROR = 'FETCH_SERVICES_ERROR';
export const FETCH_SERVICE_SELECT_CATEGORIES_SUCCESS = 'FETCH_SERVICE_SELECT_CATEGORIES_SUCCESS';
export const SET_SERVICE_SELECT_PROPERTY = 'SET_SERVICE_SELECT_PROPERTY';

//serviceCalcActions
export const FETCH_SERVICE_START = 'FETCH_SERVICE_START';
export const FETCH_SERVICE_SUCCESS = 'FETCH_SERVICE_SUCCESS';
export const FETCH_SERVICE_ERROR = 'FETCH_SERVICE_ERROR';
export const WORKERS_COUNT = 'WORKERS_COUNT';
export const FETCH_MATERIALS_START = 'FETCH_MATERIALS_START';
export const FETCH_MATERIALS_SUCCESS = 'FETCH_MATERIALS_SUCCESS';
export const FETCH_MATERIALS_ERROR = 'FETCH_MATERIALS_ERROR';
export const SWITCH_TAB_CALC = 'SWITCH_TAB_CALC';
export const UPDATE_SERVICE_CALC_PROPERTY = 'UPDATE_SERVICE_CALC_PROPERTY';
export const FETCH_ORDER_CONDITIONS_SUCCESS = 'FETCH_ORDER_CONDITIONS_SUCCESS';

//deliveryActions
export const SET_DELIVERY_DATA = 'SET_DELIVERY_DATA';
export const SET_DELIVERY_PRICE = 'SET_DELIVERY_PRICE';
export const DELIVERY_SWITCH_TAB = 'DELIVERY_SWITCH_TAB';
export const SET_DELIVERY_INPUT_VALUE = 'SET_DELIVERY_INPUT_VALUE';

//workersActions
export const FETCH_WORKERS_START = 'FETCH_WORKERS_START';
export const FETCH_WORKERS_SUCCESS = 'FETCH_WORKERS_SUCCESS';
export const FETCH_WORKERS_ERROR = 'FETCH_WORKERS_ERROR';
export const WORKER_SWITCH = 'WORKER_SWITCH';

//confirmOrder
export const SET_CONFIRM_ORDER_PROPERTY = 'SET_CONFIRM_ORDER_PROPERTY';
export const VALIDATE_FORM_CONTROLS = 'VALIDATE_FORM_CONTROLS';
export const CONFIRM_ORDER_START = 'CONFIRM_ORDER_START';
export const CONFIRM_ORDER_SUCCESS = 'CONFIRM_ORDER_SUCCESS';
export const CONFIRM_ORDER_ERROR = 'CONFIRM_ORDER_ERROR';
export const SET_CONFIRM_ORDER_TRANCHE_MAX = 'SET_CONFIRM_ORDER_TRANCHE_MAX';
export const CONFIRM_ORDER_SET_FORM_CONTROL_VALUE = 'CONFIRM_ORDER_SET_FORM_CONTROL_VALUE';

//slidersActions
export const FETCH_SLIDERS_HOMEPAGE_START = 'FETCH_SLIDERS_HOMEPAGE_START';
export const FETCH_SLIDERS_HOMEPAGE_SUCCESS = 'FETCH_SLIDERS_HOMEPAGE_SUCCESS';
export const FETCH_SLIDERS_HOMEPAGE_ERROR = 'FETCH_SLIDERS_HOMEPAGE_ERROR';
export const SET_CURRENT_SLIDE = 'SET_CURRENT_SLIDE';

//navSctions
export const FETCH_NAV_START = 'FETCH_NAV_START';
export const FETCH_NAV_SUCCESS = 'FETCH_NAV_SUCCESS';
export const FETCH_NAV_ERROR = 'FETCH_NAV_ERROR';
export const SET_CURRENT_NAV_LINK = 'SET_CURRENT_NAV_LINK';
export const SET_ACTIVE_NAV_ANCHOR = 'SET_ACTIVE_NAV_ANCHOR';

//contactsActions
export const FETCH_CONTACTS_START = 'FETCH_CONTACTS_START';
export const FETCH_CONTACTS_SUCCESS = 'FETCH_CONTACTS_SUCCESS';
export const FETCH_ONLINE_STATISTIC = 'FETCH_ONLINE_STATISTIC';
export const FETCH_CONTACTS_ERROR = 'FETCH_CONTACTS_ERROR';

//personalActions
export const SWITCH_TAB_PERSONAL = 'SWITCH_TAB_PERSONAL';
export const FETCH_USER_START = 'FETCH_USER_START';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR';

//authActions
export const SET_AUTH_FORM_PROPERTY = 'SET_AUTH_FORM_PROPERTY';
export const SET_AUTH_PROPERTY = 'SET_AUTH_PROPERTY';
export const VALIDATE_AUTH_FORM_CONTROLS = 'VALIDATE_AUTH_FORM_CONTROLS';

export const FETCH_AUTH_START = 'FETCH_AUTH_START';
export const FETCH_AUTH_SUCCESS = 'FETCH_AUTH_SUCCESS';
export const FETCH_AUTH_ERROR = 'FETCH_AUTH_ERROR';

export const FETCH_LOGIN_START = 'FETCH_LOGIN_START';
export const FETCH_LOGIN_SUCCESS = 'FETCH_LOGIN_SUCCESS';
export const FETCH_LOGIN_ERROR = 'FETCH_LOGIN_ERROR';
export const FETCH_USER_ABOUT = 'FETCH_USER_ABOUT';

export const AUTH_LOGOUT = 'AUTH_LOGOUT';

//orderListActions
export const FETCH_ORDER_LIST_START = 'FETCH_ORDER_LIST_START';
export const FETCH_ORDER_LIST_SUCCESS = 'FETCH_ORDER_LIST_SUCCESS';
export const FETCH_ORDER_LIST_ERROR = 'FETCH_ORDER_LIST_ERROR';
export const SET_ORDER_LIST_PROPERTY = 'SET_ORDER_LIST_PROPERTY';
export const ORDER_LIST_ITEM_SWITCH_TAB = 'ORDER_LIST_ITEM_SWITCH_TAB';
export const ORDER_LIST_ITEM_SWITCH_TAB_SMALL = 'ORDER_LIST_ITEM_SWITCH_TAB_SMALL';
export const FETCH_ORDER_LIST_MATERIALS_START = 'FETCH_ORDER_LIST_MATERIALS_START';
export const FETCH_ORDER_LIST_MATERIALS_SUCCESS = 'FETCH_ORDER_LIST_MATERIALS_SUCCESS';
export const FETCH_ORDER_LIST_MATERIALS_ERROR = 'FETCH_ORDER_LIST_MATERIALS_ERROR';
export const FETCH_ORDER_DETAIL_SUCCESS = 'FETCH_ORDER_DETAIL_SUCCESS';
export const FETCH_ORDER_DETAIL_START = 'FETCH_ORDER_DETAIL_START';
export const FETCH_PAYMENT_URL = 'FETCH_PAYMENT_URL';
export const FETCH_PAYMENT_LIST = 'FETCH_PAYMENT_LIST';
export const CHANGE_PAYMENT_SYSTEM = 'CHANGE_PAYMENT_SYSTEM';
export const ORDER_LIST_CANCEL_ORDER = 'ORDER_LIST_CANCEL_ORDER';
export const SET_ORDER_LIST_FORM_CONTROL_VALUE = 'SET_ORDER_LIST_FORM_CONTROL_VALUE';
export const TIMESHEETS_MASTER_REPORT_SUCCESS = 'TIMESHEETS_MASTER_REPORT_SUCCESS';


// specialist
export const SET_CURRENT_TAB_SPECIALIST = 'SET_CURRENT_TAB_SPECIALIST';
export const SET_SPECIALIST_PROPERTY = 'SET_SPECIALIST_PROPERTY';
export const SET_SPECIALIST_FORM_CONTROL = 'SET_SPECIALIST_FORM_CONTROL';
export const ADD_SPECIALIST = 'ADD_SPECIALIST';
export const SET_SPECIALIST = 'SET_SPECIALIST';
export const REMOVE_SPECIALIST = 'REMOVE_SPECIALIST';
export const SET_SPECIALIST_CARD_TYPE = 'SET_SPECIALIST_CARD_TYPE';
export const FETCH_SPECIALIST_START = 'FETCH_SPECIALIST_START';
export const FETCH_SPECIALIST_SUCCESS = 'FETCH_SPECIALIST_SUCCESS';
export const FETCH_SPECIALIST_ERROR = 'FETCH_SPECIALIST_ERROR';
export const AUTH_SPECIALIST_START = 'AUTH_SPECIALIST_START';
export const AUTH_SPECIALIST_SUCCESS = 'AUTH_SPECIALIST_SUCCESS';
export const AUTH_SPECIALIST_ERROR = 'AUTH_SPECIALIST_ERROR';
export const SEND_DATE_INSTRUCTION = 'SEND_DATE_INSTRUCTION';
export const SET_SPECIALIST_METHOD_WORK = 'SET_SPECIALIST_METHOD_WORK';
export const SET_SPECIALIST_TYPE_WORK = 'SET_SPECIALIST_TYPE_WORK';

export const GET_PROFESSIONS_START = 'GET_PROFESSIONS_START';
export const GET_PROFESSIONS_SUCCESS = 'GET_PROFESSIONS_SUCCESS';
export const GET_PROFESSIONS_ERROR = 'GET_PROFESSIONS_ERROR';

export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const SET_CATEGORY = 'SET_CATEGORY';
export const GET_SPECIALIST_SPECIALIZATIONS = 'GET_SPECIALIST_SPECIALIZATIONS';

// workerSelect
export const SET_WORKER_SELECT_PROPERTY = 'SET_WORKER_SELECT_PROPERTY';
export const SET_WORKER_SELECT_FORM_PROPERTY = 'SET_WORKER_SELECT_FORM_PROPERTY';
export const WORKER_SELECT_SEARCH_SUCCESS = 'WORKER_SELECT_SEARCH_SUCCESS';
export const WORKER_SELECT_DETAIL_SUCCESS = 'WORKER_SELECT_DETAIL_SUCCESS';
export const WORKER_SELECT_SET_MASTERS = 'WORKER_SELECT_SET_MASTERS';
export const SET_SELECTED_MASTER = 'SET_SELECTED_MASTER';
export const REMOVE_SELECTED_MASTER = 'REMOVE_SELECTED_MASTER';
export const WORKER_SELECT_CLEAR_WORKERS = 'WORKER_SELECT_CLEAR_WORKERS';

// DOCS
export const API_DOCS_FETCH_START = 'API_DOCS_FETCH_START';
export const API_DOCS_FETCH_SUCCESS = 'API_DOCS_FETCH_SUCCESS';
export const API_DOCS_FETCH_ERROR = 'API_DOCS_FETCH_ERROR';
export const API_USER_STORY_FETCH_SUCCESS = 'API_USER_STORY_FETCH_SUCCESS';
export const API_DOCS_RESPONSE_FETCH_SUCCESS = 'API_DOCS_RESPONSE_FETCH_SUCCESS';
export const API_DOCS_SET_PROP = 'API_DOCS_SET_PROP';
export const API_DOCS_FETCH_API_STORY_SUCCESS = 'API_DOCS_FETCH_API_STORY_SUCCESS';
export const API_DOCS_FETCH_ERROR_LIST_SUCCESS = 'API_DOCS_FETCH_ERROR_LIST_SUCCESS';

// DIALOGS
export const FETCH_DIALOG_START = 'FETCH_DIALOG_START';
export const FETCH_DIALOG_SUCCESS = 'FETCH_DIALOG_SUCCESS';
export const FETCH_DIALOG_ERROR = 'FETCH_DIALOG_ERROR';

export const FETCH_PROFILE_START = 'FETCH_PROFILE_START';
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export const FETCH_PROFILE_ERROR = 'FETCH_PROFILE_ERROR';

export const SET_DIALOG_PROPERTY = 'SET_DIALOG_PROPERTY';
export const READ_MSGS_SUCCESS = 'READ_MSGS_SUCCESS';

// ADMIN
export const SET_CURRENT_TAB = 'SET_CURRENT_TAB';

export const SAVE_ADMIN_CHANGES_SERVICE_NORM_START = 'SAVE_ADMIN_CHANGES_SERVICE_NORM_START';
export const SAVE_ADMIN_CHANGES_SERVICE_NORM_SUCCESS = 'SAVE_ADMIN_CHANGES_SERVICE_NORM_SUCCESS';
export const SAVE_ADMIN_CHANGES_SERVICE_NORM_ERROR = 'SAVE_ADMIN_CHANGES_SERVICE_NORM_ERROR';
export const SAVE_ADMIN_CHANGES_SERVICE_NORM_NOTHING = 'SAVE_ADMIN_CHANGES_SERVICE_NORM_NOTHING';
export const SET_ADMIN_SERVICE_NORM_PROPERTY = 'SET_ADMIN_SERVICE_NORM_PROPERTY';
/////////
export const FETCH_ADMIN_MATERIALS_SUCCESS = 'FETCH_ADMIN_MATERIALS_SUCCESS';
export const FETCH_ADMIN_SERVICES_START = 'FETCH_ADMIN_SERVICES_START';
export const FETCH_ADMIN_SERVICES_SUCCESS = 'FETCH_ADMIN_SERVICES_SUCCESS';
export const FETCH_ADMIN_SERVICES_ERROR = 'FETCH_ADMIN_SERVICES_ERROR';
export const FETCH_ADMIN_CATEGORIES_SUCCESS = 'FETCH_ADMIN_CATEGORIES_SUCCESS';
export const FETCH_ADMIN_PROFESSIONS_SUCCESS = 'FETCH_ADMIN_PROFESSIONS_SUCCESS';
export const FETCH_ADMIN_GOODS_SUCCESS = 'FETCH_ADMIN_GOODS_SUCCESS';
export const FETCH_ADMIN_ORDER_START = 'FETCH_ADMIN_ORDER_START';
export const FETCH_ADMIN_ORDER_SUCCESS = 'FETCH_ADMIN_ORDER_SUCCESS';
export const ADMIN_ORDER_SEARCH_MANAGERS_SUCCESS = 'ADMIN_ORDER_SEARCH_MANAGERS_SUCCESS';
export const ADMIN_ORDER_SET_MANAGER_SUCCESS = 'ADMIN_ORDER_SET_MANAGER_SUCCESS';
export const FETCH_ADMIN_ORDER_USERS_START = 'FETCH_ADMIN_ORDER_USERS_START';
export const FETCH_ADMIN_ORDER_USERS_SUCCESS = 'FETCH_ADMIN_ORDER_USERS_SUCCESS';
export const FETCH_ADMIN_ORDER_DETAIL_START = 'FETCH_ADMIN_ORDER_DETAIL_START';
export const FETCH_ADMIN_ORDER_DETAIL_SUCCESS = 'FETCH_ADMIN_ORDER_DETAIL_SUCCESS';
export const FETCH_ADMIN_ORDER_PAYS_SUCCESS = 'FETCH_ADMIN_ORDER_PAYS_SUCCESS';
export const ADMIN_ORDER_SET_PAY_SUCCESS = 'ADMIN_ORDER_SET_PAY_SUCCESS';

////////
export const SET_SERVICE_PROPERTY = 'SET_SERVICE_PROPERTY';
export const SET_SERVICE_LINKS = 'SET_SERVICE_LINKS';
export const SET_SERVICE_NORMS = 'SET_SERVICE_NORMS';
export const ADD_SERVICE_NORMS = 'ADD_SERVICE_NORMS';
export const DELETE_SERVICE_NORMS = 'DELETE_SERVICE_NORMS';
export const EDIT_SERVICE_NORMS = 'EDIT_SERVICE_NORMS';
export const SET_CUSTOMER_COEFFICIENT = 'SET_CUSTOMER_COEFFICIENT';
export const SET_MATERIAL_NORMS = 'SET_MATERIAL_NORMS';
export const ADD_MATERIAL_NORMS = 'ADD_MATERIAL_NORMS';
export const EDIT_MATERIAL_NORMS = 'EDIT_MATERIAL_NORMS';
export const DELETE_MATERIAL_NORMS = 'DELETE_MATERIAL_NORMS';
////////

export const FETCH_ADMIN_SERVICES_LIST_START = 'FETCH_ADMIN_SERVICES_LIST_START';
export const FETCH_ADMIN_SERVICES_LIST_SUCCESS = 'FETCH_ADMIN_SERVICES_LIST_SUCCESS';
export const FETCH_ADMIN_SERVICES_LIST_ERROR = 'FETCH_ADMIN_SERVICES_LIST_ERROR';
export const FETCH_ADMIN_CATEGORIES_LIST_SUCCESS = 'FETCH_ADMIN_CATEGORIES_LIST_SUCCESS';

export const ADD_ADMIN_SERVICES_LIST_SERVICE = 'ADD_ADMIN_SERVICES_LIST_SERVICE';
export const SET_ADMIN_SERVICES_LIST_SERVICE_PROPERTY = 'SET_ADMIN_SERVICES_LIST_SERVICE_PROPERTY';
export const DELETE_ADMIN_SERVICES_LIST_SERVICE = 'DELETE_ADMIN_SERVICES_LIST_SERVICE';

export const ADD_ADMIN_SERVICES_LIST_SUPPORT_SERVICE = 'ADD_ADMIN_SERVICES_LIST_SUPPORT_SERVICE';
export const SET_ADMIN_SERVICES_LIST_SUPPORT_SERVICE_PROPERTY = 'SET_ADMIN_SERVICES_LIST_SUPPORT_SERVICE_PROPERTY';

export const ADD_ADMIN_SERVICES_LIST_CATEGORY = 'ADD_ADMIN_SERVICES_LIST_CATEGORY';
export const SET_ADMIN_SERVICES_LIST_CATEGORY_PROPERTY = 'SET_ADMIN_SERVICES_LIST_CATEGORY_PROPERTY';
export const DELETE_ADMIN_SERVICES_LIST_CATEGORY = 'DELETE_ADMIN_SERVICES_LIST_CATEGORY';

export const ADD_ADMIN_SERVICES_LIST_SUB_CATEGORY = 'ADD_ADMIN_SERVICES_LIST_SUB_CATEGORY';
export const SET_ADMIN_SERVICES_LIST_SUB_CATEGORY_PROPERTY = 'SET_ADMIN_SERVICES_LIST_SUB_CATEGORY_PROPERTY';
export const DELETE_ADMIN_SERVICES_LIST_SUB_CATEGORY = 'DELETE_ADMIN_SERVICES_LIST_SUB_CATEGORY';

export const SAVE_ADMIN_SERVICE_LIST_CHANGES_NOTHING = 'SAVE_ADMIN_SERVICE_LIST_CHANGES_NOTHING';
export const SAVE_ADMIN_SERVICE_LIST_CHANGES_START = 'SAVE_ADMIN_SERVICE_LIST_CHANGES_START';
export const SAVE_ADMIN_SERVICE_LIST_CHANGES_SUCCESS = 'SAVE_ADMIN_SERVICE_LIST_CHANGES_SUCCESS';
export const SAVE_ADMIN_SERVICE_LIST_CHANGES_ERROR = 'SAVE_ADMIN_SERVICE_LIST_CHANGES_ERROR';

export const FETCH_ADMIN_MATERIALS_LIST_START = 'FETCH_ADMIN_MATERIALS_LIST_START';
export const FETCH_ADMIN_MATERIALS_LIST_SUCCESS = 'FETCH_ADMIN_MATERIALS_LIST_SUCCESS';
export const FETCH_ADMIN_MATERIALS_LIST_ERROR = 'FETCH_ADMIN_MATERIALS_LIST_ERROR';
export const FETCH_ADMIN_MATERIALS_LIST_CATEGORIES_SUCCESS = 'FETCH_ADMIN_MATERIALS_LIST_CATEGORIES_SUCCESS';
export const SET_ADMIN_MATERIALS_LIST_MATERIAL_PROPERTY = 'SET_ADMIN_MATERIALS_LIST_MATERIAL_PROPERTY';
export const FETCH_ADMIN_MATERIALS_LIST_UNITS_SUCCESS = 'FETCH_ADMIN_MATERIALS_LIST_UNITS_SUCCESS';
export const SAVE_ADMIN_MATERIALS_LIST_MATERIAL = 'SAVE_ADMIN_MATERIALS_LIST_MATERIAL';
export const ADD_ADMIN_MATERIALS_LIST_MATERIAL = 'ADD_ADMIN_MATERIALS_LIST_MATERIAL';
export const DELETE_ADMIN_MATERIALS_LIST_MATERIAL = 'DELETE_ADMIN_MATERIALS_LIST_MATERIAL';
export const SET_ADMIN_MATERIALS_LIST_PROPERTY = 'SET_ADMIN_MATERIALS_LIST_PROPERTY';


export const SAVE_ADMIN_CHANGES_MATERIAL_START = 'SAVE_ADMIN_CHANGES_MATERIAL_START';
export const SAVE_ADMIN_CHANGES_MATERIAL_NOTHING = 'SAVE_ADMIN_CHANGES_MATERIAL_NOTHING';
export const SAVE_ADMIN_CHANGES_MATERIAL_SUCCESS = 'SAVE_ADMIN_CHANGES_MATERIAL_SUCCESS';
export const SAVE_ADMIN_CHANGES_MATERIAL_ERROR = 'SAVE_ADMIN_CHANGES_MATERIAL_ERROR';

export const FETCH_ADMIN_PROFESSIONS_LIST_START = 'FETCH_ADMIN_PROFESSIONS_LIST_START';
export const FETCH_ADMIN_PROFESSIONS_LIST_SUCCESS = 'FETCH_ADMIN_PROFESSIONS_LIST_SUCCESS';
export const FETCH_ADMIN_PROFESSIONS_LIST_ERROR = 'FETCH_ADMIN_PROFESSIONS_LIST_ERROR';
export const FETCH_ADMIN_PROFESSIONS_LIST_CATEGORIES_SUCCESS = 'FETCH_ADMIN_PROFESSIONS_LIST_CATEGORIES_SUCCESS';
export const ADD_ADMIN_PROFESSIONS_LIST_PROFESSION = 'ADD_ADMIN_PROFESSIONS_LIST_PROFESSION';
export const SAVE_ADMIN_PROFESSIONS_LIST_PROFESSION = 'SAVE_ADMIN_PROFESSIONS_LIST_PROFESSION';
export const DELETE_ADMIN_PROFESSIONS_LIST_PROFESSION = 'DELETE_ADMIN_PROFESSIONS_LIST_PROFESSION';
export const SET_ADMIN_PROFESSIONS_LIST_PROPERTY = 'SET_ADMIN_PROFESSIONS_LIST_PROPERTY';

export const SAVE_ADMIN_CHANGES_PROFESSION_NOTHING = 'SAVE_ADMIN_CHANGES_PROFESSION_NOTHING';
export const SAVE_ADMIN_CHANGES_PROFESSION_START = 'SAVE_ADMIN_CHANGES_PROFESSION_START';
export const SAVE_ADMIN_CHANGES_PROFESSION_SUCCESS = 'SAVE_ADMIN_CHANGES_PROFESSION_SUCCESS';
export const SAVE_ADMIN_CHANGES_PROFESSION_ERROR = 'SAVE_ADMIN_CHANGES_PROFESSION_ERROR';

//complex
export const SET_COMPLEX_FORMCONTROL = 'SET_COMPLEX_FORMCONTROL';

//estimate
export const ESTIMATE_SET_FORM_ITEM_VALUE = "ESTIMATE_SET_FORM_ITEM_VALUE";
export const ESTIMATE_CHANGE_CHECKBOX_SELECT = "ESTIMATE_CHANGE_CHECKBOX_SELECT";
export const ESTIMATE_NEXT_STEP = "ESTIMATE_NEXT_STEP";
export const ESTIMATE_PREV_STEP = "ESTIMATE_PREV_STEP";
export const ESTIMATE_SET_VALUE = "ESTIMATE_SET_VALUE";
export const ESTIMATE_ADD_SERVICES_TO_ORDER = "ESTIMATE_ADD_SERVICES_TO_ORDER";
export const ESTIMATE_REMOVE_SERVICE_TO_ORDER = "ESTIMATE_REMOVE_SERVICE_TO_ORDER";
export const ESTIMATE_REMOVE_CATEGORY_OF_ORDER = "ESTIMATE_REMOVE_CATEGORY_OF_ORDER";
export const ESTIMATE_REMOVE_SUB_CATEGORY_OF_ORDER = "ESTIMATE_REMOVE_SUB_CATEGORY_OF_ORDER";
export const ESTIMATE_FILTER_CATEGORIES = 'ESTIMATE_FILTER_CATEGORIES';
export const ESTIMATE_FILTER_SUBCATEGORIES = 'ESTIMATE_FILTER_SUBCATEGORIES';
export const ESTIMATE_FILTER_SERVICES = 'ESTIMATE_FILTER_SERVICES';
export const ESTIMATE_FETCH_CONDITIONS_SUCCESS = 'ESTIMATE_FETCH_CONDITIONS_SUCCESS';
export const ESTIMATE_CHANGE_SERVICE_ANIMATE_ITEMS = 'ESTIMATE_CHANGE_SERVICE_ANIMATE_ITEMS';
export const ESTIMATE_RESET_VALUE = 'ESTIMATE_RESET_VALUE';
export const ESTIMATE_RESET_ORDER = 'ESTIMATE_RESET_ORDER';
export const ESTIMATE_SET_CALCULATE_DATA = 'ESTIMATE_SET_CALCULATE_DATA';
export const ESTIMATE_SET_CHANGED_DATA = 'ESTIMATE_SET_CHANGED_DATA';
export const ESTIMATE_CREATE_COMPLEX_START = 'ESTIMATE_CREATE_COMPLEX_START';
export const ESTIMATE_CREATE_COMPLEX_END = 'ESTIMATE_CREATE_COMPLEX_END';


//worker
export const FETCH_WORKER_CREATE_PROFESSIONS_START = 'FETCH_WORKER_CREATE_PROFESSIONS_START';
export const FETCH_WORKER_CREATE_PROFESSIONS_SUCCESS = 'FETCH_WORKER_CREATE_PROFESSIONS_SUCCESS';
export const FETCH_WORKER_CREATE_PROFESSIONS_ERROR = 'FETCH_WORKER_CREATE_PROFESSIONS_ERROR';
export const FETCH_WORKER_CREATE_CATEGORIES_START = 'FETCH_WORKER_CREATE_CATEGORIES_START';
export const FETCH_WORKER_CREATE_CATEGORIES_SUCCESS = 'FETCH_WORKER_CREATE_CATEGORIES_SUCCESS';
export const FETCH_WORKER_CREATE_CATEGORIES_ERROR = 'FETCH_WORKER_CREATE_CATEGORIES_ERROR';
export const FETCH_WORKER_CREATE_MASTERS_START = 'FETCH_WORKER_CREATE_MASTERS_START';
export const FETCH_WORKER_CREATE_MASTERS_SUCCESS = 'FETCH_WORKER_CREATE_MASTERS_SUCCESS';
export const FETCH_WORKER_CREATE_MASTERS_ERROR = 'FETCH_WORKER_CREATE_MASTERS_ERROR';


export const CREATE_WORKER_START = 'CREATE_WORKER_START';
export const CREATE_WORKER_SUCCESS = 'CREATE_WORKER_SUCCESS';
export const CREATE_WORKER_ERROR = 'CREATE_WORKER_ERROR';


export const FETCH_DATES_START = 'FETCH_DATES_START';
export const FETCH_DATES_SUCCESS = 'FETCH_DATES_SUCCESS';
export const FETCH_DATES_ERROR = 'FETCH_DATES_ERROR';
export const SET_DATES_DAY = 'SET_DATES_DAY';
export const ADD_DATES_DAY = 'ADD_DATES_DAY';
export const DELETE_DATES_DAY = 'DELETE_DATES_DAY';
export const SAVE_ADMIN_DATES_START = 'SAVE_ADMIN_DATES_START';
export const SAVE_ADMIN_DATES_SUCCESS = 'SAVE_ADMIN_DATES_SUCCESS';
export const SAVE_ADMIN_DATES_NOTHING = 'SAVE_ADMIN_DATES_NOTHING';
export const SAVE_ADMIN_DATES_ERROR = 'SAVE_ADMIN_DATES_ERROR';
export const SET_DATES_PROPERTY = 'SET_DATES_PROPERTY';
export const SET_DATES_ADDRESS_PROPERTY = 'SET_DATES_ADDRESS_PROPERTY';
export const SET_ADDRESS = 'SET_ADDRESS';
export const DELETE_ADDRESS = 'DELETE_ADDRESS';
export const SET_DATE_ADDRESS = 'SET_DATE_ADDRESS';


export const ADMIN_MASTERS_FETCH_START = 'ADMIN_MASTERS_FETCH_START';
export const ADMIN_MASTERS_FETCH_SUCCESS = 'ADMIN_MASTERS_FETCH_SUCCESS';
export const ADMIN_MASTERS_FETCH_ERROR = 'ADMIN_MASTERS_FETCH_ERROR';
export const ADMIN_MASTERS_CHECK_MODERATION = 'ADMIN_MASTERS_CHECK_MODERATION';

export const FETCH_ADMIN_ORDER_WORKERS_START = 'FETCH_ADMIN_ORDER_WORKERS_START';
export const FETCH_ADMIN_ORDER_WORKERS_SUCCESS = 'FETCH_ADMIN_ORDER_WORKERS_SUCCESS';
export const FETCH_ADMIN_ORDER_WORKERS_ERROR = 'FETCH_ADMIN_ORDER_WORKERS_ERROR';
export const FETCH_ADMIN_ORDER_TASKS_START = 'FETCH_ADMIN_ORDER_TASKS_START';
export const FETCH_ADMIN_ORDER_TASKS_SUCCESS = 'FETCH_ADMIN_ORDER_TASKS_SUCCESS';
export const FETCH_ADMIN_ORDER_TASKS_ERROR = 'FETCH_ADMIN_ORDER_TASKS_ERROR';
export const FETCH_ADMIN_ORDER_TASK_ROOM_START = 'FETCH_ADMIN_ORDER_TASK_ROOM_START';
export const FETCH_ADMIN_ORDER_TASK_ROOM_SUCCESS = 'FETCH_ADMIN_ORDER_TASK_ROOM_SUCCESS';
export const FETCH_ADMIN_ORDER_TASK_ROOM_ERROR = 'FETCH_ADMIN_ORDER_TASK_ROOM_ERROR';


export const WORKER_PERSONAL_FETCH_START = 'WORKER_PERSONAL_FETCH_START';
export const WORKER_PERSONAL_FETCH_SUCCESS = 'WORKER_PERSONAL_FETCH_SUCCESS';
export const WORKER_PERSONAL_FETCH_ERROR = 'WORKER_PERSONAL_FETCH_ERROR';
export const WORKER_PERSONAL_LOGIN_START = 'WORKER_PERSONAL_LOGIN_START';
export const WORKER_PERSONAL_LOGIN_SUCCESS = 'WORKER_PERSONAL_LOGIN_SUCCESS';
export const WORKER_PERSONAL_LOGIN_ERROR = 'WORKER_PERSONAL_LOGIN_ERROR';
export const WORKER_PERSONAL_LOGOUT = 'WORKER_PERSONAL_LOGOUT';
export const WORKER_PERSONAL_REG_START = 'WORKER_PERSONAL_REG_START';
export const WORKER_PERSONAL_REG_SUCCESS = 'WORKER_PERSONAL_REG_SUCCESS';
export const WORKER_PERSONAL_REG_ERROR = 'WORKER_PERSONAL_REG_ERROR';
export const WORKER_PERSONAL_FETCH_DATES = 'WORKER_PERSONAL_FETCH_DATES';
export const WORKER_PERSONAL_FETCH_ORDERS = 'WORKER_PERSONAL_FETCH_ORDERS';
export const WORKER_PERSONAL_FETCH_ORDER_DETAIL = 'WORKER_PERSONAL_FETCH_ORDER_DETAIL';
export const WORKER_PERSONAL_FETCH_ORDER_REPORTS = 'WORKER_PERSONAL_FETCH_ORDER_REPORTS';
export const SET_WORKER_PERSONAL_REPORT_PHOTOS = 'SET_WORKER_PERSONAL_REPORT_PHOTOS';
export const SET_WORKER_PERSONAL_REPORT_QUANTITY = 'SET_WORKER_PERSONAL_REPORT_QUANTITY';
export const DELETE_WORKER_PERSONAL_LOCAL_PHOTO = 'DELETE_WORKER_PERSONAL_LOCAL_PHOTO';

export const SET_WORKER_PERSONAL_PROPERTY = 'SET_WORKER_PERSONAL_PROPERTY';
export const FETCH_GROUPS_SUCCESS = 'FETCH_GROUPS_SUCCESS';

export const ADMIN_GOODS_FETCH_START = 'ADMIN_GOODS_FETCH_START';
export const ADMIN_GOODS_FETCH_SUCCESS = 'ADMIN_GOODS_FETCH_SUCCESS';
export const ADMIN_GOODS_FETCH_ERROR = 'ADMIN_GOODS_FETCH_ERROR';
export const ADMIN_GOODS_FETCH_CATEGORIES_SUCCESS = 'ADMIN_GOODS_FETCH_CATEGORIES_SUCCESS';
export const SET_ADMIN_GOODS_GOOD_PROPERTY = 'SET_ADMIN_GOODS_GOOD_PROPERTY';
export const ADD_ADMIN_GOODS_GOOD = 'ADD_ADMIN_GOODS_GOOD';
export const SAVE_ADMIN_GOODS_GOOD = 'SAVE_ADMIN_GOODS_GOOD';
export const ADD_ADMIN_GOODS_CATEGORY = 'ADD_ADMIN_GOODS_CATEGORY';
export const SET_ADMIN_GOODS_CATEGORY_PROPERTY = 'SET_ADMIN_GOODS_CATEGORY_PROPERTY';
export const DELETE_ADMIN_GOODS_CATEGORY = 'DELETE_ADMIN_GOODS_CATEGORY';
export const SET_ADMIN_GOODS_PROPERTY = 'SET_ADMIN_GOODS_PROPERTY';

export const GET_CLASSIFIERS_ADMIN_START = 'GET_CLASSIFIERS_ADMIN_START';
export const GET_CLASSIFIERS_ADMIN_SUCCESS = 'GET_CLASSIFIERS_ADMIN_SUCCESS';
export const GET_CLASSIFIERS_ADMIN_ERROR = 'GET_CLASSIFIERS_ADMIN_ERROR';
export const GET_CLASSIFIERS_ROLES_ADMIN_SUCCESS = 'GET_CLASSIFIERS_ROLES_ADMIN_SUCCESS';
export const EDIT_CLASSIFIER_ADMIN = 'EDIT_CLASSIFIER_ADMIN';

// ADMIN WORKER PERSONAL

export const ADMIN_WORKER_PERSONAL_FETCH_SUCCESS = 'ADMIN_WORKER_PERSONAL_FETCH_SUCCESS';
export const ADMIN_WORKER_PERSONAL_ORDERS_FETCH_SUCCESS = 'ADMIN_WORKER_PERSONAL_ORDERS_FETCH_SUCCESS';
export const ADMIN_WORKER_PERSONAL_UPDATE_PROPERTY = 'ADMIN_WORKER_PERSONAL_UPDATE_PROPERTY';

export const COUTN_SLIDER_CHANGE_CATEGORY = "COUTN_SLIDER_CHANGE_CATEGORY";
export const COUTN_SLIDER_SET_SERVICES = "COUTN_SLIDER_SET_SERVICES";
export const COUTN_SLIDER_SET_CATEGORIES = "COUTN_SLIDER_SET_CATEGORIES";
export const COUNT_SLIDER_CHANGE_SERVICE = 'COUNT_SLIDER_CHANGE_SERVICE';
export const COUNT_SLIDER_SET_WORKLOAD = 'COUNT_SLIDER_SET_WORKLOAD';
export const COUNT_SLIDER_SET_TERMOFWORK = 'COUNT_SLIDER_SET_TERMOFWORK';
export const COUNT_SLIDER_SET_MATERIALS = 'COUNT_SLIDER_SET_MATERIALS';


// GANTT

export const GANTT_CHANGE_POSITION = 'GANTT_CHANGE_POSITION';