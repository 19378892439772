import React from 'react';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Paper from "@material-ui/core/Paper";

import AdminActive from "../AdminUI/AdminActive";
import AdminBtn from "../AdminUI/AdminBtn";
import TextField from "@material-ui/core/TextField";
import {
	addServiceListSupportService,
	deleteServiceListService,
	setServiceListServiceProperty, setServiceListSupportServiceProperty
} from "../../../../store/actions/adminServiceListActions";
import {connect} from "react-redux";
import cuid from "cuid";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import parse from "html-react-parser";
import {fetchAdminMaterialListUnits} from "../../../../store/actions/adminMaterialListActions";
import {IconButton, Snackbar} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';


class AdminServiceListServices extends React.Component {

	state = {
		inputs: {},
		errors: {},
		serviceNameExist: '',
	}

	inputHandler = (serviceId, property = 'name') => {
		const inputs = {...this.state.inputs}
		const errors = {...this.state.errors}

		return (event) => {
			if (inputs[serviceId]) {
				const value = event.target.value;
				if (property === 'name') {
					inputs[serviceId].name = value;
					if (value.length > 3 && errors[serviceId] === true) {
						errors[serviceId] = false
						this.setState({errors})
					}
				}
				if (property === 'unit') {
					inputs[serviceId].unit = value
				}
				if (property === 'sort') {
					inputs[serviceId].sort = value
				}
			}
			this.setState({inputs})
		}
	}

	checkServiceName = (id, name) => {
		const {services} = this.props;
		if (services.find((service) => service.id === id && !service.isNew)) {
			return false;
		} else {
			return services.map(s => s.name.toLocaleLowerCase().trim()).includes(name.toLocaleLowerCase().trim())
		}
	}

	saveInputHandler = (serviceId, supportServiceId = null, isServiceSupport = false) => {
		const name = isServiceSupport ? this.state.inputs[supportServiceId].name : this.state.inputs[serviceId].name;
		const unit = isServiceSupport ? this.state.inputs[supportServiceId].unit : this.state.inputs[serviceId].unit;
		const sort = isServiceSupport ? this.state.inputs[supportServiceId].sort : this.state.inputs[serviceId].sort;
		const errors = {...this.state.errors};
		if (name) {
			if (this.checkServiceName(supportServiceId || serviceId, name)) {
				this.setState({serviceNameExist: name})
				errors[serviceId] = true;
			} else {
				if (isServiceSupport) {
					this.props.setServiceListSupportServiceProperty(serviceId, supportServiceId, name, 'name');
					this.props.setServiceListSupportServiceProperty(serviceId, supportServiceId, unit, 'unit');
					this.props.setServiceListSupportServiceProperty(serviceId, supportServiceId, sort, 'sort');
					this.props.setServiceListSupportServiceProperty(serviceId, supportServiceId, false, 'edit');
					this.props.setServiceListSupportServiceProperty(serviceId, supportServiceId, true, 'edited');
				} else {
					this.props.setServiceListServiceProperty(serviceId, name, 'name');
					this.props.setServiceListServiceProperty(serviceId, unit, 'unit');
					this.props.setServiceListServiceProperty(serviceId, sort, 'sort');
					this.props.setServiceListServiceProperty(serviceId, false, 'edit');
					this.props.setServiceListServiceProperty(serviceId, true, 'edited');
				}
				errors[serviceId] = false;
				this.setState({serviceNameExist: ''})
			}
			this.setState({errors})
		} else {
			errors[serviceId] = true;
			this.setState({errors})
		}
	}

	deleteHandler = (serviceId, isNew) => {
		// console.log('isNew', isNew)
		if (isNew) {
			this.props.deleteServiceListService(serviceId)
		} else {
			this.props.setServiceListServiceProperty(serviceId, true, 'deleted')
		}
	}

	addSupportServiceHandler = (serviceId) => {
		let id = cuid();
		const supportService = {
			id,
			active: false,
			name: "",
			unit: "м&sup2;",
			edit: true,
			edited: true,
			isNew: true,
		}
		const inputs = {...this.state.inputs};
		inputs[id] = {
			name: "",
			unit: "м&sup2;",
		};

		this.setState({
			inputs
		})


		this.props.addServiceListSupportService(serviceId, supportService)
		this.props.setServiceListServiceProperty(serviceId, true, 'edited');
		this.props.setExpandedHandler(serviceId, 3);
	}

	componentDidMount() {
		if (!this.props.units.length) {
			this.props.fetchAdminMaterialListUnits()
		}
		this.setServices();
	}

	setServices = () => {
		const inputs = {}
		this.props.services.forEach(service => {
			inputs[service.id] = {
				'name': service.name,
				'unit': service.unit,
				'sort': service.sort
			}
			if (!!service.items.length) {
				service.items.forEach(item => {
					inputs[item.id] = {
						'name': item.name,
						'unit': item.unit,
						'sort': service.sort
					}
				})
			}
		})

		this.setState({inputs})
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.services.length !== prevProps.services.length) {
			this.setServices();
		}

	}

	render() {
		const {
			units,
			services, expanded, catIdx,
			subCatIdx, setServiceListServiceProperty,
			setServiceListSupportServiceProperty,
			handleChange,
		} = this.props

		return (
			<>
				<List className="w-100">
					{
						services.map((service, idx) => {
								// console.log('catIdx', catIdx)
								return (
									<ListItem key={service.id}
											  className="w-100 px-0 py-1">
										{
											!!service.items.length ?
												<Accordion
													TransitionProps={{unmountOnExit: true}}
													className='w-100 px-0 Admin_works'
													key={service.id}
													expanded={expanded === service.id}
													onChange={handleChange(service.id, 3)}>
													<AccordionSummary
														expandIcon={
															<ExpandMoreIcon
																className="Admin_toggle-btn"/>}
														aria-controls="panel1bh-content"
														id="panel1bh-header"
														className="bg-admin-acc-2"
													>
														<div
															className="d-flex align-items-center justify-content-between w-100">
															{/*<div className='d-flex align-items-center'>*/}

															{
																service.edit ?
																	<div
																		className='d-flex align-items-center justify-content-between w-100'>
																		<TextField
																			variant="outlined"
																			className='Admin_service-list col-7 edit_btn__js'
																			error={!!this.state.errors[service.id]}
																			onChange={this.inputHandler(service.id)}
																			value={this.state.inputs[service.id].name || ''}
																		/>
																		<div className='col-2 px-2'>
																			<Select
																				style={{height: '27px', width: '100%'}}
																				variant="outlined"
																				size={5}
																				value={service.unit}
																				onChange={this.inputHandler(service.id, 'unit')}
																			>
																				{units.map(unit => {
																					return (
																						<MenuItem
																							key={unit.id}
																							value={unit.web_name}>
																							{parse(unit.web_name)}
																						</MenuItem>
																					)
																				})}

																			</Select>
																		</div>
																		<TextField
																			variant="outlined"
																			className='Admin_service-list col-3 edit_btn__js'
																			error={!!this.state.errors[service.id]}
																			onChange={this.inputHandler(service.id, 'sort')}
																			value={this.state.inputs[service.id].sort}
																		/>
																	</div>
																	:
																	<div className='d-flex align-items-center w-100'>
																		<div
																			className="d-flex align-items-center justify-content-between a__service-list__item">
																			<div
																				className='d-flex align-items-center'>

																				<Typography variant="button"
																							className="mr-1">{catIdx}.{subCatIdx + 1}.{idx + 1}. {service.name}&nbsp;</Typography>
																				<Typography variant="overline"
																							display="block"
																							className="mr-1"
																				>
																					{`${service.isNew ? '' : `[ id: ${service.id} ]`}`}
																				</Typography>
																				<Typography variant="overline"
																							display="block">
																					{`[ Подработ: ${service.items.length} ]`}
																				</Typography>

																			</div>
																			{
																				!!service.edit && units.length ?
																					<FormControl variant="outlined"
																								 className='Admin_material-list-item__select col mx-1 edit_btn__js'>

																						<Select
																							size={5}
																							value={this.state.inputs[service.id].unit || ''}
																							onChange={this.inputHandler(service.id, 'unit')}
																						>
																							{units.map(unit => {

																								return (

																									<MenuItem
																										key={unit.id}
																										value={unit.web_name}>
																										{parse(unit.web_name)}
																									</MenuItem>
																								)
																							})}

																						</Select>
																					</FormControl>
																					:
																					<div
																						className='d-flex align-items-center'>
																						<Typography variant="subtitle1"
																									className="d-flex mr-3">{parse(service.unit || "")}</Typography>
																						<Typography variant="button"
																									className="d-flex align-items-center justify-content-end ">Сортировка: {service.sort}</Typography>
																					</div>
																			}
																		</div>
																	</div>
															}
															{/*</div>*/}
															<div
																className="d-flex justify-content-end ">
																<AdminActive active={service.active}
																			 edited={service.edited}/>
																<AdminBtn tip="Добавить подработу"
																		  type="add"
																		  onClick={() => this.addSupportServiceHandler(service.id)}/>
																{
																	service.edit
																		?
																		<AdminBtn
																			tip="Сохранить работу"
																			type="save"
																			onClick={() => this.saveInputHandler(service.id)}/>
																		:
																		<AdminBtn
																			tip="Редактировать работу"
																			type="edit"
																			onClick={() => setServiceListServiceProperty(service.id, true, 'edit')}/>
																}


																{
																	service.active ?
																		<AdminBtn
																			tip="Деактивировать работу"
																			type="clear"
																			onClick={() => setServiceListServiceProperty(service.id, false, 'active')}/>
																		:
																		<AdminBtn
																			tip="Активировать работу"
																			type="check"
																			onClick={() => setServiceListServiceProperty(service.id, true, 'active')}/>
																}

																{
																	service.deleted
																		?
																		<AdminBtn tip="Восстановить работу"
																				  type="restore"
																				  onClick={() => setServiceListServiceProperty(service.id, false, 'deleted')}
																		/>
																		:
																		<AdminBtn tip="Удалить работу" type="delete"
																				  onClick={() => this.deleteHandler(service.id, !!service.isNew)}
																		/>
																}


															</div>
														</div>
													</AccordionSummary>
													<AccordionDetails
														className="px-0 Admin_subWorks">
														<List
															className="w-100">
															{
																service.items.filter(scsItem => (scsItem.isNew && !scsItem.deleted) || !scsItem.isNew)
																	.map((scsItem, scsItem_idx) => {

																		return (
																			<ListItem
																				key={scsItem.id}
																				className="pr-5 d-flex align-items-center justify-content-between w-100 border-bottom border-gray">
																				<div
																					className='w-100 d-flex align-items-center mr-5'>

																					{
																						scsItem.edit
																							? <TextField
																								variant="outlined"
																								className='Admin_service-list w-75 edit_btn__js mr-2'
																								error={!!this.state.errors[scsItem.id]}
																								onChange={this.inputHandler(scsItem.id)}
																								value={this.state.inputs[scsItem.id] && (this.state.inputs[scsItem.id].name || '')}
																							/>
																							: <Typography variant="overline"
																										  display="block"
																										  className="mr-3 w-75 ">  {scsItem_idx + 1}. {scsItem.name}</Typography>
																					}

																					{
																						!!scsItem.edit && units ?
																							<FormControl variant="outlined"
																										 className='Admin_material-list-item__select col mx-1 edit_btn__js'>

																								<Select
																									style={{
																										height: '27px',
																										width: '100%'
																									}}
																									variant="outlined"
																									size={5}
																									// value={this.state.inputs[service.id] ? (this.state.inputs[service.id].unit ? this.state.inputs[service.id].unit : service.unit) : service.unit}
																									value={this.state.inputs[scsItem.id] && (this.state.inputs[scsItem.id].unit || '')}
																									onChange={this.inputHandler(scsItem.id, 'unit')}
																								>
																									{units.map(unit => {
																										return (

																											<MenuItem
																												key={unit.id}
																												value={unit.web_name}>
																												{parse(unit.web_name)}
																											</MenuItem>
																										)
																									})}

																								</Select>
																							</FormControl>
																							:
																							<Typography variant="subtitle1"
																										className="col p-0 m-0 d-flex">{parse(scsItem.unit || "")}</Typography>
																					}

																				</div>

																				<div
																					className="pr-2 d-flex justify-content-between">
																					<AdminActive active={scsItem.active}
																								 edited={scsItem.edited}/>

																					{
																						scsItem.edit
																							?
																							<AdminBtn
																								tip="Сохранить подработу"
																								type="save"
																								onClick={() => this.saveInputHandler(service.id, scsItem.id, true)}/>
																							:
																							<AdminBtn
																								tip="Редактировать подработу"
																								type="edit"
																								onClick={() => setServiceListSupportServiceProperty(service.id, scsItem.id, true, 'edit')}/>
																					}


																					{
																						scsItem.active ?
																							<AdminBtn
																								tip="Деактивировать подработу"
																								type="clear"
																								onClick={() => setServiceListSupportServiceProperty(service.id, scsItem.id, false, 'active')}/>
																							:
																							<AdminBtn
																								tip="Активировать подработу"
																								type="check"
																								onClick={() => setServiceListSupportServiceProperty(service.id, scsItem.id, true, 'active')}/>
																					}

																					{
																						scsItem.deleted && !scsItem.isNew
																							?
																							<AdminBtn
																								tip="Восстановить подработу"
																								type="restore"
																								onClick={() => setServiceListSupportServiceProperty(service.id, scsItem.id, false, 'deleted')}/>
																							:
																							<AdminBtn
																								tip="Удалить подработу"
																								type="delete"
																								onClick={() => setServiceListSupportServiceProperty(service.id, scsItem.id, true, 'deleted')}/>
																					}

																				</div>
																			</ListItem>
																		)
																	})
															}
														</List>
													</AccordionDetails>
												</Accordion>
												:
												<Paper
													className="p-3 pr-5 w-100 d-flex justify-content-between Admin_works ">
													<div className='w-100 d-flex align-items-center'>

														{
															service.edit && this.state.inputs[service.id] ?
																<div
																	className='d-flex align-items-center justify-content-between w-100'>
																	<TextField
																		variant="outlined"
																		className='Admin_service-list col-7 edit_btn__js'
																		error={!!this.state.errors[service.id]}
																		onChange={this.inputHandler(service.id)}
																		value={this.state.inputs[service.id].name || ''}
																	/>
																	<div className='col-2 px-2'>
																		{
																			console.log(this.state.inputs[service.id])
																		}
																		<Select
																			style={{height: '27px', width: '100%'}}
																			variant="outlined"
																			size={5}
																			value={this.state.inputs[service.id] ? (this.state.inputs[service.id].unit ? this.state.inputs[service.id].unit : service.unit) : service.unit}
																			onChange={this.inputHandler(service.id, 'unit')}
																		>
																			{units.map(unit => {
																				return (
																					<MenuItem
																						key={unit.id}
																						value={unit.web_name}>
																						{parse(unit.web_name)}
																					</MenuItem>
																				)
																			})}
																		</Select>
																	</div>
																	<TextField
																		variant="outlined"
																		className='Admin_service-list col-3 edit_btn__js'
																		error={!!this.state.errors[service.id]}
																		onChange={this.inputHandler(service.id, 'sort')}
																		value={this.state.inputs[service.id].sort}
																	/>
																</div>
																:
																// <div className='d-flex align-items-center w-100'>
																<div
																	className="d-flex align-items-center justify-content-between a__service-list__item">
																	<div
																		className='d-flex align-items-center'>
																		<Typography variant="button"
																					className="mr-1">{catIdx}.{subCatIdx + 1}.{idx + 1}. {service.name}&nbsp;</Typography>
																		<Typography variant="overline"
																					display="block"
																					className="mr-1"
																		>
																			{`${service.isNew ? '' : `[ id: ${service.id} ]`}`}
																		</Typography>
																	</div>
																	<div className='d-flex align-items-center'>
																		<Typography variant="subtitle1"
																					className="d-flex mr-3">{parse(service.unit || "")}</Typography>
																		<Typography variant="button"
																					className="d-flex align-items-center justify-content-end  pr-1">Сортировка: {service.sort}</Typography>
																	</div>
																</div>
															// </div>
														}
													</div>


													<div
														className="pr-2 d-flex justify-content-end ">
														<AdminActive active={service.active} edited={service.edited}/>
														<AdminBtn tip="Добавить подработу"
																  type="add"
																  onClick={() => this.addSupportServiceHandler(service.id)}/>

														{
															service.edit
																?
																<AdminBtn
																	tip="Сохранить работу"
																	type="save"
																	onClick={() => this.saveInputHandler(service.id)}/>
																:
																<AdminBtn
																	tip="Редактировать работу"
																	type="edit"
																	onClick={() => setServiceListServiceProperty(service.id, true, 'edit')}/>
														}
														{
															service.active ?
																<AdminBtn
																	tip="Деактивировать работу"
																	type="clear"
																	onClick={() => setServiceListServiceProperty(service.id, false, 'active')}/>
																:

																<AdminBtn
																	tip="Активировать работу"
																	type="check"
																	onClick={() => setServiceListServiceProperty(service.id, true, 'active')}/>


														}

														{
															service.deleted
																?
																<AdminBtn tip="Восстановить работу"
																		  type="restore"
																		  onClick={() => setServiceListServiceProperty(service.id, false, 'deleted')}
																/>
																:
																<AdminBtn tip="Удалить работу" type="delete"
																		  onClick={() => this.deleteHandler(service.id, !!service.isNew)}
																/>
														}

													</div>

												</Paper>
										}

									</ListItem>
								)
							}
						)
					}
				</List>

				<Snackbar
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					open={!!this.state.serviceNameExist}
					autoHideDuration={4000}
					message={`${this.state.serviceNameExist} уже существует!`}
					action={
						<React.Fragment>
							<IconButton size="small" aria-label="close" color="inherit"
										onClick={() => this.setState({serviceNameExist: ''})}>
								<CloseIcon fontSize="small"/>
							</IconButton>
						</React.Fragment>
					}
				/>
			</>
		);

	}
}

const mapState = state => {
	return {
		units: state.adminMaterialList.units,
		// services: state.adminServiceList.services,
	}
}

const actions =
	{
		deleteServiceListService,
		setServiceListServiceProperty,
		addServiceListSupportService,
		setServiceListSupportServiceProperty,
		fetchAdminMaterialListUnits
	}

export default connect(mapState, actions)(AdminServiceListServices);