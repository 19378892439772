import React, {useState} from 'react';
import ReactJson from "react-json-view";
import * as moment from 'moment'
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {CopyToClipboard} from "react-copy-to-clipboard";
import ErrorStatuses from "./ErrorStatuses";


const EndPointContent = ({action, response}) => {
    const [collapsed, setCollapsed] = useState(true);
    const [displayObjectSize, setDisplayObjectSize] = useState(false);
    const [displayDataTypes, setDisplayDataTypes] = useState(false);
    const [copy, setCopy] = useState(false);
    const {name, description, date, method, params, endpoint, responseSchema, errors, statusesErrors} = action;

    const copyHandler = () => {
       setCopy(true);
       setTimeout(() => {
            setCopy(false);
        }, 1000)

    };

    return (
        <div className="jumbotron jumbotron-fluid w-100">
            {
                action.name &&
                <div className="container">

                    <h2>{name}</h2>
                    <p>{description}</p>

                    {
                        !!date && <>
                            <h5>Date</h5>
                            <div
                                className="my-3 text-info text-uppercase pl-5">
                                {moment.unix(date).format('DD.MM.YYYY')}
                            </div>
                        </>

                    }

                    <h5>Method</h5>
                    <div className="my-3 pl-5 text-warning text-uppercase ">
                        {method}

                    </div>

                    <h5>Endpoint</h5>
                    <div
                        className="my-3 pl-5 text-warning d-flex align-items-center">
                        <span>{endpoint}</span>
                        <CopyToClipboard text={endpoint} onCopy={copyHandler}>
                            <FileCopyIcon className="ApiDocs_copy"/>
                        </CopyToClipboard>
                        {
                            copy && <span className='ml-2 text-success'>Скопировано!</span>
                        }
                    </div>

                    <h5>Response schema</h5>
                    <div
                        className="my-3 pl-5 text-warning">{responseSchema ? responseSchema : 'application/json'}
                    </div>


                    <h5>Params</h5>
                    <ul className="my-3 pl-5">{
                        params.map((param, idx) => {
                            return (
                                <li key={param.param} className='text-warning'>
                                    {idx + 1}.&nbsp;&nbsp;
                                    {param.param}
                                    {
                                        param.description ? (' - ' + param.description) : ''
                                    }
                                </li>
                            )
                        })

                    }</ul>

                    <hr className='border-success'/>

                    <h5 className="text-success">Success:</h5>
                    <div
                        className="pl-5 text-success">200 OK
                    </div>

                    <div className="mb-5 mt-3 d-flex flex-column">
                        <div
                            className="w-100 d-flex py-3 justify-content-end flex-wrap ApiDocs_controls">
                            <button type="button"
                                    className={`m-2 btn btn-outline-success ${collapsed ? '' : 'active'}`}
                                    onClick={() => setCollapsed(collapsed => !collapsed)}>
                                {`${collapsed ? 'Развернуть' : 'Свернуть'}`}
                            </button>
                            <button type="button"
                                    className={`m-2 btn btn-outline-success ${displayObjectSize ? 'active' : ''}`}
                                    onClick={() => setDisplayObjectSize(displayObjectSize => !displayObjectSize)}>
                                {`${displayObjectSize ? 'Скрыть' : 'Показать'}`} количество элементов
                            </button>
                            <button type="button"
                                    className={`m-2 btn btn-outline-success ${displayDataTypes ? 'active' : ''}`}
                                    onClick={() => setDisplayDataTypes(displayDataTypes => !displayDataTypes)}>
                                {`${displayDataTypes ? 'Скрыть' : 'Показать'}`} тип данных

                            </button>
                            {/*<button type="button"*/}
                            {/*        className={`m-2 btn btn-outline-warning ${!!onEdit ? 'active' : ''}`}*/}
                            {/*        onClick={this.onEditHandler}>*/}
                            {/*    Редактировать*/}
                            {/*</button>*/}

                        </div>
                        {
                            Object.keys(response).length &&
                            <ReactJson src={response}
                                       collapsed={collapsed}
                                       theme={"monokai"}
                                       iconStyle={"square"}
                                       displayObjectSize={displayObjectSize}
                                       displayDataTypes={displayDataTypes}
                                // onEdit={onEdit}
                            />
                        }
                    </div>


                    <ErrorStatuses errors={errors} statusesErrors={statusesErrors}/>

                </div>
            }

        </div>
    );
};


export default EndPointContent;