import React from "react";


export const NextArrow = ({ onClick }) => (
	<div className="WorkersSlider__arrow next" onClick={onClick}>
		<svg
			width="15"
			height="24"
			viewBox="0 0 15 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M2.85426 0L0 2.8162L9.27127 11.9838L0 21.1515L2.85426 23.9677L15 11.9838L2.85426 0Z"
				fill="black"
			/>
		</svg>
	</div>
);