import React from 'react'
import './ServiceCalculateHead.scss'
import TooltipBlueWhite from '../../../../../../components/UI/TooltipBlueWhite/TooltipBlueWhite'
import Th from '../../../../../../components/UI/Th/Th'
import parse from 'html-react-parser';


const ServiceCalculateHead = ({head}) => {

	return (
		<thead className='ServiceCalculateHead'>
		<tr>
			{head.map((item) => {
				let withTooltip = false
				if (item.tooltip) {
					withTooltip = item.tooltip.length > 0 ? true : false
				}

				return (
					<Th
						key={item.id}
						colSpan={item.col ? item.col : 1}>
						<div className='d-flex justify-content-center'>
							{parse(item.name)}
						</div>
						{withTooltip
							? <TooltipBlueWhite content={item.tooltip}/>
							: ''}

					</Th>
				)
			})}

				</tr>
				</thead>
				)
			};
			export default ServiceCalculateHead;