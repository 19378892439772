import React from 'react';
import classes from './ServiceSelectWrapper.module.scss'
import ServiceSelectSubstrate from "../../img/service-select-substrate.svg";


const ServiceSelectWrapper = ({children}) => {
    return (
        <div className={classes.wrapper}
             style={{backgroundImage: `url(${ServiceSelectSubstrate})`}}>
            {children}
        </div>
    );
};

export default ServiceSelectWrapper;