import axios from "../../rest/axios";
import {
	ADMIN_ORDER_SEARCH_MANAGERS_SUCCESS,
	ADMIN_ORDER_SET_MANAGER_SUCCESS,
	FETCH_ADMIN_ORDER_DETAIL_START,
	FETCH_ADMIN_ORDER_DETAIL_SUCCESS, FETCH_ADMIN_ORDER_PAYS_SUCCESS,
	FETCH_ADMIN_ORDER_START,
	FETCH_ADMIN_ORDER_SUCCESS,
	FETCH_ADMIN_ORDER_USERS_START,
	FETCH_ADMIN_ORDER_USERS_SUCCESS, SET_ORDER_LIST_FORM_CONTROL_VALUE, TIMESHEETS_MASTER_REPORT_SUCCESS
} from "./actionTypes";
import qs from 'qs'

export const fetchAdminOrderList = (page = 1, size = 10) => {
	return async dispatch => {
		const token = localStorage.getItem('token');

		dispatch({type: FETCH_ADMIN_ORDER_START})

		if (token) {
			try {
				const response = await axios.get('/admin/orders/getList/', {
					params: {
						token,
						nav: `page-${page}-size-${size}`
					}
				})
				if (response.data.data) {
					const {items, navigation} = response.data.data
					dispatch({
						type: FETCH_ADMIN_ORDER_SUCCESS, payload: {
							items, navigation
						}
					})
				}

			} catch (e) {

			}

		}
	}
};

export function managerSelectSearch(q, roles = 'MANAGER') {
	return async dispatch => {
		if (q.length >= 3) {
			try {
				let {data} = await axios.get('/web/users/search/', {
					params: {
						q: decodeURIComponent(q), roles
					}
				})

				dispatch({
					type: ADMIN_ORDER_SEARCH_MANAGERS_SUCCESS,
					payload: {
						managers:roles === 'MANAGER' ?  data.data.items : [],
						masterList: roles === 'MASTER' ?  data.data.items : [],
					}
				})

			} catch (e) {

			}
		}

	}
}

export function setOrderManager(orderId, managerId) {
	return async dispatch => {
		try {
			let response = await axios.post('/admin/orders/setManager/', qs.stringify({
				orderId,
				managerId,
				token: localStorage.getItem('token')
			}));
			dispatch({type: ADMIN_ORDER_SET_MANAGER_SUCCESS, payload: {managers: response.data.data.items}})

		} catch (e) {

		}

	}
}

export function setOrderMaster(orderId, masterId) {
	return async dispatch => {
		try {
			let response = await axios.post('/admin/orders/setMaster/', qs.stringify({
				orderId,
				masterId,
				token: localStorage.getItem('token')
			}));
			dispatch({type: ADMIN_ORDER_SET_MANAGER_SUCCESS, payload: {managers: response.data.data.items}})

		} catch (e) {

		}

	}
}

export const fetchAdminOrderUsersList = (orderId) => {
	return async dispatch => {
		const token = localStorage.getItem('token');

		if (token) {
			dispatch({type: FETCH_ADMIN_ORDER_USERS_START})
			try {
				const response = await axios.get('/admin/orders/getExecutors/', {
					params: {
						// roles: 'MASTER,MANAGER,INDEPENDENT_MASTER',
						orderId,
						token,
					}
				})
				if (response.data.data) {
					const {items, groups} = response.data.data
					dispatch({
						type: FETCH_ADMIN_ORDER_USERS_SUCCESS,
						payload: {
							items, groups, orderId
						}
					})
				}

			} catch (e) {

			}

		}
	}
};

export const fetchAdminOrderDetail = (orderId) => {
	return async dispatch => {
		const token = localStorage.getItem('token');

		if (token) {
			dispatch({type: FETCH_ADMIN_ORDER_DETAIL_START})
			try {
				// const response = await axios.get('/mobile/orders/getDetail/', {
				// 	params: {
				// 		orderId,
				// 		token,
				// 	}
				// })

				let response = await axios.post('/mobile/orders/getDetail/', qs.stringify({
					token, orderId
				}));

				if (response.data.data) {
					const order = response.data.data
					dispatch({
						type: FETCH_ADMIN_ORDER_DETAIL_SUCCESS,
						payload: {
							order
						}
					})
				}
			} catch (e) {

			}

		}
	}
};

export const fetchAdminOrderPays = (orderId) => {
	return async dispatch => {
		const token = localStorage.getItem('token');

		if (token) {
			dispatch({type: FETCH_ADMIN_ORDER_START})
			try {
				const {data} = await axios.get('/admin/pays/getList/', {
					params: {
						orderId,
						token,
					}
				})
				if (data.data) {
					dispatch({
						type: FETCH_ADMIN_ORDER_PAYS_SUCCESS,
						payload: {
							data
						}
					})
				}

			} catch (e) {

			}

		}
	}
};

export function setOrderPay(orderId, masterId, sum, date) {
	return async dispatch => {
		try {
			let {data} = await axios.post('/admin/pays/save/', qs.stringify({
				orderId,
				masterId,
				sum,
				date,
				token: localStorage.getItem('token')
			}));
			if (data.data.result) {
				dispatch(fetchAdminOrderPays(orderId))
			}

		} catch (e) {

		}

	}
}

export function setOrderClassifier(orderId, type, checked, zoneId = [], id) {
	return async dispatch => {
		if (!!zoneId.length) {
			try {
				let {data} = await axios.post('/admin/orders/setOrderClassifier/', qs.stringify({
					orderId, type, checked, zoneId, id,
					token: localStorage.getItem('token')
				}));

				dispatch(fetchAdminOrderDetail(orderId))
			} catch (e) {

			}
		}
	}
}

export function deleteOrderClassifier(orderId, type,) {
	return async dispatch => {
		try {
			let {data} = await axios.post('/admin/orders/setOrderClassifier/', qs.stringify({
				orderId,
				type,
				isDelete: true,
				token: localStorage.getItem('token')
			}));

			dispatch(fetchAdminOrderDetail(orderId))
		} catch (e) {

		}

	}
}

export function getTimesheetMasterReport(orderId, type,) {
	return async dispatch => {
		try {
			let {data} = await axios.post('/reports/timesheetsMasterReport/', qs.stringify({
				orderId,
				token: localStorage.getItem('token')
			}));
			dispatch({
				type: TIMESHEETS_MASTER_REPORT_SUCCESS,
				payload: data.data

			})
		} catch (e) {

		}

	}
}


export const setAdminOrderListFormControlValue = (value, property) => {
	return {
		type: SET_ORDER_LIST_FORM_CONTROL_VALUE,
		payload: {
			value, property
		}
	}
}