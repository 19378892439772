import {
    FETCH_SLIDERS_HOMEPAGE_ERROR,
    FETCH_SLIDERS_HOMEPAGE_START,
    FETCH_SLIDERS_HOMEPAGE_SUCCESS
} from "../actions/actionTypes";

const initialState = {
    sliders: [],
    currentSlide: 0,
    loading: false,
    error: false
}

export default function sliderHomePageReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_SLIDERS_HOMEPAGE_START:
            return {
                ...state,
                loading: true
            }
        case FETCH_SLIDERS_HOMEPAGE_SUCCESS:
            return {
                ...state,
                sliders: action.sliders,
                loading: false
            }
        case FETCH_SLIDERS_HOMEPAGE_ERROR:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        default:
            return state
    }
}