import {
    ADMIN_LOGIN_ERROR,
    ADMIN_LOGIN_START, ADMIN_LOGIN_SUCCESS, ADMIN_LOGOUT, SET_ADMIN_PROPERTY,
    SET_CURRENT_TAB, WORKER_PERSONAL_LOGOUT,
} from "./actionTypes";
import axios from "../../rest/axios";
import qs from 'qs'

export function setCurrentTab(id) {
    return {
        type: SET_CURRENT_TAB,
        payload: {
            id
        }
    }
}

export function adminLogin({email = null, password = null, token = null} = false) {
    return async dispatch => {
        try {
            let response = {};
            dispatch(adminLoginStart());
            if (email && password) {
                response = await axios.post('/admin/login/', qs.stringify({email, password}))
            } else {
                let token = localStorage.getItem('token');
                if (token) {
                    response = await axios.post('/admin/login/', qs.stringify({token}))
                }
            }
            if (response.data) {
                const {data: {token, id}, errorMessage} = response.data;
                if (token) {
                    localStorage.setItem('token', token);
                    localStorage.setItem('userId', id);
                    if (email) {
                        localStorage.setItem('email', email)
                    }
                    dispatch(adminLoginSuccess(response.data.data))
                }
                if (errorMessage) {
                    dispatch(adminLoginError(errorMessage))
                }
            }
            dispatch(setAdminProperty(false, 'loading')) ;
            dispatch(setAdminProperty(true, 'sendAuth'));
        } catch (e) {
            dispatch(adminLoginError(e.message))
        }
    }
}

function adminLoginStart() {
    return {
        type: ADMIN_LOGIN_START
    }
}

function adminLoginSuccess(data) {
    return {
        type: ADMIN_LOGIN_SUCCESS,
        payload: {
            data
        }
    }
}

function adminLoginError(error) {
    return {
        type: ADMIN_LOGIN_ERROR,
        payload: {
            error
        }
    }
}

export function setAdminProperty(value, property) {
    return {
        type: SET_ADMIN_PROPERTY,
        payload: {
            value,
            property
        }
    }
}

///----------------------------------------------------

export const adminLogOut = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('email');
    localStorage.removeItem('quantity');
    localStorage.removeItem('dueDate');
    localStorage.removeItem('serviceSelectStep');
    localStorage.removeItem('service');
    localStorage.removeItem('subCategory');
    localStorage.removeItem('category');

    return dispatch =>{
        dispatch({type: ADMIN_LOGOUT})
        dispatch({type: WORKER_PERSONAL_LOGOUT})
    }
}



