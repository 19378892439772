import React from 'react';
import {ListItem} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import AdminActive from "../AdminUI/AdminActive";
import AdminBtn from "../AdminUI/AdminBtn";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";


const AdminProfessionListItem = ({profession, num, saveHandler, deleteHandler, categories, subCategories}) => {

    const [state, setState] = React.useState({...profession})

    const inputHandler = (value, property) => {
        state[property] = value
        setState({...state})
    }

    const saveProfessionHandler = () => {
        state.edit = false
        state.edited = true
        saveHandler(state)
    }

    const editProfessionHandler = () => {
        state.edit = true
        saveHandler(state)
    }

    const activeProfessionHandler = (active = true) => {
        state.edited = true
        state.active = active
        saveHandler(state)
    }
    const deleteProfessionHandler = () => {
        deleteHandler(state)
    }
    const restoreProfessionHandler = () => {
        state.deleted = false
        saveHandler(state)
    }

    return (
        <ListItem className="Admin_material-list-item">
            <Paper className='w-100 h-100 py-1'>
                <div
                    className="Admin_material-list-content d-flex align-items-center border-top border-bottom border-gray">

                    <Typography variant="subtitle1"
                                className="col p-0 m-0 d-flex justify-content-center">
                        {num}. {!profession.isNew && <> [{profession.id}]</>}
                    </Typography>

                    {
                        !!profession.edit ?
                            <TextField
                                variant="outlined"
                                className='Admin_material-list-item__input col-3 edit_btn__js mr-1'
                                multiline
                                onChange={evt => inputHandler(evt.target.value, 'name')}
                                value={state.name || ''}
                            />
                            :
                            <Typography variant="subtitle1"
                                        className="col-3 p-0 m-0 Admin_material-list-item__name">{state.name}</Typography>
                    }

                    {
                        !!profession.edit ?
                            <TextField
                                variant="outlined"
                                type="number"
                                className='Admin_material-list-item__input col edit_btn__js mx-1'
                                onChange={evt => inputHandler(evt.target.value, 'costHourStandard')}
                                value={state.costHourStandard || ''}
                            />
                            :
                            <Typography variant="subtitle1"
                                        className="col p-0 m-0 d-flex justify-content-center">{state.costHourStandard}</Typography>
                    }
                    {
                        !!profession.edit ?
                            <TextField
                                variant="outlined"
                                type="number"
                                className='Admin_material-list-item__input col edit_btn__js mx-1'
                                onChange={evt => inputHandler(evt.target.value, 'costHourPremium')}
                                value={state.costHourPremium || ''}
                            />
                            :
                            <Typography variant="subtitle1"
                                        className="col p-0 m-0 d-flex justify-content-center">{state.costHourPremium}</Typography>
                    }

                    {/*  3  */}
                    {
                        !!profession.edit ?
                            <FormControl variant="outlined"
                                         className='Admin_material-list-item__select col-3 mx-1'>
                                <Select
                                    className=""
                                    size={5}
                                    multiple
                                    value={state.categories}
                                    onChange={evt => inputHandler(evt.target.value, 'categories')}
                                >
                                    {subCategories.map(subCategory => {
                                        return (

                                            <MenuItem
                                                key={subCategory.id}
                                                value={subCategory.id}>
                                                {subCategory.name}
                                            </MenuItem>
                                        )
                                    })}

                                </Select>
                            </FormControl>
                            :
                            <Typography variant="subtitle1"
                                        className="col-3 p-0 m-0 d-flex justify-content-center flex-column">
                                {state.categories.map((categoryId) => {
                                    let category = subCategories.find(category => category.id === categoryId)
                                    if (category) {
                                        return (<span key={category.id}>{category.name}</span>)
                                    }
                                    return null
                                })}
                            </Typography>
                    }
                    {/*   2  */}
                    <div className="col-2  d-flex justify-content-end">

                        <AdminActive active={state.active} edited={state.edited}/>

                        {
                            profession.edit
                                ?
                                <AdminBtn
                                    tip="Сохранить специальность"
                                    type="save"
                                    onClick={saveProfessionHandler}
                                />
                                :
                                <AdminBtn
                                    tip="Редактировать специальность"
                                    type="edit"
                                    onClick={editProfessionHandler}
                                />
                        }


                        {
                            profession.active ?
                                <AdminBtn tip="Деактивировать материал" type="clear"
                                          onClick={() => activeProfessionHandler(false)}
                                />
                                :
                                <AdminBtn tip="Активировать материал" type="check"
                                          onClick={() => activeProfessionHandler(true)}
                                />
                        }
                        {
                            profession.deleted
                                ?
                                <AdminBtn tip="Восстановить специальность" type="restore"
                                          onClick={restoreProfessionHandler}
                                />
                                :
                                <AdminBtn tip="Удалить специальность" type="delete"
                                          onClick={deleteProfessionHandler}
                                />
                        }

                    </div>
                </div>
            </Paper>
        </ListItem>
    );
};

export default AdminProfessionListItem;