import React from 'react';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const AdminMaterialListHeader = ({header}) => {
    return (
        <Paper className='d-flex mt-2 Admin_head'>

                {
                    header.map(item => {
                        return (
                            <Typography variant="overline"
                                        key={item.id}
                                        className={`Admin_head-item d-flex justify-content-center align-items-center flex-column p-0 col ${item.col ? 'col-' + item.col : 'col'}`}>
                                <span className='text-center'> {item.title}</span>
                                {
                                    !!item.subtitle &&
                                    <span className='text-center'>{item.subtitle}</span>
                                }
                            </Typography>
                        )
                    })
                }

        </Paper>
    );
};

export default AdminMaterialListHeader;