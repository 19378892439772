import React from 'react';

import DraggableScroll from '../../../../../components/UI/DraggableScroll/DraggableScroll';
import PaysTranches from './PaysTranches';
import { useSelector } from 'react-redux';
import { renderFormControl } from '../../../../../form/formFramework';

const PaysDates = ({ inputHandler }) => {
    const { formControls: { tranches } } = useSelector(state => state.confirmOrder);
    return (
        <div className="ConfirmOrder container position-relative mt-3">

            <div className="row justify-content-center mt-4">
                <div className="col-12 col-sm-6">
                    {renderFormControl(tranches, inputHandler)}
                </div>
            </div>

            <div className="ConfirmOrderTranches">
                <div className="ConfirmOrderTranches_LeftBlock border-right border-main-1-3">
                    <div className="ConfirmOrderTranches_LeftBlock__title">Плановый график выполнения</div>
                    <div className="d-flex align-content-center justify-content-end h70">
                        {/*<div className="w-10"/>*/}
                        <div className="w-70 pl-2 d-flex align-items-center">
                            Работы
                        </div>
                        <div
                            className="w-30 pl-3 d-flex  align-items-center">Ед.<br />изм
                        </div>
                    </div>
                    <div className="position-relative d-flex align-items-center w-100">

                        <div
                            className="w-101 ConfirmOrderTranches_LeftBlock-leftHeadingRotate top">

                            <div>
                                <span>План</span>
                            </div>

                        </div>

                        <div className="w-70">
                            <div
                                className="border-bottom border-main-1-3 d-flex align-items-center h50 pl-2">Объемы
                            </div>
                            <div className="border-bottom border-main-2-1 d-flex align-items-center h50 pl-2">Фин.
                                выполнение
                            </div>
                        </div>

                        <div className="w-30">
                            <div
                                className="pl-3 border-bottom border-main-1-3 d-flex align-items-center h50">м2
                            </div>
                            <div
                                className="pl-3 border-bottom border-main-2-1 d-flex align-items-center h50">руб.
                            </div>
                        </div>
                    </div>
                    <div className="position-relative d-flex align-items-center bg-white w-100">
                        <div
                            className="ConfirmOrderTranches_LeftBlock-leftHeadingRotate w-101 bottom">

                            <div>
                                <span>План по траншу</span>
                            </div>

                        </div>

                        <div className="w-70">
                            <div
                                className="border-bottom border-main-1-3 d-flex align-items-center h50 pl-2">Плановые
                                объемы
                            </div>
                            <div
                                className="border-bottom border-main-1-3 d-flex align-items-center h50 pl-2">Плановое
                                Фин. выполнение
                            </div>
                        </div>

                        <div className="w-30">
                            <div
                                className="pl-3 border-bottom border-main-1-3 d-flex align-items-center h50">м2
                            </div>
                            <div
                                className="pl-3 border-bottom border-main-1-3 d-flex align-items-center h50">руб.
                            </div>
                        </div>
                    </div>
                </div>
                <DraggableScroll>
                    <div className="ConfirmOrderTranches_RightBlock pb-3">
                        <PaysTranches inputHandler={inputHandler}/>
                    </div>
                </DraggableScroll>
            </div>
        </div>
    );
};

export default PaysDates;
