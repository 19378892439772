import React, { useRef, useEffect } from 'react'
import Slider from "react-slick";
import cn from '../Advantages.module.scss'

const AdvantagesServicesSlider = (props) => {

  const slider = useRef();

  const settings = {
    draggable: false,
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    beforeChange(_, index) {
      props.countSliderChangeService(props.services[index]);
    },
  };

  useEffect(() => { //Слик продолжает листать карусель, если ты перключился с неё и вернулся. Это фикс этого поведения
    if (slider.current) {
      slider.current.slickGoTo(0, true)
    }
  }, [props.services])

  const nextServices = () => {
    slider.current.slickNext();
  };
  const previousServices = () => {
    slider.current.slickPrev();
  };

  return (
    <div className={cn.sliderWrapper}>
        <Slider {...settings} ref={slider}>
          {props.services.map((s) => (
            <div key={s.id} className={cn.sliderItem}>
              <div className={cn.sliderName}>{s.name}</div>
            </div>
          ))}
        </Slider>
        <div className={cn.sliderArrows}>
          <div className={cn.sliderArrow} onClick={() => previousServices()}>
            <svg
              width="7"
              height="9"
              viewBox="0 0 7 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.35247 0L6.40997 1.0575L2.97497 4.5L6.40997 7.9425L5.35247 9L0.852472 4.5L5.35247 0Z"
                fill="black"
                fillOpacity="0.5"
              />
            </svg>
          </div>
          <div className={cn.sliderArrow} onClick={() => nextServices()}>
            <svg
              width="6"
              height="9"
              viewBox="0 0 6 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.05762 0L0.00012207 1.0575L3.43512 4.5L0.00012207 7.9425L1.05762 9L5.55762 4.5L1.05762 0Z"
                fill="black"
                fillOpacity="0.5"
              />
            </svg>
          </div>
        </div>
      </div>
  )
}

export default AdvantagesServicesSlider