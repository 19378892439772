import React, {Component, Fragment} from 'react'
import './ChatImageUploader.scss'
import ph from "../../../img/ph.jpg";

import {connect} from "react-redux";
import {setDialogProperty} from "../../../store/actions/dialogActions";


import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/shift-away.css'
import 'tippy.js/themes/light.css'
// import TooltipWhiteBlue from "../../UI/TooltipWhiteBlue/TooltipWhiteBlue";


// const specialistPhoto = React.createRef()
// const errorPhoto = React.createRef()

class ChatImageUploader extends Component {

    fileUploadInput = React.createRef()

    state = {
        // id: this.props.id,
        src: ph,
        maxSize: 5000,
        deleteBtn: false,
        error: null,
        showTip: false
    }

    fileLoaderClick = (evt) => {
        let input = this.fileUploadInput.current
        console.dir(input)
        if (evt.target.tagName !== 'INPUT') {
            input.click()
        }
    }

    fileLoader = () => {
        let file = this.fileUploadInput.current.files[0]

        // console.log(file)

        if (!file) {
            return
        }
        if (!file.type.startsWith('image/')) {
            this.fileUploadInput.current.value = ""

            this.setState({
                error: 'Допустимые расширения jpg, png, gif'
            })
            setTimeout(() => {
                this.setState({
                    error: null
                })
            }, 5000)

            return

        } else if (file.size / 1024 > this.state.maxSize) {
            this.fileUploadInput.current.value = ""
            this.setState({
                error: `Максимальный размер файла ${(this.state.maxSize / 1000).toFixed(2)} МБ`
            })
            setTimeout(() => {
                this.setState({
                    error: null
                })
            }, 5000)

            return
        }

        this.setState({
            error: null
        })

        let reader = new FileReader()

        reader.onload = () => {

            // let src = URL.createObjectURL(file)
            // this.setState({
            //     src,
            //     deleteBtn: true
            // })

            console.log(file)

            if (reader.result) {
                let value = {
                    name: file.name,
                    src: reader.result,
                    encoding: "base64"
                }
                this.props.setDialogProperty(value, 'currentImage')
            }

        }
        reader.readAsDataURL(file)

    }

    // deleteImage = () => {
    //     this.props.setDialogProperty({}, 'currentImage')
    //     this.setState({
    //         src: ph,
    //         deleteBtn: false
    //     })
    // }

    showTipHandler = () => {
        this.setState({showTip: true})
    }
    hideTipHandler = () => {
        this.setState({showTip: false})
    }


    render() {
        // console.log(this.state)

        const {error, showTip} = this.state

        // console.log('visible', !!error)

        return (
            <Fragment>

                <div className="ChatImageUploader_photo position-relative">


                    {/*<img src={this.state.src} alt="specialist"/>*/}
                    <div onClick={this.fileLoaderClick}
                         onMouseEnter={this.showTipHandler}
                         onMouseLeave={this.hideTipHandler}
                         className="d-flex justify-content-center align-items-center upload_file">

                        <Tippy

                            visible={!!error || showTip}
                            content={error ? error : "Загрузить фото из галереи"}
                            animation="shift-away"
                            theme="light"
                            placement="top-start"
                        >
                            <svg
                                // enableBackground="new 0 0 488.455 488.455"
                                // viewBox="0 0 488.455 488.455"
                                viewBox="0 60 480 320"
                            >
                                <path
                                    d="m287.396 216.317c23.845 23.845 23.845 62.505 0 86.35s-62.505 23.845-86.35 0-23.845-62.505 0-86.35 62.505-23.845 86.35 0"/>
                                <path
                                    d="m427.397 91.581h-42.187l-30.544-61.059h-220.906l-30.515 61.089-42.127.075c-33.585.06-60.925 27.429-60.954 61.029l-.164 244.145c0 33.675 27.384 61.074 61.059 61.074h366.338c33.675 0 61.059-27.384 61.059-61.059v-244.236c-.001-33.674-27.385-61.058-61.059-61.058zm-183.177 290.029c-67.335 0-122.118-54.783-122.118-122.118s54.783-122.118 122.118-122.118 122.118 54.783 122.118 122.118-54.783 122.118-122.118 122.118z"/>
                            </svg>

                            {/*<svg viewBox="0 0 477.867 427.867" >*/}
                            {/*<svg viewBox="0 60 480 320">*/}
                                {/*<g>*/}
                                    {/*<path d="M426.667,68.267H51.2c-28.277,0-51.2,22.923-51.2,51.2V358.4c0,28.277,22.923,51.2,51.2,51.2h375.467*/}
                                    {/*c28.277,0,51.2-22.923,51.2-51.2V119.467C477.867,91.19,454.944,68.267,426.667,68.267z M443.733,266.001L336.333,158.601*/}
                                    {/*c-6.664-6.663-17.468-6.663-24.132,0L170.667,300.134l-56.201-56.201c-6.664-6.663-17.468-6.663-24.132,0l-56.201,56.201V119.467*/}
                                    {/*c0-9.426,7.641-17.067,17.067-17.067h375.467c9.426,0,17.067,7.641,17.067,17.067V266.001z"/>*/}
                                {/*</g>*/}
                                {/*<g>*/}
                                    {/*<circle cx="153.6" cy="187.733" r="51.2"/>*/}
                                {/*</g>*/}
                            {/*</svg>*/}

                        </Tippy>
                    </div>
                    <input ref={this.fileUploadInput} className='d-none' onChange={this.fileLoader} type="file"/>
                </div>

                {/*{*/}
                {/*deleteBtn*/}
                {/*?*/}
                {/*<small className='text-secondary text-center d-block'*/}
                {/*style={{cursor: "pointer"}}*/}
                {/*onClick={this.deleteImage}>Удалить</small>*/}
                {/*: null*/}
                {/*}*/}

            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        setDialogProperty: (value, property) => dispatch(setDialogProperty(value, property)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatImageUploader)

