import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputMask from "react-input-mask";
import is from 'is_js';
import { KeyboardDatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import * as moment from "moment";
import 'moment/locale/ru';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeSquare, faPhoneSquareAlt } from "@fortawesome/free-solid-svg-icons";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import parse from 'html-react-parser';
import cn from './formFramework.module.scss';

export function createFormControl(config, validation) {
    return {
        ...config,
        validation,
        valid: !validation
    };
}

function PhoneMask(props) {
    const { id, inputRef, onChange, ...other } = props;
    return (
        <InputMask id={id} mask="+9-999-999-9999" className="MuiInputBase-input MuiInput-input" onChange={onChange}
                   value={(props.value).toString()} />
    );
}


export function renderFormControl(formControl, onChange, options = {}) {

    const focusHandler = (id) => {
        const helperText = document.getElementById(id + '-helper-text');
        if (helperText && helperText.classList.contains('focus')) {
            helperText.classList.remove('focus');
        } else {
            helperText.classList.add('focus');
        }
    };

    if (formControl.type === 'select') {

        return (
            <>
                <div className={`${options.className ? options.className : ''}`}>
                     <span id={`${formControl.id}-helper-text`}
                           className={'worker_label'}>{formControl.label} {options.addLabel ? ' ' + parse(options.addLabel) : ''}</span>
                    <Select
                        id={formControl.id}
                        className="worker_input"
                        size={options.size || 5}
                        value={formControl.value}
                        onChange={evt => onChange(evt.target.value, formControl.id)}
                        // error={!formControl.valid}
                        error={!!formControl.error}
                    >
                        {options.select.map(option => {
                            return (
                                <MenuItem
                                    classes={{
                                        root: 'worker_select__item'
                                    }}
                                    key={option.id}
                                    id={formControl.id}
                                    value={+option.id}>
                                    {option.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    {
                        !!formControl.error &&
                        <FormHelperText>{formControl.errorMessage[formControl.error]}</FormHelperText>
                    }
                </div>
                {/*<p className="Mui-error">{formControl.error ? formControl.errorMessage[formControl.error] : ''}</p>*/}
            </>
        );

    } else {
        if (formControl.type === 'date') {
              if (formControl.validation.expirationDateCard) {
                return (
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                           <span id={`${formControl.id}-helper-text`}
                                 className={'worker_label'}>{formControl.label} {options.addLabel ? ' ' + parse(options.addLabel) : ''}</span>
                        <KeyboardDatePicker
                            id={formControl.id}
                            className="worker_input"
                            placeholder={moment().format('MM / YYYY')}
                            views={["year", "month"]}
                            value={formControl.value ? moment(formControl.value, 'MM / YYYY') : null}
                            onChange={evt => {
                                let value = evt ? evt.format('MM / YYYY') : '';
                                onChange(value, formControl.id);
                            }}
                            format="MM / YYYY"
                            cancelLabel={"Отмена"}
                            okLabel={"ОК"}
                            variant={options.variant || 'filled'}
                            minDate={moment().add(1, 'days')}
                            maxDate={moment().add(3, 'years')}
                            onFocus={() => focusHandler(formControl.id)}
                            onBlur={() => focusHandler(formControl.id)}
                            error={!!formControl.error}
                            helperText={formControl.error ? formControl.errorMessage[formControl.error] : ''}
                        />
                    </MuiPickersUtilsProvider>
                );
            } else if (formControl.validation.birthDayMin || formControl.validation.birthDayMax) {
                return (
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                           <span id={`${formControl.id}-helper-text`}
                                 className={'worker_label'}>{formControl.label} {options.addLabel ? ' ' + parse(options.addLabel) : ''}</span>
                        <KeyboardDatePicker
                            id={formControl.id}
                            className="worker_input"
                            placeholder={moment().subtract(18, 'years').format('DD.MM.YYYY')}
                            value={formControl.value ? moment(formControl.value, 'DD.MM.YYYY') : null}
                            onChange={evt => {
                                let value = evt ? evt.format('DD.MM.YYYY') : '';
                                onChange(value, formControl.id);
                            }}
                            maxDate={moment().subtract(18, 'years')}
                            minDate={moment().subtract(100, 'years')}
                            format="DD.MM.YYYY"
                            cancelLabel={'Отмена'}
                            okLabel={'ОК'}
                            variant={options.variant || 'filled'}
                            onFocus={() => focusHandler(formControl.id)}
                            onBlur={() => focusHandler(formControl.id)}
                            error={!!formControl.error}
                            helperText={formControl.error ? formControl.errorMessage[formControl.error] : ''}
                        />
                    </MuiPickersUtilsProvider>
                );
            } else if (formControl.validation.dateTime) {
                return (
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                           <span id={`${formControl.id}-helper-text`}
                                 className={'worker_label'}>{formControl.label} {options.addLabel ? ' ' + parse(options.addLabel) : ''}</span>
                        <KeyboardDateTimePicker
                            id={formControl.id}
                            className="worker_input"
                            placeholder={moment().format('DD.MM.YYYY HH:mm')}
                            value={formControl.value ? moment(formControl.value, 'DD.MM.YYYY HH:mm') : null}
                            ampm={false}
                            format="DD.MM.YYYY HH:mm"
                            onChange={evt => {
                                let value = evt ? evt.format('DD.MM.YYYY HH:mm') : '';
                                onChange(value, formControl.id);
                            }}
                            minDate={moment().format('DD.MM.YYYY HH:mm')}
                            okLabel={'ОК'}
                            cancelLabel={'Отмена'}
                            variant={options.variant || 'filled'}
                            onFocus={() => focusHandler(formControl.id)}
                            onBlur={() => focusHandler(formControl.id)} v
                            error={!!formControl.error}
                            helperText={formControl.error ? formControl.errorMessage[formControl.error] : ''}

                        />
                    </MuiPickersUtilsProvider>
                );
            } else if (formControl.validation.minDate >= 0) {
                return (
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <span id={`${formControl.id}-helper-text`}
                              className={'worker_label'}>{formControl.label} {options.addLabel ? ' ' + parse(options.addLabel) : ''}</span>
                        <KeyboardDatePicker
                            id={formControl.id}
                            className="worker_input"
                            placeholder={moment().add(formControl.validation.minDate, 'days').format('DD.MM.YYYY')}
                            //	value={formControl.value ? moment(formControl.value, 'DD.MM.YYYY') : null}
                            onChange={evt => {
                                let value = evt ? evt.format('DD.MM.YYYY') : '';
                                onChange(value, formControl.id);
                            }}
                            onFocus={() => focusHandler(formControl.id)}
                            onBlur={() => focusHandler(formControl.id)}
                            minDate={moment().add(formControl.validation.minDate, 'days')}
                            format="DD.MM.YYYY"
                            cancelLabel={'Отмена'}
                            okLabel={'ОК'}
                            variant={options.variant || 'filled'}
                            error={!!formControl.error}
                            helperText={formControl.error ? formControl.errorMessage[formControl.error] : ''}
                        />
                    </MuiPickersUtilsProvider>
                );
            } else {

                let value = moment(formControl.value, "DD.MM.YYYY").isValid()
                    ?
                    moment(formControl.value, "DD.MM.YYYY")
                    :
                    moment(formControl.value).isValid()
                        ?
                        moment(formControl.value)
                        :
                        null;
                return (
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <span id={`${formControl.id}-helper-text`}
                              className={'worker_label'}>{formControl.label} {options.addLabel ? ' ' + parse(options.addLabel) : ''}</span>
                        <KeyboardDatePicker
                            id={formControl.id}
                            className="worker_input"
                            placeholder={moment().format('DD.MM.YYYY')}
                            value={value}
                            onChange={evt => {
                                let value = evt ? evt.format('DD.MM.YYYY') : '';
                                onChange(value, formControl.id);
                            }}
                            format="DD.MM.YYYY"
                            cancelLabel={"Отмена"}
                            okLabel={"ОК"}
                            variant={options.variant || 'filled'}
                            onFocus={() => focusHandler(formControl.id)}
                            onBlur={() => focusHandler(formControl.id)}
                            error={!!formControl.error}
                            helperText={formControl.error ? formControl.errorMessage[formControl.error] : ''}
                        />
                    </MuiPickersUtilsProvider>
                );
            }

        } else if (formControl.type === 'file') {
            let changeFileinput = (e) => {
                onChange(e.target.files, formControl.id);
            };
            let filesName = formControl.value.length < 17 ? formControl.value : `${formControl.value.slice(0, 10)}...`;
            return (
                <div className={cn.fileInput__wrapper}>
                    <span id={`${formControl.id}-helper-text`}
                          className={'worker_label'}>{formControl.label} {options.addLabel ? ' ' + parse(options.addLabel) : ''}</span>
                    <div className={cn.fileInput__bottom}>
                        <label className={cn.fileInput__label}>
                            <input
                                multiple={options.multiple}
                                className={cn.fileInput__input}
                                type="file" id={formControl.id}
                                onChange={changeFileinput}
                                accept={formControl.validation.allowedExt ? formControl.validation.allowedExt.join(',') : '*/*'}
                            />
                            <div className={cn.fileInput__button}>Загрузить файл{options.multiple ? 'ы' : ''}</div>
                            <div title={formControl.value} className={cn.fileInput__text}>{filesName}</div>
                        </label>
                        {formControl.hasFile && (
                            <div className={cn.fileInput__cross} onClick={() => options.clear(formControl.id)}>
                                <svg width="8" height="8" viewBox="0 0 8 8" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <rect x="6.00005" y="0.000641227" width="2.82835" height="8.48505" rx="1.41418"
                                          transform="rotate(45 6.00005 0.000641227)" fill="#DADADA" />
                                    <rect x="7.99979" y="5.99984" width="2.82835" height="8.48505" rx="1.41418"
                                          transform="rotate(135 7.99979 5.99984)" fill="#DADADA" />
                                </svg>
                            </div>
                        )}
                        <div className={cn.fileInput__error}>{formControl.error}</div>
                    </div>
                </div>
            );
        } else {
            let isPhone = formControl.id === 'phone' || formControl.id === 'user.phone' || formControl.type === 'tel';
            let isEmail = formControl.id === 'email' || formControl.id === 'user.email' || formControl.type === 'email';
            let isCreditCard = formControl.id === 'numberCard' || formControl.id === 'card.numberCard';
            let cardThumb = <FontAwesomeIcon icon={faCreditCard}
                                             size={"lg"} />;
            // if (isCreditCard && options.typeCard && formControl.valid) {
            //     switch (options.typeCard) {
            //         case 'visa': //4916338506082832
            //             cardThumb = <FontAwesomeIcon icon={faCcVisa}
            //                                          size={"lg"}/>;
            //             break;
            //         case 'mastercard': //5280934283171080
            //             cardThumb = <FontAwesomeIcon icon={faCcMastercard}
            //                                          size={"lg"}/>;
            //             break;
            //         default:
            //             cardThumb = <FontAwesomeIcon icon={faCreditCard}
            //                                          size={"lg"}/>
            //     }
            //
            //
            // }
            let InputProps = {};
            switch (true) {
                case isPhone:
                    InputProps.startAdornment = <InputAdornment className="pl-3 inputAdornment"
                                                                position="start"><FontAwesomeIcon
                        icon={faPhoneSquareAlt}
                        size={"lg"} /></InputAdornment>;
                    InputProps.inputComponent = PhoneMask;
                    break;
                case isEmail:
                    InputProps.startAdornment = <InputAdornment className="pl-3 inputAdornment"
                                                                position="start"><FontAwesomeIcon
                        icon={faEnvelopeSquare}
                        size={"lg"} /></InputAdornment>;
                    break;
                case isCreditCard:
                    InputProps.startAdornment = <InputAdornment className="pl-3 inputAdornment"
                                                                position="start">{cardThumb}</InputAdornment>;
                    break;
                default :
                    InputProps.startAdornment = null;
            }

            return (
                <>
                    <span id={`${formControl.id}-helper-text`}
                          className={'worker_label'}>{formControl.label} {options.addLabel ? ' ' + parse(options.addLabel) : ''}</span>
                    <TextField
                        id={formControl.id}
                        className={`worker_input ${options.className}`}
                        aria-describedby={`${formControl.id}-helper-text`}
                        variant={options.variant || "standard"}
                        multiline={options.multiline || false}
                        type={formControl.type || "text"}
                        rows={options.rows || 1}
                        // className={isPhone || isEmail ? 'isInputAdornment' : ''}
                        disabled={options.disabled || false}
                        value={formControl.value || ''}
                        onFocus={() => focusHandler(formControl.id)}
                        onBlur={() => focusHandler(formControl.id)}
                        error={!!formControl.error}
                        helperText={formControl.error ? formControl.errorMessage[formControl.error] : ''}
                        onChange={evt => onChange(evt.target.value, formControl.id)}
                        InputProps={InputProps}
                        name={formControl.id}
                    />
                </>


            );
        }
    }


}

export function renderStandardInput(formControl, onChange, className = '', exclusion = []) {
    let id = formControl.id + Math.random();
    className += " fw-input";
    if (formControl.error) {
        className += ' danger';
    }

    if (exclusion.indexOf(formControl.id) === -1) {
        return (
            <div className={className}>
                <label htmlFor={id}>{formControl.label}</label>
                <input type={formControl.type}
                    // className="form-control"
                       value={formControl.value}
                       onChange={evt => onChange(evt.target.value, formControl.id)}
                       id={id} />
                {
                    formControl.error
                        ?
                        <small className="danger">{formControl.errorMessage[formControl.error]}</small>
                        : null
                }

            </div>

        );
    } else {
        return null;
    }
}

export function renderSpecialistInput(value, formControl, onChange, className = '', exclusion = []) {
    let id = formControl.id + Math.random();
    className += " form-group fw-input";

    if (exclusion.indexOf(formControl.id) === -1) {
        return (
            <div className={className}>
                <label htmlFor={id}>{formControl.label}</label>
                <input type={formControl.type}
                       className="form-control"
                       value={value}
                       onChange={evt => onChange(evt.target.value, formControl.id)}
                       id={id} />
                <small>{formControl.errorMessage[formControl.error]}</small>
            </div>

        );
    } else {
        return null;
    }
}

export function validateControl(value, validation = null, confirmPass = null, name = null) {
    if (!validation) {
        return false;
    }

    if (validation.required) {
        if (!value || value === '') {
            return 'required';
        }
    }
    if (value && !!validation) {
        value = value.toString().trim();

        if (validation.minLength > 0) {
            if (+value.length < validation.minLength) {
                return 'minLength';
            }
        }
        if (validation.phone) {
            const phone = value.replace(/[\s-_+()]/g, '');
            const re = /^\d{6,11}$/;
            if (!re.test(phone)) {
                return 'phone';
            }
        }
        if (validation.email) {
            if (!is.email(value)) {
                return 'email';
            }
        }
        if (validation.number) {
            if (!is.number(+value) || +value === 0) {
                return 'number';
            }
        }
        if (validation.confirmPass) {
            if (value !== confirmPass) {
                return 'confirmPass';
            }
        }
        if (validation.max > 0) {
            if (+value > validation.max) {
                return 'max';
            }
        }
        if (typeof validation.min !== 'undefined') {
            if (+value < validation.min) {
                return 'min';
            }
        }
        if (validation.creditCard) {
            // const cardNum = value.replace(/[\s_]/g, '')
            if (!is.creditCard(value)) {
                return 'creditCard';
            } else {
                return detectCardType(value);
            }
        }

        if (validation.date) {
            if (!moment(value, "DD.MM.YYYY").isValid()) {
                return 'date';
            }
        }
        if (validation.minDate > 0) {

            if (moment(value, "DD.MM.YYYY").format("DD.MM.YYYY") < moment().add(validation.minDate, 'days')
                .format("DD.MM.YYYY")) {
                return 'minDate';
            }
        }
        if (validation.birthDay) {
            if (moment(value, "DD.MM.YYYY").format("DD.MM.YYYY") > moment().subtract(18, 'years')
                .format("DD.MM.YYYY")) {
                return 'birthDay';
            }
        }
        if (validation.birthDayMin) {
            if (moment(value, "DD.MM.YYYY").isAfter(moment().subtract(18, 'years'))) {
                return 'birthDayMin';
            }
        }
        if (validation.birthDayMax) {
            if (moment(value, "DD.MM.YYYY").isBefore(moment().subtract(100, 'years'))) {
                return 'birthDayMax';
            }
        }
        if (validation.expirationDateCard) {

            if (moment(value, "MM / YYYY") > moment().add(3, 'years')) {
                return 'expirationDateCard';
            }
        }
    }

    return false;

}

export function detectCardType(number) {
    var re = {
        electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
        maestro: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
        dankort: /^(5019)\d+$/,
        interpayment: /^(636)\d+$/,
        unionpay: /^(62|88)\d+$/,
        visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
        mastercard: /^5[1-5][0-9]{14}$/,
        amex: /^3[47][0-9]{13}$/,
        diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
        discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
        jcb: /^(?:2131|1800|35\d{3})\d{11}$/
    };

    for (var key in re) {
        if (re[key].test(number)) {
            return key;
        }
    }
}