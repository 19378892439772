import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useChartsContext } from '../Сharts/ChartsContext';
import * as moment from 'moment';
import { setConfirmOrderProperty } from '../../../../../store/actions/confirmOrderActions';

const PaysPeriod = () => {
    const dispatch = useDispatch();
    const { formControls: { period, percents } } = useSelector(state => state.confirmOrder);
    const { formControls: { startDate, dueDate } } = useSelector(state => state.estimateOrder);
    const { total, counts } = useChartsContext();
    const [tranches, setTranches] = useState(1);
    const [trancheIndexes, setTrancheIndexes] = useState([]);
    const [prepaid, setPrepaid] = useState(0);
    const [trancheData, setTrancheData] = useState([]);
    const totalSum = total.reduce((acc, i) => acc + i, 0);

    const getPrepaid = useCallback((sum) => {
        return sum * percents.value / 100;
    }, [percents.value]);

    useEffect(() => {
        setPrepaid(getPrepaid(totalSum));
    }, [totalSum]);

    useEffect(() => {
        let tranches = Math.floor(dueDate.value / period.value);
        setTranches(+tranches || 1);
    }, [period.value, dueDate]);

    useEffect(() => {
        // периоды между траншами
        let periods = Math.ceil(dueDate.value / tranches);
        let trancheIndex = 0;
        // индекс транша
        let trancheIndexes = [trancheIndex];
        while (trancheIndex < dueDate.value - 1) {
            trancheIndex += periods;
            if (trancheIndexes.length === +tranches) {
                break;
            } else {
                trancheIndexes.push(trancheIndex);
            }
        }
        setTrancheIndexes(trancheIndexes);
    }, [tranches, dueDate.value]);

    useEffect(() => {
        const trancheData = [];
        let trancheIdx = 1;

        Object.values(counts).forEach((count, i) => {
            let quantityDaySum = 0;
            let costDaySum = 0;
            if (+tranches === 1) {
                costDaySum = totalSum - prepaid;
            } else {
                if (trancheIndexes.indexOf(i) !== -1) {
                    // индекс текущего транша
                    const currentIndex = trancheIndexes.indexOf(i);
                    // значение следующего транша
                    const nextTrancheValue = trancheIndexes[currentIndex + 1];

                    if (nextTrancheValue) {
                        for (let j = i; j < nextTrancheValue; j++) {
                            const dayCount = Object.values(counts)[j];
                            if (dayCount) {
                                  costDaySum += Object.values(counts)[j].workPrice;
                            }
                        }
                    } else {
                        for (let j = i; j < dueDate.value; j++) {
                            const dayCount = Object.values(counts)[j];
                            if (dayCount) {
                                costDaySum += Object.values(counts)[j].workPrice;
                            }
                        }
                    }
                } else {
                    costDaySum += count.workPrice;
                }
            }

            let day = moment(startDate.value, "DD.MM.YYYY").add(i, 'd');
            let isTrancheInfo = trancheIndexes.indexOf(i) !== -1;
            const dayPrepaid = getPrepaid(costDaySum);
            if (isTrancheInfo) {
                ++trancheIdx;
            }
            trancheData.push({
                isTrancheInfo,
                weekDay: day.format("dddd"),
                dayFormat: day.format("DD.MM.YYYY"),
                costDay: count.workPrice,
                quantityDay: count.value,
                costDaySum,
                quantityDaySum,
                dayPrepaid,
                value: +(costDaySum - dayPrepaid).toFixed(2),
                trancheIdx: isTrancheInfo ? trancheIdx : null,
            });
        });
        setTrancheData(trancheData);
        const trancheDataComplex = trancheData.filter((data) => data.isTrancheInfo).map(data => ({
            day: data.dayFormat,
            value: data.value,
        }));
        dispatch(setConfirmOrderProperty( [{ day: startDate.value, value: prepaid }, ...trancheDataComplex], 'trancheDataComplex'));
    }, [dispatch, counts, getPrepaid, prepaid, dueDate, startDate, totalSum, trancheIndexes, tranches]);


    const Tranches = () => {

        const table = [];
        // индекс для визуальной части
        let trancheIdx = 1;

        table.push(
            <div key={-1} className={`ConfirmOrderTranches_RightBlock__item active`}>
                <div
                    className="ConfirmOrderTranches_RightBlock__item_head-label bg-tranche-1 d-flex align-items-center justify-content-center">
                    АВАНС
                </div>
                <div
                    className={`ConfirmOrderTranches_RightBlock__item_head px-3`}>
                 <span className="d-flex align-items-center justify-content-between py-4">
                        <span>%</span>
                    <span>руб.</span>
                 </span>
                </div>
                <div
                    className={`px-3 bg-tranche-1 border-tranche-1 border-bottom d-flex align-items-center justify-content-center h50`} />
                <div
                    className={`px-3 bg-tranche-1 border-tranche-2 border-bottom border-main-1-3 d-flex align-items-center justify-content-center h50`} />
                <div
                    className={`px-3 bg-tranche-1 border-tranche-1 border-bottom d-flex align-items-center justify-content-center h50`}>
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <div><strong>{percents.value}</strong></div>
                        <div><strong>{prepaid}</strong></div>
                    </div>
                </div>
            </div>
        );


        // суммы для транша
        Object.values(counts).forEach((count, i) => {
            let quantityDaySum = 0;
            let costDaySum = 0;
            if (+tranches === 1) {
                //quantityDaySum = Object.values(counts).reduce((acc, count) => acc + count.value, 0);
                costDaySum = totalSum - prepaid;
            } else {
                if (trancheIndexes.indexOf(i) !== -1) {
                    // индекс текущего транша
                    const currentIndex = trancheIndexes.indexOf(i);
                    // значение следующего транша
                    const nextTrancheValue = trancheIndexes[currentIndex + 1];

                    if (nextTrancheValue) {
                        // console.log(nextTrancheValue);
                        for (let j = i; j < nextTrancheValue; j++) {
                            const dayCount = Object.values(counts)[j];
                            if (dayCount) {
                                // quantityDaySum += Object.values(counts)[j].value;
                                costDaySum += Object.values(counts)[j].workPrice;
                            }
                        }
                    } else {
                        for (let j = i; j < dueDate.value; j++) {
                            const dayCount = Object.values(counts)[j];
                            if (dayCount) {
                                // quantityDaySum += Object.values(counts)[j].value;
                                costDaySum += Object.values(counts)[j].workPrice;
                            }
                        }
                    }
                } else {
                    // quantityDaySum += count.value;
                    costDaySum += count.workPrice;
                }
            }

            let day = moment(startDate.value, "DD.MM.YYYY").add(i, 'd');
            let isTrancheInfo = trancheIndexes.indexOf(i) !== -1;
            const dayPrepaid = getPrepaid(costDaySum);
            table.push(

            );

            if (isTrancheInfo) {
                ++trancheIdx;
            }
        });

        return table;
    };

    return (
        <>
            <div key={-1} className={`ConfirmOrderTranches_RightBlock__item active`}>
                <div
                    className="ConfirmOrderTranches_RightBlock__item_head-label bg-tranche-1 d-flex align-items-center justify-content-center">
                    АВАНС
                </div>
                <div
                    className={`ConfirmOrderTranches_RightBlock__item_head px-3`}>
                 <span className="d-flex align-items-center justify-content-between py-4">
                        <span>%</span>
                    <span>руб.</span>
                 </span>
                </div>
                <div
                    className={`px-3 bg-tranche-1 border-tranche-1 border-bottom d-flex align-items-center justify-content-center h50`} />
                <div
                    className={`px-3 bg-tranche-1 border-tranche-2 border-bottom border-main-1-3 d-flex align-items-center justify-content-center h50`} />
                <div
                    className={`px-3 bg-tranche-1 border-tranche-1 border-bottom d-flex align-items-center justify-content-center h50`}>
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <div><strong>{percents.value}</strong></div>
                        <div><strong>{prepaid}</strong></div>
                    </div>
                </div>
            </div>

            {
             trancheData.map((
                 {
                     isTrancheInfo,
                     dayFormat,
                     weekDay,
                     quantityDay,
                     costDay,
                     costDaySum,
                     quantityDaySum,
                     trancheIdx,
                     dayPrepaid
                 }, i
             )=> (
                 <React.Fragment key={i}>
                     <div className={`ConfirmOrderTranches_RightBlock__item ${isTrancheInfo ? 'active' : ''}`}>
                         {/*шапка*/}
                         <div
                             className={`ConfirmOrderTranches_RightBlock__item_head`}>
                             <div>
                                 <strong>{dayFormat}</strong>
                                 <span>{weekDay}</span>
                                 <span>{i + 1} день</span>
                             </div>
                         </div>
                         <div
                             className={`px-3 ${isTrancheInfo ? 'bg-tranche-1 border-tranche-1' : 'bg-white border-main-1-3'} border-bottom d-flex align-items-center justify-content-center h50`}>
                             {isTrancheInfo
                                 ?
                                 <strong>{parseFloat(costDaySum).toFixed(2)}</strong>
                                 : null}
                         </div>
                         <div
                             className={`px-3 ${isTrancheInfo ? 'bg-tranche-1 border-tranche-2' : 'bg-white border-main-2-1'} border-bottom border-main-1-3 d-flex align-items-center justify-content-center h50`}>
                             {isTrancheInfo
                                 ?
                                 <strong>{parseFloat(dayPrepaid).toFixed(2)}</strong>
                                 : null}
                         </div>
                         <div
                             className={`px-3 ${isTrancheInfo ? 'bg-tranche-1 border-tranche-1' : 'bg-white border-main-1-3'} border-bottom d-flex align-items-center justify-content-center h50`}>
                             {isTrancheInfo
                                 ?
                                 <strong>{parseFloat(costDaySum - dayPrepaid).toFixed(2)}</strong>
                                 : null}
                         </div>
                         {/*<div*/}
                         {/*    className={`px-3  ${isTrancheInfo ? 'bg-tranche-1 border-tranche-1' : 'bg-white border-main-1-3'} border-bottom d-flex align-items-center justify-content-center h50`}>*/}
                         {/*    {isTrancheInfo*/}
                         {/*        ? <strong>{parseFloat(costDaySum).toFixed(2)}</strong>*/}
                         {/*        : null*/}
                         {/*    }*/}
                         {/*</div>*/}


                         {isTrancheInfo
                             ? <div
                                 className="ConfirmOrderTranches_RightBlock__item_head-label bg-tranche-1 d-flex align-items-center justify-content-center">Оплата&nbsp;
                                 {trancheIdx}
                             </div>
                             : null}
                     </div>

                 </React.Fragment>
             ))
            }
        </>
    );
};

export default PaysPeriod;
