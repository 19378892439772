import React from "react";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ServiceItem from "./ServiceItem";

export default function SubCategoryitem({ subCategory, ...props }) {
    return (
        <Accordion
            key={subCategory.id}
            TransitionProps={{ unmountOnExit: true }}
            className="estimate__sub-categories-accordion"
        >
            <AccordionSummary
                onClick={() => props.subCategoryClickHandler(subCategory.id)}
                expandIcon={
                    <ExpandMoreIcon className="estimate__sub-categories-icon" />
                }
            >
                <div
                    className={`estimate__sub-categories-name ${
                        props.selectedSubCategories.includes(subCategory.id) ? "active" : ""
                    }`}
                >
                    {subCategory.name}
                </div>
            </AccordionSummary>
            <AccordionDetails className="d-flex flex-column">
                {props.preOrder.services
                    .filter((s) => s.category.subCategory.id === subCategory.id)
                    .map((s) => (
                        <ServiceItem
                            key={s.id}
                            service={s}
                            serviceCheckhandler={props.serviceCheckhandler}
                            serviceCountHandler={props.serviceCountHandler}
                            countInputBlurHandler={props.countInputBlurHandler}
                        />
                    ))}
            </AccordionDetails>
        </Accordion>
    );
}
