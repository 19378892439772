import React, {useEffect, useState} from 'react';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
// import {a11yProps} from '../../functions';
import {useDispatch, useSelector} from 'react-redux';
import {getAdminOrderListState} from '../../selectors';
import AdminOrderFinancePaysCompany from '../AdminOrderFinancePaysCompany';
import AdminOrderFinancePaysMasters from '../AdminOrderFinancePaysMasters';
import {fetchAdminOrderPays} from '../../../../../../store/actions/adminOrderListActions';
import {Typography} from '@material-ui/core';

const AdminOrderListFinanceThree = ({order, reports}) => {
		const {employersTabsFinance, pays} = useSelector(getAdminOrderListState);
		const [currentTab, setCurrentTab] = useState(1);
		const dispatch = useDispatch();
		// const setCurrentTabHandler = (event, newValue) => {
		// 	setCurrentTab(newValue)
		// };
		useEffect(() => {
			dispatch(fetchAdminOrderPays(order.id))
		}, [order])

		return (
			<div className="row mx-0 px-0 pt-2 d-flex flex-column">
				{/*<Tabs*/}
				{/*	value={currentTab}*/}
				{/*	variant="fullWidth"*/}
				{/*	indicatorColor="secondary"*/}
				{/*	onChange={setCurrentTabHandler}*/}
				{/*	scrollButtons={'on'}*/}
				{/*	classes={{*/}
				{/*		root: "AdminOrderList__finance-tabs border-bottom"*/}
				{/*	}}*/}
				{/*>*/}
				{/*	{*/}
				{/*		employersTabsFinance.map((tab) => <Tab key={tab.id} value={tab.id}*/}
				{/*											   disabled={tab.disabled}*/}
				{/*											   label={tab.name} {...a11yProps(tab.id)} />)*/}
				{/*	}*/}
				{/*</Tabs>*/}
				<Typography variant="button" className="text-center pt-3 pb-1 border-bottom">Бригадиры</Typography>
				{
					currentTab === 1 &&
					<AdminOrderFinancePaysMasters order={order} pays={pays} reports={reports}/>
				}
				{
					currentTab === 2 &&
					<AdminOrderFinancePaysCompany order={order}/>
				}
			</div>
		);
	}
;

export default AdminOrderListFinanceThree;