import {
    ADD_ADMIN_PROFESSIONS_LIST_PROFESSION,
    DELETE_ADMIN_PROFESSIONS_LIST_PROFESSION,
    FETCH_ADMIN_PROFESSIONS_LIST_CATEGORIES_SUCCESS,
    FETCH_ADMIN_PROFESSIONS_LIST_ERROR,
    FETCH_ADMIN_PROFESSIONS_LIST_START,
    FETCH_ADMIN_PROFESSIONS_LIST_SUCCESS,

    SAVE_ADMIN_CHANGES_PROFESSION_ERROR,
    SAVE_ADMIN_CHANGES_PROFESSION_NOTHING,
    SAVE_ADMIN_CHANGES_PROFESSION_START,
    SAVE_ADMIN_CHANGES_PROFESSION_SUCCESS,

    SAVE_ADMIN_PROFESSIONS_LIST_PROFESSION, SET_ADMIN_PROFESSIONS_LIST_PROPERTY,
} from "../actions/actionTypes";


const initialState = {
    professions: [],
    categories: [],
    saveResult: {
        title: null,
        description: null,
        list: []
    },
    saveInProcess: false,
    loading: false,
    error: null
}

const adminProfessionalListReducer = (state = initialState, {type, payload}) => {

    let professions = [...state.professions]
    // let findProfessionId = null
    // if (payload && payload.materialId) {
    //     findProfessionId = professions.findIndex(material => {
    //         return material.id === payload.materialId
    //     })
    // }

    switch (type) {
        case FETCH_ADMIN_PROFESSIONS_LIST_START:
            return {
                ...state,
                loading: true
            }
        case FETCH_ADMIN_PROFESSIONS_LIST_SUCCESS:
            return {
                ...state,
                professions: payload.professions,
                loading: false
            }
        case FETCH_ADMIN_PROFESSIONS_LIST_ERROR:
            return {
                ...state,
                error: payload.error,
            }
        case FETCH_ADMIN_PROFESSIONS_LIST_CATEGORIES_SUCCESS:
            return {
                ...state,
                categories: payload.categories,
                loading: false
            }

        case SAVE_ADMIN_CHANGES_PROFESSION_START:
            return {
                ...state,
                saveInProcess: true,
            }

        case SAVE_ADMIN_CHANGES_PROFESSION_SUCCESS:

            professions = [...professions.map(profession => {
                profession.edited = false
                // profession.isNew = false
                return profession
            })]

            return {
                ...state,
                saveResult: {
                    title: "Отправлено!",
                    description: "Результат сохранения:",
                    list: payload.saveResult
                },
                professions,
                saveInProcess: false
            }

        case SAVE_ADMIN_CHANGES_PROFESSION_NOTHING:
            return {
                ...state,
                saveResult: {title: "!", description: "Нечего сохранять!", list: []},
                saveInProcess: false
            }

        case SAVE_ADMIN_CHANGES_PROFESSION_ERROR:
            return {
                ...state,
                error: payload.error
            }

        case ADD_ADMIN_PROFESSIONS_LIST_PROFESSION:
            professions = [...professions, payload.profession]
            return {
                ...state,
                professions
            }
        case SAVE_ADMIN_PROFESSIONS_LIST_PROFESSION:

            let professionIdx = professions.findIndex(profession => profession.id === payload.profession.id)
            professions = [
                ...professions.slice(0, professionIdx),
                payload.profession,
                ...professions.slice(professionIdx + 1)
            ]
            return {
                ...state,
                professions
            }
        case DELETE_ADMIN_PROFESSIONS_LIST_PROFESSION:
            if (payload.profession.isNew) {
                professions = professions.filter(profession => profession.id !== payload.profession.id)
            } else {
                let professionIdx = professions.findIndex(profession => profession.id === payload.profession.id)
                let profession = professions[professionIdx]
                profession.deleted = true
                profession.edited = true
            }
            return {
                ...state,
                professions
            }
        case SET_ADMIN_PROFESSIONS_LIST_PROPERTY:
            return {
                ...state,
                [payload.property]: payload.value
            }

        default:
            return {
                ...state
            }
    }

}

export default adminProfessionalListReducer;
