import React, { useState } from 'react';
import ServiceConfirmTypeTabs
    from '../../../../../containers/ServiceRoute/components/ServiceConfirm/components/ServiceConfirmTypeTabs/ServiceConfirmTypeTabs';
import { Typography } from '@material-ui/core';
import ServiceConfirmTranches
    from '../../../../../containers/ServiceRoute/components/ServiceConfirm/components/ServiceConfirmTranches/ServiceConfirmTranches';
import PaysDates from './PaysDates';
import { useSelector } from 'react-redux';
import PaysPercents from './PaysPercents';

const tabs = [
    {
        'id': 1,
        'name': 'Траншами',
    },
    {
        'id': 2,
        'name': 'Процентами',
    }
];

const Pays = ({ inputHandler }) => {
    const [tab, setTab] = useState(1);
    return (
        <div className="d-flex flex-column align-items-center justify-content-center">
            <Typography variant="h6">Выберите порядок оплат</Typography>
            <ServiceConfirmTypeTabs
                currentTab={tab}
                setCurrentTab={setTab}
                tabs={tabs}
            />
            {
                tab === 1 && <PaysDates inputHandler={inputHandler} />
            }
            {
                tab === 2 && <PaysPercents inputHandler={inputHandler} />
            }
        </div>
    );
};

export default Pays;
