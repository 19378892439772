import React, {Fragment} from 'react'
import './PersonalOrderItemControls.scss'
import Badge from '@material-ui/core/Badge';
import ListItem from '@material-ui/core/ListItem';

export default class PersonalOrderItemControls extends React.Component {
	dialogHandler = () => {
		if ('dialogs' in this.props.order) {
			this.props.dialogHandler()
		}
	}
	ksHandler = (ks) => {
		if (this.props.order.reports.length) {
			this.props.ksHandler(ks)
		}
	}


	render() {
		const {order, ks, chats, dialog: {profile}} = this.props
		let unread = 0;
		Object.values(chats).forEach(chat => {
			unread += Object.values(chat.messages).filter(msg => msg.unread && profile.user.id !== msg.uid).length
		})
		const isDialogExist = order.hasOwnProperty('dialogs');

		return (<div className="PersonalOrderItemControls">
			<div
				className={`PersonalOrderItemControls-btn violet ${isDialogExist ? '' : 'disabled'}`}
				onClick={this.dialogHandler}>
				Перейти к диалогу с менеджером
				{
					!!unread && isDialogExist && <Badge color="secondary" className="ml-4"
														badgeContent={unread}/>
				}

			</div>

			<Fragment>
				<div className={`PersonalOrderItemControls-btn violet ${!!order.reports.length ? '' : 'disabled'}`}
					 onClick={() => this.ksHandler('ks2')}>
					{ks === "ks2" ? "Свернуть" : " Онлайн-выполнение. Акты КС-2"}
				</div>
				<div className={`PersonalOrderItemControls-btn violet ${!!order.reports.length ? '' : 'disabled'}`}
					 onClick={() => this.ksHandler('ks3')}>
					{ks === "ks3" ? "Свернуть" : " Онлайн-выполнение. Акты КС-3"}
				</div>
			</Fragment>

			<div className="PersonalOrderItemControls-btn violet disabled">
				Состав исполнителей
			</div>
			{/*<div className="PersonalOrderItemControls-btn green">*/}
			{/*    Перейти к оплате*/}
			{/*</div>*/}
			<div className="PersonalOrderItemControls-btn green disabled">
				Завершить заказ
			</div>
		</div>)
	}
}