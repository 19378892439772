import React, {Component, Fragment} from 'react';
import './ActKS3TableColumn.scss'

class ActKS3TableColumn extends Component {

	render() {

		const {services, column, order} = this.props
		let total = 0;
		const withMaterials = !!order.materials.length;
		const serviceCost = (services[0].price * services[0].value).toFixed(2)
		const materialsPrice = (order.totalPrice - order.servicePrice) / order.serviceValue;
		const materialsCost = (materialsPrice * services[0].value).toFixed(2)
		const totalCost = Number(serviceCost) + Number(materialsCost)

		return (
			<Fragment>
				{
					!!services.length
						?
						<>
							{
								services.map((service, idx) => {
									if (column === 1) {
										return (
											<Fragment key={service.id}>
												<div key={service.id}
													 className="ActKSTableColumn border-left border-right border-bottom border-dark name">{idx + 1}</div>
												<div className="total"/>
												{/*{!withMaterials && <div className="total"/>}*/}
											</Fragment>
										)
									}
									if (column === 2) {
										return (
											<Fragment key={service.id}>
												<div key={service.id}
													 className="ActKSTableColumn border-right border-bottom border-dark name justify-content-start">{service.name}</div>
												<div className="total"/>
												{/*{!withMaterials && <div className="total"/>}*/}
											</Fragment>
										)
									}
									if (column === 3) {
										return (
											<Fragment key={service.id}>
												<div key={service.id}
													 className="ActKSTableColumn border-right border-bottom border-dark name justify-content-start"/>
												<div className="total"/>
													 {/*{!withMaterials && <div className="total"/>}*/}
											</Fragment>
										)
									}
									if (column === 4) {
										let totalRow = service.price * service.value
										return (
											<Fragment key={service.id}>
												<div key={service.id}
													 className="ActKSTableColumn border-right border-bottom border-dark name">{totalRow}
												</div>
												<div className="total"/>
												{/*{!withMaterials && <div className="total"/>}*/}
											</Fragment>
										)
									}
									if (column === 5) {
										let totalRow = service.price * service.value
										return (
											<Fragment key={service.id}>
												<div key={service.id}
													 className="ActKSTableColumn border-right border-bottom border-dark name">{totalRow}</div>
												{/*{!withMaterials &&*/}
												<div className="total border-right border-dark">Итого</div>
												{/*}*/}
											</Fragment>
										)
									}
									if (column === 6) {
										let totalRow = service.price * service.value
										total += totalRow

										return (
											<Fragment key={service.id}>
												<div
													className="ActKSTableColumn border-right border-bottom border-dark name">{totalRow}</div>
												{/*{!withMaterials &&*/}
												<div
													className="total border-right border-bottom border-dark">{total}</div>
												{/*}*/}
											</Fragment>
										)
									}
									if (column === 7) {
										return (
											<Fragment key={service.id}>
												<div key={service.id}
													 className="ActKSTableColumn border-right border-bottom border-dark name">{service.price}</div>
												{!withMaterials && <div
													className="total  border-right border-bottom border-dark">Х</div>}
											</Fragment>
										)
									}
									if (column === 8) {
										let totalRow = service.price * service.value

										total += totalRow
										return (
											<Fragment key={service.id}>
												<div key={service.id}
													 className="ActKSTableColumn border-right border-bottom border-dark name">{totalRow.toFixed(2)}</div>
												{!withMaterials && <div
													className="total  border-right border-bottom border-dark">
													<strong>{total.toFixed(2)}</strong></div>}
											</Fragment>
										)
									}
									return null
								})
							}
						</>
						: null
				}
				{/*{*/}
				{/*	!!order.materials.length*/}
				{/*	&& <>*/}
				{/*		{*/}
				{/*			column === 1 &&*/}
				{/*			<>*/}
				{/*				<div*/}
				{/*					className="ActKSTableColumn border-left border-right border-bottom border-dark name">{services.length + 1}</div>*/}
				{/*				<div className="total"/>*/}
				{/*			</>*/}

				{/*		}*/}
				{/*		{*/}
				{/*			column === 2 &&*/}
				{/*			<>*/}
				{/*				<div*/}
				{/*					className="ActKSTableColumn border-right border-bottom border-dark name justify-content-start">Метериалы*/}
				{/*				</div>*/}
				{/*				<div className="total"/>*/}
				{/*			</>*/}
				{/*		}*/}
				{/*		{*/}
				{/*			column === 3 &&*/}
				{/*			<>*/}
				{/*				<div*/}
				{/*					className="ActKSTableColumn border-right border-bottom border-dark name justify-content-start"/>*/}
				{/*				<div className="total"/>*/}
				{/*			</>*/}
				{/*		}*/}
				{/*		{*/}
				{/*			column === 4 &&*/}
				{/*			<>*/}
				{/*				<div*/}
				{/*					className="ActKSTableColumn border-right border-bottom border-dark name">{materialsCost}*/}
				{/*				</div>*/}
				{/*				<div className="total"/>*/}
				{/*			</>*/}
				{/*		}*/}
				{/*		{*/}
				{/*			column === 5 &&*/}
				{/*			<>*/}
				{/*				<div*/}
				{/*					className="ActKSTableColumn border-right border-bottom border-dark name">{materialsCost}</div>*/}
				{/*				<div className="total border-right border-dark">Итого</div>*/}
				{/*			</>*/}
				{/*		}*/}
				{/*		{*/}
				{/*			column === 6 &&*/}
				{/*			<>*/}
				{/*				<div*/}
				{/*					className="ActKSTableColumn border-right border-bottom border-dark name">{materialsCost}</div>*/}
				{/*				<div className="total border-right border-bottom border-dark">{totalCost}</div>*/}

				{/*			</>*/}
				{/*		}*/}
				{/*	</>*/}
				{/*}*/}

			</Fragment>
		);
	}
}

export default ActKS3TableColumn;

