import React, {useContext, useEffect, useState} from 'react';

// Slomux - реализация Flux, в которой, как следует из названия, что-то сломано.
// Нужно починить то, что сломано, и подготовить Slomux к использованию на больших проектах, где крайне важна производительность

// ВНИМАНИЕ! Замена slomux на готовое решение не является решением задачи

const context = React.createContext(null);

const createStore = (reducer, initialState) => {
	let currentState = initialState;
	let prevState = initialState;
	let listeners = [];

	const getState = () => currentState
	const getPrevState = () => prevState
	const dispatch = action => {
		prevState = currentState;
		currentState = reducer(currentState, action);
		listeners.forEach(listener => listener(prevState, currentState))
	}

	const subscribe = listener => listeners.push(listener)
	return {getState, dispatch, subscribe, getPrevState}
}

const useSelector = (selector, fn = null) => {
	// const ctx = React.useContext(React.createContext(null))
	const ctx = React.useContext(context)
	const store = ctx.store;

    store.subscribe(() => {
        return selector(store.getState())
    })

	if (!ctx) {
		return 0
	}

	if (fn && fn(store.getPrevState(), store.getState())) {
		return selector(store.getPrevState())
	}

	return selector(store.getState())
}
const useDispatch = () => {
	// const ctx = React.useContext(React.createContext(null))
	const ctx = React.useContext(context)
	if (!ctx) {
		return () => {
		}
	}
	return ctx.store.dispatch
}

const Provider = ({store, context, children}) => {
	const Context = context || React.createContext(null)
	return <Context.Provider value={{store}}>{children}</Context.Provider>
}

// APP


// actions
const UPDATE_COUNTER = 'UPDATE_COUNTER'
const CHANGE_STEP_SIZE = 'CHANGE_STEP_SIZE'

// action creators
const updateCounter = value => ({
	type: UPDATE_COUNTER,
	payload: value,
})

const changeStepSize = value => ({
	type: CHANGE_STEP_SIZE,
	payload: value,
})


// reducers
const defaultState = {
	counter: 1,
	stepSize: 1,
}

const reducer = (state = defaultState, action) => {
	//console.log(action)
	switch (action.type) {
		case UPDATE_COUNTER:
			return {
				...state,
				counter: state.counter += action.payload
			}
		case CHANGE_STEP_SIZE:
			return {
				...state,
				stepSize: state.stepSize = action.payload
			}
		default:
			return state
	}
}

// ВНИМАНИЕ! Использование собственной реализации useSelector и dispatch обязательно
const store = createStore(reducer, defaultState)

// console.log(store)
// console.log(context)


const Counter = () => {
	const counter = useSelector(state => state.counter)
	const dispatch = useDispatch()

	return (
		<div>
			<button onClick={() => dispatch(updateCounter(-1))}>-</button>
			<span> {counter} </span>
			<button onClick={() => dispatch(updateCounter(1))}>+</button>
		</div>
	)
}

const Step = () => {
	let {store} = useContext(context);
	let stepSize = useSelector(state => state.stepSize, (current, prev) => current === prev)
	const dispatch = useDispatch()
    console.log(store)

	return (
		<div>
			<div>Значение счётчика должно увеличиваться или уменьшаться на заданную величину шага</div>
			<div>Текущая величина шага: {stepSize}</div>
			<input
				type="range"
				min="1"
				max="5"
				value={stepSize}
				onChange={({target}) => dispatch(changeStepSize(target.value))}
			/>
		</div>
	)
}


const Test = () => {
	// const [state, setState] = useState({});
	// useEffect(() => {
	// 	let isMounted = true;
	// 	store.subscribe(() => {
	// 		if (isMounted) {
	// 			setState(store.getState())
	// 		}
	// 	})
	// 	return () => {
	// 		isMounted = false
	// 	}
	// }, [store]);

	return (
		<Provider store={store} context={context}>
			<Step/>
			<Counter/>
		</Provider>
	);
};

export default Test;