import React from 'react';
import ChartsTable from './components/ChartsTable';
import { useSelector } from 'react-redux';
import { eachDayOfInterval, parse } from 'date-fns';
import ChartsTableLeft from './components/ChartsTableLeft';
import ChartsTableHeadTitle from './components/ChartsTableHeadTitle';
import ChartsTableRight from './components/ChartsTableRight';
import ChartsTableHeadDates from './components/ChartsTableHeadDates';
import ChartsTableLeftRow from './components/ChartsTableLeftRow';
import { Typography } from '@material-ui/core';
import ChartsTableRightRow from './components/ChartsTableRightRow';
import styled from 'styled-components';
import { CHART_WORKERS } from './config';

const titleCols = [
    {
        id: 1,
        title: 'Наименование',
        col: 12,
    },
];
const StyledTitle = styled.div`
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.1;
  letter-spacing: 0.00714em;
`;

const ChartsWorkers = () => {
    return (
        <ChartsTable>
            <ChartsTableLeft>
                <ChartsTableHeadTitle titleCols={titleCols} />
                <ChartsTableLeftRow>
                    <Typography variant="subtitle2" className="col-12">
                        <StyledTitle>
                            Итого мастеров
                        </StyledTitle>
                    </Typography>
                </ChartsTableLeftRow>
            </ChartsTableLeft>
            <ChartsTableRight table={CHART_WORKERS}>
                <ChartsTableHeadDates />
                <ChartsTableRightRow />
            </ChartsTableRight>
        </ChartsTable>
    );
};

export default ChartsWorkers;
