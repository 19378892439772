import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getActiveServices } from "../../store/selectors";
import {
  fetchServiceSelectCategories,
  fetchServiceSelectServices,
} from "../../store/actions/serviceSelectActions";
import ServiceRoute from "./ServiceRoute";

const ServiceContainer = (props) => {
  const {
    fetchServiceSelectServices,
    fetchServiceSelectCategories,
    services,
    categories,
    user,
  } = props;

  useEffect(() => {
    if (!categories.length) {
      fetchServiceSelectCategories();
    }
  }, [fetchServiceSelectCategories, categories]);

  useEffect(() => {
    // if (!services.length && !user.id) {
      fetchServiceSelectServices();
    // }
  }, []);

  // useEffect(() => {
    // if (user.id) {
    //   fetchServiceSelectServices();
    // }
  // }, [fetchServiceSelectServices, user]);

  return <ServiceRoute isLoaded={!!services.length && !!categories.length} />;
};

const mapStateToProps = (state) => ({
  services: getActiveServices(state),
  categories: state.serviceSelect.categories,
  user: state.workerPersonal.user,
});

const actions = {
  fetchServiceSelectServices,
  fetchServiceSelectCategories,
};

export default connect(mapStateToProps, actions)(ServiceContainer);
