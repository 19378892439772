import React, {useEffect, useState} from 'react';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import {Box, Collapse, Paper, Table, TableBody, TableRow} from '@material-ui/core';
import * as moment from 'moment';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import IconButton from "@material-ui/core/IconButton";

const AdminOrderListDetailScoreboardTwoRow = ({idx, task, order, currentDay, setShowTasks, showTasks, setWorkerOpen, worker, workerOpen}) => {
	const [count, setCount] = useState(0);
	const [items, setItems] = useState([]);
	const [open, setOpen] = useState(false);
	useEffect(() => {
		if (items) {
			const count = items.map((item) => item.acceptedValue).reduce((acc, val) => acc + val, 0);
			setCount(count)
		}
	}, [items])

	useEffect(() => {
		const items = task.items && task.items.filter(item => {
			const day = moment(item.lastInspectDate, "DD.MM.YYYY hh:mm:ss")
			return moment(currentDay, "DD.MM.YYYY").diff(moment(day), 'days') >= 0;
		}).filter((item) => item.acceptedValue > 0);
		setItems(items);
	}, [task, currentDay])

	useEffect(() => {
		if (!showTasks) {
			setShowTasks(!!count || !!items.length)
			if(!!count && !workerOpen[worker.id]){
				const workerOpenNew = {...workerOpen}
				workerOpenNew[worker.id] = true;
				setWorkerOpen(workerOpenNew)
			}
		}
	}, [count, items, showTasks, setShowTasks, workerOpen])

	return (
		<>
			{
				!!count
					&& (
						<><TableRow onClick={() => setOpen(!open)} className="AdminOrderList__row">
							<TableCell className="AdminOrderList__cell-row"><Typography
								align='center'>{idx + 1}</Typography></TableCell>

							<TableCell className="AdminOrderList__cell-row" align="left"><Typography
								align='left'>{task.id}</Typography></TableCell>

							<TableCell className="AdminOrderList__cell-row" align="left"><Typography
								align='left'>{task.name}</Typography>

							</TableCell>

							<TableCell className="AdminOrderList__cell-row" align="left"><Typography
								align='left'>{order.unit}</Typography></TableCell>

							<TableCell className="AdminOrderList__cell-row" align="left"><Typography
								align='left'>{count}</Typography></TableCell>


							<TableCell className="AdminOrderList__cell-row" align="left"><Typography
								align='left'>{order.unitPrice}</Typography>
							</TableCell>

							<TableCell className="AdminOrderList__cell-row" align="left"><Typography
								align='left'>{(order.unitPrice * count).toFixed(2)}</Typography>
							</TableCell>
							<TableCell className="AdminOrderList__cell-row" align="center">
								<IconButton aria-label="expand row" size="small">
									{open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
								</IconButton>
							</TableCell>
						</TableRow>
							<TableRow className="AdminOrderList__detail-modal-row">
								<TableCell style={{padding: 0}} colSpan={12} className="border-0 w-100">
									<Collapse in={open} timeout="auto" unmountOnExit>
										<Box margin={0} className="AdminOrderList__detail-modal-box">
											<Typography variant="button" gutterBottom component="div" className="p-2 m-0">
												Зоны работ
											</Typography>
											<Table className="w-100">
												<Paper component={'thead'} className="AdminOrderList__hidden">
													<TableRow className="AdminOrderList__table-head">
														<TableCell className="AdminOrderList__cell border-right">
															№ п/п
														</TableCell>
														<TableCell className="AdminOrderList__cell border-right">
															№ ТЗ
														</TableCell>
														<TableCell align="left"
																   className="AdminOrderList__cell large border-right">
															Наименование работ
														</TableCell>
														<TableCell align="left"
																   className="AdminOrderList__cell  border-right">
															Ед. изм.
														</TableCell>
														<TableCell align="left"
																   className="AdminOrderList__cell  border-right">
															Кол-во
														</TableCell>
														<TableCell align="left"
																   className="AdminOrderList__cell  border-right">
															Цена
														</TableCell>
														<TableCell align="left"
																   className="AdminOrderList__cell  border-right">
															Сумма, руб.
														</TableCell>
														<TableCell className="AdminOrderList__cell"/>
													</TableRow>
												</Paper>

												<TableBody>
													{task.items && task.items.map((item, idx) => (
														<TableRow key={item.id} className=""
														>
															<TableCell className="AdminOrderList__cell px-3 py-2">
																<Typography align='center'>{idx + 1}</Typography>
															</TableCell>
															<TableCell/>
															<TableCell className="p-2"
																	   align="left">
																<Typography
																	align='left'>{item.place}</Typography>
															</TableCell>
															<TableCell/>
															<TableCell className="p-2"
																	   align="left">
																<Typography
																	align='left'>{item.acceptedValue}
																</Typography>
															</TableCell>
															<TableCell/><TableCell/><TableCell/>

														</TableRow>
													))}
												</TableBody>
											</Table>
										</Box>
									</Collapse>
								</TableCell>
							</TableRow>
						</>
					)

			}
		</>
	);
};

export default AdminOrderListDetailScoreboardTwoRow;