import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import BackBtn from '../../../../../components/UI/BackBtn/BackBtn';
import { CONFIRM_COMPLEX_ANCHOR, CONFIRM_ORDER } from '../../../../../config';
import ServiceConfirmTypeTabs
    from '../../../../../containers/ServiceRoute/components/ServiceConfirm/components/ServiceConfirmTypeTabs/ServiceConfirmTypeTabs';
import { renderFormControl, validateControl } from '../../../../../form/formFramework';
import NumberFormat from 'react-number-format';
import * as moment from 'moment';
import { validatePhone } from '../../../../../form/validators';
import {
    setConfirmOrderProperty,
    setFormControlsValue,
    validateFormControls
} from '../../../../../store/actions/confirmOrderActions';
import { useDispatch, useSelector } from 'react-redux';
import { complexOrderCreate, estimateResetorder } from '../../../../../store/actions/estimateActions';
import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import Pays from '../Pays/Pays';
import AdminSendLoader from "../../../../../containers/Admin/AdminComponents/AdminUI/AdminSendLoader";
import { setActiveNavAnchor } from '../../../../../store/actions/navActions';

const ComplexConfirm = ({ setPage }) => {
    const dispatch = useDispatch();
    const { push } = useHistory();
    let tabs = useSelector(state => state.confirmOrder.tabsSmall);
    let { orders } = useSelector(state => state.estimateOrder);
    const [open, setOpen] = useState(false);
    const { changedData, formControls: { startDate, endDate }, loading } = useSelector(state => state.estimateOrder);
    const { formControls, userType } = useSelector(state => state.confirmOrder);
    const { contacts } = useSelector(state => state.contacts);

    const closeModalHandler = () => {
        setOpen(false);
    };

    const clearErrors = () => {
        Object.keys(formControls).forEach(key => {
            dispatch(setFormControlsValue(formControls[key].id, 'error', false));
        });
    };

    const confirmOrder = async (evt) => {
        evt.preventDefault();
        let formValid = true;
        const generalFields = ['objectName', 'objectAddress', 'customerName', 'phone', 'email'];
        const loginFields = ['customerName', 'phone', 'email'];

        Object.keys(formControls).forEach((property) => {
            const formControl = formControls[property];
            if ((userType === 1 && !formControl.isCompany) || ((userType === 2 && formControl.isCompany)
                || (userType === 2 && generalFields.includes(formControl.id)))) {

                const error = validateControl(formControl.value, formControl.validation);
                const valid = !error;
                dispatch(validateFormControls({
                    property,
                    error,
                    value: formControl.value
                }));
                formValid = valid && formValid;
            }
        });

        if (formValid) {
            await dispatch(complexOrderCreate());
        }

    };

    const userTypeHandler = (id) => {
        clearErrors();
        dispatch(setConfirmOrderProperty(id, 'userType'));
    };

    const inputHandler = (value, property) => {

        if (property === 'phone') {
            value = validatePhone(value);
        }
        const error = validateControl(value, formControls[property].validation);
        dispatch(validateFormControls({
            property,
            error,
            value
        }));
    };

    useEffect(() => {
        dispatch(setActiveNavAnchor(CONFIRM_COMPLEX_ANCHOR));
    }, []);

    useEffect(() => {
        if (!changedData.length) {
            push('/complex-confirm');
        }
    }, [changedData]);

    useEffect(() => {
        if (!!orders.length) {
            dispatch(estimateResetorder());
            push('/personal');
        }

    }, [orders]);

    const total = changedData.reduce((acc, item) => {
        return acc + item.workPrice * item.count;
    }, 0);
    return (
        <div id={CONFIRM_COMPLEX_ANCHOR}>
            {loading && <AdminSendLoader />}
            <BackBtn onClick={() => setPage(1)} variant="dark" />
            <form
                id={CONFIRM_ORDER}
                onSubmit={confirmOrder}
            >
                {/*{!isUserLogin*/}
                {/*    && (*/}
                <div className="ConfirmOrder">
                    <div className="row">
                        <ServiceConfirmTypeTabs
                            currentTab={userType}
                            setCurrentTab={userTypeHandler}
                            tabs={tabs}
                        />

                        <div className="col-12 col-sm-4 mt-4">
                            {renderFormControl(formControls.customerName, inputHandler, { addLabel: "*" })}
                        </div>
                        <div className="col-12 col-sm-4 mt-4">
                            {renderFormControl(formControls.phone, inputHandler, { addLabel: "*" })}
                        </div>
                        <div className="col-12 col-sm-4 mt-4">
                            {renderFormControl(formControls.email, inputHandler, { addLabel: "*" })}
                        </div>


                    </div>
                </div>
                {/*)*/}

                {/*}*/}
                {
                    userType === 2 &&
                    <div className="ConfirmOrder mt-1">
                        <div className="row">
                            <div className="col-12 col-sm-6 mt-4">
                                {renderFormControl(formControls.companyName, inputHandler, { addLabel: "*" })}
                            </div>
                            <div className="col-12 col-sm-6 mt-4">
                                {renderFormControl(formControls.companyInn, inputHandler)}
                            </div>
                            <div className="col-12 col-sm-6 mt-4">
                                {renderFormControl(formControls.companyKpp, inputHandler)}
                            </div>
                            <div className="col-12 col-sm-6 mt-4">
                                {renderFormControl(formControls.companyPhone, inputHandler, { addLabel: "*" })}
                            </div>
                            <div className="col-12 col-sm-6 mt-4">
                                {renderFormControl(formControls.companyAddress, inputHandler)}
                            </div>
                            <div className="col-12 col-sm-6 mt-4">
                                {renderFormControl(formControls.companyKorBank, inputHandler)}
                            </div>
                            <div className="col-12 col-sm-4 mt-4">
                                {renderFormControl(formControls.companyBank, inputHandler)}
                            </div>
                            <div className="col-12 col-sm-4 mt-4">
                                {renderFormControl(formControls.companyBik, inputHandler)}
                            </div>
                            <div className="col-12 col-sm-4 mt-4">
                                {renderFormControl(formControls.companyRasBank, inputHandler)}
                            </div>
                        </div>
                    </div>
                }
                <div className="ConfirmOrder mt-1">
                    <div className="row">
                        <div className="col-12 col-sm-6 mt-4">
                            {renderFormControl(formControls.objectPlace, inputHandler, { addLabel: "*" })}
                        </div>
                        <div className="col-12 col-sm-6 mt-4">
                            {renderFormControl(formControls.objectAddress, inputHandler, { addLabel: "*" })}
                        </div>
                        <div className="col-12 col-sm-6 mt-4">
                            {renderFormControl(formControls.objectName, inputHandler, { addLabel: "*" })}
                        </div>
                        <div className="col-12 col-sm-6 mt-4 d-flex flex-column">
                            <div className="ConfirmOrder_label">
                                Что сделать
                            </div>
                            <div
                                className="ConfirmOrder_input w-100 fixed border-bottom border-main-3">
                                {/*{currentService.name}*/}
                                Комплекс работ
                            </div>
                        </div>
                        <div className="col-12 mt-4">
                            {renderFormControl(formControls.requirements, inputHandler)}
                        </div>
                        {/*<div className="col-12 mt-4">*/}
                        {/*    {renderFormControl(formControls.tranches, inputHandler)}*/}
                        {/*</div>*/}

                    </div>
                    <div className="ConfirmOrder_total-row row mt-3 mt-sm-5">
                        {/*<div className="col mt-2 mt-sm-0">*/}
                        {/*    <div className="ConfirmOrder_label">*/}
                        {/*        Сумма работ*/}
                        {/*    </div>*/}
                        {/*    <div className="ConfirmOrder_total mt-2">*/}

                        {/*        {*/}
                        {/*            <NumberFormat*/}
                        {/*                // value={totalServicePrice}*/}
                        {/*                value={100000}*/}
                        {/*                displayType={'text'}*/}
                        {/*                thousandSeparator={" "} />*/}
                        {/*        }*/}
                        {/*        &nbsp;руб*/}

                        {/*    </div>*/}
                        {/*</div>*/}

                        {/*{*/}
                        {/*    needMaterials === 2*/}
                        {/*    && (*/}
                        {/*        <>*/}
                        {/*            <div className="col mt-2 mt-sm-0">*/}
                        {/*                <div className="ConfirmOrder_label">*/}
                        {/*                    Сумма материалов*/}
                        {/*                </div>*/}
                        {/*                <div className="ConfirmOrder_total mt-0 mt-sm-2">*/}

                        {/*                    <NumberFormat*/}
                        {/*                        value={materialPrice}*/}
                        {/*                        displayType={'text'}*/}
                        {/*                        thousandSeparator={" "}/>*/}

                        {/*                    &nbsp;руб*/}

                        {/*                </div>*/}
                        {/*            </div>*/}

                        {/*        </>*/}
                        {/*    )*/}

                        {/*}*/}
                        <div className="col mt-2 mt-sm-0">
                            <div className="ConfirmOrder_label">
                                Итоговая сумма
                            </div>
                            <div className="ConfirmOrder_total mt-0 mt-sm-2">
                                <NumberFormat
                                    // value={sum}
                                    value={total}
                                    displayType={'text'}
                                    thousandSeparator={" "} />

                                &nbsp;руб
                            </div>
                        </div>


                        <div className="col mt-2 mt-sm-0">
                            <div className="ConfirmOrder_label">
                                Срок начала работ
                            </div>
                            <div className="ConfirmOrder_total mt-0 mt-sm-2">

                                {startDate && startDate.value && moment(startDate.value, "DD.MM.YYYY")
                                    .format("DD.MM.YYYY")}
                            </div>
                        </div>
                        <div className="col mt-2 mt-sm-0">
                            <div className="ConfirmOrder_label">
                                Срок завершения работ
                            </div>
                            <div className="ConfirmOrder_total mt-0 mt-sm-2">
                                {endDate && endDate.value && moment(endDate.value, "DD.MM.YYYY")
                                    .add((+15 > 0 ? +15 - 1 : 1), 'd')
                                    .format("DD.MM.YYYY")}
                            </div>
                        </div>
                    </div>

                </div>
                {/*<div className="ConfirmOrder container position-relative mt-1">*/}
                {/*    <ServiceConfirmTranches />*/}
                {/*</div>*/}

                <div className="col-12 py-5">
                    <Pays inputHandler={inputHandler} />
                </div>

                <div className="container mt-3 d-flex justify-content-end align-items-center">
                    <button
                        className="ConfirmOrder_btn" type="submit">Подтвердить заказ
                    </button>
                </div>
            </form>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="d-flex justify-content-center align-items-center"
                open={open}
                onClose={closeModalHandler}
                closeAfterTransition
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <div
                    className="d-flex text-center flex-column align-items-center justify-content-center bg-white rounded p-5 m-5">
                    <Typography variant="h5">
                        По вашему заказу <br />позвоните по телефону
                        <br />
                        {contacts.phone.value}
                    </Typography>
                </div>
            </Modal>
        </div>
    );
};

export default ComplexConfirm;
