import * as moment from "moment";
import {
    ESTIMATE_CHANGE_CHECKBOX_SELECT,
    ESTIMATE_SET_FORM_ITEM_VALUE,
    ESTIMATE_SET_VALUE,
    ESTIMATE_ADD_SERVICES_TO_ORDER,
    ESTIMATE_FETCH_CONDITIONS_SUCCESS,
    ESTIMATE_RESET_ORDER,
    CONFIRM_ORDER_SET_FORM_CONTROL_VALUE,
    ESTIMATE_SET_CALCULATE_DATA,
    ESTIMATE_SET_CHANGED_DATA,
    ESTIMATE_REMOVE_SERVICE_TO_ORDER,
    ESTIMATE_REMOVE_CATEGORY_OF_ORDER,
    ESTIMATE_REMOVE_SUB_CATEGORY_OF_ORDER,
    ESTIMATE_CREATE_COMPLEX_START, ESTIMATE_CREATE_COMPLEX_END
} from "../actions/actionTypes";
import { format } from 'date-fns';

const initialState = {
    formControls: {
        name: {
            id: "name",
            value: localStorage.getItem('estimate-name') || "",
            type: "text",
            label: "Имя",
        },
        workType: {
            id: "workType",
            value: localStorage.getItem('estimate-workType') || "standart",
            label: "",
            options: [
                {
                    label: "Работа уровня Стандарт",
                    sublabel: 'Расчет ведется по ценам стандартных материалов и работ',
                    id: "standart",
                },
                {
                    label: "Работа уровня Премиум",
                    sublabel: 'Расчет ведется по ценам премиальных материалов и работ',
                    id: "premium",
                },
            ],
        },
        termOfWork: {
            value: localStorage.getItem('estimate-termOfWork') || "",
            type: "text",
            label: "Срок выполнения",
            id: "termOfWork",
        },
        startDate: {
            id: "startDate",
            type: "date",
            label: "Дата начала работ",
            value: localStorage.getItem('estimate-startDate') || format(new Date(), 'dd.MM.yyyy'),
            valid: true,
            validation: {
                minDate: 0,
            },
            errorMessage: {
                minDate: "Не ранее, чем сегодня",
            },
        },
        endDate: {
            id: "endDate",
            type: "date",
            label: "Дата завершения работ",
            value: localStorage.getItem('estimate-endDate') || format(new Date(), 'dd.MM.yyyy'),
            valid: true,
            validation: {
                minDate: 0,
            },
            errorMessage: {
                minDate: "Не ранее, чем сегодня",
            },
        },
        dueDate: {
            id: 'dueDate',
            label: 'Срок выполнения (дней)',
            type: 'text',
            value: localStorage.getItem('estimate-dueDate') || null,
            valid: true,
            error: '',
            validation: {
                required: true,
                number: true,
            },
            errorMessage: {
                required: 'Обязательное поле',
                number: 'Введите число',
            },
        },
        supplyMaterials: {
            id: "supplyMaterials",
            value: localStorage.getItem('estimate-supplyMaterials') || "performer",
            label: "",
            options: [
                {
                    label: "Материалы поставляются исполнителем",
                    sublabel: 'Расчет ведется без учета поставки материалов',
                    id: "performer",
                },
                {
                    label: "Материалы предоставлены заказчиком",
                    sublabel: 'Расчет ведется с учетом поставки материалов',
                    id: "customer",
                },
            ],
        },
        locationObjectConditions: {
            id: "locationObjectConditions",
            value: localStorage.getItem('estimate-locationObjectConditions') || "15-30km from mkad",
            label: "Расположение объекта",
            conditional: true,
            options: [
                {
                    label: "до МКАД",
                    id: "mkad",
                },
                {
                    label: "за МКАД до 15 км",
                    id: "15km from mkad",
                },
                {
                    label: "за МКАД 15-30 км",
                    id: "15-30km from mkad",
                },
                {
                    label: "за МКАД от 50 км",
                    id: "50+km from mkad",
                },
            ],
        },
        floorConditions: {
            id: "floorConditions",
            value: localStorage.getItem('estimate-floorConditions') || "20+ floors",
            label: "Этажность зоны работ",
            conditional: true,
            options: [
                {
                    label: "до 5 этажей",
                    id: "5 floors",
                },
                {
                    label: "5-20 этажей",
                    id: "5-20 floors",
                },
                {
                    label: "более 20 этажей",
                    id: "20+ floors",
                },
            ],
        },
        instrumentConditions: {
            id: "instrumentConditions",
            value: localStorage.getItem('estimate-instrumentConditions') || "performer",
            label: "Инструменты",
            conditional: true,
            options: [
                {
                    label: "Исполнителя",
                    id: "performer",
                },
                {
                    label: "Заказчика",
                    id: "customer",
                },
            ],
        },
        equipmentConditions: {
            id: "equipmentConditions",
            value: localStorage.getItem('estimate-equipmentConditions') || "performer",
            label: "Оборудование",
            conditional: true,
            options: [
                {
                    label: "Исполнителя",
                    id: "performer",
                },
                {
                    label: "Заказчика",
                    id: "customer",
                },
            ],
        },
        housingConditions: {
            id: "housingConditions",
            value: localStorage.getItem('estimate-housingConditions') || "performer",
            label: "Жилье",
            conditional: true,
            options: [
                {
                    label: "Исполнителя",
                    id: "performer",
                },
                {
                    label: "Заказчика",
                    id: "customer",
                },
            ],
        },
        materialPlaceConditions: {
            id: "materialPlaceConditions",
            value: localStorage.getItem('estimate-materialPlaceConditions') || "on ground",
            label: "Материал",
            conditional: true,
            viewType: 2,
            options: [
                {
                    id: "on ground",
                    label: "На площадке",
                },
                {
                    id: "on floor",
                    label: "На этаже",
                },
            ],
        },
        supplyDocument: {
            id: "supplyDocument",
            value: localStorage.getItem('estimate-supplyDocument') || "performer",
            label: "Исполнительная документация",
            viewType: 2,
            options: [
                {
                    label: "Исполнителя",
                    id: "performer",
                },
                {
                    label: "Заказчика",
                    id: "customer",
                },
            ],
        },
        classifiersConditions: {
            id: "classifiersConditions",
            value: JSON.parse(localStorage.getItem('estimate-classifiersConditions')) || [],
            label: "",
            conditional: true,
            options: [
                {
                    id: "geodesy",
                    label: "Геодезия",
                },
                {
                    id: "executiveDrawings",
                    label: "Исполнительный чертеж",
                },
                {
                    id: "hiddenWorksCertificates",
                    label: "Акты на скрытые работы",
                },
                {
                    id: "materialCertificates",
                    label: "Сертификаты материалов",
                },
            ],
        },
    },
    workKind: localStorage.getItem('estimate-kind-of-work') || null,
    services: JSON.parse(localStorage.getItem('estimate-services')) || [],
    categories: JSON.parse(localStorage.getItem('estimate-categories')) || [],
    subCategories: JSON.parse(localStorage.getItem('estimate-subCategories')) || [],
    startDate: localStorage.getItem('estimate-kind-of-work') || null,
    calculateData: [],
    changedData: [],
    loading: false,
    orders: [],
};

export default function (state = initialState, { type, payload }) {
    const formControls = JSON.parse(JSON.stringify(state.formControls));
    let tempCategories = [...state.categories];
    let tempSubcategories = [...state.subCategories];
    let tempServices = [...state.services];
    switch (type) {
        case ESTIMATE_SET_CALCULATE_DATA:
            return {
                ...state,
                calculateData: payload.data,
            };
        case ESTIMATE_SET_CHANGED_DATA:
            return {
                ...state,
                changedData: payload.data,
            };
        case ESTIMATE_ADD_SERVICES_TO_ORDER:
            //Добавление новых категоий
            if (
                !tempCategories.some(
                    (stateCategory) => stateCategory.id === payload.category.id
                )
            )
                tempCategories.push(payload.category);

            //Добавление уникальных подкатегорий
            payload.subCategories.forEach((payloadSubc) => {
                if (
                    !tempSubcategories.some(
                        (stateSubc) => stateSubc.id === payloadSubc.id
                    )
                )
                    tempSubcategories.push(payloadSubc);
            });

            //Если услуга уже есть в заказе, то только увеличиваем количество, иначе добавляем полностью услугу
            payload.services.forEach((payloadService) => {
                if (
                    state.services.some(
                        (stateService) => stateService.id === payloadService.id
                    )
                ) {
                    tempServices.find(
                        (stateService) => stateService.id === payloadService.id
                    ).count += payloadService.count;
                } else {
                    tempServices.push(payloadService);
                }
            });

            //Добавление к каждому материалу в услуге его необходимое количество
            tempServices = tempServices.map((s) => ({
                ...s,
                materials: s.materials.map((m) => ({
                    ...m,
                    quanity: (m.norms * s.count) || 0,
                })),
                items:
                    s.items.map((i) => ({
                        ...i,
                        materials: i.materials.map((m) => ({
                            ...m,
                            quanity: (m.norms * s.count) || 0,
                        })),
                    })) || [],
            }));

            //Фильтрация категрий
            tempCategories.sort((a, b) => a.sort - b.sort);

            //Фильтрация услуг
            tempServices = tempServices.map((s) => {
                const categorySort = tempCategories.find(
                    (c) => c.id === s.category.id
                ).sort;
                const subCategorySort = tempSubcategories.find(
                    (c) => c.id === s.category.subCategory.id
                ).sort;
                return {
                    ...s,
                    //В поле sort у услуг видимо не учитывается сортировка услуги, поэтому тут я даю ей максимальный приоритет, умножая на 10000000
                    sortNew: s.sort + categorySort * 10000000 + subCategorySort * 1000,
                };
            });
            tempServices.sort((a, b) => a.sortNew - b.sortNew);

            tempServices = tempServices.map((s, index) => ({
                ...s,
                index: index + 1,
            }));

            localStorage.setItem('estimate-categories', JSON.stringify(tempCategories));
            localStorage.setItem('estimate-subCategories', JSON.stringify(tempSubcategories));
            localStorage.setItem('estimate-services', JSON.stringify(tempServices));

            return {
                ...state,
                categories: tempCategories,
                subCategories: tempSubcategories,
                services: tempServices,
            };
        case ESTIMATE_REMOVE_SERVICE_TO_ORDER:
            tempServices = tempServices.filter((service) => service.id !== payload.id)
                .map((s, index) => ({
                    ...s,
                    index: index + 1,
                }));
            localStorage.setItem('estimate-services', JSON.stringify(tempServices));
            return {
                ...state,
                services: tempServices,
            };
        case ESTIMATE_REMOVE_CATEGORY_OF_ORDER:
            tempCategories = tempCategories.filter((category) => category.id !== payload);
            tempServices = tempServices.map((s, index) => ({
                ...s,
                index: index + 1,
            }));
            localStorage.setItem('estimate-categories', JSON.stringify(tempCategories));
            localStorage.setItem('estimate-services', JSON.stringify(tempServices));
            return {
                ...state,
                categories: tempCategories,
                services: tempServices,
            };
        case ESTIMATE_REMOVE_SUB_CATEGORY_OF_ORDER:
            tempSubcategories = tempSubcategories.filter((subCategory) => subCategory.id !== payload);
            tempServices = tempServices.map((s, index) => ({
                ...s,
                index: index + 1,
            }));
            localStorage.setItem('estimate-subCategories', JSON.stringify(tempSubcategories));
            localStorage.setItem('estimate-services', JSON.stringify(tempServices));
            return {
                ...state,
                subCategories: tempSubcategories,
                services: tempServices,
            };

        case ESTIMATE_SET_FORM_ITEM_VALUE:
            formControls[payload.id].value = payload.value;
            localStorage.setItem(`estimate-${payload.id}`, payload.value);
            return { ...state, formControls };
        case ESTIMATE_CHANGE_CHECKBOX_SELECT:
            let chexBoxControlValue = formControls[payload.id].value;
            if (chexBoxControlValue.includes(payload.value)) {
                chexBoxControlValue.splice(
                    chexBoxControlValue.indexOf(payload.value),
                    1
                );
            } else {
                chexBoxControlValue.splice(
                    chexBoxControlValue.length,
                    0,
                    payload.value
                );
            }
            localStorage.setItem(`estimate-${payload.id}`, JSON.stringify(chexBoxControlValue));
            return { ...state, formControls };
        case ESTIMATE_SET_VALUE:
            let temp = { ...state };
            temp[payload.id] = payload.value;
            return { ...temp };
        case ESTIMATE_FETCH_CONDITIONS_SUCCESS:
            Object.entries(payload).forEach(([key, payloadOptions]) => {
                payloadOptions.forEach((option) => {
                    formControls[key].options.find(o => o.label === option.title).percent = option.percent;
                });
            });
            return {
                ...state,
                formControls
            };
        case ESTIMATE_RESET_ORDER:
            return {
                ...state,
                services: [],
                categories: [],
                subCategories: [],
                orders: [],
            };
        case ESTIMATE_CREATE_COMPLEX_START:
            return {
                ...state,
                loading: true,
            }
        case ESTIMATE_CREATE_COMPLEX_END:
            console.log('payload', payload)
            return {
                ...state,
                orders: payload,
                loading: false,
            }
        default:
            return state;
    }
}