import { addDays, differenceInDays, format, getDay, isAfter, max, parse } from 'date-fns';
import { getServicePrice } from '../Estimate/Order/calculateFunctions';

export const getDayInfo = (date) => {
    const day = getDay(date);
    const dayNum = format(new Date(date), 'dd');
    switch (day) {
        case 0:
            return { value: dayNum, weekDay: day, title: 'Вс' };
        case 1:
            return { value: dayNum, weekDay: day, title: 'Пн' };
        case 2:
            return { value: dayNum, weekDay: day, title: 'Вт' };
        case 3:
            return { value: dayNum, weekDay: day, title: 'Ср' };
        case 4:
            return { value: dayNum, weekDay: day, title: 'Чт' };
        case 5:
            return { value: dayNum, weekDay: day, title: 'Пт' };
        case 6:
            return { value: dayNum, weekDay: day, title: 'Сб' };
    }
};

export const getServiceLink = (link) => {
    return +link.split(':')[0];
};

export const getServiceChildren = (data, service) => {
    return data.filter(item => !!item.links && item.links.map(getServiceLink).includes(service.id));
};

export const setServiceDates = (data, service) => {
    let addDaysCount = 0;
    let startDate = service.globalStartDate;
    const { level } = service;
    if (level) {
        const allParents = data.filter(service => service.level === level - 1);
        if (allParents) {
            const parents = allParents.filter(parent => service.links.includes(parent.id));
            if (!!parents.length) {
                const sortedParents = parents.sort((a, b) => b.daysCount - a.daysCount);
                addDaysCount = sortedParents[0].daysCount;
                startDate = sortedParents[0].startDate;
            }
        }
    }


    service.initialStartDate = format(new Date(addDays(new Date(parse(startDate, 'dd.MM.yyyy', new Date())), addDaysCount)), 'dd.MM.yyyy');
    service.startDate = format(new Date(addDays(new Date(parse(startDate, 'dd.MM.yyyy', new Date())), addDaysCount)), 'dd.MM.yyyy');
    service.endDate = format(new Date(addDays(new Date(parse(service.startDate, 'dd.MM.yyyy', new Date())), service.daysCount - 1)), 'dd.MM.yyyy');
    if (differenceInDays(parse(service.globalEndDate, 'dd.MM.yyyy', new Date()), parse(service.endDate, 'dd.MM.yyyy', new Date())) < 0) {
        service.endDate = service.globalEndDate;
        service.daysCount = differenceInDays(parse(service.globalEndDate, 'dd.MM.yyyy', new Date()), parse(service.startDate, 'dd.MM.yyyy', new Date()));
    }
    service.offset = differenceInDays(parse(service.startDate, 'dd.MM.yyyy', new Date()), parse(service.globalStartDate, 'dd.MM.yyyy', new Date()));

    if (!!service.items && !!service.items.length) {
        service.items.forEach(item => {
            item.endDate = format(new Date(addDays(new Date(parse(service.startDate, 'dd.MM.yyyy', new Date())), item.daysCount - 1)), 'dd.MM.yyyy');
        });
    }

    return service;
};

export const setServiceDatesAfterChange = (data, service) => {
    const { level } = service;
    if (level) {
        const allParents = data.filter(service => service.level === level - 1);
        if (allParents) {
            const parents = allParents.filter(parent => service.links.includes(parent.id));
            if (!!parents.length) {
                const sortedParents = parents.sort((a, b) => b.daysCount - a.daysCount);
                let addDaysCount = sortedParents[0].daysCount;
                let startDate = sortedParents[0].startDate;
                service.startDate = format(new Date(addDays(new Date(parse(startDate, 'dd.MM.yyyy', new Date())), addDaysCount)), 'dd.MM.yyyy');
                service.endDate = format(new Date(addDays(new Date(parse(service.startDate, 'dd.MM.yyyy', new Date())), service.daysCount - 1)), 'dd.MM.yyyy');
                if (!!service.items && !!service.items.length) {
                    service.items.forEach(item => {
                        item.endDate = format(new Date(addDays(new Date(parse(service.startDate, 'dd.MM.yyyy', new Date())), item.daysCount - 1)), 'dd.MM.yyyy');
                    });
                }
                if (differenceInDays(parse(service.globalEndDate, 'dd.MM.yyyy', new Date()), parse(service.endDate, 'dd.MM.yyyy', new Date())) < 0) {
                    service.endDate = service.globalEndDate;
                    service.daysCount = differenceInDays(parse(service.globalEndDate, 'dd.MM.yyyy', new Date()), parse(service.startDate, 'dd.MM.yyyy', new Date()));
                }
                service.offset = differenceInDays(parse(service.startDate, 'dd.MM.yyyy', new Date()), parse(service.globalStartDate, 'dd.MM.yyyy', new Date()));
            }
        }
    }


    // service.initialStartDate = format(new Date(addDays(new Date(parse(startDate, 'dd.MM.yyyy', new Date())), addDaysCount)), 'dd.MM.yyyy');
    // service.startDate = format(new Date(addDays(new Date(parse(startDate, 'dd.MM.yyyy', new Date())), addDaysCount)), 'dd.MM.yyyy');
    //  service.endDate = format(new Date(addDays(new Date(parse(service.startDate, 'dd.MM.yyyy', new Date())), service.daysCount - 1)), 'dd.MM.yyyy');
    // if (differenceInDays(parse(service.globalEndDate, 'dd.MM.yyyy', new Date()), parse(service.endDate, 'dd.MM.yyyy', new Date())) < 0) {
    //     service.endDate = service.globalEndDate;
    //     service.daysCount = differenceInDays(parse(service.globalEndDate, 'dd.MM.yyyy', new Date()), parse(service.startDate, 'dd.MM.yyyy', new Date()));
    // }
    // service.offset = differenceInDays(parse(service.startDate, 'dd.MM.yyyy', new Date()), parse(service.globalStartDate, 'dd.MM.yyyy', new Date()));

    // if (!!service.items && !!service.items.length) {
    //     service.items.forEach(item => {
    //         item.endDate = format(new Date(addDays(new Date(parse(service.startDate, 'dd.MM.yyyy', new Date())), item.daysCount - 1)), 'dd.MM.yyyy');
    //     });
    // }

    return service;
};

export const getReserveDays = (data, service, dates) => {
    const { children, globalStartDate, initialStartDate, initialDaysCount, endDate } = service;
    if (!!children.length) {
        const parents = children.map(child => child.links.map(getServiceLink).filter((link) => !!link));
        const dates = [];
        parents.forEach((group) => {
            group.forEach((parent) => {
                const service = data.find(item => parent === item.id);
                if (service) {
                    dates.push(parse(service.endDate, 'dd.MM.yyyy', new Date()));
                }
            });
        });
        return differenceInDays(max(dates), parse(endDate, 'dd.MM.yyyy', new Date()));
    } else {
        return dates.length -
            initialDaysCount -
            differenceInDays(parse(initialStartDate, 'dd.MM.yyyy', new Date()), parse(globalStartDate, 'dd.MM.yyyy', new Date()));
    }
};