import React from 'react';

const AdminOrderListFinancePayRows = ({pays}) => {

	return (
		<div className="w-100">
			<div className="d-flex align-items-center justify-content-between">
				<div className="col-6">Аванс</div>
				<div className="col-3">Дата</div>
				<div className="col-3">Сумма</div>
			</div>
			{
				pays.map((pay) => {
					return (
						<div key={pay.id} className="d-flex align-items-center justify-content-between mt-2">
							<div className="col-6 px-2">
								<div className="p-2 pl-3 bg-light">{pay.psName}</div>
							</div>
							<div className="col-3 px-2">
								<div className="p-2 pl-3 bg-light">От {pay.date || pay.type}</div>
							</div>
							<div className="col-3 px-2">
								<div className="p-2 pl-3 bg-light">{pay.sum}</div>
							</div>
						</div>
					)
				})
			}
		</div>
	);
};

export default AdminOrderListFinancePayRows;