import React from 'react';
import styled, { css } from 'styled-components';
import { getDayInfo } from '../functions';
import { DATE_WIDTH } from '../config';

const StyledHeadDatesCol = styled.div`
  display: flex;
  align-items: center;
  width: ${DATE_WIDTH};
  min-width: ${DATE_WIDTH};
  flex-direction: column;

  &:nth-child(even) {
    > div:last-child {
      border-bottom: 1px solid #fff;
    }
  }

  ${({ disabled }) => disabled && css`
    * {
      color: #B4B4B4 !important;
    }
  `};

  div {
    text-align: center;
    width: 100%;
    background: #7B53DA;
    height: 25px;
    border-right: 1px solid #7251C3;
    font-size: 12px;
    font-weight: 500;
    line-height: 175%;
    user-select: none;
  }

  > div:first-child {
    background: #7251C3;
  }
`;


const ChartsTableHeadDatesCol = ({ date }) => {
    const day = getDayInfo(date);
    return (
        <StyledHeadDatesCol disabled={day.weekDay === 0}>
            <div>{day.title}</div>
            <div>{day.value}</div>
        </StyledHeadDatesCol>
    );
};

export default ChartsTableHeadDatesCol;
