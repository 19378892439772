import React, {createContext, useContext, useState} from "react";

export const AdminContext = createContext();

export const useAdmin = () => {
    return useContext(AdminContext)
};

const AdminContextProvider = (props) => {
    const {children} = props;
    const [compact, setCompact] = useState(false);

    const compactHandler = () => {
        setCompact(prev => !prev);
    };

    return (
        <AdminContext.Provider value={{
            compact, compactHandler,

        }}>
            {children}
        </AdminContext.Provider>
    )

};
export default AdminContextProvider;