import React, { Fragment } from 'react';

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import TextField from "@material-ui/core/TextField";

import { connect } from "react-redux";
import { setServiceLinks, setServiceProperty } from "../../../store/actions/adminServiceActions";
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';


class AdminCommunication extends React.Component {

    inputHandler = (evt, property, itemId = null) => {
        this.props.setServiceProperty(this.props.serviceId, +evt.target.value, property, itemId);
    };

    linksHandler = (evt, nextValue, service, idx, currentPosition = 0) => {
        const { value } = evt.target;
        // const nextValue = service.links[idx].split(':')[currentPosition] || '';
        const formattedValue = currentPosition === 0
            ? `${value}:${nextValue}`
            : `${nextValue}:${value}`;
        const links = [
            ...service.links.slice(0, idx),
            formattedValue,
            ...service.links.slice(idx + 1)
        ];
        this.props.setServiceLinks(service.id, links);
    };

    linksDeleteHandler = (service, idx) => {
        const links = [
            ...service.links.slice(0, idx),
            ...service.links.slice(idx + 1)
        ];
        this.props.setServiceLinks(service.id, links);
    };
    linksAddHandler = (service) => {
        const links = [
            ...service.links,
            '',
        ];
        this.props.setServiceLinks(service.id, links);
    };

    render() {
        // const properties = ['links', 'earlyStart', 'lateStart', 'earlyFinish', 'lateFinish']
        const { services } = this.props;
        const service = services.find(service => service.id === this.props.serviceId);
        const height = 60;


        return (

            <Fragment>
                {
                    !!service &&
                    <List className="px-0">
                        {
                            !!service.links.length && service.links.map((link, idx) => {
                                const value = link.split(':');
                                return (
                                    <ListItem key={idx} className="border-top border-bottom border-gray justify-content-end"
                                              style={{ height }}>

                                        <TextField
                                            variant="outlined"
                                            className="Admin_cell col-2"
                                            type="number"
                                            onChange={(evt) => this.linksHandler(evt, value[1] || '', service, idx)}
                                            value={value[0] || ''}
                                        />

                                        <TextField
                                            variant="outlined"
                                            className="Admin_cell col-2"
                                            type="number"
                                            onChange={(evt) => this.linksHandler(evt, value[0] || '', service, idx, 1)}
                                            value={value[1] || ''}
                                        />

                                        <Button
                                            className="Admin_btn ml-2"
                                            variant="contained" color="secondary"
                                            onClick={() => this.linksDeleteHandler(service, idx)}
                                        >
                                            <DeleteIcon
                                                className="Admin_btn-icon Admin_btn-icon-create" />
                                        </Button>

                                    </ListItem>
                                )
                            })
                        }

                        <ListItem
                            className="w-100 d-flex align-items-center justify-content-end align-self-center border-top border-bottom border-gray"
                            style={{ height: 50 }}>
                            <Button
                                className="Admin_btn align-self-center"
                                variant="contained" color="primary"
                                onClick={() => this.linksAddHandler(service)}
                            >
                                <AddCircleOutlineIcon
                                    className="Admin_btn-icon Admin_btn-icon-create" />
                            </Button>
                        </ListItem>

                    </List>
                }
            </Fragment>

        );
    }

}

const mapState = (state) => {
    return {
        services: state.adminService.services
    };
};
const actions = {
    setServiceProperty,
    setServiceLinks,
};

export default connect(mapState, actions)(AdminCommunication);