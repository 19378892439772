import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Paper} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import {renderFormControl, validateControl} from '../../../../form/formFramework';
import Button from '@material-ui/core/Button';
import {useDropzone} from 'react-dropzone';
import cuid from 'cuid';
import {
	deleteWorkerPersonalLocalPhoto,
	setWorkerPersonalReportPhotos,
	setWorkerPersonalReportQuantity, workerPersonalMakeReport
} from '../../../../store/actions/workerPersonalActions';
import {getWorkerPersonalPhotos, getWorkerPersonalLocalReports} from '../../../../store/selectors';
import {ROLE_MASTER} from '../../../../config';

const WorkerPersonalReportsModal = ({orderId, services, dayReport, onClose, setDayReport, user, report}) => {
	const [error, setError] = useState(null)
	const dispatch = useDispatch();
	const {quantity} = useSelector(state => state.workerPersonal.reportFormControl)
	const reports = useSelector(getWorkerPersonalLocalReports)
	const photos = useSelector(getWorkerPersonalPhotos)

	const onDrop = useCallback(acceptedFiles => {
		acceptedFiles.forEach((file) => {
			const id = cuid()
			const reader = new FileReader();
			reader.onload = () => {
				if (reader.result) {
					let value = {
						id,
						name: file.name,
						src: reader.result,
						encoding: "base64"
					};

					dispatch(setWorkerPersonalReportPhotos(dayReport, value))

					console.log(value);
					// if (good) {
					// 	dispatch(setAdminGoodsGoodProperty(good.id, true, 'edit'));
					// 	dispatch(setAdminGoodsGoodProperty(good.id, [...good.pictures, value], 'pictures'));
					// 	dispatch(setAdminGoodsGoodProperty(good.id, true, 'edited'));
					// }
				}
			};
			reader.readAsDataURL(file)
		})

	}, [dispatch]);

	const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

	const formControlHandler = (value, property) => {
		value = value.replace(/[,\s]/gi, '.')
		let error = validateControl(value, quantity.validation)
		dispatch(setWorkerPersonalReportQuantity(dayReport, value, error))
	}

	const makeReportHandler = () => {
		const serviceReport = [];
		serviceReport.push({
			id: services[0].id || null,
			value: quantity.value
		})

		let error = validateControl(quantity.value, quantity.validation)
		setError(error)
		if (!error) {
			dispatch(workerPersonalMakeReport(orderId, report ? report.id : null, dayReport, serviceReport, photos[dayReport]))
		}
		setDayReport(null);
	}

	const deletePhotoHandler = (id) => {
		dispatch(deleteWorkerPersonalLocalPhoto(dayReport, id))
	}

	useEffect(() => {
		if(report){
			if(report.services[0].value) {
				dispatch(setWorkerPersonalReportQuantity(dayReport, report.services[0].value))
			}
		}
	}, [report]);

	//console.log(reports);
	return (
		<div className={`AdminOrderList__modal js-close`}
			 onClick={onClose}>
			<Paper className="p-4">
				<form className="d-flex flex-column">
					<Typography variant="h5">
						Заказ №{orderId}
					</Typography>
					<Typography variant="h5">
						{dayReport}
					</Typography>
					<CloseIcon className="AdminOrderList__modal-close js-close"/>
					{renderFormControl(quantity, formControlHandler)}
					{
						user.role === ROLE_MASTER
						&& <>
							<div
								className={`AdminGoodsDetail__dropzone ${isDragActive ? 'active' : '2'}`}
								{...getRootProps()}>
								<input {...getInputProps()} />
								{
									isDragActive ?
										<p>Бросьте файл ...</p> :
										<p>Для загрузки перетащите файлы сюда или кликните по этом полю
											Допустимые форматы: jpg, png. Размеры файла до 5 МБ
										</p>
								}
							</div>
							{
								!!photos[dayReport] &&
								<div className="wp__modal-gallery">
									{
										photos[dayReport].map((photo) => {
											return (
												<div key={photo.id}>
													<img src={photo.src} alt={photo.name}/>
													<CloseIcon
														onClick={() => deletePhotoHandler(photo.id)}
														className="wp__modal-gallery_close js_gallery_close"/>
												</div>
											)
										})
									}
								</div>
							}
						</>
					}
					<Button variant="contained"
							color="primary"
							className="mt-3"
							onClick={makeReportHandler}
					>
						Подтвердить
					</Button>
				</form>
			</Paper>
		</div>
	);
};

export default WorkerPersonalReportsModal;