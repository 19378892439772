import {
	DELETE_WORKER_PERSONAL_LOCAL_PHOTO,
	FETCH_WORKER_CREATE_MASTERS_ERROR,
	FETCH_WORKER_CREATE_MASTERS_START,
	FETCH_WORKER_CREATE_MASTERS_SUCCESS,
	SET_WORKER_PERSONAL_PROPERTY, SET_WORKER_PERSONAL_REPORT_PHOTOS, SET_WORKER_PERSONAL_REPORT_QUANTITY,
	WORKER_PERSONAL_FETCH_DATES,
	WORKER_PERSONAL_FETCH_ERROR,
	WORKER_PERSONAL_FETCH_ORDER_DETAIL,
	WORKER_PERSONAL_FETCH_ORDER_REPORTS,
	WORKER_PERSONAL_FETCH_ORDERS,
	WORKER_PERSONAL_FETCH_START,
	WORKER_PERSONAL_FETCH_SUCCESS,
	WORKER_PERSONAL_LOGIN_ERROR,
	WORKER_PERSONAL_LOGIN_START,
	WORKER_PERSONAL_LOGIN_SUCCESS,
	WORKER_PERSONAL_LOGOUT,
	WORKER_PERSONAL_REG_ERROR,
	WORKER_PERSONAL_REG_START,

} from "./actionTypes";
import axios from "../../rest/axios";

import qs from "qs";
import * as moment from 'moment';


///----------------------------------------------
export function workerPersonalFetch(token = false) {
	return async dispatch => {
		try {
			if (!token) {
				token = localStorage.getItem('token');
			}
			if (token) {
				dispatch(workerPersonalFetchStart())
				const about = await axios.post('/worker/about/', qs.stringify({
					token
				}));
				dispatch(workerPersonalFetchSuccess(about.data.data))
			}

		} catch (e) {
			dispatch(workerPersonalFetchError(e))
		}
	}
}

function workerPersonalFetchStart() {
	return {
		type: WORKER_PERSONAL_FETCH_START
	}
}

function workerPersonalFetchSuccess(about) {
	return {
		type: WORKER_PERSONAL_FETCH_SUCCESS,
		payload: {
			about
		}
	}
}

function workerPersonalFetchError(error) {
	return {
		type: WORKER_PERSONAL_FETCH_ERROR,
		payload: {
			error
		}
	}
}

///-----------------------------------------------

export function workerPersonalLogin(email = null, password = null, role = 9) {
	return async dispatch => {
		try {

			let response = {}
			if (email && password) {
				dispatch(workerPersonalLoginStart())
				response = await axios.post('/worker/login/', qs.stringify({email, password, role}));
			} else {
				let token = localStorage.getItem('token')
				if (token) {
					dispatch(workerPersonalLoginStart())
					response = await axios.post('/worker/login/', qs.stringify({token}));
				}
			}
			if (response.data) {
				dispatch(workerPersonalLoginError(null));
				const {data: {token, id}, errorMessage} = response.data
				if (token) {
					// await dispatch(workerPersonalFetch(token))
					dispatch(workerPersonalLoginSuccess(response.data.data))
					localStorage.setItem('token', token)
					localStorage.setItem('userId', id)
					if (email) {
						localStorage.setItem('email', email)
					}
					dispatch(setWorkerPersonalProperty(token, 'token'))
				}
				if (errorMessage) {
					dispatch(workerPersonalLoginError(errorMessage))
				}
				dispatch(setWorkerPersonalProperty(false, 'loading'))
			} else {

				dispatch(setWorkerPersonalProperty(false, 'loading'))
			}
			dispatch(setWorkerPersonalProperty(2, 'authType'))

		} catch (e) {
			dispatch(workerPersonalLoginError(e.message))
		}
	}
}

function workerPersonalLoginStart() {
	return {
		type: WORKER_PERSONAL_LOGIN_START
	}
}

function workerPersonalLoginSuccess(data) {
	return {
		type: WORKER_PERSONAL_LOGIN_SUCCESS,
		payload: {
			data
		}
	}
}

function workerPersonalLoginError(error) {
	return {
		type: WORKER_PERSONAL_LOGIN_ERROR,
		payload: {
			error
		}
	}
}

///----------------------------------------------------

export function workerPersonalReg(email, password, role, name = '', phone = '') {
	return async dispatch => {
		dispatch(workerPersonalRegStart())
		try {
			let response = await axios.post('/worker/register/', qs.stringify({email, password, role, name, phone}))
			const {data: {token, id}, errorMessage} = response.data
			if (token) {
				await dispatch(workerPersonalFetch(token))
				localStorage.setItem('token', token)
				localStorage.setItem('userId', id)
				await localStorage.setItem('email', email)
				dispatch(workerPersonalLoginSuccess(response.data.data))
			}
			if (errorMessage) {
				dispatch(workerPersonalLoginError(errorMessage))
			}
			dispatch(setWorkerPersonalProperty(false, 'loading'))
			dispatch(setWorkerPersonalProperty(1, 'authType'))

		} catch (e) {
			dispatch(workerPersonalRegError(e))
		}

	}
}

function workerPersonalRegStart() {
	return {
		type: WORKER_PERSONAL_REG_START
	}
}

function workerPersonalRegError() {
	return {
		type: WORKER_PERSONAL_REG_ERROR
	}
}


/// UTILS
///----------------------------------------------------

export const workerPersonalLogOut = () => {
	localStorage.removeItem('token');
	localStorage.removeItem('userId');
	localStorage.removeItem('email');
	localStorage.removeItem('quantity');
	localStorage.removeItem('dueDate');
	localStorage.removeItem('serviceSelectStep');
	localStorage.removeItem('service');
	localStorage.removeItem('subCategory');
	localStorage.removeItem('category');
	//     // window.location.href = '/'
	return {
		type: WORKER_PERSONAL_LOGOUT
	}
}

///---------------------------------------------------

export function setWorkerPersonalProperty(value, property) {
	return {
		type: SET_WORKER_PERSONAL_PROPERTY,
		payload: {
			value, property
		}
	}
}

////--------------------------------------------------------

export function setWorkerPersonalRequestWorkers(workerId, action) {

	return async dispatch => {
		try {
			dispatch(workerPersonalFetchStart());

			let token = await localStorage.getItem('token');

			let response = await axios.post('/worker/requestWorker/', qs.stringify({workerId, action, token}))

			if (response.data) {
				dispatch(workerPersonalFetch())
			} else {
				dispatch(setWorkerPersonalProperty(false, 'loading'))
			}

		} catch (e) {
			dispatch(workerPersonalFetchError(e))
		}
	}
}

/////----------------------------------------------------------
export function workerPersonalUpdate(formData) {

	let token = localStorage.getItem('token');
	return async dispatch => {
		if (token) {
			dispatch(workerPersonalFetchStart());
			try {
				const response = await axios.post('/worker/update/', qs.stringify(
					{...formData, token}));

				if (response.data) {
					dispatch(workerPersonalFetch())
				} else {
					dispatch(setWorkerPersonalProperty(false, 'loading'))
				}

			} catch (e) {
				dispatch(workerPersonalFetchError(e))
			}
		}
	}
}


//////-----------------------------------------------

export function fetchMasters() {

	return async dispatch => {
		try {
			dispatch(fetchMastersStart())
			let response = await axios.get('/worker/masters/')
			dispatch(fetchMastersSuccess(response.data.data))

		} catch (e) {
			dispatch(fetchMastersError(e))
		}

	}
}


function fetchMastersStart() {
	return {
		type: FETCH_WORKER_CREATE_MASTERS_START
	}
}

function fetchMastersSuccess(data) {
	return {
		type: FETCH_WORKER_CREATE_MASTERS_SUCCESS,
		payload: {
			data
		}
	}
}

function fetchMastersError(error) {
	return {
		type: FETCH_WORKER_CREATE_MASTERS_ERROR,
		error
	}
}

/////-------------------------------------
export function setWorkerPersonalDate(date) {
	return async dispatch => {
		try {
			dispatch(workerPersonalFetchStart());
			let token = await localStorage.getItem('token');
			let response = await axios.post('/worker/setDate/', qs.stringify({date, token}))

			if (response.data) {
				dispatch(workerPersonalFetch())
			} else {
				dispatch(setWorkerPersonalProperty(false, 'loading'))
			}

			window.location.href = '/worker/personal/data'

		} catch (e) {
			dispatch(workerPersonalFetchError(e))
		}
	}
}

/////-------------------------------------
export function setMasterRequest(masters) {
	return async dispatch => {
		try {
			dispatch(workerPersonalFetchStart());
			let token = await localStorage.getItem('token');
			let response = await axios.post('/worker/setMasterRequest/', qs.stringify({masters, token}))

			if (response.data) {
				dispatch(workerPersonalFetch())
			} else {
				dispatch(setWorkerPersonalProperty(false, 'loading'))
			}

		} catch (e) {
			dispatch(workerPersonalFetchError(e))
		}
	}
}


////--------------------------------------------------------

export function setWorkerPersonalActivity(workerId, active) {

	return async dispatch => {
		try {
			dispatch(workerPersonalFetchStart());

			let token = await localStorage.getItem('token');

			let response = await axios.post('/worker/setActivity/', qs.stringify({workerId, active, token}))

			if (response.data) {
				dispatch(workerPersonalFetch())
			} else {
				dispatch(setWorkerPersonalProperty(false, 'loading'))
			}

		} catch (e) {
			dispatch(workerPersonalFetchError(e))
		}
	}
}

export function deleteWorkerPersonal(workerId) {

	return async dispatch => {
		try {
			dispatch(workerPersonalFetchStart());

			let token = await localStorage.getItem('token');

			let response = await axios.post('/worker/deleteWorker/', qs.stringify({workerId, token}))

			if (response.data) {
				dispatch(workerPersonalFetch())
			} else {
				dispatch(setWorkerPersonalProperty(false, 'loading'))
			}

		} catch (e) {
			dispatch(workerPersonalFetchError(e))
		}
	}
}

export function fetchWorkerPersonalDates() {
	return async dispatch => {
		let token = localStorage.getItem('token')
		if (token) {
			try {
				const dates = await axios.post('/admin/dates/', qs.stringify({token}));

				const filteredDates = dates.data.data.dates.filter(date => {
					return moment.unix(date.date).isAfter(moment());
				})

				dispatch({
					type: WORKER_PERSONAL_FETCH_DATES,
					payload: {
						dates: filteredDates,
						addresses: dates.data.data.addresses
					}
				})
			} catch (e) {
				dispatch(workerPersonalFetchError(e))
			}
		}
	}
}

export function fetchWorkerPersonalOrders() {
	return async dispatch => {
		let token = localStorage.getItem('token')
		if (token) {
			try {
				dispatch(workerPersonalFetchStart());
				const response = await axios.get('/mobile/orders/getMy/',
					{
						params: {
							token
						}
					}
				);
				dispatch({
					type: WORKER_PERSONAL_FETCH_ORDERS,
					payload: {
						orders: response.data.data,
					}
				})
			} catch (e) {
				dispatch(workerPersonalFetchError(e))
			}
		}
	}
}

export function fetchWorkerPersonalOrderDetail(orderId, userId = '') {
	return async dispatch => {
		let token = localStorage.getItem('token')
		if (token) {
			try {
				dispatch(workerPersonalFetchStart());
				const response = await axios.get('/mobile/orders/getMy/',
					{
						params: {
							token,
							orderId,
							web: true,
							userId
						}
					}
				);
				dispatch({
					type: WORKER_PERSONAL_FETCH_ORDER_DETAIL,
					payload: {
						order: response.data.data[0],
					}
				})
			} catch (e) {
				dispatch(workerPersonalFetchError(e))
			}
		}
	}
}

export function fetchWorkerPersonalOrderReports(orderId) {
	return async dispatch => {
		let token = localStorage.getItem('token')
		if (token) {
			try {
				dispatch(workerPersonalFetchStart());
				const response = await axios.get('/mobile/report/',
					{
						params: {
							orderId, token
						},
						// headers: {
						// 	Authorization: "Bearer " + token,
						// }
					}
				);
				dispatch({
					type: WORKER_PERSONAL_FETCH_ORDER_REPORTS,
					payload: {
						data: response.data.data,
					}
				})
			} catch (e) {
				dispatch(workerPersonalFetchError(e))
			}
		}
	}
}

export const setWorkerPersonalReportPhotos = (day, photo) => {
	return {
		type: SET_WORKER_PERSONAL_REPORT_PHOTOS,
		payload: {
			day,
			photo
		}
	}
}

export const setWorkerPersonalReportQuantity = (day, quantity, error = null) => {
	return {
		type: SET_WORKER_PERSONAL_REPORT_QUANTITY,
		payload: {
			day,
			quantity,
			error
		}
	}
}

export const deleteWorkerPersonalLocalPhoto = (day, id) => {
	return {
		type: DELETE_WORKER_PERSONAL_LOCAL_PHOTO,
		payload: {
			day,
			id
		}
	}
}

export function workerPersonalMakeReport(orderId, reportId, date, services, photos) {
	return async dispatch => {
		let token = localStorage.getItem('token')
		if (token) {
			const data = {
				orderId,
				date,
				services,
				photos,
				token
			}
			if (reportId) {
				data.reportId = reportId;
			}

			try {
				dispatch(workerPersonalFetchStart());
				const response = await axios.post('/mobile/makeReport/',
					qs.stringify(data),
					// {
					// 	headers: {
					// 		Authorization: "Bearer " + token,
					// 	}
					// }
				);

				dispatch({
					type: WORKER_PERSONAL_FETCH_ORDER_REPORTS,
					payload: {
						data: response.data.data
					}
				})

				//fetchWorkerPersonalOrderReports(orderId)
			} catch (e) {
				dispatch(workerPersonalFetchError(e))
			}
		}
	}
}

export function saveWorkerPrice(workerPrice, userId) {

	return async dispatch => {
		try {
			// dispatch(workerPersonalFetchStart());

			let token = await localStorage.getItem('token');

			let response = await axios.post('/worker/setWorkerPrice/', qs.stringify({workerPrice, userId, token }));
			console.log(response)

			// if (response.data) {
			// 	dispatch(workerPersonalFetch())
			// } else {
			// 	dispatch(setWorkerPersonalProperty(false, 'loading'))
			// }

		} catch (e) {
			dispatch(workerPersonalFetchError(e))
		}
	}
}