import React, {useEffect, Fragment, useState} from 'react'
import './PersonalOrderItem.scss'
import {fetchOrderDetail, fetchOrderList, switchTab, switchTabSmall} from "../../../../store/actions/orderListActions";
import {connect} from "react-redux";
import PersonalOrderItemTabs from "./PersonalOrderItemTabs/PersonalOrderItemTabs";
import PersonalOrderItemTabsSmall from "./PersonalOrderItemTabsSmall/PersonalOrderItemTabsSmall";
import PersonalOrderItemService from "./PersonalOrderItemService/PersonalOrderItemService";
import PersonalOrderItemMaterial from "./PersonalOrderItemMaterial/PersonalOrderItemMaterial";
import PersonalOrderItemTranches from "./PersonalOrderItemTranches/PersonalOrderItemTranches";
import PersonalOrderItemControls from "./PersonalOrderItemControls/PersonalOrderItemControls";
import PersonalOrderItemAccordion from "./PersonalOrderItemAccordion/PersonalOrderItemAccordion";
import ActPDFViewer from "../../../../components/Documents/ActPDFViewer/ActPDFViewer";
import BackBtn from "../../../../components/UI/BackBtn/BackBtn";
import Dialog from "../../../../components/Dialog/Dialog";
import Total from "../../../../components/UI/Total/Total";
import PersonalOrderItemPS from "./PersonalOrderItemPS/PersonalOrderItemPS";
import {fetchDialogs, getProfile} from '../../../../store/actions/dialogActions';
import firebase from '../../../../rest/firebase';


const PersonalOrderItem =(props)=> {
	const {match, order, fetchOrderDetail, fetchDialogs, history, switchTab, switchTabSmall, dialog} = props
	const [ks, setKs] = useState(null);
	const [showDialog, setDialog] = useState(false);
	const [chats, setChats] = useState({});
	const [paymentPage, setPaymentPage] = useState(false);
	const [trancheId, setTrancheId] = useState(null);
	const [currentTrancheNumber, setCurrentTrancheNumber] = useState(null);

	// const state = {
	// 	order: {},
	// 	ks: null,
	// 	dialog: false,
	// 	paymentPage: false,
	// 	trancheId: null,
	// 	chats: {},
	// 	limit: 50
	// }

	const ksHandler = (curKs) => {
		setKs(curKs === ks ?null:curKs)
		setDialog(false)
	}

	const dialogHandler = () => {
	setDialog(!showDialog);
		setKs(null)
	}

	const paymentPageHandler = (trancheId, currentTrancheNumber) => {
		// this.setState(prev => ({paymentPage: !prev.paymentPage, trancheId, currentTrancheNumber}))
		setPaymentPage(!paymentPage)
		setTrancheId(trancheId)
		setCurrentTrancheNumber(currentTrancheNumber)
	}

	useEffect(() => {
		let currentOrderId = order.id
		let orderId = match.params.id
		if (orderId && +orderId !== currentOrderId) {
			history.push('/personal/orders/' + orderId)
			 fetchOrderDetail(orderId)
		}

		 props.getProfile()
		const limit = 50;

		if(order.id) {
			console.log('order', order)

			let dialogs = []
			if(dialog && dialog.profile){
				dialogs.push(dialog.profile.adminDialog)
			}


			if (order.dialogs) {
				dialogs = [...dialogs,...order.dialogs]
			}

			if (!!dialogs.length) {
				console.log('dialogs', dialogs)
				dialogs.forEach(async dialog => {
					let messages = []
					let key = dialog.opponent.role === 8 || dialog.opponent.role === 1 ? 'admin' : order.id

					const db = await firebase.database()
						.ref(`dialogs/${key}/${dialog.id}`)
						.orderByChild('timestamp')
						.limitToLast(limit);

					db.on("value", snapShot => {
						messages = snapShot.val() || []
						chats[dialog.id] = {
							opponent: dialog.opponent,
							messages,
						}
						fetchDialogs(key, dialog.id, dialog.opponent)
					})
				})
			}

			setChats(chats);
		}
	}, [order]);

		const {servicePrice, totalPrice, materials} = order
		const {tabs, tabsSmall, currentTab, currentTabSmall} = props.orderList
		const materialPrice = materials && !!materials.length ? totalPrice - servicePrice : null;

		return (
			<Fragment>
				{
					order.id
						?
						<Fragment>
							{
								paymentPage ?
									<BackBtn onClick={paymentPageHandler} n title='Назад' variant={'dark'}/>
									:
									<BackBtn to="/personal/orders/" title='Назад к заказам' variant={'dark'}/>
							}
							{
								paymentPage
									?
									<PersonalOrderItemPS order={order} trancheId={trancheId} currentTrancheNumber={currentTrancheNumber}/>
									:
									<>
										{
											!showDialog
												?
												<PersonalOrderItemControls ksHandler={ksHandler}
																		   order={order}
																		   ks={ks}
																		   chats={chats}
																		   dialog={dialog}
																		   dialogHandler={dialogHandler}/>
												: null
										}
										{
											ks
												?
												<ActPDFViewer order={order}
															  ks={ks}
															  ksHandler={ksHandler}/>
												:
												<Fragment>
													{
														showDialog ?
															<Dialog order={order} chats={chats} dialogHandler={dialogHandler}/>
															:
															<Fragment>
																<div className='d-flex justify-content-between'>
																	<PersonalOrderItemTabs
																		tabs={tabs}
																		onSwitchTab={switchTab}
																		currentTab={currentTab}
																		order={order}
																	/>
																</div>

																<div
																	className={`PersonalOrderItem_container mb-1 py-4 pr-4 rounded-bottom ${currentTab === 2 ? 'pl-0' : 'pl-4'}`}>

																	{
																		currentTab === 1
																			?
																			<div
																				className={`PersonalOrderItem_container-sub`}>

																				<PersonalOrderItemTabsSmall
																					tabs={tabsSmall}
																					onSwitchTab={switchTabSmall}
																					currentTab={currentTabSmall}
																				/>

																				{currentTabSmall === 1 ?
																					<div
																						className={`PersonalOrderItem_container-sub`}>

																						<PersonalOrderItemService
																							order={order}
																						/>
																						<div
																							className='d-flex align-items-center'>
																							<Total
																								title={"Сумма за работы"}
																								sum={servicePrice}/>
																							{
																								materialPrice &&
																								<Total
																									title={"Сумма за материалы"}
																									sum={materialPrice}/>
																							}
																						</div>
																					</div>
																					: <div
																						className={`PersonalOrderItem_container-sub2`}>

																						<PersonalOrderItemMaterial
																							order={order}
																						/>
																						<Total
																							title={"Сумма за материалы"}
																							sum={totalPrice - servicePrice}/>
																					</div>
																				}

																			</div>
																			: null
																	}
																	{
																		currentTab === 2
																			?
																			<>
																				<div
																					className={`PersonalOrderItem_container-sub2`}>
																					<PersonalOrderItemTranches
																						order={order}
																						paymentPageHandler={paymentPageHandler}
																					/>
																				</div>

																			</>
																			: null
																	}
																</div>

																{
																	currentTab === 2
																		?
																		<PersonalOrderItemAccordion order={order}
																									paymentPageHandler={paymentPageHandler}/>
																		:
																		<div className="px-4 py-1">
																			<Total title={"Общая сумма заказа"}
																				   sum={totalPrice}/>
																		</div>
																}

															</Fragment>
													}
												</Fragment>
										}
									</>
							}
						</Fragment>
						: null
				}
			</Fragment>
		)
}


function mapStateToProps(state) {
	return {
		orderList: state.orderList,
		order: state.orderList.order,
		dialog: state.dialog
	}
}

const actions = {
	fetchOrderDetail,
	fetchOrderList,
	switchTab,
	switchTabSmall,
	fetchDialogs,
	getProfile
}


export default connect(mapStateToProps, actions)(PersonalOrderItem)