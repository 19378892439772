import React from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';

const StyledHeadTitleCol = styled.div`
  display: flex;
  //padding: 0 5px;
  padding: 0;
  align-items: center;
  word-break: break-all;
  white-space: pre;
  flex: 1 0 auto;
`;

const ChartsTableHeadTitleCol = ({ col, title }) => {
    return (
        <StyledHeadTitleCol
              className={`col-${col}`}
        >
            {parse(title)}
        </StyledHeadTitleCol>
    );
};

export default ChartsTableHeadTitleCol;
