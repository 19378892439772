import React from 'react'
import cn from './HowItWork.module.scss'

const HowItWorkSimpleItem = ({number, title, image, className}) => {

  return (
    <div className={`${cn.item} ${className}`}>
      <div className={cn.itemInner}>
        <div className={cn.itemTextWrapper}>
          <div className={cn.itemNumber}>{number}</div>
          <div className={cn.itemTitle}>{title}</div>
        </div>
        <img className={cn.itemImage} src={image}/>
      </div>
    </div>
  )
}

export default HowItWorkSimpleItem