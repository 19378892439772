export const DATE_WIDTH = '70px';
export const DATE_WIDTH_NUM = 70;
export const LEFT_WIDTH = '660px';
// export const LEFT_WIDTH = '45%';
export const RIGHT_WIDTH = '55%';
export const ROW_HEIGHT = '50px';
export const ROW_HEIGHT_NUM = '50';

export const CHART_VOLUMES = 'CHART_VOLUMES';
export const CHART_RESOURCES = 'CHART_RESOURCES';
export const CHART_WORKERS = 'CHART_WORKERS';
export const CHART_WORKERS_COUNTS = 'CHART_WORKERS_COUNTS';

export const TITLE_COLS = [
    {
        id: 1,
        title: '№',
    },
    {
        id: 2,
        title: 'Наименование работ',
        col: 6,
    },
    {
        id: 3,
        title: `Дата <br/>начала`,
        col: 'auto',
    },
    {
        id: 4,
        title: `Дата <br/>завершения`,
        col: 'auto',
    },
    {
        id: 5,
        title: `Кол-во <br/>дней`,
        col: 1,
    },
    {
        id: 6,
        title: `Кол-во <br/>рабочих`,
        col: 1,
    },
];

export const COLORS = [
    '#5BDFEB',
    '#6DADF2',
    '#6E74DB',
    '#A26DF2',
    '#D468E8',
    '#9029F2',
    '#5536A5',
    '#4505AE',
    '#6E74DB',
    '#A26DF2',
];

export const CRITICAL = '#ff6a7b';

export const WORKER_COLORS = {
    3016: '#5BDFEB',
    2958: '#6DADF2',
    3260: '#6E74DB',
    348: '#A26DF2',
    347:   '#D468E8',
    3549:  '#9029F2',
    3254: '#5536A5',
    353:    '#4505AE',
    356:  '#A26DF2',
};