import React, {useEffect} from 'react';
import parse from "html-react-parser";
import {connect} from "react-redux";
import {fetchUserStory} from "../../../store/actions/apiDocsActions";

const UserStory = ({userStory, fetchUserStory}) => {
    useEffect(() => {
        fetchUserStory()
    }, [fetchUserStory]);
    return (
        <ul className="container-fluid">
            {
                userStory.map(group => {
                    return (
                        <li key={group.id}>
                            <h2 className=" m-3 text-warning">{group.name}</h2>
                            <ul>
                                {
                                    group.stories.map(story => {
                                        return (
                                            <li key={story.id}>

                                                <div
                                                    className="ApiDocs_left jumbotron jumbotron-fluid">
                                                    <div className="container">
                                                        <h4 className="display-5 text-success">{story.name}</h4>
                                                        <p className='ApiDocs_text'>  {parse(story.value)}</p>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </li>
                    )
                })
            }
        </ul>
    );
};

const mapState = state => {
    return {
        userStory: state.apiDocs.userStory
    }
};

const actions = {
    fetchUserStory
};

export default connect(mapState, actions)(UserStory);