import React from 'react';
import '../ui.scss';

const Button = ({title}) => {
	return (
		<div className="UI__Button">
			{title}
		</div>
	);
};

export default Button;