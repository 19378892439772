import React, { Fragment } from 'react';
import * as moment from 'moment';
import 'moment/locale/ru';
import PersonalOrderItemTranchesDay from './PersonalOrderItemTranchesDay';
import { withMaterials } from '../functions';

const PersonalOrderItemTranchesDays = ({ order, paymentPageHandler, acceptedReportValues }) => {
    // обработка дат
    let startDate = moment(order.startDate, 'DD.MM.YYYY')
    let finishDate = moment(order.finishDate, 'DD.MM.YYYY')
    // количество дней
    let days = finishDate.diff(startDate, 'days')
    if (!days) {
        days = 1;
    }
    const table = []
    // индекс для визуальной части
    let trancheIdx = 1;
    let trancheDates = Object.keys(order.tranches)
        .map(key => {
            return order.tranches[key]
        })
        .filter(tranche => !!tranche.date);

    // План
    let quantityDay = (order.serviceValue / days).toFixed(2)
    let costDay = (order.servicePrice / days).toFixed(2)
    const totalMaterialPrice = order.totalPrice - order.servicePrice
    const materialCostDay = (totalMaterialPrice / days).toFixed(2)

    for (let i = 0; i < days; i++) {
        const day = moment(startDate, "DD.MM.YYYY").add(i, 'day').format("DD.MM.YYYY")
        const tranches = trancheDates.filter(tranche => tranche.date === day)

        const isTranche = !!tranches.length
        const isDayArrive = moment().diff(moment(day, "DD.MM.YYYY"), 'days') > 0
        // const id = day + Math.random();

        table.push(
            <Fragment key={day}>
                <div className={`PersonalOrderItemTranches_RightBlock__item ${isTranche ? 'active' : ''}`}>
                    <div
                        className={`PersonalOrderItemTranches_RightBlock__item_head`}>
                        {/* табличка дня сверху */}
                        <div className={`${isDayArrive ? 'reported' : ''}`}>
                            <strong>{day}</strong>
                            <span
                                className="text-capitalize">{moment(startDate, "DD.MM.YYYY").add(i, 'd')
                                .format("dd")}</span>
                        </div>
                    </div>

                    <div
                        className={`px-2 ${isTranche ? 'border-tranche-1' : 'border-main-1-3'} border-bottom d-flex align-items-center justify-content-center h60`}
                    >
                        {/* количество в день - план */}
                        <div
                            className={`${isTranche ? 'bg-tranche-1' : 'bg-white'} d-flex align-items-center justify-content-center h-100 w-100 pt-3`}>
                            {quantityDay}
                        </div>
                    </div>
                    {
                        withMaterials(order)
                            ? <div
                                className={`px-2 ${isTranche ? 'border-tranche-1' : 'border-main-1-3'}  border-bottom border-main-1-3 d-flex align-items-center justify-content-center h40`}
                            >
                                {/* план по услуге - план */}
                                <div
                                    className={` ${isTranche ? 'bg-tranche-1' : 'bg-white'} d-flex align-items-center justify-content-center h-100 w-100`}>
                                    {costDay}
                                </div>
                            </div>
                            : <div
                                className={`px-2 ${isTranche ? 'border-tranche-2' : 'border-main-2-1'}  border-bottom border-main-1-3 d-flex align-items-center justify-content-center h40`}
                            >
                                {/* план по материалу - план */}
                                <div
                                    className={` ${isTranche ? 'bg-tranche-1' : 'bg-white'} d-flex align-items-center justify-content-center h-100 w-100`}>
                                    {costDay}
                                </div>
                            </div>
                    }
                    {
                        withMaterials(order) &&
                        <div>
                            <div
                                className={`px-2 ${isTranche ? 'border-tranche-1' : 'border-main-1-3'} border-bottom border-main-1-3 d-flex align-items-center justify-content-center h40`}
                            >
                                {/* план по материалам - план */}
                                <div
                                    className={` ${isTranche ? 'bg-tranche-1' : 'bg-white'} d-flex align-items-center justify-content-center h-100 w-100`}>
                                    {materialCostDay}
                                </div>
                            </div>
                            <div
                                className={`px-2 ${isTranche ? 'border-tranche-2' : 'border-main-2-1'} border-bottom border-main-1-3 d-flex align-items-center justify-content-center h40`}
                            >
                                {/* план по материалам - план */}
                                <div
                                    className={` ${isTranche ? 'bg-tranche-1' : 'bg-white'} d-flex align-items-center justify-content-center h-100 w-100`}>
                                    {(Number(+materialCostDay + +costDay)).toFixed(2)}
                                </div>
                            </div>
                        </div>
                    }

                    {/* рассчеты по дню работа и материалы */}
                    <PersonalOrderItemTranchesDay order={order} day={day} acceptedReportValues={acceptedReportValues}/>

                    {/* кнопка оплатить снизу */}
                    {isTranche
                        ?
                        <div
                            className='PersonalOrderItemTranches_RightBlock__item_head-label px-2'
                        >
                            <div
                                className='bg-tranche-1 h-100 w-100'>Транш&nbsp;
                                {trancheIdx}
                            </div>
                        </div>
                        : null
                    }

                    {isTranche
                        && <>
                            {
                                tranches.map((tranche, idx) => {
                                    return (
                                        <div
                                            key={tranche.id}
                                            className='PersonalOrderItemTranches_RightBlock__item_pay px-3'
                                        >
                                            {
                                                tranche.isPaid
                                                    ?
                                                    <div
                                                        className={`PersonalOrderItemTranches_RightBlock__item_pay-btn paid`}>
                                                        Оплачено
                                                    </div>
                                                    :
                                                    <div onClick={() => paymentPageHandler(tranche.id, idx)}
                                                         className={`PersonalOrderItemTranches_RightBlock__item_pay-btn`}>
                                                        Оплатить
                                                    </div>

                                            }
                                            <div
                                                className={`PersonalOrderItemTranches_RightBlock__item_pay-sum ${tranche.isPaid ? 'paid' : ''}`}>

                                                <div
                                                    className="text-secondary d-flex align-items-center justify-content-center mt-2">
                                                    {new Intl.NumberFormat('ru-RU').format(tranche.sum)}&nbsp;р.
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </>

                    }
                </div>
            </Fragment>
        )
        if (isTranche) {
            ++trancheIdx;
        }
    }

    return table;
};

export default PersonalOrderItemTranchesDays;