import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { CRITICAL, DATE_WIDTH_NUM, ROW_HEIGHT, ROW_HEIGHT_NUM } from '../config';
import { useDispatch, useSelector } from 'react-redux';
import { addDays, differenceInDays, format, max, parse } from 'date-fns';
import { setCalculateData, setChangedData } from '../../../../../../store/actions/estimateActions';
import { getReserveDays, getServiceLink, setServiceDates, setServiceDatesAfterChange } from '../functions';
import { useChartsContext } from '../ChartsContext';

const StyledRangeRow = styled.div`
  position: relative;
  width: ${({ width }) => width}px;
  min-width: ${({ width }) => width}px;
  border-bottom: 1px solid #E2E4F1;
  background: #f3f3f8;
  height: ${({ height }) => height}px;
  transition: height 500ms;
`;
const StyledRangeContainer = styled.div`
  position: relative;
  left: ${({ offset }) => offset * DATE_WIDTH_NUM}px;
  background: #fff;
  width: ${({ width }) => width}px;
  min-width: ${({ width }) => width}px;
  border-left: 1px solid #E2E4F1;
  border-right: 1px solid #E2E4F1;
`;

const ChartsTableRange = ({ service, color, serviceOpen }) => {
    const dispatch = useDispatch();
    const { dates } = useChartsContext();
    const [background, setBackground] = useState(color);
    const { dayNorm } = useSelector(state => state.adminService);

    const { changedData } = useSelector(state => state.estimateOrder);

    const changeHandler = useCallback((value) => {
        const data = [...changedData];
        const { globalStartDate, items, norms, count, id, offset } = service;
        const serviceStartDate = format(new Date(addDays(new Date(parse(globalStartDate, 'dd.MM.yyyy', new Date())), value[0] + offset)), 'dd.MM.yyyy');
        const serviceEndDate = format(new Date(addDays(new Date(parse(globalStartDate, 'dd.MM.yyyy', new Date())), value[1] - 1 + offset)), 'dd.MM.yyyy');
        const daysCount = value[1] - value[0];

        let workerNorm = 0;
        if (!!items && !!items.length) {
            items.forEach(item => {
                item.norms.forEach(norm => {
                    workerNorm += norm.norm * norm.cc;
                });
            });
        } else {
            norms.forEach(norm => {
                workerNorm += norm.norm * norm.cc;
            });
        }
        const workersCount = Math.ceil(count * workerNorm / (daysCount * dayNorm));
        const servicePosition = data.findIndex(item => item.id === id);
        data[servicePosition] = {
            ...service,
            startDate: serviceStartDate,
            endDate: serviceEndDate,
            daysCount,
            workersCount,
            value,
        };
        const dataWithNewDates = data.map((service) => setServiceDatesAfterChange(data, service))
        dispatch(setChangedData(dataWithNewDates));
    }, [service, changedData]);
    const { initialDaysCount, daysCount, offset, daysReserve, value } = service;

    const criticalDaysCount = initialDaysCount + daysReserve;

    useEffect(() => {
        const changedService = changedData.find((item) => item.id === service.id);
        if (changedService) {
            const { daysCount } = changedService;
            setBackground(criticalDaysCount === daysCount ? CRITICAL : color);
        }
    }, [changedData, criticalDaysCount, service, color]);

    const height = serviceOpen === service.id
        ? ROW_HEIGHT_NUM * (service.items.length + 1)
        : ROW_HEIGHT_NUM;

    return (
        <StyledRangeRow
            width={dates.length * DATE_WIDTH_NUM}
            height={height}
        >
            <StyledRangeContainer
                offset={offset}
                width={criticalDaysCount * DATE_WIDTH_NUM}>
                <Slider
                    key={service.id}
                    range
                    min={0}
                    max={criticalDaysCount}
                    // defaultValue={[0, daysCount]}
                    step={1}
                    onChange={changeHandler}
                    value={!!value ? value : [0, daysCount]}
                    trackStyle={{
                        backgroundColor: background,
                        height: ROW_HEIGHT_NUM - 2,
                        borderRadius: 5,
                    }}
                    railStyle={{
                        borderRadius: 0,
                        height: ROW_HEIGHT_NUM - 2,
                        border: '1px solid rgb(169 173 179)',
                    }}
                    handleStyle={{
                        top: `${ROW_HEIGHT_NUM / 2 - 1}px`,
                    }}
                    pushable
                    draggableTrack
                    style={{
                        height: ROW_HEIGHT,
                        padding: 0,
                        cursor: 'ew-resize'
                    }}

                />
            </StyledRangeContainer>
        </StyledRangeRow>
    );
};

export default ChartsTableRange;
