import React from "react";
import { useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import "./Calculator.scss";
import IndividualWorks from "./IndividualWorks/IndividualWorks";
import { getOrderState } from "../../../store/selectors";
import Complex from "./Complex/Complex";
import CalculatorContextProvider from "./CalculatorContext";
import ConditionsContextProvider from "./ConditionsContext";
import MasterHourly from "./MasterHourly/MasterHourly";
import CalculatorTopTabs from './CalculatorTopTabs';

const Calculator = () => {
    const { category } = useSelector(getOrderState);
    const { pathname } = useLocation();

    return (
        <CalculatorContextProvider>
            <ConditionsContextProvider>
                <div id="calculator" className="Calculator">
                    <div className="Calculator__layout">
                        <div className="Calculator__layout-title">СДЕЛАТЬ ЗАКАЗ</div>
                        <div className="Calculator__layout-desc">
                            Быстрый старт работ прямо сейчас! Оформите заказ и вам будет
                            доступен личный кабинет. Состав рабочей группы. Персональный
                            менеджер. Акты КС-2 и КС-3 онлайн. Все в режиме реального времени.
                        </div>
                        <div className="Calculator__layout-heading">
                            <div className="Calculator__layout-heading-line mr-3" />
                            <div>Выберите вид работ</div>
                            <div className="Calculator__layout-heading-line ml-3" />
                        </div>
                    </div>
                    <div className="Calculator__content">
                        {!category && <CalculatorTopTabs />}
                        <div
                            className={`Calculator__content-body ${
                                category ? "Calculator__content-body_round" : ""
                            }`}
                        >
                            {pathname === "/" && <IndividualWorks />}
                            {pathname === "/complex-works" && <Complex />}
                            {pathname === "/master-hourly" && <MasterHourly />}
                        </div>
                    </div>
                </div>
            </ConditionsContextProvider>
        </CalculatorContextProvider>
    );
};

export default Calculator;
