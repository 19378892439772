import React from 'react'
import classes from './Loader.module.scss'

const LoaderAdmin = () => {
    return (
        <div className={classes.LoaderContainer}>
            <div className={classes.Loader}>
                <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                    <circle cx="50" cy="50" r="25" strokeWidth="3" stroke="#3f51b5"
                            strokeDasharray="39.269908169872416 39.269908169872416" fill="none" strokeLinecap="round">
                        <animateTransform attributeName="transform" type="rotate" dur="2.2222222222222223s"
                                          repeatCount="indefinite" keyTimes="0;1" values="0 50 50;360 50 50"/>
                    </circle>
                    <circle cx="50" cy="50" r="21" strokeWidth="3" stroke="#f50057"
                            strokeDasharray="32.98672286269283 32.98672286269283" strokeDashoffset="32.98672286269283"
                            fill="none" strokeLinecap="round">
                        <animateTransform attributeName="transform" type="rotate" dur="2.2222222222222223s"
                                          repeatCount="indefinite" keyTimes="0;1" values="0 50 50;-360 50 50"/>
                    </circle>
                </svg>
            </div>
        </div>

    )
}
export default LoaderAdmin