import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';
import {fetchApiStory} from '../../../store/actions/apiDocsActions';
import AdLoader from "../ad-loader/ad-loader";

const ApiStory = () => {
    const dispatch = useDispatch();
    const apiStory = useSelector((state) => state.apiDocs.apiStory);
    const {loading} = useSelector((state) => state.apiDocs.loading);
    const [page, setPage] = useState(apiStory.navigation.currentPage);

    useEffect(() => {
        dispatch(fetchApiStory(page));
    }, [dispatch, page]);

    const pagHandler = (evt, value) => {
        setPage(value);
    };

    if (loading) {
        return <AdLoader/>;
    }

    return (
        <div className="py-5">
            {
                !!apiStory.items.length && (
                    <>
                        <List>
                            {
                                apiStory.items.map((item) => {
                                    return (
                                        <ListItem key={item.id} className="border-bottom d-flex flex-column">
                                            <Typography variant="subtitle1" className="w-100 text-success">
                                                {item.dateCreateFormat}
                                            </Typography>
                                            <Typography variant="subtitle1" className="w-100 text-warning">
                                                {item.name}
                                            </Typography>
                                            <Typography variant="body2" className="w-100">
                                                {item.description}
                                            </Typography>
                                        </ListItem>
                                    );
                                })
                            }
                        </List>
                        <div className="d-flex justify-content-center my-3">
                            <Pagination
                                count={apiStory.navigation.pageCount}
                                variant="outlined"
                                shape="rounded"
                                color="primary"
                                onChange={pagHandler}
                                page={apiStory.navigation.currentPage}
                                classes={{
                                    root: 'ApiDocs__pagination',
                                }}
                            />
                        </div>
                    </>
                )
            }
        </div>
    );
};

export default ApiStory;
