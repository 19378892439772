import {
	ADMIN_MASTERS_CHECK_MODERATION, ADMIN_MASTERS_FETCH_ERROR,
	ADMIN_MASTERS_FETCH_START,
	ADMIN_MASTERS_FETCH_SUCCESS
} from "../actions/actionTypes";

import {getUserRoleName} from '../../utils/roleFunctions';

const initialState = {
	users: {
		masters: [],
		managers: [],
		independentMasters: [],
		specialists: [],
		ancillary: [],
		clients: [],
	},
	navigation: {
		masters: {},
		managers: {},
		specialists: {},
		ancillary: {},
		independentMasters: {},
		clients: {},
	},
	usersTotalCount: {
		masters: 0,
		managers: 0,
		specialists: 0,
		ancillary: 0,
		independentMasters: 0,
		clients: 0,
	},
	filter: null,
	loading: false,
	error: null
}

const adminMastersListReducer = (state = initialState, {type, payload}) => {
	const masters = [...state.users.masters]
	switch (type) {
		case ADMIN_MASTERS_FETCH_START:
			return {
				...state,
				loading: true
			}
		case ADMIN_MASTERS_FETCH_ERROR:
			return {
				...state,
				loading: false
			}
		case ADMIN_MASTERS_FETCH_SUCCESS:
			let users = {...state.users};
			let navigation = {...state.navigation};
			let usersTotalCount = {...state.usersTotalCount};
			let role = getUserRoleName(payload.role);
			users[role] = payload.data.items;
			navigation[role] = payload.data.navigation;
			usersTotalCount[role] = payload.data.total;

			return {
				...state,
				loading: false,
				error: null,
				users,
				navigation,
				usersTotalCount,
				filter: payload.moderation
			};
		case ADMIN_MASTERS_CHECK_MODERATION:
			const master = masters.find(master => master.id === payload.masterId);
			if (payload.workerId) {
				const worker = master.workers.find(worker => worker.id === payload.workerId);
				if (worker.moderation.id === 1) {
					worker.moderation = {
						id: 2,
						name: 'Прошел модерацию'
					}
				} else {
					worker.moderation = {
						id: 1,
						name: 'На модерации'
					}
				}
			} else {
				if (master.moderation.id === 1) {
					master.moderation = {
						id: 2,
						name: 'Прошел модерацию'
					}
				} else {
					master.moderation = {
						id: 1,
						name: 'На модерации'
					}
				}
			}
			return {
				...state,
				masters
			}
		default:
			return {
				...state
			}
	}
}

export default adminMastersListReducer;