import React from 'react'
import classes from './ServiceCalculateRows.module.scss'
import Counter from '../../../../../../components/UI/Counter/Counter'
import Td from '../../../../../../components/UI/Td/Td'
import NumberFormat from 'react-number-format'
import * as moment from 'moment'
import parse from 'html-react-parser'
import {useDispatch, useSelector} from "react-redux";

import {useConditionsContext} from '../../../../../../app/containers/Calculator/ConditionsContext';
import {getOrderState} from '../../../../../../store/selectors';
import {
	setDefaultMaterialQuantity,
	setOrderFormControlValue,
	setOrderProperty
} from '../../../../../../store/actions/orderActions';

const ServiceCalculateRows = () => {
	const dispatch = useDispatch();
	const {
		currentService,
		quantity, startDate, dueDate, workerCount,
	} = useSelector(getOrderState);
	const {servicePrice, totalServicePrice,} = useConditionsContext();
	const quantityHandler = async num => {
		await dispatch(setOrderProperty(num, 'quantity'))
		dispatch(setOrderFormControlValue(num, 'quantity'))
		dispatch(setDefaultMaterialQuantity())
	};
	console.log('currentService', currentService)

	return (
		<>
			{
				currentService && servicePrice
					?
					<tbody className={classes.ServiceCalcRows}>
					<tr className={classes.ServiceCalcRow}>
						<Td colSpan={3} width={220} center={false}>
							<div className="text-left">{currentService.name}</div>
							{
								currentService.items && !!currentService.items.length
								&& <ul className="ServiceCalc__subwork-list">
									{
										currentService.items.map(item => {
											return <li key={item.id}>{item.name}</li>
										})
									}
								</ul>
							}


						</Td>
						<Td width={50} center={false}>{parse(currentService.unit)}</Td>
						<Td className={['py-0']} center={false}>
							<Counter
								quantityHandler={quantityHandler}
								value={quantity}
							/>
						</Td>
						<Td width={90} center={false}>
							<NumberFormat
								value={servicePrice}
								displayType={'text'}
								thousandSeparator={" "}/>
						</Td>
						<Td width={120} center={false}>
							<NumberFormat
								value={totalServicePrice}
								displayType={'text'}
								thousandSeparator={" "}/>
						</Td>
						<Td center={false}>{startDate} </Td>
						<Td center={false} bg={"#fff"}>{moment(startDate, "DD.MM.YYYY")
							.add((+dueDate > 0 ? +dueDate - 1 : 1), 'd').format("DD.MM.YYYY")} </Td>
						<Td center={false}>
							<input
								disabled
								className={classes.WorkerCount}
								value={workerCount}
							/></Td>
					</tr>
					</tbody>
					:
					null
			}
		</>
	)
}

export default ServiceCalculateRows;