import React from "react";
import cn from "./HowItWork.module.scss";
import HowItWorkSimpleItem from "./HowItWorkSimpleItem";
import tablet from "../../../img/howItWorkTablet.png";
import manager1 from '../../../img/manager1.png'
import manager2 from '../../../img/manager2.png'
import foreman1 from '../../../img/foreman1.png'
import foreman2 from '../../../img/foreman2.png'

const HowItWork = (props) => {
	return (
		<section className={cn.main}>
			<h2 className={cn.title}>Все работы - у вас под контролем</h2>
			<p className={cn.subtitle}>Вся внутренняя работа сервиса автоматизирована. Удаленный контроль ваших работ из Личного кабинета. Вы всегда можете держать контакт с персональным менеджером через чат и выдавать ему приоритетные задачи.</p>
			<div className={cn.wrapper}>
				<HowItWorkSimpleItem
					number={1}
					title="Менеджер выдаёт текущее задание"
					image={manager1}
				/>
				<div className={cn.iconWrapepr}>
					<svg width="20" height="29" viewBox="0 0 20 29" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M5.39561 24.751C4.9239 24.751 4.4623 24.5548 4.19076 24.1959C3.76887 23.6382 3.96556 22.8992 4.63027 22.5451C7.4461 21.0455 9.45611 18.4846 10.007 15.6951C10.7675 11.8443 8.62561 7.82164 4.67716 5.68514C4.01549 5.32708 3.82511 4.58695 4.2519 4.03183C4.67846 3.47662 5.56065 3.31671 6.22264 3.67495C11.112 6.32085 13.7632 11.3084 12.8197 16.0861C12.1382 19.5364 9.64802 22.7061 6.15834 24.5646C5.93042 24.6864 5.66582 24.751 5.39561 24.751ZM10.865 28.0486C15.5185 25.5703 18.8393 21.3431 19.7481 16.7409C21.0062 10.3704 17.4703 3.7195 10.9496 0.191181C10.2876 -0.167063 9.40542 -0.00715446 8.97885 0.548056C8.55206 1.10317 8.74245 1.84339 9.40411 2.20136C14.9838 5.22047 18.0102 10.9064 16.9354 16.3499C16.157 20.2914 13.3163 23.9099 9.33677 26.0292C8.67217 26.3832 8.47537 27.1223 8.89726 27.68C8.9975 27.8127 9.12795 27.9276 9.28114 28.0179C9.43433 28.1083 9.60725 28.1724 9.79001 28.2066C9.97278 28.2409 10.1618 28.2445 10.3462 28.2174C10.5307 28.1903 10.707 28.1329 10.865 28.0486ZM2.18988 19.8475C4.13234 18.8127 5.46372 17.1188 5.84263 15.1999C6.36026 12.5785 4.90693 9.84259 2.22611 8.39209C1.56466 8.03412 0.68215 8.19385 0.255472 8.74897C-0.171312 9.30408 0.0190716 10.0443 0.680845 10.4024C2.4203 11.3436 3.36438 13.1144 3.02985 14.8089C2.78692 16.0389 1.90158 17.1675 0.661695 17.828C-0.00301552 18.1821 -0.199707 18.9213 0.222183 19.4789C0.350887 19.6491 0.528858 19.7893 0.739523 19.8864C0.950188 19.9835 1.1867 20.0342 1.42704 20.034C1.69728 20.034 1.96192 19.9693 2.18988 19.8475Z" fill="#8864DA"/>
					</svg>
				</div>
				<div className={`${cn.item} ${cn.big}`}>
					<div className={cn.itemInner}>
					<div className={cn.itemTextWrapper}>
						<div className={cn.itemNumber}>2</div>
						<div className={cn.itemTitle}>
							Бригадиры выполняют задание и отправляют отчёты
						</div>
						</div>
						<div className={cn.itemWrapper}>
							<div className={cn.itemPart}>
								<img src={foreman1} className={cn.itemImage} />
								<div className={cn.itemLine}>
									<svg
										width="8"
										height="27"
										viewBox="0 0 8 27"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<line
											x1="4"
											x2="4"
											y2="21"
											stroke="#8864DA"
											strokeWidth="2"
										/>
										<rect y="19" width="8" height="8" rx="4" fill="#8864DA" />
									</svg>
								</div>
								<div className={cn.itemText}>
									Ежедневый отчёт с объёмом выполненных работ
								</div>
							</div>
							<div className={cn.itemPart}>
								<img src={foreman2} className={cn.itemImage} />
								<div className={cn.itemLine}>
									<svg
										width="8"
										height="27"
										viewBox="0 0 8 27"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<line
											x1="4"
											x2="4"
											y2="21"
											stroke="#8864DA"
											strokeWidth="2"
										/>
										<rect y="19" width="8" height="8" rx="4" fill="#8864DA" />
									</svg>
								</div>
								<div className={cn.itemText}>Отчёт о завершённой работе</div>
							</div>
						</div>
					</div>
				</div>
				<div className={cn.iconWrapepr}>
					<svg width="20" height="29" viewBox="0 0 20 29" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M5.39561 24.751C4.9239 24.751 4.4623 24.5548 4.19076 24.1959C3.76887 23.6382 3.96556 22.8992 4.63027 22.5451C7.4461 21.0455 9.45611 18.4846 10.007 15.6951C10.7675 11.8443 8.62561 7.82164 4.67716 5.68514C4.01549 5.32708 3.82511 4.58695 4.2519 4.03183C4.67846 3.47662 5.56065 3.31671 6.22264 3.67495C11.112 6.32085 13.7632 11.3084 12.8197 16.0861C12.1382 19.5364 9.64802 22.7061 6.15834 24.5646C5.93042 24.6864 5.66582 24.751 5.39561 24.751ZM10.865 28.0486C15.5185 25.5703 18.8393 21.3431 19.7481 16.7409C21.0062 10.3704 17.4703 3.7195 10.9496 0.191181C10.2876 -0.167063 9.40542 -0.00715446 8.97885 0.548056C8.55206 1.10317 8.74245 1.84339 9.40411 2.20136C14.9838 5.22047 18.0102 10.9064 16.9354 16.3499C16.157 20.2914 13.3163 23.9099 9.33677 26.0292C8.67217 26.3832 8.47537 27.1223 8.89726 27.68C8.9975 27.8127 9.12795 27.9276 9.28114 28.0179C9.43433 28.1083 9.60725 28.1724 9.79001 28.2066C9.97278 28.2409 10.1618 28.2445 10.3462 28.2174C10.5307 28.1903 10.707 28.1329 10.865 28.0486ZM2.18988 19.8475C4.13234 18.8127 5.46372 17.1188 5.84263 15.1999C6.36026 12.5785 4.90693 9.84259 2.22611 8.39209C1.56466 8.03412 0.68215 8.19385 0.255472 8.74897C-0.171312 9.30408 0.0190716 10.0443 0.680845 10.4024C2.4203 11.3436 3.36438 13.1144 3.02985 14.8089C2.78692 16.0389 1.90158 17.1675 0.661695 17.828C-0.00301552 18.1821 -0.199707 18.9213 0.222183 19.4789C0.350887 19.6491 0.528858 19.7893 0.739523 19.8864C0.950188 19.9835 1.1867 20.0342 1.42704 20.034C1.69728 20.034 1.96192 19.9693 2.18988 19.8475Z" fill="#8864DA"/>
					</svg>
				</div>
				<HowItWorkSimpleItem
					number={3}
					title="Менеджер проверяет и принимает отчёты"
					image={manager2}
				/>
				<div className={cn.iconWrapepr}>
					<svg width="20" height="29" viewBox="0 0 20 29" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M5.39561 24.751C4.9239 24.751 4.4623 24.5548 4.19076 24.1959C3.76887 23.6382 3.96556 22.8992 4.63027 22.5451C7.4461 21.0455 9.45611 18.4846 10.007 15.6951C10.7675 11.8443 8.62561 7.82164 4.67716 5.68514C4.01549 5.32708 3.82511 4.58695 4.2519 4.03183C4.67846 3.47662 5.56065 3.31671 6.22264 3.67495C11.112 6.32085 13.7632 11.3084 12.8197 16.0861C12.1382 19.5364 9.64802 22.7061 6.15834 24.5646C5.93042 24.6864 5.66582 24.751 5.39561 24.751ZM10.865 28.0486C15.5185 25.5703 18.8393 21.3431 19.7481 16.7409C21.0062 10.3704 17.4703 3.7195 10.9496 0.191181C10.2876 -0.167063 9.40542 -0.00715446 8.97885 0.548056C8.55206 1.10317 8.74245 1.84339 9.40411 2.20136C14.9838 5.22047 18.0102 10.9064 16.9354 16.3499C16.157 20.2914 13.3163 23.9099 9.33677 26.0292C8.67217 26.3832 8.47537 27.1223 8.89726 27.68C8.9975 27.8127 9.12795 27.9276 9.28114 28.0179C9.43433 28.1083 9.60725 28.1724 9.79001 28.2066C9.97278 28.2409 10.1618 28.2445 10.3462 28.2174C10.5307 28.1903 10.707 28.1329 10.865 28.0486ZM2.18988 19.8475C4.13234 18.8127 5.46372 17.1188 5.84263 15.1999C6.36026 12.5785 4.90693 9.84259 2.22611 8.39209C1.56466 8.03412 0.68215 8.19385 0.255472 8.74897C-0.171312 9.30408 0.0190716 10.0443 0.680845 10.4024C2.4203 11.3436 3.36438 13.1144 3.02985 14.8089C2.78692 16.0389 1.90158 17.1675 0.661695 17.828C-0.00301552 18.1821 -0.199707 18.9213 0.222183 19.4789C0.350887 19.6491 0.528858 19.7893 0.739523 19.8864C0.950188 19.9835 1.1867 20.0342 1.42704 20.034C1.69728 20.034 1.96192 19.9693 2.18988 19.8475Z" fill="#8864DA"/>
					</svg>
				</div>
				<HowItWorkSimpleItem
					number={4}
					title="Все отчёты отображаются в личном кабинете Заказчика"
					image={tablet}
					className={cn.tablet}
				/>
			</div>
		</section>
	);
};

export default HowItWork;
