import React from 'react';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import {getIsLogin} from '../../../../../store/selectors';
import {workerPersonalLogOut} from '../../../../../store/actions/workerPersonalActions';


export default function HeaderPersonal() {
	const isLogin = useSelector(getIsLogin);
	const dispatch = useDispatch();

	const logOutHandler = async () => {
		await dispatch(workerPersonalLogOut);
		window.location.reload();
	}
	return (
		<div className="Header__personal">
			<Link to={'/personal'} className="Header__nav-link">
				<svg viewBox="0 0 18 20">
					<path
						d="M8.85745 9.63406C10.181 9.63406 11.3271 9.15936 12.2635 8.22278C13.1999 7.28635 13.6746 6.14057 13.6746 4.81688C13.6746 3.49364 13.1999 2.34771 12.2634 1.41097C11.3268 0.474699 10.1808 0 8.85745 0C7.53376 0 6.38798 0.474699 5.45155 1.41113C4.51512 2.34756 4.04027 3.49349 4.04027 4.81688C4.04027 6.14057 4.51512 7.2865 5.4517 8.22293C6.38828 9.1592 7.53421 9.63406 8.85745 9.63406Z"
						/>
					<path
						d="M17.2862 15.379C17.2592 14.9893 17.2046 14.5642 17.1242 14.1152C17.043 13.663 16.9385 13.2354 16.8134 12.8446C16.6841 12.4407 16.5083 12.0419 16.291 11.6597C16.0655 11.2629 15.8006 10.9175 15.5034 10.6332C15.1926 10.3358 14.812 10.0967 14.372 9.92229C13.9334 9.7488 13.4474 9.66091 12.9276 9.66091C12.7234 9.66091 12.526 9.74468 12.1446 9.99294C11.91 10.146 11.6355 10.323 11.3291 10.5188C11.0671 10.6857 10.7121 10.8421 10.2738 10.9837C9.84606 11.1221 9.4118 11.1923 8.98318 11.1923C8.55456 11.1923 8.12045 11.1221 7.69229 10.9837C7.25437 10.8422 6.89945 10.6858 6.63776 10.5189C6.33427 10.325 6.05961 10.148 5.82142 9.99279C5.44056 9.74453 5.24296 9.66076 5.0388 9.66076C4.51878 9.66076 4.03295 9.7488 3.59456 9.92245C3.15481 10.0965 2.7741 10.3357 2.46297 10.6334C2.16589 10.9178 1.90084 11.2631 1.67562 11.6597C1.45849 12.0419 1.28271 12.4406 1.15332 12.8448C1.02835 13.2356 0.923827 13.663 0.842651 14.1152C0.762237 14.5635 0.707611 14.9888 0.680603 15.3794C0.654053 15.7621 0.640625 16.1593 0.640625 16.5605C0.640625 17.6045 0.972502 18.4496 1.62695 19.073C2.27331 19.688 3.12856 20.0001 4.1686 20.0001H13.7987C14.8387 20.0001 15.6937 19.6882 16.3402 19.073C16.9948 18.4501 17.3267 17.6048 17.3267 16.5603C17.3265 16.1573 17.3129 15.7598 17.2862 15.379Z"
						/>
				</svg>
				{isLogin ? 'Личный кабинет' : 'Войти'}
			</Link>
			{
				isLogin &&
				<span className="Header__personal-exit Header__nav-link" onClick={logOutHandler}>
					Выход
				</span>
			}
		</div>
	);
}

