import React from 'react';
import ChartsTableRightCol from './ChartsTableRightCol';
import { format } from 'date-fns';
import { useChartsContext } from '../ChartsContext';

const ChartsTableRightRow = () => {
    const { dates, counts } = useChartsContext();
    return (
        <div className="d-flex align-items-center">
            {
                dates.map((date, idx) => {
                    const d = format(new Date(date), 'dd.MM.yyyy', new Date());
                    const value = counts[d]
                        ? counts[d].value
                        : '';
                    return (<ChartsTableRightCol
                        key={idx}
                        date={date}
                        value={value}
                    />);
                })
            }
        </div>
    );
};

export default ChartsTableRightRow;
