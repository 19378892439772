import React, {Fragment, useEffect, useState} from 'react';

import * as moment from "moment";
import 'moment/locale/ru';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import DraggableScroll from '../../../../components/UI/DraggableScroll/DraggableScroll';
import {getUserReports} from './functions';


// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
var keys = {37: 1, 38: 1, 39: 1, 40: 1};

function preventDefault(e) {
	e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
	if (keys[e.keyCode]) {
		preventDefault(e);
		return false;
	}
}

// modern Chrome requires { passive: false } when adding event
var supportsPassive = false;
try {
	window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
		get: function () {
			supportsPassive = true;
			return true
		}
	}));
} catch (e) {
}

var wheelOpt = supportsPassive ? {passive: false} : false;
var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

// call this to Disable
function disableScroll() {
	window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
	window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
	window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
	window.addEventListener('keydown', preventDefaultForScrollKeys, false);
}

// call this to Enable
function enableScroll() {
	window.removeEventListener('DOMMouseScroll', preventDefault, false);
	window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
	window.removeEventListener('touchmove', preventDefault, wheelOpt);
	window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
}


const WorkerPersonalReportsPhotos = ({user, masterId = null, order, reports, currentDay, setCurrentDay}) => {

	//const [reportedDates, setReportedDates] = useState([])
	const [photoReports, setPhotoReports] = useState([])
	const [currentPhotoReports, setCurrentPhotoReports] = useState([])
	const [masterReports, setMasterReports] = useState([]);

	function setGallery(currentDay) {
		let currentPhotoReports = photoReports[currentDay] ? photoReports[currentDay] : []
		setCurrentPhotoReports(currentPhotoReports)
		setCurrentDay(currentDay)
	}

	const renderPopup = (img) => {
		const MySwal = withReactContent(Swal)
		MySwal.fire({
			imageUrl: img,
			animation: false,
			showConfirmButton: false,
			showCloseButton: true,
			backdrop: `rgba(135, 100, 218, .9)`,
			customClass: {
				popup: 'PersonalOrderItemPhotoReports_popup'
			}
		})
	}

	const renderGallery = () => {
		return <>
			{
				!!currentPhotoReports.length
					?
					currentPhotoReports.map((photo, idx) => {
						return (
							<div
								key={idx}
								className="PersonalOrderItemPhotoReports_photos"
								onClick={() => renderPopup(photo)}>
								<LazyLoadImage
									alt={'фото отчета'}
									effect="blur"
									src={photo}/>
							</div>
						)
					})
					: <div className="h5 p-3">
						В этот день фотографии не были загружены
					</div>

			}
		</>
	}

	const getLastReport = (reportedDates) => {

		return reportedDates.reduce((max, current) => {
			let date1 = moment(max, "DD.MM.YYYY")
			let date2 = moment(current, "DD.MM.YYYY")
			return moment.max(date1, date2).format("DD.MM.YYYY")
		})

	}

	useEffect(() => {
		let startDate = moment(order.startDate, 'DD.MM.YYYY')
		let days = moment().diff(startDate, 'days')
		let photoReports = []
		const userReports = masterReports.filter((report) => {
			return report.master === masterId || report.master === user.id
		})
		console.log(masterReports);
		console.log(userReports);
		for (let i = 0; i <= days; i++) {
			let day = moment(startDate, "DD.MM.YYYY").add(i, 'd').format("DD.MM.YYYY")
			let dayReports = userReports.filter(report => report.date === day)
			let gallery = []
			dayReports.forEach(dayReport => {
				if (dayReport.photos && !!dayReport.photos.length) {
					dayReport.photos.forEach(photo => {
						gallery.push(photo)
					})
				}
			})
			if (!!gallery.length) {
				photoReports[day] = gallery
			}
		}

		let reportedDays = userReports.map(report => report.date)
		let reportedDates = reportedDays.filter((date, index, self) => {
			return self.indexOf(date) === index;
		})

		// console.log(getLastReport(reportedDates))
		let currentDay = !!reportedDates.length ? getLastReport(reportedDates) : ''
		setCurrentDay(currentDay)
		setPhotoReports(photoReports)
		//setReportedDates(reportedDates)
		setGallery(currentDay)

	}, [masterReports]);

	useEffect(() => {
		setGallery(currentDay)
	}, [currentDay])

	useEffect(() => {
		// console.log(reports);
		setMasterReports(reports)
	}, [reports])

	return (
		<div className="PersonalOrderItemPhotoReports">
			<div className="PersonalOrderItemPhotoReports_currentDay">Дата отчета: {currentDay}</div>
			<DraggableScroll>
				<div key={reports.length} className="PersonalOrderItemPhotoReports_gallery">
					{renderGallery()}
				</div>
			</DraggableScroll>
		</div>
	)

}
export default WorkerPersonalReportsPhotos;