import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker, { registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import styled from 'styled-components';
import { BsCalendarEvent } from 'react-icons/bs';
import { format, getDay, isBefore, parse } from 'date-fns';

registerLocale('ru', ru);

const StyledDatePicker = styled.div`
  position: relative;

  input {
    margin: 0.6rem 0 !important;
    background-color: #F1F2FA;
    width: 100%;
    padding: 10px 15px;
    border: none;
    height: 40px;
    border-bottom: 1px solid #D4C8F2;
  }

  .datepicker__icon {
    position: absolute;
    right: 15px;
    top: 38px;
    color: rgba(0, 0, 0, 0.54);
  }

  .react-datepicker {
    &__day {
      &--selected {
        background-color: #7b53da;
      }
    }
  }
`;

const FWDatePicker = ({ formControl = {}, onChange, options = {} }) => {
    const {
        value,
        id,
        label,
    } = formControl;
    const { addLabel, minDate } = options;
    const [selected, setSelected] = useState(new Date());
    const filterDates = (date) => {
        const weekDay = getDay(date);
        return weekDay !== 0;
    };

    useEffect(() => {
        let date = parse(value, 'dd.MM.yyyy', new Date());
        const currentMinDate = minDate || new Date();
        if (date) {
            if (isBefore(new Date(date), new Date(currentMinDate))) {
                date = currentMinDate;
                onChange(format(new Date(date), 'dd.MM.yyyy'), id);
            }
            setSelected(date);
        }
    }, [value, minDate, id]);

    return (
        <StyledDatePicker>
             <span id={`${id}-helper-text`}
                   className="worker_label">
                 {label} {addLabel}
             </span>
            <DatePicker
                locale="ru"
                selected={selected}
                onChange={(date) => onChange(format(new Date(date), 'dd.MM.yyyy'), id)}
                minDate={minDate || new Date()}
                dateFormat="dd.MM.yyyy"
                filterDate={filterDates}
                className="uiInputBase-input MuiInput-input MuiInputBase-inputAdornedEnd"
            />
            <BsCalendarEvent className="datepicker__icon" size={20} />
        </StyledDatePicker>
    );
};

export default FWDatePicker;
