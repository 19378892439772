import React from 'react';
import { useSelector } from 'react-redux';
import ChartsTableWorker from './ChartsTableWorker';
import { COLORS } from '../config';

const ChartsTableWorkers = ({ serviceOpen }) => {
    const { changedData } = useSelector(state => state.estimateOrder);
    return (
        <div>
            {
                changedData.map((service, idx) => {
                    const color = COLORS[idx % 10];
                    return (
                        <ChartsTableWorker
                            key={service.id}
                            service={service}
                            color={color}
                            serviceOpen={serviceOpen}
                        />
                    );
                })
            }
        </div>
    );
};

export default ChartsTableWorkers;
