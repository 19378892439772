import React, { useState } from 'react';
import ChartsTable from './components/ChartsTable';
import ChartsTableLeft from './components/ChartsTableLeft';
import ChartsTableRight from './components/ChartsTableRight';
import ChartsTableHeadTitle from './components/ChartsTableHeadTitle';
import ChartsTableHeadDates from './components/ChartsTableHeadDates';
import ChartsTableLeftServices from './components/ChartsTableLeftServices';
import ChartsTableRanges from './components/ChartsTableRanges';
import { CHART_VOLUMES, TITLE_COLS } from './config';

const ChartsVolumes = () => {
    const [serviceOpen, setServiceOpen] = useState(null);
    return (
        <ChartsTable>
            <ChartsTableLeft>
                <ChartsTableHeadTitle titleCols={TITLE_COLS} />
                <ChartsTableLeftServices
                    serviceOpen={serviceOpen}
                    setServiceOpen={setServiceOpen}
                />
            </ChartsTableLeft>
            <ChartsTableRight table={CHART_VOLUMES}>
                <ChartsTableHeadDates />
                <ChartsTableRanges serviceOpen={serviceOpen} />
            </ChartsTableRight>
        </ChartsTable>
    );
};

export default ChartsVolumes;
