import React, { useEffect } from 'react';
import BackBtn from '../../../../../components/UI/BackBtn/BackBtn';
import ChartsSectionTitle from './components/ChartsSectionTitle';
import ChartsVolumes from './ChartsVolumes';
import ChartsResources from './ChartsResources';
import ChartsWorkerCounts from './ChartsWorkerCounts';
import ChartsWorkers from './ChartsWorkers';
import ChartsFinance from './ChartsFinance';
import ChartsNotification from './components/ChartsNotification';
import { useHistory } from 'react-router-dom';
import { setActiveNavAnchor } from '../../../../../store/actions/navActions';
import { CHARTS_ANCHOR } from '../../../../../config';
import { useDispatch } from 'react-redux';

const ChartsTune = ({ setPage }) => {
    const { push } = useHistory();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setActiveNavAnchor(CHARTS_ANCHOR));
    }, []);
    return (
        <div id={CHARTS_ANCHOR}>
            <BackBtn onClick={() => push('/complex-works')} variant="dark" />
            <ChartsSectionTitle>График работ с объемами выполнения</ChartsSectionTitle>
            <ChartsVolumes />

            <ChartsSectionTitle>График трудовых ресурсов</ChartsSectionTitle>
            <ChartsResources />

            <ChartsSectionTitle>Гистограмма трудовых ресурсов</ChartsSectionTitle>
            <ChartsWorkerCounts />
            <ChartsWorkers />

            <ChartsSectionTitle>График финансового выполнения</ChartsSectionTitle>
            <ChartsFinance />
            <ChartsSectionTitle>Существенные договорные условия работы:</ChartsSectionTitle>
            <ChartsNotification setPage={setPage} />
        </div>
    );
};

export default ChartsTune;
