import React, {useState} from 'react';

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TooltipBtn from "../../../../components/UI/TooltipBtn/TooltipBtn";
import {renderFormControl} from "../../../../form/formFramework";

const WorkerRecommendations = ({controls, formControlHandler}) => {
    const [recommendation, setRecommendation] = useState(false)

    return (
        <Paper className='p-3 mt-2'>
            <Typography variant="button" display="block" className='py-3'>
                Рекомендации
            </Typography>
            <div className='RegisterSpecialist_btns mt-2 row'>
                <div className="col-12 col-sm-6 col-lg-3 pr-sm-2">
                    {/*<div*/}
                    {/*    // onClick={() => setSpecialistProperty(true, 'recommendationsExist')}*/}
                    {/*    className={` RegisterSpecialist_btns-btn withTooltip ${recommendationsExist ? `active` : ``}`}>*/}
                    {/*    <div>Есть</div>*/}
                    {/*    <TooltipBtn content={"Напишите того, кто мог Вас порекомендовать"}*/}
                    {/*                active={recommendationsExist}/>*/}
                    {/*</div>*/}

                    <Button
                        variant="contained"
                        color={recommendation ? `primary` : `default`}
                        onClick={() => setRecommendation(true)}
                        className='w-100 py-2'>
                        <div>Есть</div>
                        <TooltipBtn content={"Напишите того, кто мог Вас порекомендовать"}
                                    active={recommendation}/>
                    </Button>


                </div>
                <div className="col-12 col-sm-6 col-lg-3 pl-sm-2 mt-2 mt-sm-0">
                    <Button
                        variant="contained"
                        color={recommendation ? `default` : `primary`}
                        onClick={() => setRecommendation(false)}
                        className='w-100 py-2'>
                        <div>Нет</div>
                    </Button>
                </div>
            </div>
            {
                recommendation &&
                <div className="row">
                    {
                        Object.keys(controls).map(property => {
                            const control = controls[property]
                            return (
                                <div key={control.id} className="col-12 mt-3">
                                    <div className="wc-input mt-2" key={control.id}>
                                        {renderFormControl(control, formControlHandler)}
                                    </div>
                                </div>


                            )
                        })
                    }
                </div>
            }
        </Paper>
    );
};

export default WorkerRecommendations;

