import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { getOrderState, getServiceSelectState } from '../../../../store/selectors';
import { renderFormControl } from '../../../../form/formFramework';
import {
    setDefaultMaterialQuantity,
    setOrderFormControlValue,
    setOrderProperty
} from '../../../../store/actions/orderActions';
import { setServiceSelectProperty } from '../../../../store/actions/serviceSelectActions';
import IndividualWorksChoice from './IndividualWorksChoice';
import IndividualWorksTop from './IndividualWorksTop';

import { useConditionsContext } from '../ConditionsContext';
import FWDatePicker from '../../../ui/FWDatePicker/FWDatePicker';

const IndividualWorksConditions = ({ formValidHandler }) => {
    const dispatch = useDispatch();
    const {
        needMaterialsTabs,
        workTypeTabs
    } = useSelector(getServiceSelectState);
    const {
        workType,
        needMaterials,
        quantity,
        formControls,
        dueDate,
        currentService,
        currentCategory,
    } = useSelector(getOrderState);
    const { dayNorm } = useSelector(state => state.adminService);
    const { basePrice } = useConditionsContext();

    const formControlHandler = (value, property) => {
        dispatch(setOrderProperty(value, property));
        dispatch(setOrderFormControlValue(value, property));
        formValidHandler();
        if (property === 'quantity') {
            dispatch(setDefaultMaterialQuantity());
        }
        dispatch(setServiceSelectProperty(true, 'formTouched'));
        localStorage.setItem(property, value);
    };

    const tabHandler = (value, property) => {
        dispatch(setOrderProperty(value, property));
    };

    useEffect(() => {
        if (currentService) {
            let workerNorm = 0;
            if (!!currentService.items && !!currentService.items.length) {
                currentService.items.forEach(item => {
                    item.norms.forEach(norm => {
                        workerNorm += norm.norm * norm.cc;
                    });
                });
            } else {
                currentService.norms.forEach(norm => {
                    workerNorm += norm.norm * norm.cc;
                });
            }

            let days = !!(+dueDate) ? dueDate : 1;
            const count = Math.ceil(quantity * workerNorm / (days * dayNorm));
            if (count) {
                dispatch(setOrderProperty(count, 'workerCount'));
            }
        }
    }, [dispatch, currentService, quantity, dueDate, dayNorm, setOrderProperty]);
    return (
        <div className="IndividualWorks__conditions">
            {
                !!currentCategory && !!currentService &&
                <> <IndividualWorksTop name={currentService.name} picture={currentCategory.picture} price={basePrice}
                                       service={currentService} />
                    <div className="IndividualWorks__conditions-desc">
                        Укажите объем работ, сроки выполнения, дату начала работ,
                        наличие материалов и уровень работ для расчета стоимости и назначении количества рабочих
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-4 mt-4 d-flex flex-column">
                            {renderFormControl(formControls.quantity, formControlHandler, {
                                addLabel: parse(currentService.unit),
                            })}
                        </div>
                        <div className="col-12 col-lg-4 mt-4 d-flex flex-column">
                            {/*{renderFormControl(formControls.startDate, formControlHandler,)}*/}
                            <FWDatePicker formControl={formControls.startDate} onChange={formControlHandler}/>
                        </div>
                        <div className="col-12 col-lg-4 mt-4 d-flex flex-column">
                            {renderFormControl(formControls.dueDate, formControlHandler,)}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-6">
                            <IndividualWorksChoice
                                onClick={tabHandler}
                                choices={workTypeTabs}
                                active={workType}
                                property="workType"
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <IndividualWorksChoice
                                onClick={tabHandler}
                                choices={needMaterialsTabs}
                                active={needMaterials}
                                property="needMaterials"
                            />
                        </div>
                    </div>
                </>
            }
        </div>
    );
};
export default IndividualWorksConditions;