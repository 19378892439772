import React from 'react';
import { renderFormControl } from '../../../../../form/formFramework';
import DraggableScroll from '../../../../../components/UI/DraggableScroll/DraggableScroll';
import PaysTranches from './PaysTranches';
import { useSelector } from 'react-redux';
import PaysPeriod from './PaysPeriod';

const PaysPercents = ({ inputHandler }) => {
    const { formControls: { percents, period } } = useSelector(state => state.confirmOrder);
    const percentHandler = (value, property) => {
        let inputValue = +value;
        if (+value > 100) {
            inputValue = 100;
        }
        inputHandler(inputValue, property);
    };
    return (
        <div className="ConfirmOrder container position-relative mt-3">

            <div className="row mt-4 align-items-center justify-content-center">
                <div className="col-4">
                    {renderFormControl(percents, percentHandler)}
                </div>

                <div className="col-4">
                    {renderFormControl(period, inputHandler, period.options)}
                </div>
            </div>

            <div className="ConfirmOrderTranches">
                <div className="ConfirmOrderTranches_LeftBlock border-right border-main-1-3">
                    <div className="ConfirmOrderTranches_LeftBlock__title">Плановый график выполнения</div>
                    <div className="d-flex align-content-center justify-content-end h70">
                        {/*<div className="w-10"/>*/}
                        {/*<div className="w-70 pl-2 d-flex align-items-center">*/}
                        {/*    Работы*/}
                        {/*</div>*/}
                        <div
                            className="w-30 pl-3 d-flex  align-items-center">Ед.<br />изм
                        </div>
                    </div>
                    <div className="position-relative d-flex align-items-center w-100">
                        <div className="w-70">
                            <div
                                className="border-bottom border-main-1-3 d-flex align-items-center h50 pl-2">
                                Фин. выполнение (Работы + материалы)
                            </div>
                            <div className="border-bottom border-main-2-1 d-flex align-items-center h50 pl-2">
                                Удержание аванса
                            </div>
                        </div>

                        <div className="w-30">
                            <div
                                className="pl-3 border-bottom border-main-1-3 d-flex align-items-center h50">м2
                            </div>
                            <div
                                className="pl-3 border-bottom border-main-2-1 d-flex align-items-center h50">руб.
                            </div>
                        </div>
                    </div>
                    <div className="position-relative d-flex align-items-center bg-white w-100">
                        <div className="w-70">
                            <div
                                className="border-bottom border-main-1-3 d-flex align-items-center h50 pl-2">
                                Для оплаты
                            </div>
                            <div
                                className="border-bottom border-main-1-3 d-flex align-items-center h50 pl-2">
                                <strong>Всего:</strong>
                            </div>
                        </div>

                        <div className="w-30">
                            <div
                                className="pl-3 border-bottom border-main-1-3 d-flex align-items-center h50">м2
                            </div>
                            <div
                                className="pl-3 border-bottom border-main-1-3 d-flex align-items-center h50">руб.
                            </div>
                        </div>
                    </div>
                </div>
                <DraggableScroll>
                    <div className="ConfirmOrderTranches_RightBlock pb-3">
                        <PaysPeriod />
                    </div>
                </DraggableScroll>
            </div>
        </div>
    );
};

export default PaysPercents;
