import {combineReducers} from 'redux'

import sliderHomePageReducer from "./sliderHomePageReducer";
import serviceSelectReducer from "./serviceSelectReducer"
import workerSelectReducer from "./workerSelectReducer";
import confirmOrderReducer from "./confirmOrderReducer";
import adminServiceReducer from "./adminServiceReducer";
import serviceCalcReducer from './serviceCalcReducer'
import specialistReducer from "./specialistReducer";
import materialsReducer from "./materialsReducer";
import orderListReducer from "./orderListReducer";
import deliveryReducer from "./deliveryReducer";
import contactsReducer from "./contactsReducer";
import personalReducer from "./personalReducer";
import complexReducer from "./complexReducer";
import workersReducer from "./workersReducer";
import apiDocsReducer from "./apiDocsReducer";
import dialogReducer from "./dialogReducer";
import orderReducer from './orderReducer'
import adminReducer from "./adminReducer";
import authReducer from "./authReducer";
import navReducer from "./navReducer";
import adminServiceListReducer from "./adminServiceListReducer";
import adminMaterialListReducer from "./adminMaterialListReducer";
import adminProfessionalListReducer from "./adminProfessionalListReducer";
import workerCreateReducer from "./workerCreateReducer";
import adminDatesReducer from "./adminDatesReducer";
import adminMastersList from "./adminMastersListReducer";
import workerPersonalReducer from "./workerPersonalReducer";
import adminGoodsReducer from "./adminGoodsReducer";
import homePageAboutReducer from "./homePageAboutReducer";
import adminOrderListReducer from "./adminOrderListReducer";
import adminOrderListTasksReducer from './adminOrderListTasksReducer';
import adminClientsReducer from './adminClientsReducer';
import adminClassifiersReducer from './adminClassifiersReducer';
import adminWorkerPersonalReducer from './adminWorkerPersonalReducer';
import estimateReducer from './estimateReducer';
import estimateOrderReducer from './estimateOrderReducer';
import countSliderReducer from './countSliderReducer';
import modalReducer from './modalReducer';
import workersSliderReducer from './workersSliderReducer';
import inspectionFormReducer from './inspectionFormReducer';
import projectCalcReducer from './projectCalcFormReducer';
import ganttReducer from './ganttReducer'

export default combineReducers({
    apiDocs              : apiDocsReducer,
    nav                  : navReducer,
    sliderHomePage       : sliderHomePageReducer,
    contacts             : contactsReducer,
    confirmOrder         : confirmOrderReducer,
    serviceCalc          : serviceCalcReducer,
    materials            : materialsReducer,
    orderList            : orderListReducer,
    delivery             : deliveryReducer,
    personal             : personalReducer,
    workers              : workersReducer,
    complex              : complexReducer,
    dialog               : dialogReducer,
    auth                 : authReducer,
    specialist           : specialistReducer,
    admin                : adminReducer,
    adminService         : adminServiceReducer,
    adminServiceList     : adminServiceListReducer,
    adminMaterialList    : adminMaterialListReducer,
    adminProfessionalList: adminProfessionalListReducer,
    workerCreate         : workerCreateReducer,
    order                : orderReducer,
    adminDates           : adminDatesReducer,
    adminMastersList     : adminMastersList,
    workerPersonal       : workerPersonalReducer,
    adminGoods           : adminGoodsReducer,
    homePageAbout        : homePageAboutReducer,
    adminOrderList       : adminOrderListReducer,
    workerSelect         : workerSelectReducer,
    adminOrderListTasks  : adminOrderListTasksReducer,
    adminClients         : adminClientsReducer,
    adminClassifiers     : adminClassifiersReducer,
    adminWorkerPersonal  : adminWorkerPersonalReducer,
    serviceSelect        : serviceSelectReducer,
    estimate             : estimateReducer,
    estimateOrder        : estimateOrderReducer,
    categorySlider       : countSliderReducer,
    modal                : modalReducer,
    workersSlider        : workersSliderReducer,
    inspectionForm       : inspectionFormReducer,
    projectCalc          : projectCalcReducer,
    gantt                : ganttReducer,
})