import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Slider from "react-slick";
import { CALCULATOR_ANCHOR } from "../../../config";
import { setActiveNavAnchor } from "../../../store/actions/navActions";
import cn from "./CategoryList.module.scss";

const CategoryListSlider = (props) => {
	const settings = {
		draggable: false,
		infinite: true,
		arrows: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 6000,
		afterChange(index) {
			props.beforeChange(props.categories[index].id);
		},
	};
	const dispatch = useDispatch();
	const history = useHistory();

	const gotoCategory = (id) => {
		dispatch(setActiveNavAnchor(CALCULATOR_ANCHOR))
		history.replace('/')
	};

	const nextCategories = () => {
		sliderCategories.current.slickNext();
	};
	const previousCategories = () => {
		sliderCategories.current.slickPrev();
	};
	const sliderCategories = useRef();

	return (
		<div className={cn.categoriesWrapper}>
			<Slider {...settings} ref={sliderCategories}>
				{props.categories.map((s) => (
					<div onClick={() => gotoCategory(s.id)} key={s.id}>
						<div className={cn.categoriesItem}>
							{s.picture && (
								<img className={cn.categoriesImage} src={s.picture} />
							)}
							<div className={cn.categoriesName}>{s.name}</div>
						</div>
					</div>
				))}
			</Slider>
			<div className={cn.categoriesArrows}>
				<div className={cn.categoriesArrow} onClick={() => previousCategories()}>
					<svg
						width="24"
						height="37"
						viewBox="0 0 24 37"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M18.75 0L23.1562 4.3475L8.84374 18.5L23.1562 32.6525L18.75 37L0 18.5L18.75 0Z"
							fill="black"
							fillOpacity="0.7"
						/>
					</svg>
				</div>
				<div className={cn.categoriesArrow} onClick={() => nextCategories()}>
					<svg
						width="24"
						height="37"
						viewBox="0 0 24 37"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M4.40626 0L9.6941e-06 4.3475L14.3125 18.5L9.6941e-06 32.6525L4.40626 37L23.1562 18.5L4.40626 0Z"
							fill="black"
							fillOpacity="0.7"
						/>
					</svg>
				</div>
			</div>
		</div>
	);
};

export default CategoryListSlider;
