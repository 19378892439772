import React, {useEffect, useState} from 'react';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {renderFormControl} from '../../../../form/formFramework';

const WorkerTypes = ({checkBoxHandler, formData, controls, formControlHandler}) => {

	const data = {
		methodOfWork: [
			{
				id: 1,
				name: 'По объему',
				checked: true
			},
			{
				id: 2,
				name: 'Почасовой',
				checked: false
			}
		],
		typeOfWork: [
			{
				id: 1,
				name: 'Стандарт',
				checked: true
			},
			{
				id: 2,
				name: 'Премиум',
				checked: false
			}
		]
	};

	const [types, setTypes] = useState(data);
	const [priceDisabled, setPriceDisabled] = useState(false);

	useEffect(() => {

		if (formData) {
			let data = {...types};

			Object.keys(formData).forEach(key => {
				if (data[key]) {
					data[key].forEach(type => {
						type.checked = formData[key].indexOf(type.id) !== -1;
					})
				}
			});
			setTypes(data)
		}

	}, [formData]);

	useEffect(() => {
		const checkbox = types.methodOfWork.find(type => type.id === 2);
		if(!checkbox.checked) {
			formControlHandler('', 'workerPrice')
		}
		setPriceDisabled(!checkbox.checked)

	}, [types])


	return (
		<div className='mt-2 d-flex flex-column flex-sm-row'>
			<div className="col-12 col-sm-8 px-0 pr-sm-1">
				<Paper>
					<Typography variant="button" display="block" className='pt-3 px-3'>
						Метод работы
					</Typography>
					<div className="d-flex align-items-center">
						<List className='mt-2 row col-8'>
							{
								types.methodOfWork.map(item => {
									return (
										<div key={item.id} className="col-12 col-sm-6 ">
											<ListItem className='py-0'>
												<FormControlLabel
													control={
														<Checkbox
															value={item.id}
															checked={item.checked}
															onChange={(e) => checkBoxHandler(e.target.value, 'methodOfWork')}
															color="primary"
														/>
													}
													label={item.name}
												/>
											</ListItem>
										</div>
									)
								})
							}
						</List>
						<div className="d-flex align-items-center">
							<div className={`mr-3 ${priceDisabled ? 'text-secondary' : ''}`}>Цена за час</div>
							<div className="wc-input">
								{renderFormControl(controls.workerPrice, formControlHandler, {disabled: priceDisabled})}
							</div>
						</div>
					</div>
				</Paper>
			</div>

			<div className="col-12 col-sm-4 px-0 pl-sm-1 mt-2 mt-sm-0">
				<Paper>
					<Typography variant="button" display="block" className='pt-3 px-3'>
						Уровень работ
					</Typography>
					<List className='mt-2 row'>
						{
							types.typeOfWork.map(item => {
								return (
									<div key={item.id} className="col-12 col-sm-6 ">
										<ListItem className='py-0'>
											<FormControlLabel
												control={
													<Checkbox
														value={item.id}
														checked={item.checked}
														onChange={(e) => checkBoxHandler(e.target.value, 'typeOfWork')}
														color="primary"
													/>
												}
												label={item.name}
											/>
										</ListItem>
									</div>
								)
							})
						}
					</List>
				</Paper>
			</div>

		</div>
	);
};

export default WorkerTypes;
