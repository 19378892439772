import React from 'react';
import {connect} from "react-redux";
import DraggableScroll from "../../../../components/UI/DraggableScroll/DraggableScroll";
import WorkerListLineRequest from "../WorkerPersonalList/WorkerListLineRequest";
import WorkerListHeaderRequest from "../WorkerPersonalList/WorkerListHeaderRequest";

const WorkerPersonalRequest = ({workers}) => {
    return (
        <DraggableScroll>
            <WorkerListHeaderRequest/>
            {
                !!workers &&
                <>
                    {
                        workers.map((worker, idx) => <WorkerListLineRequest key={worker.id} idx={idx + 1}
                                                                     master={worker}/>)
                    }
                </>
            }
        </DraggableScroll>
    );
};

const mapState = state => {
    return {
        workers: state.workerPersonal.user.requestWorkers
    }
};


export default connect(mapState)(WorkerPersonalRequest);