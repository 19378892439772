import React from 'react'

function WorkKindItem({label, image, id, onClick, selectedWorkKind}) {
  return (
    <div
      className='col-12 col-lg-4'
      onClick={() => onClick(id)}
    >
      <div className={`estimate-work-kind-item ${selectedWorkKind === id ? 'active' : ''}`}>
        <img className='estimate-work-kind-image mb-3' src={image} alt={label} />
        <div className="estimate-work-kind-text">
          {label}
        </div>
      </div>
    </div>
  )
}

export default WorkKindItem
