import {
    FETCH_USER_ERROR,
    FETCH_USER_START,
    FETCH_USER_SUCCESS,
    SWITCH_TAB_PERSONAL
} from "../actions/actionTypes";

const initialState = {
    currentTab: 1,
    personalTabs: [
        {
            id: 1,
            name: 'Заказы',
            to: 'orders'
        },
        // {
        //     id: 2,
        //     name: 'Личные данные',
        //     to: 'private'
        // },
        // {
        //     id: 3,
        //     name: 'Правила работы',
        //     to: 'rules'
        // },
    ],
    userData: {},
    isLogin: false
}

export default function personalReducer(state = initialState, action) {
    switch (action.type) {
        case SWITCH_TAB_PERSONAL:
            return {
                ...state,
                currentTab: action.payload
            }
        case FETCH_USER_START:
            return {
                ...state,
                loading: true
            }
        case FETCH_USER_SUCCESS:
            return {
                ...state,
                userData: action.userData,
                loading: false
            }
        case FETCH_USER_ERROR:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        default:
            return state
    }
}