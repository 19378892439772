import React, {useState} from 'react';
import {Accordion, AccordionDetails, Paper} from '@material-ui/core';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AdminOrderListFinancePayed from '../AdminOrderListFinanceOne/AdminOrderListFinancePayed';

import AdminOrderListFinanceCompanyTotalPayed from '../AdminOrderListFinanceCompanyTotalPayed';
import AdminOrderListFinanceCompanyTotalDone from '../AdminOrderListFinanceCompanyTotalDone';


const AdminOrderListFinanceCompanies = ({order}) => {
	const [expanded, setExpanded] = useState(null);
	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};
	return (
		<div className="d-flex flex-column">
			<div className="d-flex align-items-center justify-content-end p-3 px-4 border-bottom border-top mb-1 bg-white">
				<div>Расчетное количество мастеров: 10</div>
				<div className="ml-3">Суммарное фактическое количество мастеров: 10</div>
			</div>
			<Accordion expanded={expanded === 1} onChange={handleChange(1)} className="mt-2">
				<AccordionSummary
					expandIcon={<ExpandMoreIcon/>}
					aria-controls="panel1bh-content"
					id="panel1bh-header"
					className="AdminOrderList__finance-accordion-head"
				>
					<div className="w-100 d-flex align-items-center justify-content-between">
						<strong className="w-50">Компания № 1</strong>
						<strong className="w-50 text-left">ООО "Кирпич"</strong>
					</div>
				</AccordionSummary>
				<AccordionDetails className="px-1 pb-2 bg-light">
					<div className="row w-100 p-0 m-0">
						<div className="col-6 px-1 d-flex flex-column">
							<ul className="px-0 m-0">
								<li className="AdminOrderList__finance-li">
									<Paper
										className="h-100 d-flex align-items-center justify-content-between py-2 pl-3 pr-5">
										<div>Расчетное количество мастеров</div>
										<div>20</div>
									</Paper>
								</li>
							</ul>
						</div>
						<div className="col-6 px-1 d-flex flex-column">
							<ul className="px-0 m-0">
								<li className="AdminOrderList__finance-li">
									<Paper
										className="h-100 d-flex align-items-center justify-content-between py-2 pl-3 pr-5">
										<div>Фактическое количество мастеров</div>
										<div>18</div>
									</Paper>
								</li>
							</ul>
						</div>
						<div className="col-12 px-1 d-flex flex-column">
							<ul className="px-0 m-0">
								<li className="AdminOrderList__finance-li mt-2">
									<Paper
										className="h-100 d-flex align-items-center justify-content-between py-2 pl-3 pr-5">
										<div>Представитель компании по проекту</div>
										<div>Иван Иванович Петров</div>
										<div>+7 (922) 543-55-66</div>
										<div>ivanov1983@gmail.com</div>
									</Paper>
								</li>
							</ul>
						</div>
						<div className="col-6 px-1 d-flex flex-column">
							<ul className="px-0 m-0">
								<li className="AdminOrderList__finance-li mt-2">
									<Paper
										className="h-100 d-flex align-items-center justify-content-between py-2 pl-3 pr-5">
										<div>Сумма заказа</div>
										<div>200 000</div>
									</Paper>
								</li>
							</ul>
							<AdminOrderListFinanceCompanyTotalPayed order={order}/>
						</div>
						<div className="col-6 px-1 d-flex flex-column">
							<ul className="px-0 m-0">
								<li className="AdminOrderList__finance-li mt-2">
									<Paper
										className="h-100 d-flex align-items-center justify-content-between py-2 pl-3 pr-5">
										<div>Сумма заказа</div>
										<div>200 000</div>
									</Paper>
								</li>
							</ul>
							<AdminOrderListFinanceCompanyTotalDone order={order}/>
						</div>
					</div>
				</AccordionDetails>
			</Accordion>

			<Accordion expanded={expanded === 2} onChange={handleChange(2)} className="mt-2">
				<AccordionSummary
					expandIcon={<ExpandMoreIcon/>}
					aria-controls="panel1bh-content"
					id="panel1bh-header"
					className="AdminOrderList__finance-accordion-head"
				>
					<div className="w-100 d-flex align-items-center justify-content-between">
						<strong className="w-50">Компания № 2</strong>
						<strong className="w-50 text-left">ООО "Кирпич"</strong>
					</div>
				</AccordionSummary>
				<AccordionDetails className="px-1 pb-2 bg-light">
					<div className="row w-100 p-0 m-0">
						<div className="col-6 px-1 d-flex flex-column">
							<ul className="px-0 m-0">
								<li className="AdminOrderList__finance-li">
									<Paper
										className="h-100 d-flex align-items-center justify-content-between py-2 pl-3 pr-5">
										<div>Расчетное количество мастеров</div>
										<div>20</div>
									</Paper>
								</li>
							</ul>
						</div>
						<div className="col-6 px-1 d-flex flex-column">
							<ul className="px-0 m-0">
								<li className="AdminOrderList__finance-li">
									<Paper
										className="h-100 d-flex align-items-center justify-content-between py-2 pl-3 pr-5">
										<div>Фактическое количество мастеров</div>
										<div>18</div>
									</Paper>
								</li>
							</ul>
						</div>
						<div className="col-12 px-1 d-flex flex-column">
							<ul className="px-0 m-0">
								<li className="AdminOrderList__finance-li mt-2">
									<Paper
										className="h-100 d-flex align-items-center justify-content-between py-2 pl-3 pr-5">
										<div>Представитель компании по проекту</div>
										<div>Иван Иванович Петров</div>
										<div>+7 (922) 543-55-66</div>
										<div>ivanov1983@gmail.com</div>
									</Paper>
								</li>
							</ul>
						</div>
						<div className="col-6 px-1 d-flex flex-column">
							<ul className="px-0 m-0">
								<li className="AdminOrderList__finance-li mt-2">
									<Paper
										className="h-100 d-flex align-items-center justify-content-between py-2 pl-3 pr-5">
										<div>Сумма заказа</div>
										<div>200 000</div>
									</Paper>
								</li>
							</ul>
							<AdminOrderListFinanceCompanyTotalPayed order={order}/>
						</div>
						<div className="col-6 px-1 d-flex flex-column">
							<ul className="px-0 m-0">
								<li className="AdminOrderList__finance-li mt-2">
									<Paper
										className="h-100 d-flex align-items-center justify-content-between py-2 pl-3 pr-5">
										<div>Сумма заказа</div>
										<div>200 000</div>
									</Paper>
								</li>
							</ul>
							<AdminOrderListFinanceCompanyTotalDone order={order}/>
						</div>
					</div>
				</AccordionDetails>
			</Accordion>

			<Accordion expanded={expanded === 3} onChange={handleChange(3)} className="mt-2">
				<AccordionSummary
					expandIcon={<ExpandMoreIcon/>}
					aria-controls="panel1bh-content"
					id="panel1bh-header"
					className="AdminOrderList__finance-accordion-head"
				>
					<div className="w-100 d-flex align-items-center justify-content-between">
						<strong className="w-50">Компания № 3</strong>
						<strong className="w-50 text-left">ООО "Кирпич"</strong>
					</div>
				</AccordionSummary>
				<AccordionDetails className="px-1 pb-2 bg-light">
					<div className="row w-100 p-0 m-0">
						<div className="col-6 px-1 d-flex flex-column">
							<ul className="px-0 m-0">
								<li className="AdminOrderList__finance-li">
									<Paper
										className="h-100 d-flex align-items-center justify-content-between py-2 pl-3 pr-5">
										<div>Расчетное количество мастеров</div>
										<div>20</div>
									</Paper>
								</li>
							</ul>
						</div>
						<div className="col-6 px-1 d-flex flex-column">
							<ul className="px-0 m-0">
								<li className="AdminOrderList__finance-li">
									<Paper
										className="h-100 d-flex align-items-center justify-content-between py-2 pl-3 pr-5">
										<div>Фактическое количество мастеров</div>
										<div>18</div>
									</Paper>
								</li>
							</ul>
						</div>
						<div className="col-12 px-1 d-flex flex-column">
							<ul className="px-0 m-0">
								<li className="AdminOrderList__finance-li mt-2">
									<Paper
										className="h-100 d-flex align-items-center justify-content-between py-2 pl-3 pr-5">
										<div>Представитель компании по проекту</div>
										<div>Иван Иванович Петров</div>
										<div>+7 (922) 543-55-66</div>
										<div>ivanov1983@gmail.com</div>
									</Paper>
								</li>
							</ul>
						</div>
						<div className="col-6 px-1 d-flex flex-column">
							<ul className="px-0 m-0">
								<li className="AdminOrderList__finance-li mt-2">
									<Paper
										className="h-100 d-flex align-items-center justify-content-between py-2 pl-3 pr-5">
										<div>Сумма заказа</div>
										<div>200 000</div>
									</Paper>
								</li>
							</ul>
							<AdminOrderListFinanceCompanyTotalPayed order={order}/>
						</div>
						<div className="col-6 px-1 d-flex flex-column">
							<ul className="px-0 m-0">
								<li className="AdminOrderList__finance-li mt-2">
									<Paper
										className="h-100 d-flex align-items-center justify-content-between py-2 pl-3 pr-5">
										<div>Сумма заказа</div>
										<div>200 000</div>
									</Paper>
								</li>
							</ul>
							<AdminOrderListFinanceCompanyTotalDone order={order}/>
						</div>
					</div>
				</AccordionDetails>
			</Accordion>

		</div>
	);
};

export default AdminOrderListFinanceCompanies;