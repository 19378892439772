import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./Estimate-Step5.scss";
import EstimateButton from "../../../UI/EstimateButton";
import {
    addServices,
    estimateChangeServiceAnimateItems, nextStep,
    prevStep,
} from "../../../../../../../store/actions/estimateActions";
import EstimateProcess from "../../Process/EstimateProcess";
import SubCategoryitem from "./SubCategoryitem";
import { useHistory } from 'react-router-dom';

const Step5 = (props) => {
    const {
        selectedCategoryId,
        services,
        categories,
        subCategories,
        estimateChangeServiceAnimateItems,
    } = props;


    const selectedCategory = categories.find((c) => c.id === selectedCategoryId);

    const [preOrder, setPreOrder] = useState({
        category: null,
        subCategories: [],
        services: [],
    });

    const [selectedSubCategories, setSelectedSubCategories] = useState([]);

    const { push } = useHistory();

    const subCategoryClickHandler = (id) => {
        let tempSelectedSubCategories = [...selectedSubCategories];
        if (tempSelectedSubCategories.includes(id)) {
            tempSelectedSubCategories.splice(
                tempSelectedSubCategories.indexOf(id),
                1
            );
        } else {
            tempSelectedSubCategories.push(id);
        }
        setSelectedSubCategories(tempSelectedSubCategories);
    };

    const serviceCheckhandler = (id, e) => {
        const tempPreOrder = { ...preOrder };
        const service = tempPreOrder.services.find((s) => s.id === id);
        service.checked = !service.checked;
        estimateChangeServiceAnimateItems({ id: service.id, name: service.name });
        setPreOrder(tempPreOrder);
    };

    const serviceCountHandler = (id, value) => {
        const tempPreOrder = { ...preOrder };
        // if (value === "") {
        //   tempPreOrder.services.find((s) => s.id === id).count = 0;
        //   setPreOrder(tempPreOrder);
        //   return;
        // }
        const reg = /^\d+\.?\d{0,2}$/;
        if (!reg.test(value) && value) return;
        tempPreOrder.services.find((s) => s.id === id).count = value;
        setPreOrder(tempPreOrder);
    };

    const countInputBlurHandler = (id, value) => {
        const tempPreOrder = { ...preOrder };
        tempPreOrder.services.find((s) => s.id === id).count = +value;
        setPreOrder(tempPreOrder);
    };

    const addButtonhandler = () => {
        let subCategories = [];
        preOrder.services.forEach((s) => {
            if (s.checked)
                subCategories.push({
                    id: s.category.subCategory.id,
                    sort: s.category.subCategory.sort,
                });
        });
        const dataToAdd = {
            category: {
                id: selectedCategoryId,
                sort: selectedCategory.sort,
            },
            subCategories,
            services: preOrder.services.filter((s) => s.checked),
        };
        props.prevStep();
        props.addServices(dataToAdd);
    };

    useEffect(() => {
        const tempPreOrder = {
            category: selectedCategoryId,
            subCategories: categories
                .find((item) => item.id === selectedCategoryId)
                .subCategory.filter((sc) => subCategories.includes(sc.id)),
            services: services.reduce((accum, curService) => {
                if (curService.category.id === selectedCategoryId)
                    accum.push({
                        ...curService,
                        count: '',
                        checked: false,
                        subCategoryId: curService.category.subCategory.id,
                    });
                return accum;
            }, []),
        };
        setPreOrder(tempPreOrder);
    }, []);

    return (
        <>
            <EstimateProcess />
            <div className="complex-body">
                <div className="d-flex align-items-center mb-4">
                    <img
                        className="estimate__sub-categories-selected-category"
                        src={selectedCategory.picture}
                        alt={selectedCategory.name}
                    />
                    <div className="estimate__sub-categories-selected-category-text">
                        {selectedCategory.name}
                    </div>
                </div>
                <div className="estimate__sub-categories">
                    {preOrder.subCategories.map((subCategory) => (
                        <SubCategoryitem
                            key={subCategory.id}
                            subCategoryClickHandler={subCategoryClickHandler}
                            subCategory={subCategory}
                            selectedSubCategories={selectedSubCategories}
                            preOrder={preOrder}
                            serviceCheckhandler={serviceCheckhandler}
                            serviceCountHandler={serviceCountHandler}
                            countInputBlurHandler={countInputBlurHandler}
                        />
                    ))}
                </div>
            </div>
            <div className="complex-footer mt-5">
                <div className="row">
                    <div className="col-4 col-lg-4 estimate-footer-item pr-0">
                        <EstimateButton
                            className="back"
                            back={true}
                            onClick={props.prevStep}
                            text="Назад"
                        />
                    </div>
                    <div className="col-8 col-lg-8 estimate-footer-item p-0">
                        <EstimateButton
                            disabled={!preOrder.services.some((s) => s.checked && s.count)}
                            onClick={addButtonhandler}
                            noIcon
                            text={
                                preOrder.services.some((s) => s.checked && s.count)
                                    ? "Добавить услуги"
                                    : "Выберите услуги"
                            }
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    selectedCategoryId: state.estimate.selectedCategoryId,
    services: state.estimate.activeServices,
    categories: state.estimate.activeCategories,
    subCategories: state.estimate.activeSubCategories,
});

const actions = {
    addServices,
    prevStep,
    nextStep,
    estimateChangeServiceAnimateItems,
};

export default connect(mapStateToProps, actions)(Step5);
