import React from 'react';
import Tab from '../../ui/Tab/Tab';
import { useCalculatorContext } from './CalculatorContext';
import { useLocation, useHistory } from 'react-router-dom';

const tabs = [
    { path: "/", title: "Отдельные виды работ" },
    { path: "/complex-works", title: "Комплекс работ «Под ключ»" },
    { path: "/master-hourly", title: "Почасовой мастер" },
];

const CalculatorTopTabs = () => {
    const { pathname } = useLocation();
    const { push } = useHistory();

    return (
        <div className="Calculator__content-header">
            {tabs.map((tab) => (
                <Tab
                    key={tab.path}
                    title={tab.title}
                    active={pathname === tab.path}
                    onClick={() => push(tab.path)}
                />
            ))}
        </div>

    );
};

export default CalculatorTopTabs;
