import {
    ADD_ADMIN_MATERIALS_LIST_MATERIAL,
    DELETE_ADMIN_MATERIALS_LIST_MATERIAL,
    FETCH_ADMIN_MATERIALS_LIST_CATEGORIES_SUCCESS,
    FETCH_ADMIN_MATERIALS_LIST_ERROR,
    FETCH_ADMIN_MATERIALS_LIST_START,
    FETCH_ADMIN_MATERIALS_LIST_SUCCESS,
    FETCH_ADMIN_MATERIALS_LIST_UNITS_SUCCESS, SAVE_ADMIN_CHANGES_MATERIAL_ERROR, SAVE_ADMIN_CHANGES_MATERIAL_NOTHING,
    SAVE_ADMIN_CHANGES_MATERIAL_START,
    SAVE_ADMIN_CHANGES_MATERIAL_SUCCESS,
    SAVE_ADMIN_MATERIALS_LIST_MATERIAL,
    SET_ADMIN_MATERIALS_LIST_MATERIAL_PROPERTY, SET_ADMIN_MATERIALS_LIST_PROPERTY
} from "../actions/actionTypes";


const initialState = {
    materials: [],
    categories: [],
    saveResult: {
        title: null,
        description: null,
        list: []
    },
    units: [],
    saveInProcess: false,
    loading: false,
    error: null
}

const adminMaterialListReducer = (state = initialState, {type, payload}) => {

    let materials = [...state.materials]
    let findMaterialId = null
    if (payload && payload.materialId) {
        findMaterialId = materials.findIndex(material => {
            return material.id === payload.materialId
        })
    }

    switch (type) {
        case FETCH_ADMIN_MATERIALS_LIST_START:
            return {
                ...state,
                loading: true
            }
        case FETCH_ADMIN_MATERIALS_LIST_SUCCESS:
            return {
                ...state,
                materials: payload.materials,
                loading: false
            }
        case FETCH_ADMIN_MATERIALS_LIST_ERROR:
            return {
                ...state,
                error: payload.error,
            }
        case FETCH_ADMIN_MATERIALS_LIST_CATEGORIES_SUCCESS:
            return {
                ...state,
                categories: payload.categories,
                loading: false
            }
        case FETCH_ADMIN_MATERIALS_LIST_UNITS_SUCCESS:
            return {
                ...state,
                units: payload.units,
                loading: false
            }
        /// MATERIAL SAVE CHANGES

        case SAVE_ADMIN_CHANGES_MATERIAL_START:
            return {
                ...state,
                saveInProcess: true,
            }

        case SAVE_ADMIN_CHANGES_MATERIAL_SUCCESS:

            materials = [...materials.map(material=>{
                material.edited = false
                return material
        })]

            return {
                ...state,
                saveResult: {
                    title: "Отправлено!",
                    description: "Результат сохранения:",
                    list: payload.saveResult
                },
                materials,
                saveInProcess: false
            }

        case SAVE_ADMIN_CHANGES_MATERIAL_NOTHING:
            return {
                ...state,
                saveResult: {title: "!", description: "Нечего сохранять!", list: []},
                saveInProcess: false
            }

        case SAVE_ADMIN_CHANGES_MATERIAL_ERROR:
            return {
                ...state,
                error: payload.error
            }
        /// MATERIAL SAVE CHANGES

        case SET_ADMIN_MATERIALS_LIST_MATERIAL_PROPERTY:
            if (findMaterialId >= 0) {
                let material = materials[findMaterialId]
                material[payload.property] = payload.value
            }
            return {
                ...state,
                materials
            }

        case SAVE_ADMIN_MATERIALS_LIST_MATERIAL:
            let materialIdx = materials.findIndex(material => material.id === payload.material.id)
            materials = [
                ...materials.slice(0, materialIdx),
                payload.material,
                ...materials.slice(materialIdx + 1)
            ]
            return {
                ...state,
                materials
            }

        case ADD_ADMIN_MATERIALS_LIST_MATERIAL:
            materials = [...materials, payload.material]
            return {
                ...state,
                materials
            }

        case DELETE_ADMIN_MATERIALS_LIST_MATERIAL:
            if (payload.material.isNew) {
                materials = materials.filter(material => material.id !== payload.material.id)
            } else {
                let materialIdx = materials.findIndex(material => material.id === payload.material.id)
                let material = materials[materialIdx]
                material.deleted = true
                material.edited = true
            }
            return {
                ...state,
                materials
            }

        case SET_ADMIN_MATERIALS_LIST_PROPERTY:
            return {
                ...state,
                [payload.property]: payload.value
            }
        default:
            return {
                ...state
            }
    }

}

export default adminMaterialListReducer;
