import React from 'react';
import { useChartsContext } from '../ChartsContext';
import styled from 'styled-components';
import ChartsWorkerCountRowItem from './ChartsWorkerCountRowItem';
import { format } from 'date-fns';

const StyledCounts = styled.div`
  height: 500px;
  display: flex;
  align-items: flex-end;
`;

const ChartsWorkerCountRow = () => {
    const { counts, dates } = useChartsContext();
    return (
        <StyledCounts>
            {
                dates.map((date) => {
                    const d = format(new Date(date), 'dd.MM.yyyy', new Date());
                    const workers = counts[d]
                        ? counts[d].workers
                        : '';
                    return (
                        <ChartsWorkerCountRowItem
                            key={d}
                            workers={workers}
                        />
                    );
                })
            }
        </StyledCounts>
    );
};

export default ChartsWorkerCountRow;
