import React, {useState, useEffect} from 'react'
import classes from './Counter.module.scss'
import is from 'is_js'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faMinus, faPlus} from '@fortawesome/free-solid-svg-icons'


export default ({value, quantityHandler}) => {

	// let inputValue = props.table.amount
	const [count, setCount] = useState(value);

	const inputHandler = (event) => {
		// const num = +event.target.value.trim()
		const num = event.target.value
		setCount(num)

	}
	const minusBtn = () => {
		if (+count - 1 >= 0) {
			setCount(+count - 1)
		}
	}
	const plusBtn = () => {
		setCount(+count + 1)
	}

	useEffect(() => {
		if (is.number(+count)) {
			quantityHandler((+count))
		}
	}, [count]);


	return (
		<div className={classes.rowCounter}>
			<div className={classes.counterBtn} onClick={minusBtn}>
				<FontAwesomeIcon
					icon={faMinus}
					className='MinusPlus'
					size={'xs'}
				/>
			</div>
			<div className={classes.counterInput}>
				<input
					onChange={event => inputHandler(event)}
					type="text"
					value={count}
				/>
			</div>
			<div className={classes.counterBtn} onClick={plusBtn}>
				<FontAwesomeIcon
					icon={faPlus}
					className='MinusPlus'
					size={'xs'}
				/></div>
		</div>
	)
}