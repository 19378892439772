import React from 'react';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";


const WorkerListHeader = ({user}) => {
	return (
		<Paper className='wp__line my-2 p-3'>

			<Typography variant="overline"
						className="col p-0 m-0 font-weight-bold"># / ID</Typography>

			<Typography variant="overline"
						className={`${user.role === 5 ? 'col-2' : 'col-3'} p-0 m-0 font-weight-bold`}>ФИО</Typography>

			<Typography variant="overline"
						className={`${user.role === 5 ? 'col-2' : 'col-3'} p-0 m-0 font-weight-bold`}>
				Категории
			</Typography>

			<Typography variant="overline"
						className="col-2 p-0 m-0 font-weight-bold">
				Профессия
			</Typography>

			{/*<Typography variant="overline"*/}
			{/*            className="col-1 p-0 m-0 font-weight-bold">*/}
			{/*    Телефон*/}
			{/*</Typography>*/}

			<Typography variant="overline"
						className="col-2 p-0 m-0 text-center font-weight-bold">
				Статус
			</Typography>

			{
				user.role === 5
				&&
				<>
					<Typography variant="overline"
								className="col-2 p-0 m-0 font-weight-bold text-center">
						Цена в час
					</Typography>
					<Typography variant="overline"
								className="col p-0 m-0 font-weight-bold text-center">
						Управление
					</Typography>
				</>
			}

		</Paper>
	);
};

export default WorkerListHeader;