import React from 'react';
import './AdminUI.scss';
import Tooltip from "@material-ui/core/Tooltip";

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreFromTrash from '@material-ui/icons/RestoreFromTrash';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const AdminBtn = ({tip = '', type, onClick, text = null, className="ml-2", color = 'primary'}) => {

    if (type === 'delete') {
        color = 'secondary'
    }

    return (
        <Tooltip title={tip}
                 placement="top"
                 className={className}>

            <Button
                className="Admin_btn text edit_btn__js"
                variant="contained" color={color}
                onClick={onClick}
            >
                {
                    type === 'add' &&
                    <AddIcon
                        className={`AdminUI__icon ${color}`}/>
                }
                {
                    type === 'delete' &&
                    <DeleteIcon
                        className={`AdminUI__icon ${color}`}/>
                }
                {
                    type === 'restore' &&
                    <RestoreFromTrash
                        className={`AdminUI__icon ${color}`}/>
                }
                {
                    type === 'clear' &&
                    <ClearIcon
                        className={`AdminUI__icon ${color}`}/>
                }
                {
                    type === 'check' &&
                    <CheckIcon
                        className={`AdminUI__icon ${color}`}/>
                }
                {
                    type === 'edit' &&
                    <EditIcon
                        className={`AdminUI__icon ${color}`}/>
                }
                {
                    type === 'save' &&
                    <SaveIcon
                        className={`AdminUI__icon ${color}`}/>
                }
                {
                    text &&
                    <Typography className='ml-2' variant="overline" display="block">
                        {text}
                    </Typography>
                }

            </Button>

        </Tooltip>
    );
};

export default AdminBtn;