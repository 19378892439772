import React from 'react'
// import {NavLink} from "react-router-dom";
import {useHistory} from "react-router";
import {useDispatch} from "react-redux";
import {ABOUT_ANCHOR, CALCULATOR_ANCHOR, COTNACTS_ANCHOR, HOME_ANCHOR} from '../../../../../config';
import {setActiveNavAnchor} from '../../../../../store/actions/navActions';


const HeaderNav = ({mobile, setOpen = null}) => {

	const history = useHistory();
	const dispatch = useDispatch();

	const addLink = (anchor) => {
		history.push('/');
		dispatch(setActiveNavAnchor(anchor))
		if (setOpen) {
			setOpen()
		}
	};

	return (
		<div className="Header__nav">
			{
				<nav>
					<ul className={`Header__nav-list ${mobile ? 'Header__nav-list-mobile' : ''}`}>
						<li onClick={() => addLink(HOME_ANCHOR)}>
							{/*<NavLink className="Header__nav-link" exact to={'/app'}*/}
							{/*		 activeClassName='active'>*/}
							<span className="Header__nav-link">Главная</span>
							{/*</NavLink>*/}
						</li>
						<li onClick={() => addLink(CALCULATOR_ANCHOR)}>
							<span className="Header__nav-link">Услуги</span>
						</li>
						<li onClick={() => addLink(COTNACTS_ANCHOR)}>
							<span className="Header__nav-link">Контакты</span>							
						</li>
					</ul>
				</nav>
			}
		</div>
	)
};


export default HeaderNav