import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import {adminLeftNavIcons as icons} from './icons';


const AdminLeftNavItem = ({onClick, code, name, selected}) => {
	return (
		<ListItem button
				  className='pr-2'
				  selected={selected === code} onClick={() => onClick(code)}>
			<ListItemText primary={name}/>
			<ListItemIcon className='justify-content-end'>
				{icons[code]}
			</ListItemIcon>
		</ListItem>
	);
};

export default AdminLeftNavItem;