import React, { useState } from "react";
import { connect } from "react-redux";
import { renderFormControl, validateControl } from "../../../../../form/formFramework";
import {
	projectCalcChangeFieldValue,
	projectCalcChangeFile,
	projectCalcClearFile,
	projectCalcChangeFieldError,
	projectCalcSendForm
} from "../../../../../store/reducers/projectCalcFormReducer";
import EstimateButton from "../UI/EstimateButton";

const ProjectCalculation = ({
	formControls,
	projectCalcChangeFieldValue,
	projectCalcChangeFile,
	projectCalcClearFile,
	projectCalcChangeFieldError,
	projectCalcSendForm
}) => {

	const [formTouched, setFormTouched] = useState(false)
	const [formValid, setFormValid] = useState(false)
  const formValidHandler = () => {
		let formValid = true
		Object.keys(formControls).forEach(property => {
			const formControl = formControls[property];
			const error = validateControl(formControl.value, formControl.validation, property);
			const valid = !error;
      if (error) projectCalcChangeFieldError(error, property);
			formValid = valid && formValid;
		});
		return formValid;
	};
  const submitFormHandler = (e) => {
    e.preventDefault();
		setFormTouched(false)
		setFormValid(formValidHandler())
    if (formValidHandler()) {
			let formData = new FormData();
			Object.keys(formControls).forEach(fieldId => {
				if (formControls[fieldId].value !== '') {
					if (fieldId === 'contact') {
						if (formControls[fieldId].value.includes('@')) {
							formData.append('email', formControls[fieldId].value)
						} else {
							formData.append('phone', formControls[fieldId].value)
						}
					} else if (fieldId === 'file') {
						formData.append(fieldId, formControls[fieldId].files)
					} else {
						formData.append(fieldId, formControls[fieldId].value)
					}
				}
			});
			projectCalcSendForm(formData)
    } else {
      console.log('false')
		}
  }
	const setFormFieldValue = (value, id) => {
		setFormTouched(true)
		projectCalcChangeFieldError(false, id);
		projectCalcChangeFieldValue(value, id)
	}

	return (
		<>
			<div className="complex-body flex-grow-1">
				<div className="row mt-2">
					<div className="col-12 col-lg-4">
						{renderFormControl(formControls.name, setFormFieldValue)}
					</div>
					<div className="col-12 col-lg-4">
						{renderFormControl(
							formControls.contact,
							setFormFieldValue
						)}
					</div>
					<div className="col-12 col-lg-4">
						{renderFormControl(formControls.file, projectCalcChangeFile, {
							clear: projectCalcClearFile,
							multiple: true,
						})}
					</div>
					<div className="col-12 col-lg-6 mt-2">
						{renderFormControl(
							formControls.task_text,
							setFormFieldValue,
							{ multiline: true, rows: 5 }
						)}
					</div>
					<div className="col-12 col-lg-6 mt-2">
						{renderFormControl(
							formControls.project_text,
							setFormFieldValue,
							{ multiline: true, rows: 5 }
						)}
					</div>
				</div>
			</div>
			<div className="complex-footer">
				<div className="row">
					<div className="col-12 estimate-footer-item">
						<EstimateButton
							onClick={submitFormHandler}
							disabled={!formValid && !formTouched}
							text="Отправить заявку"
						/>
					</div>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	formControls: state.projectCalc.formControls,
});
const mapDispatchToprops = {
	projectCalcChangeFieldValue,
	projectCalcChangeFile,
	projectCalcClearFile,
	projectCalcChangeFieldError,
	projectCalcSendForm,
};
export default connect(mapStateToProps, mapDispatchToprops)(ProjectCalculation);
