import React, {useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import {getReportsValue, getUserReports} from './functions';

const WorkerPersonalReportsTotal = ({reports, order, name}) => {
	const [totalValue, setTotalValue] = useState(0)
	useEffect(() => {
		setTotalValue(getReportsValue(reports))
	}, [reports])
	return (
		<>
			<div className="d-flex justify-content-between align-items-center">
				{/*<Typography variant="h6">{name}</Typography>*/}
				<Typography variant="h6">{order.serviceName}</Typography>
				<div className="d-flex flex-column mr-5">
					<Typography variant="h6">Итого объем: {totalValue.toFixed(2)} {order.serviceUnit}</Typography>
					<Typography variant="h6">Итого
						сумма: {(totalValue * order.unitPrice).toFixed(2)} ₽</Typography>
				</div>
			</div>
			<hr/>
		</>
	);
};

export default WorkerPersonalReportsTotal;