import React from "react";

export default function CategoryItem({
  category,
  setSelectedCategory,
  selectedCategoryId,
}) {
  let categoryName = category.name.trim().split(" ");
  const categoryLastWord = categoryName[categoryName.length - 1];
  categoryName = categoryName.slice(0, -1).join(" ");
  return (
    <div
      className={`Estimate__categories-item col-6 col-md-4 col-lg-3 ${
        category.id === selectedCategoryId ? "active" : ""
      }`}
      onClick={() => setSelectedCategory("selectedCategoryId", category.id)}
    >
      <img src={category.picture} alt={category.name} />
      <div className="Estimate__categories-item-name">
        {categoryName}
        <div className="Estimate__categories-item-name-with-arrow">
          {categoryLastWord}&nbsp;({category.subCategory.length})
        </div>
      </div>
    </div>
  );
}
