import React, { useEffect, useState } from 'react';
import classes from './Counter.module.scss';
import is from 'is_js';
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Counter = (props) => {
    const { quantityHandler, materialId, value, inPack } = props;
    let [count, setCount] = useState(value);

    useEffect(() => {
        setCount(value);
    }, [value]);

    const inputHandler = (event) => {
        // const num = +event.target.value.trim()
        const num = +event.target.value;
        // if (count !== num && is.number(num)) {
        console.log("event.target.value", event.target.value);
        setCount(num);
        quantityHandler(materialId, num, inPack);
        // }
    };
    const minusBtn = () => {
        if (+count - 1 >= 0) {
            setCount(+count - 1);
            quantityHandler(materialId, +count - 1, inPack);
        }
    };
    const plusBtn = () => {
        setCount(+count + 1);
        quantityHandler(materialId, +count + 1, inPack);
    };

    return (
        <div className={classes.counter}>
            <div className={classes.btn} onClick={minusBtn}>
                <FontAwesomeIcon
                    icon={faMinus}
                    className="MinusPlus"
                    size={'xs'}
                />
            </div>
            <div className={classes.input}>
                <input
                    onChange={event => inputHandler(event)}
                    type="text"
                    value={count}
                />
            </div>
            <div className={classes.btn} onClick={plusBtn}>
                <FontAwesomeIcon
                    icon={faPlus}
                    className="MinusPlus"
                    size={'xs'}
                /></div>
        </div>
    );
};

export default Counter;