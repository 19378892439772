import React from 'react'

import BlueLineResult from "../../../../../../components/UI/BlueLineResult/BlueLineResult";
import {useSelector} from "react-redux";
import {getOrderState} from "../../../../../../store/selectors";
import {useConditionsContext} from '../../../../../../app/containers/Calculator/ConditionsContext';


const ServiceCalculateTotal = () => {

	const {
		needMaterials,
	} = useSelector(getOrderState);
	const {totalServicePrice, materialPrice, sum} = useConditionsContext();

	return (

		<BlueLineResult>
			<div className='d-flex flex-column'>
				<div>
					<span>Итого сумма работ:&nbsp;</span>
					<span className="text-sum">
						{
							new Intl.NumberFormat('ru-RU').format(totalServicePrice)
						}
						&nbsp;р.
                                        </span>
				</div>
				{
					needMaterials === 2 &&
					<>
						<div>
							<span>Итого сумма материалов:&nbsp;</span>
							<span className="text-sum">
								{
									new Intl.NumberFormat('ru-RU').format(materialPrice)
								}
								&nbsp;р.
                                        </span>
						</div>
						<div>
							<span>Итого общая сумма:&nbsp;</span>
							<span className="text-sum">
									{
										new Intl.NumberFormat('ru-RU').format(sum)
									}
								&nbsp;р.
                                        </span>
						</div>
					</>
				}
			</div>
		</BlueLineResult>

	)
}

export default ServiceCalculateTotal;