import React, {Component} from 'react';
import List from "@material-ui/core/List";
import {connect} from "react-redux";
import {
	addDatesDay, deleteDatesDay,
	fetchDates,
	saveAdminDates, setDateAddress,
	setDatesDay,
	setDatesProperty
} from "../../../store/actions/adminDatesActions";
import AdminSendLoader from "./AdminUI/AdminSendLoader";
import {ListItem} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import AdminBtn from "./AdminUI/AdminBtn";
import {
	KeyboardDatePicker,
	KeyboardTimePicker,
	MuiPickersUtilsProvider
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import * as moment from "moment";
import AdminActive from "./AdminUI/AdminActive";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import cuid from 'cuid'
import AdminPopup from "./AdminUI/AdminPopup";
import LoaderAdmin from "../../../components/UI/LoaderAdmin/Loader";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import parse from "html-react-parser";

class AdminDates extends Component {

	dateHandler = (id, date) => {
		const day = this.props.dates.find(day => day.id === id)
		day.date = date
		day.edited = true
		this.props.setDatesDay(id, day)
	}

	deleteHandler = (id) => {
		const day = this.props.dates.find(day => day.id === id)
		if (day.isNew) {
			this.props.deleteDatesDay(id)
		} else {
			day.edited = true
			day.deleted = true
			this.props.setDatesDay(id, day)
		}
	}

	restoreHandler = (id) => {
		const day = this.props.dates.find(day => day.id === id)
		day.edited = true
		day.deleted = false
		this.props.setDatesDay(id, day)
	}
	addTimeHandler = (id) => {
		const day = this.props.dates.find(day => day.id === id)
		day.edited = true
		let addTime = '12:00';
		if (!!day.times.length) {
			const lastTime = day.times[day.times.length - 1]
			addTime = moment(lastTime, 'HH:mm').add(1, 'hour').format('HH:mm')
		}
		day.times.push(addTime)
		this.props.setDatesDay(id, day)
	}
	addDateHandler = () => {
		let date = moment().unix();
		if (!!this.props.dates.length) {
			const lastDay = this.props.dates[this.props.dates.length - 1];
			// date = moment(lastDay.date, 'DD.MM.YYYY').add(1, 'day').format('DD.MM.YYYY')
			date = moment.unix(lastDay.date).add(1, 'day').unix();
		}
		const day = {
			id: cuid(),
			date,
			edited: true,
			isNew: true,
			address: {},
			times: ['12:00']
		};
		this.props.addDatesDay(day)
	}

	timeHandler = (id, timeIdx, time) => {
		const day = this.props.dates.find(day => day.id === id)
		day.edited = true

		day.times = [
			...day.times.slice(0, timeIdx),
			time,
			...day.times.slice(timeIdx + 1)
		]

		this.props.setDatesDay(id, day)
	}
	timeDeleteHandler = (id, timeIdx) => {
		const day = this.props.dates.find(day => day.id === id)
		day.edited = true

		day.times = [
			...day.times.slice(0, timeIdx),
			...day.times.slice(timeIdx + 1)
		]

		this.props.setDatesDay(id, day)
	}

	clearResultHandler = () => {
		this.props.setDatesProperty({}, 'saveResult')
	}

	componentDidMount() {
		if (!this.props.dates.length) {
			this.props.fetchDates()
		}
	}


	render() {

		const {dates, loading, saveInProcess, saveResult, addresses} = this.props
		// console.log(this.props.dates)
		return (
			<>
				{
					loading ?
						<LoaderAdmin/>
						:
						<>
							<div>
								<h3 className='text-center'>Даты инструктажа</h3>
							</div>
							<List>
								{
									!!dates.length && dates.map((day, day_idx) => {
										return (
											<ListItem key={day.id} className='Admin_material-list-item px-0'>
												<Paper className='w-100 h-100 py-1'>
													<div
														className='Admin_material-list-content border-top border-bottom border-gray px-5 py-3'>
														<span>Дата инструктажа</span>
														<div
															className='d-flex justify-content-between border-bottom border-gray py-3'>
															<div className='d-flex align-items-center'>
                                                               <span className='mr-3'>
                                                                   {day_idx + 1}.
                                                               </span>
																<MuiPickersUtilsProvider utils={MomentUtils}>
																	<KeyboardDatePicker
																		id={`date-${day.id}`}
																		value={moment.unix(day.date)}
																		ampm={false}
																		format='DD.MM.YYYY'
																		onChange={evt => {
																			let date = evt ? evt.unix() : '';
																			this.dateHandler(day.id, date)
																		}}

																		okLabel={'ОК'}
																		cancelLabel={'Отмена'}
																		variant="filled"
																	/>
																</MuiPickersUtilsProvider>
															</div>


															<FormControl variant="standard"
																		 className='w-50'>
																<Select
																	className="w-100"
																	size={5}
																	value={day.address.id || ''}
																	defaultValue={''}
																	onChange={(evt) => this.props.setDateAddress(day.id, evt.target.value)}
																>
																	{addresses.map(address => {
																		return (
																			<MenuItem
																				key={address.id}
																				value={address.id}>
																				{parse(address.value)}
																			</MenuItem>
																		)
																	})}

																</Select>
															</FormControl>

															<div className='d-flex'>

																<AdminActive active={true} edited={day.edited}/>

																{
																	day.deleted
																		?
																		<AdminBtn tip="Восстановить дату"
																				  type="restore"
																				  onClick={() => this.restoreHandler(day.id)}
																		/>
																		:
																		<AdminBtn tip="Удалить дату" type="delete"
																				  onClick={() => this.deleteHandler(day.id)}
																		/>
																}
															</div>

														</div>
														<div
															className='pt-3 d-flex align-items-center justify-content-between'
															style={{width: '283px'}}>
															<div className='d-flex align-items-center'> Время
																инструктажа
															</div>
															<AdminBtn
																tip="Добавить время"
																type="add"
																onClick={() => this.addTimeHandler(day.id)}
															/>
														</div>
														<List>

															{
																day.times.map((time, timeIdx) => {
																	return (
																		<ListItem key={timeIdx}>
                                                                       <span className='mr-3'>
                                                                            {timeIdx + 1}.
                                                                       </span>
																			<MuiPickersUtilsProvider
																				utils={MomentUtils}>
																				<KeyboardTimePicker
																					// id={timeIdx}
																					placeholder={moment()
																						.format('HH:mm')}
																					value={moment(time, 'HH:mm') || null}
																					ampm={false}
																					format='HH:mm'
																					onChange={evt => {
																						console.log(evt)
																						let value = evt ? evt.format('HH:mm') : ''
																						this.timeHandler(day.id, timeIdx, value)
																					}}
																					// minDate={moment().format('HH:mm')}
																					okLabel={'ОК'}
																					cancelLabel={'Отмена'}
																					variant="filled"
																					disablePast
																				/>
																			</MuiPickersUtilsProvider>
																			<AdminBtn tip="Удалить время" type="delete"
																					  onClick={() => this.timeDeleteHandler(day.id, timeIdx)}
																			/>

																		</ListItem>

																	)

																})
															}
														</List>
													</div>
												</Paper>
											</ListItem>
										)
									})
								}
							</List>


							<Paper className="mt-2">
								<div className="d-flex p-3 justify-content-end">
									<Button
										className="Admin_btn text mr-2"
										variant="contained"
										color="primary"
										onClick={this.addDateHandler}
									>
										<AddIcon
											className='Admin_btn-icon Admin_btn-icon-create mr-1'/>
										<Typography variant="overline" display="block">
											Добавить дату
										</Typography>
									</Button>
									<Button
										className="Admin_btn text "
										variant="contained"
										color="primary"
										onClick={this.props.saveAdminDates}
									>
										<SaveIcon
											className='Admin_btn-icon Admin_btn-icon-create mr-1'/>
										<Typography variant="overline" display="block">
											Сохранить изменения
										</Typography>
									</Button>
								</div>

							</Paper>

							{
								saveResult.title &&
								<AdminPopup saveResult={saveResult} clearResultHandler={this.clearResultHandler}/>
							}
							{
								saveInProcess &&
								<AdminSendLoader/>
							}

						</>

				}
			</>
		);
	}
}

const mapState = state => {
	return {
		dates: state.adminDates.dates,
		addresses: state.adminDates.addresses,
		loading: state.adminDates.loading,
		saveResult: state.adminDates.saveResult,
		saveInProcess: state.adminDates.saveInProcess,
	}
}
const actions = {
	fetchDates,
	setDatesDay,
	addDatesDay,
	setDatesProperty,
	saveAdminDates,
	deleteDatesDay,
	setDateAddress
}

export default connect(mapState, actions)(AdminDates);