import React, {useEffect, useState} from 'react';
import './AdminClientList.scss'
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import AdminOrderListDetail from '../../AdminOrderList/AdminOrderListDetail/AdminOrderListDetail';
import AdminClientListDetail from './AdminClientListDetail';
import {adminMastersFetch} from '../../../../../store/actions/adminMastersListActions';
import {useDispatch, useSelector} from 'react-redux';
import {ROLE_CLIENT} from '../../../../../config';
import {getAdminMasterListUsers} from '../selectors';

const AdminClientList = () => {
	const [currentClient, setCurrentClient] = useState(null)
	const [clients, setClients] = useState([])
	const stateUsers = useSelector(getAdminMasterListUsers);
	const dispatch = useDispatch();
	const closeHandler = () => {
		setCurrentClient(null)
	}

	useEffect(() => {
		if (!!stateUsers['clients'].length) {
			setClients(stateUsers['clients'])
		}
	}, [stateUsers])

	useEffect(() => {
		dispatch(adminMastersFetch(ROLE_CLIENT, 'all', 'all', 'all', 'all'))
	}, []);

	return (
		<div>
			<Typography variant={"h5"} className="my-3">Заказчики</Typography>
			{
				clients.map((client, idx) => {
					const {id, name, picture, phone, email} = client;
					return (
						<Paper key={client.id} className='w-100 p-3 my-1 d-flex align-items-center'
							   onClick={() => setCurrentClient(client)}
							   style={{cursor: "pointer"}}>
							<div className='col-2 p-0 d-flex justify-content-between align-items-center pr-3'>
								{idx + 1}. / {id}
								<Avatar alt="Cindy Baker" src={picture}/>
							</div>
							<div className='col-4 p-0 d-flex justify-content-start'>
								<span>{name}</span>
							</div>
							<div className='col p-0'>{email}</div>
							<div className='col p-0'>{phone}</div>
						</Paper>
					)
				})
			}
			{
				currentClient &&
				<AdminOrderListDetail closeHandler={closeHandler} title={`Заказчик: ${currentClient.name}`}>
					<AdminClientListDetail client={currentClient}/>
				</AdminOrderListDetail>
			}
		</div>
	);
};

export default AdminClientList;