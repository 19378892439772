import {
    FETCH_NAV_ERROR,
    FETCH_NAV_START,
    FETCH_NAV_SUCCESS, SET_ACTIVE_NAV_ANCHOR,
    SET_CURRENT_NAV_LINK
} from "../actions/actionTypes";

const initialState = {
    nav: {
        top: [],
        bottom: []
    },
    current: '',
    loading: false,
    error: false

}

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_NAV_START:
            return {
                ...state,
                loading: true
            }
        case FETCH_NAV_SUCCESS:
            return {
                ...state,
                nav: action.nav,
                loading: false
            }
        case FETCH_NAV_ERROR:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case SET_CURRENT_NAV_LINK:
            return {
                ...state,
                current: action.id,
                loading: false
            };
        case SET_ACTIVE_NAV_ANCHOR:
            return {
                ...state,
                activeAnchor: action.payload.anchor
            }
        default:
            return state
    }
}