import React from "react";
import cn from "./ForCompanies.module.css";


const ForCompanies = (props) => {
  const items = [
    {
      icon: `<svg width="30" height="41" viewBox="0 0 30 41" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M23.9636 24.5954C23.3661 24.5954 22.9179 24.8001 22.4698 24.8001L18.1379 18.8619L21.7229 13.9474L26.3536 11.695L29.7893 4.11856C29.9387 3.70903 29.9387 3.09472 29.7893 2.88995L27.9967 0.432739C27.698 0.023203 27.2499 0.023203 26.9511 0.227971L21.2748 4.73287L19.4823 11.2854L15.8972 16.1999L11.864 10.2616C11.864 9.6473 12.0134 9.033 12.0134 8.21392C12.0134 2.88996 8.27898 -1.00064 4.54455 0.227971L6.78521 3.29949C7.98023 4.93763 7.98023 7.39485 6.78521 9.03299C5.59019 10.6711 3.79767 10.6711 2.60265 9.03299L0.212621 6.16624C-0.982395 12.1045 3.05078 17.838 7.5321 15.9951L11.864 21.9334L10.0715 24.3906C9.02587 23.9811 7.98023 24.3906 7.23334 25.4144L1.10888 33.8099C-0.086133 35.4481 -0.23551 37.9053 0.810129 39.5434C2.00515 41.3863 3.94705 41.3863 5.14206 39.7482L11.4159 31.1479C12.1628 30.1241 12.4615 28.486 12.1628 27.2573L13.9553 24.8001L18.2872 30.7384C18.1379 31.3527 18.1379 32.1718 18.1379 32.7861C18.1379 38.1101 21.8723 42.0006 25.6067 40.772L23.3661 37.7005C22.171 36.0624 22.171 33.6052 23.3661 31.967C24.5611 30.3289 26.3536 30.3289 27.5486 31.967L29.7893 35.0385C30.8349 29.7146 27.8474 24.5954 23.9636 24.5954Z" fill="#88f61b"/></svg>`,
      text: "Собственная база строителей всех специальностей и необходимой квалификации",
    },
    {
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" fill="none"viewBox="0 0 37 37"><path fill="#88f61b" d="M.01 19.336a.12.12 0 00-.01.042V30.23c0 .277.148.535.388.673l10.4 5.98V26.03l-10.4-5.98a.767.767 0 01-.379-.714zM7.822 21.34a.769.769 0 00.839.339l23.407-5.887a2.429 2.429 0 001.833-2.365c0-.927-.514-1.761-1.338-2.177L28.3 9.106l-.729 1.834a2.34 2.34 0 01-.957 1.13l-7.705 4.651a.769.769 0 01-1.057-.267.779.779 0 01.263-1.063l7.706-4.65a.791.791 0 00.32-.38l.777-1.952.655-1.648.457-.231c.226.134.478.223.75.223.143 0 .288-.02.43-.062l5.94-1.708a2.552 2.552 0 001.833-2.445A2.533 2.533 0 0034.458 0h-.709a2.53 2.53 0 00-1.58.557l-4.32 3.477c-.36.288-.549.705-.563 1.14l-.663.333a.782.782 0 00-.37.406l-.718 1.801-2.154-1.084c-1.643-.826-3.752-.38-4.925 1.036L7.883 20.431a.78.78 0 00-.06.908zM1.153 18.707l5.2 2.989c-.286-.785-.171-1.63.346-2.254l1.062-1.282a.78.78 0 00.057-.913.765.765 0 00-.847-.332L.577 18.63a.758.758 0 01.576.076zM12.329 26.148V37l24.116-7.58c.322-.1.541-.4.541-.74V18.352l-24.657 7.796zM35.06 16.273a.77.77 0 00-.904.1 3.945 3.945 0 01-1.712.926L9.038 23.186a.749.749 0 01-.074.01l2.683 1.543 24.608-7.78-1.196-.686z"></path></svg>`,
      text: "Актуальная база строительных товаров по номенклатуре поставщиков",
    },
    {
      icon: `<svg xmlns='http://www.w3.org/2000/svg' width='39' height='38' fill='none' viewBox='0 0 39 38'><path fill='#88f61b'd='M.658 9.008h8.32V.688l-8.32 8.32zM29.254 20.13c-4.95 0-8.978 3.992-8.978 8.898S24.304 38 29.254 38s9.053-4.066 9.053-8.972-4.102-8.898-9.053-8.898zm4.16 8.572l-4.489 4.449a1.129 1.129 0 01-1.587 0l-2.244-2.224a1.105 1.105 0 010-1.573 1.13 1.13 0 011.587 0l1.45 1.438 3.697-3.663a1.13 1.13 0 011.586 0 1.105 1.105 0 010 1.573z'></path><path fill='#88f61b' d='M5.611 24.58h13.372a11.233 11.233 0 0110.271-6.674V3.372c0-1.84-1.51-3.336-3.367-3.336H11.223V10.12c0 .615-.502 1.112-1.123 1.112H0V34.59C0 36.43 1.51 38 3.367 38H22.59c-2.177-1.606-3.77-4.02-4.332-6.747H5.611c-.62 0-1.122-.498-1.122-1.113s.502-1.112 1.122-1.112h12.42c0-.762.08-1.505.227-2.224H5.611c-.62 0-1.122-.498-1.122-1.113 0-.614.502-1.112 1.122-1.112zM14.59 6.782h9.053c.62 0 1.122.498 1.122 1.113 0 .614-.502 1.112-1.122 1.112H14.59c-.62 0-1.123-.498-1.123-1.112 0-.615.502-1.113 1.123-1.113zm0 4.45h9.053c.62 0 1.122.497 1.122 1.111 0 .615-.502 1.113-1.122 1.113H14.59c-.62 0-1.123-.498-1.123-1.113 0-.614.502-1.112 1.123-1.112zM5.61 15.68h18.032c.62 0 1.122.498 1.122 1.112 0 .615-.502 1.113-1.122 1.113H5.61c-.62 0-1.122-.498-1.122-1.113 0-.614.502-1.112 1.122-1.112zm0 4.45h13.963c.62 0 1.123.497 1.123 1.111 0 .615-.502 1.113-1.123 1.113H5.611c-.62 0-1.122-.498-1.122-1.113 0-.614.502-1.112 1.122-1.112z'></path></svg>`,
      text: "Все расчеты ведутся онлайн по нормативам",
    },
    {
      icon: `<svg xmlns='http://www.w3.org/2000/svg' width='34' height='41' fill='none' viewBox='0 0 34 41'><path fill='#88f61b' d='M9.326 10.354c-.65 0-1.176-.574-1.176-1.283V1.284C8.15.574 8.677 0 9.326 0c.65 0 1.177.575 1.177 1.284V9.07c0 .709-.527 1.284-1.177 1.284zM24.674 10.354c-.65 0-1.177-.574-1.177-1.283V1.284c0-.71.527-1.284 1.177-1.284s1.176.575 1.176 1.284V9.07c0 .709-.527 1.284-1.176 1.284zM0 17.81v17.714C0 38.544 2.25 41 5.018 41h23.964C31.75 41 34 38.543 34 35.524V17.81H0z'></path><path fill='#88f61b' d='M34 15.243V9.37c0-3.02-2.251-5.476-5.018-5.476h-.78V9.07c0 2.123-1.583 3.851-3.528 3.851-1.946 0-3.53-1.728-3.53-3.851V3.893h-8.289v5.178c0 2.123-1.583 3.851-3.529 3.851-1.945 0-3.528-1.728-3.528-3.851V3.893h-.78C2.25 3.893 0 6.35 0 9.37v5.873h34z'></path></svg>`,
      text: "Индивидуальный график оплат",
    },
    {
      icon: `<svg xmlns='http://www.w3.org/2000/svg' width='27' height='38' fill='none' viewBox='0 0 27 38'><path fill='#88f61b' d='M0 6.753V31.47h26.39c-.023-.41-.055.185-.055-.227l.055.227c0-2.706-8.143-1.247-6.47-.848l6.47.848-.11-.453c0-2.377-3.07-.395-.502-.395 2.568 0-5.817-19.389-5.817-17.012v1.032c.606-.267 6.561 20.68 5.817 15.98 2.069 0 .006-.883.612.848.07-.035.152.032.224 0V6.753H0zM22.879.204H4.249C2.047.204.256 1.862.256 3.9v1.232h26.615V3.9c0-2.038-1.79-3.696-3.992-3.696zM4.248 37.898l18.63.084c2.202.01 3.997-1.64 4.002-3.678l.003-1.233-26.614-.12-.003 1.233c-.006 2.038 1.78 3.704 3.982 3.714z'></path></svg>`,
      text: "Онлайн контроль за ходом выполнения работ",
    },
    {
      icon: `<svg xmlns='http://www.w3.org/2000/svg' width='35' height='41' fill='none' viewBox='0 0 35 41'><path fill='#88f61b' d='M20.4 0H3.6C1.615 0 0 1.682 0 3.75V5h24V3.75C24 1.682 22.385 0 20.4 0zM0 7.698v22.796h4.966c-.021-.378-.057-.756-.057-1.136v-2.272c0-2.496 1.25-4.803 3.428-6.329L9.82 19.72v-5.698c0-2.192 1.927-3.976 4.295-3.976 2.369 0 4.296 1.784 4.296 3.976v.952a4.557 4.557 0 011.84-.384c1.909 0 3.53 1.157 4.09 2.754.064-.033.14-.046.207-.076v-9.57H0z'></path><path fill='#88f61b' d='M33.019 22.026c-1.017 0-1.841.763-1.841 1.704v3.976c0 .628-.549 1.136-1.227 1.136-.679 0-1.228-.508-1.228-1.136v-2.001-.096-4.151c0-.941-.824-1.704-1.84-1.704-1.017 0-1.842.763-1.842 1.704v3.976c0 .628-.549 1.136-1.227 1.136s-1.227-.508-1.227-1.136v-6.248c0-.941-.825-1.704-1.841-1.704-1.017 0-1.841.763-1.841 1.704v6.248c0 .628-.55 1.136-1.228 1.136-.678 0-1.227-.508-1.227-1.136V20.73v-1.392-4.696c0-.941-.824-1.704-1.84-1.704-1.018 0-1.842.762-1.842 1.704v15.336c0 .628-.549 1.136-1.227 1.136s-1.228-.508-1.228-1.136v-6.79c-1.482 1.039-2.454 2.669-2.454 4.518v2.272c0 3.149.804 6.376 2.325 9.193.335.62.55 1.107 1.357 1.107h19.637c.807 0 1.021-.486 1.357-1.107a19.555 19.555 0 002.325-9.193V23.73c0-.941-.824-1.704-1.841-1.704z'></path></svg>`,
      text: "Регулярные отчёты о проделанной работе с мобильного приложения Исполнителей",
    },
    {
      icon: `<svg xmlns='http://www.w3.org/2000/svg' width='38' height='42' fill='none' viewBox='0 0 38 42'><path fill='#88f61b' d='M27.344 7.653L23.983.71c-.16-.33-.423-.571-.73-.667a1.027 1.027 0 00-.906.159L12.071 7.636l15.273.017z'></path><path fill='#88f61b' d='M4.75 9.545c-.87 0-1.583-.859-1.583-1.909s.712-1.91 1.583-1.91h5.336l5.288-3.817H4.75C2.407 1.909.46 3.97.08 6.68c-.033.153-.08.306-.08.478v29.114C0 39.423 2.138 42 4.75 42h26.917c1.741 0 3.166-1.718 3.166-3.818v-3.818h-3.958c-3.927 0-7.125-3.857-7.125-8.591 0-4.735 3.198-8.591 7.125-8.591h3.958v-3.819c0-2.1-1.425-3.818-3.166-3.818H4.75zm25.333-3.818c0-1.928-1.187-3.532-2.739-3.78l2.74 5.689v-1.91z'></path><path fill='#88f61b' d='M36.813 20.045h-5.938c-2.619 0-4.75 2.57-4.75 5.728 0 3.157 2.131 5.727 4.75 5.727h5.938c.655 0 1.187-.642 1.187-1.432v-8.59c0-.791-.532-1.433-1.188-1.433zm-5.938 7.637c-.874 0-1.583-.856-1.583-1.91 0-1.053.709-1.909 1.583-1.909s1.583.856 1.583 1.91c0 1.053-.709 1.909-1.583 1.909z'></path></svg>`,
      text: "Сравнение плана с фактом",
    },
    {
      icon: ` <svg xmlns='http://www.w3.org/2000/svg' width='36' height='44' fill='none' viewBox='0 0 36 44'><path fill='#88f61b' d='M0 26.484c.011 1.66.879 3.002 1.953 3.021h31.59c1.074-.019 1.941-1.36 1.953-3.02v-2.6H0v2.6z'></path><path fill='#88f61b' d='M33.542 0H1.953C.891 0 0 1.06 0 2.434V25.29h35.495V2.434c0-1.373-.89-2.434-1.953-2.434zM25.917 41.543h-3.933V33.31h-8.35v8.232h-3.485c-.47 0-.852.55-.852 1.229 0 .678.381 1.228.852 1.228h15.768c.47 0 .852-.55.852-1.228 0-.68-.381-1.23-.852-1.23z'></path></svg>`,
      text: "Онлайн учет выполненных работ. КС-2 онлайн.",
    },
  ];

  return (
    <section>
      <div className={cn.inner}>
        <h2 className={cn.title}>Наши возможности</h2>
        <ul className={`${cn.list} row`}>
          {items.map((item, index) => (
            <li key={index} className={`${cn.item} col-12 col-md-6 col-lg-3`}>
              <div className={cn.itemTop}>
                <div className={cn.index}>
                  0{index+1}
                </div>
                <div
                  className={cn.iconWrapper}
                  dangerouslySetInnerHTML={{ __html: item.icon }}
                />
              </div>
              <div className={cn.textWrapper}>{item.text}</div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default ForCompanies;
