import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { clearSelectedWorkers } from '../../../store/actions/workerSelectActions';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderState, getServiceSelectState } from '../../../store/selectors';
import { setDefaultMaterialQuantity, setMaterials, setOrderProperty } from '../../../store/actions/orderActions';
import { TITLE_CATEGORY, TITLE_SERVICE, TITLE_SUB_CATEGORY } from '../../../config';
import { filterSubCategories } from './serviceFunctions';

export const CalculatorContext = createContext(null);
export const useCalculatorContext = () => {
    return useContext(CalculatorContext);
};

const CalculatorContextProvider = ({ children }) => {
    const dispatch = useDispatch();
    const {
        categories, services,
    } = useSelector(getServiceSelectState);
    const {
        service, subCategory, category,
    } = useSelector(getOrderState);
    const [activeCategories, setActiveCategories] = useState([]);
    const [activeSubCategories, setActiveSubCategories] = useState([]);
    const [currentCategoryId, setCurrentCategoryId] = useState(category);
    const [currentSubCategoryId, setCurrentSubCategoryId] = useState(subCategory);
    const [currentServiceId, setCurrentServiceId] = useState(service);
    const [step, setStep] = useState(0);
    const [steps, setSteps] = useState([TITLE_CATEGORY, TITLE_SUB_CATEGORY, TITLE_SERVICE]);
    const [orderCalculate, setOrderCalculate] = useState(false);

    const setServiceHandler = (id, type) => () => {
        const value = id || '';
        dispatch(setOrderProperty(value, type));
        switch (type) {
            case 'category':
                setCurrentCategoryId(value);
                localStorage.setItem('category', value);
                setOrderCalculate(true);
                break;
            case 'subCategory':
                setCurrentSubCategoryId(value);
                localStorage.setItem('subCategory', value);
                break;
            default:
                setCurrentServiceId(service === value ? '' : value);
                localStorage.setItem('service', service === value ? '' : value);
        }
    };

    useEffect(() => {
        if (!!categories && !!services && !!categories.length && !!services.length) {
            let filteredCategories = categories.filter(category => !!category.subCategory.length && category.active);
            if (!!filteredCategories.length) {
                filteredCategories = filteredCategories.filter(filteredCategory => {
                    return !!filterSubCategories(filteredCategory.subCategory, services).length;
                });
                if (!!filteredCategories.length) {
                    setActiveCategories(filteredCategories);
                    dispatch(clearSelectedWorkers());
                }
            }
        }
    }, [categories, services, filterSubCategories, dispatch]);

    useEffect(() => {
        if (currentCategoryId) {
            let currentCategory = categories.find(category => category.id === currentCategoryId);
            if (currentCategory) {
                let filteredSubCategories = filterSubCategories(currentCategory.subCategory, services);
                if (!!filteredSubCategories.length) {
                    setActiveSubCategories(filteredSubCategories);
                }
            }
        }
    }, [currentCategoryId, categories, services, filterSubCategories]);

    useEffect(() => {
        if (currentCategoryId) {
            const setOrderService = (value) => {
                dispatch(setOrderProperty(value, 'service'));
                dispatch(setMaterials(value));
                dispatch(setDefaultMaterialQuantity());
            };
            setOrderService(currentServiceId);
        }

    }, [dispatch, service, services, currentServiceId, currentCategoryId, currentSubCategoryId, setOrderProperty, setMaterials, setDefaultMaterialQuantity]);

    useEffect(() => {
        if (!!categories.length && !!currentCategoryId) {
            const currentCategory = categories.find(category => category.id === currentCategoryId);
            if (currentCategory && steps[0] !== currentCategory['name']) {
                //	setSteps([currentCategory['name'], TITLE_SUB_CATEGORY, TITLE_SERVICE]);
                //	dispatch(setOrderProperty(currentCategoryId, 'category'));
                setStep(1);
                localStorage.setItem('serviceSelectStep', 1);
            }
        }
    }, [categories, currentCategoryId, steps, setOrderProperty, dispatch]);

    useEffect(() => {
        if (currentSubCategoryId && currentCategoryId) {
            let currentCategory = categories.find(category => category.id === currentCategoryId);
            if (currentCategory) {
                let filtredSubCategories = filterSubCategories(currentCategory.subCategory, services);
                if (!!filtredSubCategories.length) {
                    const subCategory = filtredSubCategories.find(sub => sub.id === currentSubCategoryId);
                    if (subCategory) {
                        //if (steps[1] !== subCategory['name']) {
                        //	setSteps([steps[0], subCategory['name'], TITLE_SERVICE]);
                        dispatch(setOrderProperty(currentSubCategoryId, 'subCategory'));
                        setStep(2);
                        localStorage.setItem('serviceSelectStep', 2);
                        // }
                    }
                }

            }
        }
    }, [categories, services, currentSubCategoryId, filterSubCategories, currentCategoryId, steps, setOrderProperty, dispatch]);

    useEffect(() => {
        if (!!services) {
            dispatch(setOrderProperty(services.find((s) => s.id === service) || null, 'currentService'));
        }
    }, [services, service, dispatch]);

    useEffect(() => {
        if (!!categories) {
            dispatch(setOrderProperty(categories.find((c) => c.id === category) || null, 'currentCategory'));
        }
    }, [category, categories, dispatch]);

    return (
        <CalculatorContext.Provider value={{
            activeCategories,
            activeSubCategories,
            step,
            setStep,
            currentServiceId,
            setCurrentCategoryId,
            setCurrentSubCategoryId,
            setCurrentServiceId,
            filterSubCategories,
            setServiceHandler,
            orderCalculate,
            setOrderCalculate,
        }}>
            {children}
        </CalculatorContext.Provider>
    );
};

export default CalculatorContextProvider;