import axios from '../../rest/axios';
import {
	FETCH_ADMIN_ORDER_TASK_ROOM_ERROR,
	FETCH_ADMIN_ORDER_TASK_ROOM_START, FETCH_ADMIN_ORDER_TASK_ROOM_SUCCESS,
	FETCH_ADMIN_ORDER_TASKS_ERROR,
	FETCH_ADMIN_ORDER_TASKS_START, FETCH_ADMIN_ORDER_TASKS_SUCCESS,
	FETCH_ADMIN_ORDER_WORKERS_ERROR,
	FETCH_ADMIN_ORDER_WORKERS_START,
	FETCH_ADMIN_ORDER_WORKERS_SUCCESS
} from './actionTypes';

// получить список бригадиров в заказе
export const fetchAdminOrderWorkers = (orderId) => {
	return async dispatch => {
		const token = localStorage.getItem('token');
		if (token) {
			dispatch({type: FETCH_ADMIN_ORDER_WORKERS_START})
			try {
				// const response = await axios.get('/mobile/orderworkersbyday/managerMastersList/', {
				// 	params: {
				// 		orderId,
				// 		token,
				// 	}
				// })

				const data = {
					"orderId": 204,
					"date": "2020-09-09",
					"timestampDate": 1599609600,
					"countWorkersFull": 6,
					"workers": [
						{
							"id": 100,
							"name": "Ренат Бригадир",
							"picture": "https://finish-worker.alex/upload/resize_cache/main/d5c/300_300_1/d5cb11f7dbc1c7c22aec77fa106cab9f.jpg",
							"countWorkers": 2
						},
						{
							"id": 146,
							"name": "Дмитрий Сафонов",
							"picture": "https://finish-worker.alex/upload/resize_cache/main/e20/300_300_1/e2018a47f3724843d5a5a1b294901266.jpg",
							"countWorkers": 4
						}
					]
				}

				// if (response.data.data) {
				dispatch({
					type: FETCH_ADMIN_ORDER_WORKERS_SUCCESS,
					payload: {
						// data: response.data.data
						data
					}
				})
				// }
			} catch (e) {
				dispatch({type: FETCH_ADMIN_ORDER_WORKERS_ERROR})
			}
		}
	}
};

export const fetchAdminOrderTasks = (orderId) => {
	return async dispatch => {
		const token = localStorage.getItem('token');
		if (token) {
			dispatch({type: FETCH_ADMIN_ORDER_TASKS_START})
			try {
				const response = await axios.get('/admin/master/getMasterTasks/', {
					params: {
						orderId,
						token,
					}
				})
				let data = response.data.data;
				if(data.error){
					data = [];
				}
				if (response.data.data) {
					dispatch({
						type: FETCH_ADMIN_ORDER_TASKS_SUCCESS,
						payload: {
							data
						}
					})
				}
			} catch (error) {
				dispatch({type: FETCH_ADMIN_ORDER_TASKS_ERROR, payload: {error}})
			}
		}
	}
};
