import React from 'react'
import './ChatLoader.scss'

export default () => {
    return (
        <div className="ChatLoader">
            <svg  viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <circle cx="50" cy="50" r="30" strokeWidth="5" stroke="#533b8e"
                        strokeDasharray="43.982297150257104 43.982297150257104" fill="none" strokeLinecap="round"
                        transform="rotate(159.626 50 50)">
                    <animateTransform attributeName="transform" type="rotate" dur="1.4492753623188404s"
                                      repeatCount="indefinite" keyTimes="0;1" values="0 50 50;360 50 50"/>
                </circle>
                <circle cx="50" cy="50" r="22" strokeWidth="5" stroke="#d76af8"
                        strokeDasharray="34.55751918948772 34.55751918948772" strokeDashoffset="34.55751918948772"
                        fill="none" strokeLinecap="round" transform="rotate(-159.626 50 50)">
                    <animateTransform attributeName="transform" type="rotate" dur="1.4492753623188404s"
                                      repeatCount="indefinite" keyTimes="0;1" values="0 50 50;-360 50 50"/>
                </circle>
            </svg>
        </div>
    )
}
