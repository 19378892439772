import React from 'react';

const AdminOrderListFinanceActRows = ({acts, order}) => {

	return (
		<div className="w-100">
			<div className="d-flex align-items-center justify-content-between">
				<div className="col-6">Аванс</div>
				<div className="col-3">Дата</div>
				<div className="col-3">Сумма</div>
			</div>
			{
				acts.map((act) => {
					return (
						<div key={act.id} className="d-flex align-items-center justify-content-between mt-2">
							<div className="col-6 px-2">
								<div className="p-2 pl-3 bg-light">Акт №{act.id}</div>
							</div>
							<div className="col-3 px-2">
								<div className="p-2 pl-3 bg-light">От {act.date}</div>
							</div>
							<div className="col-3 px-2">
								<div className="p-2 pl-3 bg-light">{(act.value * order.unitPrice).toFixed()}</div>
							</div>
						</div>
					)
				})
			}
		</div>
	);
};

export default AdminOrderListFinanceActRows;