import {
	DELETE_WORKER_PERSONAL_LOCAL_PHOTO,
	FETCH_WORKER_CREATE_MASTERS_ERROR,
	FETCH_WORKER_CREATE_MASTERS_START,
	FETCH_WORKER_CREATE_MASTERS_SUCCESS,
	SET_WORKER_PERSONAL_PROPERTY, SET_WORKER_PERSONAL_REPORT_PHOTOS, SET_WORKER_PERSONAL_REPORT_QUANTITY,
	WORKER_PERSONAL_FETCH_DATES,
	WORKER_PERSONAL_FETCH_ERROR,
	WORKER_PERSONAL_FETCH_ORDER_DETAIL,
	WORKER_PERSONAL_FETCH_ORDER_REPORTS,
	WORKER_PERSONAL_FETCH_ORDERS,
	WORKER_PERSONAL_FETCH_START,
	WORKER_PERSONAL_FETCH_SUCCESS,
	WORKER_PERSONAL_LOGIN_ERROR,
	WORKER_PERSONAL_LOGIN_START,
	WORKER_PERSONAL_LOGIN_SUCCESS,
	WORKER_PERSONAL_LOGOUT,
	WORKER_PERSONAL_REG_ERROR,
	WORKER_PERSONAL_REG_START
} from "../actions/actionTypes";

const initialState = {
	user: {
		id: null
	},
	adminDialog: {},
	masters: [],
	dates: [],
	addresses: [],
	formControls: {
		user: {
			phone: {
				id: 'phone',
				label: 'Телефон',
				type: 'tel',
				value: '',
				valid: true,
				validation: {
					required: true,
					phone: true
				},
				errorMessage: {
					required: 'Это поле необходимо заполнить',
					phone: `Введите корректный номер телефона`
				},
			},
			// email: {
			//     id: 'email',
			//     label: 'email',
			//     type: 'email',
			//     value: '',
			//     valid: true,
			//     validation: {
			//         required: true,
			//         email: true
			//     },
			//     errorMessage: {
			//         required: 'Это поле необходимо заполнить',
			//         email: `Введите корректный email`
			//     },
			// },
			photo: {
				id: 'photo',
				label: '',
				type: 'file',
				value: '',
				valid: true,
				validation: {
					maxSize: 5000,
					image: true
				},
				errorMessage: {
					maxSize: 'Максимальный размер файла 5 МБ',
					image: `Допустимые расширения jpg, png, gif`
				},
			},
			description: {
				id: 'description',
				label: '',
				type: 'text',
				value: '',
				valid: true,
				validation: {},
				errorMessage: {},
			},
		},
		passport: {
			issuedPassport: {
				id: 'issuedPassport',
				label: 'Кем выдан',
				type: 'text',
				value: '',
				valid: true,
				error: '',
				validation: {
					// required: true
				},
				errorMessage: {
					required: 'Это поле необходимо заполнить',
				},
			},
			numberPassport: {
				id: 'numberPassport',
				label: 'Серия и номер',
				type: 'text',
				value: '',
				valid: true,
				error: '',
				validation: {
					// required: true
				},
				errorMessage: {
					required: 'Это поле необходимо заполнить',
				},
			},
			datePassport: {
				id: 'datePassport',
				label: 'Когда выдан',
				type: 'date',
				value: '',
				valid: true,
				error: '',
				validation: {
					// required: true
				},
				errorMessage: {
					required: 'Это поле необходимо заполнить',
				},
			},
			registrationPassport: {
				id: 'registrationPassport',
				label: 'Место регистрации',
				type: 'text',
				value: '',
				valid: true,
				error: '',
				validation: {
					// required: true
				},
				errorMessage: {
					required: 'Это поле необходимо заполнить',
				},
			},
		},
		card: {
			numberCard: {
				id: 'numberCard',
				label: 'Номер карты',
				type: 'text',
				value: '',
				valid: true,
				error: '',
				validation: {
					// required: true,
					// creditCard: true
				},
				errorMessage: {
					required: 'Это поле необходимо заполнить',
					// creditCard: 'Введите корректный номер карты'
				},
			},
			expirationDateCard: {
				id: 'expirationDateCard',
				label: 'Срок действия карты',
				type: 'date',
				value: '',
				valid: true,
				error: '',
				validation: {
					// required: true,
					expirationDateCard: true
				},
				errorMessage: {
					required: 'Это поле необходимо заполнить',
					expirationDateCard: 'Введите корректную дату'
				},
			},
			owner: {
				id: 'owner',
				label: 'Владелец',
				type: 'text',
				value: '',
				valid: true,
				error: '',
				validation: {
					// required: true,
				},
				errorMessage: {
					required: 'Это поле необходимо заполнить',
				},
			},
		},
	},
	isLogin: false,
	isClient: false,
	authType: 1, // тип аутентификации 1 - рег, 2 - логин
	error: null,
	loading: false,
	orders: [],
	order: {},
	reports: [],
	localReports: {},
	localPhotos: {},
	reportFormControl: {
		quantity: {
			id: 'quantity',
			label: 'Объем выполнения',
			type: 'text',
			value: 0,
			valid: true,
			error: null,
			validation: {
				required: true,
				number: true,
			},
			errorMessage: {
				required: 'Обязательное поле',
				number: 'Введите число',
			},
		},
	}
};

const workerPersonalReducer = (state = initialState, {type, payload}) => {
	const localPhotos = {...state.localPhotos}
	switch (type) {
		case WORKER_PERSONAL_FETCH_START:
			return {
				...state,
				loading: true
			}
		case WORKER_PERSONAL_FETCH_SUCCESS:

			return {
				...state,
				user: payload.about,
				loading: false,

			}
		case WORKER_PERSONAL_FETCH_ERROR:
			return {
				...state,
				error: payload.error,
				loading: false
			}
		case WORKER_PERSONAL_LOGIN_START:
			return {
				...state,
				loading: true
			}
		case WORKER_PERSONAL_LOGIN_SUCCESS:

			return {
				...state,
				loading: false,
				isLogin: true,
				role: payload.data.role,
				isClient: payload.data.isClient,
			}
		case WORKER_PERSONAL_LOGIN_ERROR:
			return {
				...state,
				loading: false,
				error: payload.error
			}
		case SET_WORKER_PERSONAL_PROPERTY:
			return {
				...state,
				[payload.property]: payload.value
			}
		case WORKER_PERSONAL_REG_START:
			return {
				...state,
				loading: true
			}
		case WORKER_PERSONAL_REG_ERROR:
			return {
				...state,
				loading: false,
				error: payload.error
			}
		case WORKER_PERSONAL_LOGOUT:
			return {
				...state,
				isLogin: false,
				token: null,
				user: {}
			}
		case FETCH_WORKER_CREATE_MASTERS_START:
			return {
				...state,
				loading: true
			}
		case FETCH_WORKER_CREATE_MASTERS_ERROR:
			return {
				...state,
				loading: false,
				error: payload.error
			}
		case FETCH_WORKER_CREATE_MASTERS_SUCCESS:
			return {
				...state,
				loading: false,
				masters: payload.data
			}
		case WORKER_PERSONAL_FETCH_DATES:
			return {
				...state,
				dates: payload.dates,
				addresses: payload.addresses,
			};
		case WORKER_PERSONAL_FETCH_ORDERS:
			return {
				...state,
				orders: payload.orders,
				loading: false
			}
		case WORKER_PERSONAL_FETCH_ORDER_DETAIL:
			return {
				...state,
				order: payload.order,
				loading: false
			}
		case WORKER_PERSONAL_FETCH_ORDER_REPORTS:
			//console.log(payload)
			return {
				...state,
				reports: payload.data.reports,
				loading: false
			}
		case SET_WORKER_PERSONAL_REPORT_PHOTOS:
			localPhotos[payload.day] = [...localPhotos[payload.day] || [], payload.photo]
			return {
				...state,
				localPhotos
			}
		case SET_WORKER_PERSONAL_REPORT_QUANTITY:
			const localReports = {...state.localReports}
			localReports[payload.day] = payload.quantity
			const reportFormControl = {...state.reportFormControl}
			reportFormControl.quantity.value = payload.quantity
			reportFormControl.quantity.error = payload.error
			return {
				...state,
				localReports,
				reportFormControl
			}
		case DELETE_WORKER_PERSONAL_LOCAL_PHOTO:
			let photos = [...localPhotos[payload.day] || []]
			localPhotos[payload.day] = photos.filter((photo) => photo.id !== payload.id)
		default:
			return {
				...state,
				localPhotos
			}
	}

};

export default workerPersonalReducer;
