import React from 'react'
import { connect } from 'react-redux'
import { modalCloseModal, modalSendMessage, modalSetFieldError, modalSetFieldValue } from '../../../store/reducers/modalReducer.js'
import Modal from './Modal.jsx'

const ModalContainer = ({modal, modalCloseModal, modalSetFieldValue, modalSetFieldError, modalSendMessage}) => {

  if (!modal.isOpen) return null

  return (
    <Modal {...modal} changeFieldValue={modalSetFieldValue} closeModal={modalCloseModal} modalSetFieldError={modalSetFieldError} sendmessage={modalSendMessage}/>
  )
}

const mapStateToProps = (state) => ({
  modal: state.modal
})
const mapDispatchToprops = {
  modalCloseModal,
  modalSetFieldValue,
  modalSetFieldError,
  modalSendMessage
}
export default connect(mapStateToProps, mapDispatchToprops)(ModalContainer)