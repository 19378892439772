import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';

const StyledRowContainer = styled.div`
  display: flex;
  align-items: end;
  flex-direction: column;
  padding: 328px 0 1rem;
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  width: 60%;
  background-color: #F1F2FA;
  padding: 15px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;

  .bold {
    font-weight: 600;
  }
`;

const ChartsFinanceLeft = ({ worksCount, materialCount, totalCount }) => {
    return (
        <StyledRowContainer>
            <StyledRow>
                <Typography variant="subtitle1" className="w-50">
                    Фин. выполнение (Работа)
                </Typography>
                <Typography variant="subtitle1" className="w-20 d-flex justify-content-end">
                    руб.
                </Typography>
                <Typography variant="subtitle1" className="w-30 d-flex justify-content-end">
                    {new Intl.NumberFormat('ru-RU').format(worksCount)}
                </Typography>
            </StyledRow>
            {
                !!materialCount && (
                    <StyledRow className="mt-3">
                        <Typography variant="subtitle1" className="w-50">
                            Фин. выполнение (Материалы)
                        </Typography>
                        <Typography variant="subtitle1" className="w-20 d-flex justify-content-end">
                            руб.
                        </Typography>
                        <Typography variant="subtitle1" className="w-30 d-flex justify-content-end">
                            {new Intl.NumberFormat('ru-RU').format(materialCount)}
                        </Typography>
                    </StyledRow>
                )
            }
            <StyledRow className="mt-3">
                <Typography variant="subtitle1" className="w-50 bold">
                    Фин. выполнение
                    <br />
                    (Работа + Материалы)
                </Typography>
                <Typography variant="subtitle1" className="bold w-20 d-flex justify-content-end">
                    руб.
                </Typography>
                <Typography variant="subtitle1" className="bold w-30 d-flex justify-content-end">
                    {new Intl.NumberFormat('ru-RU').format(totalCount)}
                </Typography>
            </StyledRow>
        </StyledRowContainer>
    );
};

export default ChartsFinanceLeft;
