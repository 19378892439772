import {FETCH_MATERIALS_ERROR, FETCH_MATERIALS_START, FETCH_MATERIALS_SUCCESS} from "../actions/actionTypes";

const initialState = {
    materials: []
}

export default function materialsReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_MATERIALS_START:
            return {
                ...state,
                loading: true
            }
        case FETCH_MATERIALS_SUCCESS:
            return {
                ...state,
                materials: action.materials,
                loading: false
            }
        case FETCH_MATERIALS_ERROR:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        default:
            return state
    }
}