import {
	ADMIN_LOGIN_ERROR,
	ADMIN_LOGIN_START, ADMIN_LOGIN_SUCCESS, ADMIN_LOGOUT, SET_ADMIN_PROPERTY,
	SET_CURRENT_TAB,

} from "../actions/actionTypes";
import {ROLE_ADMINISTRATORS, ROLE_ADMINS, ROLE_ENGINEER_PTO, ROLE_GEODESIST, ROLE_SUPPLIER} from '../../config';

const initialState = {
	clientIndividualServiceScrollTop: 0,
	currentTab: 1,
	isLogin: false,
	sendAuth: false,
	loading: false,
	navTabs: [
		{
			id: 1,
			name: "Связи для графика"
		},
		{
			id: 3,
			name: "Нормы работ",
		},
		{
			id: 4,
			name: "Цена Бригадира",
			disabled: false
		},
		{
			id: 5,
			name: "Цена Заказчику",
			disabled: false
		},
		{
			id: 2,
			name: "Нормы материалов"
		},
		{
			id: 6,
			name: "Товары",
			disabled: false
		}
	],
	tables: [
		{
			id: 1,
			name: 'communication',
			head: [
				{
					id: 5,
					title: '',

				},
				{
					id: 6,
					title: '',
					// subtitle: '(кол.дней)',

				}, {
					id: 7,
					title: '',
					// subtitle: '(кол.дней)',

				}, {
					id: 8,
					title: 'Непосредственно',
					subtitle: 'предшествующие позиции',

				}, {
					id: 9,
					title: 'Время',
					subtitle: 'выдержки',

				}
			]
		},
		{
			id: 2,
			name: 'materials',
			head: [
				{
					id: 5,
					title: 'Материалы по норме',
					col: 6
				}, {
					id: 6,
					title: 'Ед.',
					subtitle: 'изм.',
					col: 1
				}, {
					id: 7,
					title: 'Норма',
					subtitle: 'материалов',
					col: 1
				}, {
					id: 8,
					title: 'Коэффициент',
					subtitle: 'перерасхода',
					col: 3,
				}
			]
		},
		{
			id: 3,
			name: 'normWorks',
			head: [
				{
					id: 5,
					title: 'Специальность',
					col: 4
				},
				{
					id: 6,
					title: 'Норма работы час/ед.изм.',
					col: 3
				},
				{
					id: 7,
					title: 'Поправочный коэффициент',
					col: 3
				},

			]
		},
		{
			id: 4,
			name: 'priceForeman',
			head: [
				{
					id: 5,
					title: 'Специальность',
					col: 4
				},
				{
					id: 6,
					title: 'Стоимость часа',
					subtitle: 'Стандарт, ₽',
					col: 2
				},
				{
					id: 7,
					title: 'Стоимость часа',
					subtitle: 'Премиум, ₽',
					col: 2
				},
				{
					id: 8,
					title: 'Цена бригадира',
					subtitle: 'Стандарт, ₽',
					col: 2
				},
				{
					id: 9,
					title: 'Цена бригадира',
					subtitle: 'Премиум, ₽',
					col: 2
				}
			]
		},
		{
			id: 5,
			name: 'priceСustomer',
			head: [
				{
					id: 5,
					title: 'Поправочный коэффициент',
					subtitle: 'Стандарт, ₽',
					col: 3
				},
				{
					id: 6,
					title: 'Цена заказчика',
					subtitle: 'Стандарт, ₽',
					col: 3
				},
				{
					id: 7,
					title: 'Поправочный коэффициент',
					subtitle: 'Премиум, ₽',
					col: 3
				},
				{
					id: 8,
					title: 'Цена заказчика Премиум',
					subtitle: 'Премиум, ₽',
					col: 3
				}
			]
		},
		{
			id: 6,
			name: 'products',
			head: [
				{
					id: 5,
					title: 'Материал',
					col: 4
				},
				{
					id: 6,
					title: 'Ед.',
					subtitle: 'изм.',
					col: 1
				},
				{
					id: 7,
					title: 'Товар',
					col: 4
				},
				{
					id: 8,
					title: 'Рассчетное',
					subtitle: 'количество',

				},
				{
					id: 9,
					title: 'Цена, ₽',

				},
				{
					id: 10,
					title: 'Сумма, ₽',

				}
			]
		}
	],
	materialTabs: [
		{
			id: 1,
			title: '# [id]'
		},
		{
			id: 2,
			title: 'Наименование',
			col: 3
		},
		{
			id: 3,
			title: 'Ед.изм'
		},
		{
			id: 4,
			title: 'Количество',
			subtitle: '',
		},
		{
			id: 5,
			title: 'Цена, ₽',
			subtitle: '',
		},
		{
			id: 6,
			title: 'Категория',
			col: 3
		},
		{
			id: 7,
			title: '',
			col: 2
		},
	],
	professionalTabs: [
		{
			id: 1,
			title: '# [id]'
		},
		{
			id: 2,
			title: 'Наименование',
			col: 3
		},
		{
			id: 3,
			title: 'Стоимость часа',
			subtitle: 'Стандарт, ₽',
		},
		{
			id: 4,
			title: 'Стоимость часа',
			subtitle: 'Премиум, ₽',
		},
		{
			id: 5,
			title: 'Категория',
			col: 3
		},
		{
			id: 6,
			title: '',
			col: 2
		},
	],
	formControls: {
		email: {
			id: 'email',
			label: 'EMAIL',
			type: 'email',
			value: '',
			valid: true,
			error: false,
			validation: {
				required: true,
				email: true
			},
			errorMessage: {
				required: 'Это поле необходимо заполнить',
				email: `Введите корректный email`
			},
		},
		password: {
			id: 'password',
			label: 'Пароль',
			type: 'password',
			value: '',
			valid: true,
			error: false,
			validation: {
				required: true,
				// password: true
			},
			errorMessage: {
				required: 'Это поле необходимо заполнить',
				// password: 'Недопустимые символы для пароля'
			},
		},
	},
	data: {},
	leftNav: [
		[
			{
				id: 1,
				code: 'norms',
				name: 'Нормы',
				roles: [ROLE_ADMINS, ROLE_ADMINISTRATORS],
			},
			{
				id: 2,
				code: 'professions',
				name: 'Специальности',
				roles: [ROLE_ADMINS, ROLE_ADMINISTRATORS],
			},
			{
				id: 3,
				code: 'materials',
				name: 'Материалы',
				roles: [ROLE_ADMINS, ROLE_ADMINISTRATORS],
			},
			{
				id: 4,
				code: 'services',
				name: 'Услуги',
				roles: [ROLE_ADMINS, ROLE_ADMINISTRATORS],
			},
			{
				id: 5,
				code: 'goods',
				name: 'Товары',
				roles: [ROLE_ADMINS, ROLE_ADMINISTRATORS],
			}
		],
		[
			{
				id: 1,
				code: 'managers',
				name: 'Менеджеры',
				roles: [ROLE_ADMINS, ROLE_ADMINISTRATORS],
			},
			{
				id: 2,
				code: 'masters',
				name: 'Бригадиры',
				roles: [ROLE_ADMINS, ROLE_ADMINISTRATORS],
			},
			{
				id: 3,
				code: 'independentMaster',
				name: 'Индивидуальные мастера',
				roles: [ROLE_ADMINS, ROLE_ADMINISTRATORS],
			},
			{
				id: 4,
				code: 'specialists',
				name: 'Специалисты',
				roles: [ROLE_ADMINS, ROLE_ADMINISTRATORS],
			},
			{
				id: 5,
				code: 'ancillary',
				name: 'Подсобники',
				roles: [ROLE_ADMINS, ROLE_ADMINISTRATORS],
			},
			{
				id: 6,
				code: 'projectDirector',
				name: 'Руководители проектов',
				roles: [ROLE_ADMINS, ROLE_ADMINISTRATORS],
			},
			{
				id: 7,
				code: 'client',
				name: 'Заказчики',
				roles: [ROLE_ADMINS, ROLE_ADMINISTRATORS],
			},
		],
		[
			{
				id: 1,
				code: 'dates',
				name: 'Даты инструктажа',
				roles: [ROLE_ADMINS, ROLE_ADMINISTRATORS],
			},
			{
				id: 2,
				code: 'addresses',
				name: 'Адреса инструктажа',
				roles: [ROLE_ADMINS, ROLE_ADMINISTRATORS],
			},
			{
				id: 3,
				code: 'classifiers',
				name: 'Классификаторы',
				roles: [ROLE_ADMINS, ROLE_ADMINISTRATORS],
			},
			{
				id: 4,
				code: 'orderSettings',
				name: 'Настройки заказа',
				roles: [ROLE_ADMINS, ROLE_ADMINISTRATORS],
			},
		],
		[
			{
				id: 1,
				code: 'orders',
				name: 'Заказы',
				roles: [ROLE_ADMINS, ROLE_ADMINISTRATORS, ROLE_GEODESIST, ROLE_SUPPLIER, ROLE_ENGINEER_PTO],
			},
		]
	]
};

const adminReducer = (state = initialState, {type, payload}) => {


	switch (type) {
		case SET_CURRENT_TAB:
			return {
				...state,
				currentTab: payload.id
			};
		case ADMIN_LOGIN_START:
			return {
				...state,
				loading: true
			};
		case ADMIN_LOGIN_SUCCESS:
			return {
				...state,
				loading: false,
				isLogin: true,
				user: payload.data
			};
		case ADMIN_LOGIN_ERROR:
			return {
				...state,
				loading: false,
				error: payload.error
			};
		case ADMIN_LOGOUT:
			return {
				...state,
				isLogin: false
			}
		case SET_ADMIN_PROPERTY:
			return {
				...state,
				[payload.property]: payload.value
			};
		default:
			return state
	}
}

export default adminReducer



