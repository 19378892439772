import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    estimateFetchConditions,
    setCalculateData,
    setChangedData
} from '../../../../../store/actions/estimateActions';
import {
    getServicePrice,
    getTotalCategoryServicesPrice,
    getTotalMaterialsPricePerOner
} from '../Estimate/Order/calculateFunctions';
import { addDays, format, parse } from 'date-fns';
import { getReserveDays, getServiceChildren, getServiceLink, setServiceDates } from './functions';
import { useChartsContext } from './ChartsContext';

const ChartsCalculation = () => {
    const dispatch = useDispatch();
    const [levels, setLevels] = useState({});
    const { dayNorm } = useSelector(state => state.adminService);
    const { formControls, services } = useSelector(state => state.estimateOrder);
    const { dates } = useChartsContext();
    const tempServices = useMemo(() => services.map(service => ({
        ...service,
        // count: service.count * Math.random() * 10
    })), [services]);
    // console.log('dates', dates);
    // console.log('levels', levels)

    useEffect(() => {
        dispatch(estimateFetchConditions());
    }, [dispatch]);

    useEffect(() => {
        const data = [];
        const { startDate, endDate, workType } = formControls;
        const totalDays = dates.length;
        const globalStartDate = startDate.value;
        const globalEndDate = endDate.value;
        const totalWorkPrice = getTotalCategoryServicesPrice(
            tempServices,
            workType.value,
            formControls
        );
        if (!isNaN(totalWorkPrice)) {
            const services = [];
            Object.keys(levels).forEach((key) => {
                levels[key].forEach((service) => services.push(service));
            });

            services.forEach((service) => {
                const { count } = service;
                const workPrice = +getServicePrice(service, workType.value, formControls).toFixed();
                const materialPrice = +getTotalMaterialsPricePerOner(service).toFixed();
                // 30дней * (30000/100000) = 9дней
                let daysCount = Math.ceil(totalDays * (workPrice * count / totalWorkPrice));
                let workerNorm = 0;
                if (!!service.items && !!service.items.length) {
                    service.items.forEach(item => {
                        let subWorkerNorm = 0;
                        item.norms.forEach(norm => {
                            subWorkerNorm += norm.norm * norm.cc;
                        });
                        item.workPrice = +getServicePrice({
                            ...item,
                            customerCoefficient: service.customerCoefficient
                        }, workType.value, formControls).toFixed();
                        workerNorm += subWorkerNorm;

                        const itemDaysCount = Math.ceil(daysCount * (item.workPrice / workPrice));
                        item.workersCount = Math.ceil(count * subWorkerNorm / (itemDaysCount * dayNorm));
                        item.daysCount = itemDaysCount;

                    });
                } else {
                    service.norms.forEach(norm => {
                        workerNorm += norm.norm * norm.cc;
                    });
                }
                const workersCount = Math.ceil(count * workerNorm / (daysCount * dayNorm));

                let endDate = format(new Date(addDays(new Date(parse(globalStartDate, 'dd.MM.yyyy', new Date())), daysCount - 1)), 'dd.MM.yyyy');
                const links = !!service.links && !!service.links.length ?
                    service.links.map(getServiceLink).filter((link) => !!link)
                    : [];
                data.push({
                    ...service,
                    globalStartDate,
                    globalEndDate,
                    daysCount,
                    initialDaysCount: daysCount,
                    workersCount,
                    startDate: globalStartDate,
                    endDate,
                    links,
                    workPrice,
                    materialPrice: !isNaN(materialPrice) ? materialPrice : 0,
                });
            });

            const dataWithNewDates = data.map((service) => setServiceDates(data, service))
                .sort((a, b) => a.level - b.level || a.children.length - b.children.length || a.daysCount - b.daysCount);


            const dataWithReserveDays = data.map((service) => {
                const daysReserve = getReserveDays(dataWithNewDates, service, dates);
                return { ...service, daysReserve };
            });
            dispatch(setCalculateData(dataWithReserveDays));
            dispatch(setChangedData(dataWithReserveDays));
        }
    }, [formControls, levels, tempServices]);

    useEffect(() => {

        const getParents = (children) => {
            // находим всех детей, в связях которых нет id из этого же списка
            return children.filter((service) => {
                if (service.links) {
                    const links = service.links.filter(link => children.map(service => service.id).includes(getServiceLink(link)));
                    return !links.length;
                }
                return true;
            }).map(service => ({ ...service, children: getServiceChildren(tempServices, service) }));
        };

        const getLevel = (parents, level) => {
            // отбираем услуги, которые не относяться к предыдущему родительскому уровню
            const children = tempServices.filter(service => !parents.map(parent => parent.id).includes(service.id)).map(service => ({
                ...service,
                level
            }));
            return getParents(children);
        };

        /*
        * разбиваем услуги по уровням
        * */
        const levels = {};
        for (let i = 0; i < tempServices.length; i++) {
            const parents = [];
            Object.values(levels).forEach(value => {
                value.forEach(parent => parents.push(parent));
            });
            const level = getLevel(parents, i);
            if (!!level.length) {
                levels[i] = level;
            } else {
                break;
            }
        }

        setLevels(levels);

    }, [tempServices]);

    return null;
};

export default ChartsCalculation;
