import {
	ROLE_ANCILLARY, ROLE_CLIENT,
	ROLE_INDEPENDENT_MASTER,
	ROLE_MANAGER,
	ROLE_MASTER,
	ROLE_PROJECT_DIRECTOR,
	ROLE_SPECIALIST
} from '../config';

export function getUserRoleName(role) {
	switch (role) {
		case ROLE_MASTER:
			return 'masters';
		case ROLE_MANAGER:
			return 'managers';
		case ROLE_SPECIALIST:
			return 'specialists';
		case ROLE_INDEPENDENT_MASTER:
			return 'independentMasters';
		case ROLE_ANCILLARY:
			return 'ancillary';
		case ROLE_PROJECT_DIRECTOR:
			return 'projectDirectors';
		case ROLE_CLIENT:
			return 'clients';
	}
}

export function getUserRoleCode(role) {
	switch (role) {
		case ROLE_MASTER:
			return 'MASTER';
		case ROLE_MANAGER:
			return 'MANAGER';
		case ROLE_SPECIALIST:
			return 'SPECIALIST';
		case ROLE_INDEPENDENT_MASTER:
			return 'INDEPENDENT_MASTER';
		case ROLE_ANCILLARY:
			return 'ANCILLARY';
		case ROLE_PROJECT_DIRECTOR:
			return 'PROJECT_DIRECTOR';
		case ROLE_CLIENT:
			return 'CLIENT';
	}
}

export function getSpecialization(role) {
	switch (role) {
		case 5:
			return {
				id: role,
				name: 'Бригадир'
			};
		case 6:
			return {
				id: role,
				name: 'Менеджер'
			};
		case 7:
			return {
				id: role,
				name: 'Специалист'
			};
		case 9:
			return {
				id: role,
				name: 'Клиент'
			};
		case 10:
			return {
				id: role,
				name: 'Подсобник'
			};
		case 11:
			return {
				id: role,
				name: 'Самостоятельный специалист'
			};
		case 12:
			return {
				id: role,
				name: 'Руководитель проекта'
			};
		default:
			return {}
	}
}