import React, {Component, Fragment} from 'react'

import './PersonalOrderItemMaterial.scss'
import {connect} from "react-redux";

import parse from 'html-react-parser'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import empty from '../../../../../img/nophoto.jpg'
import {fetchMaterials} from "../../../../../store/actions/orderListActions";
import DraggableScroll from "../../../../../components/UI/DraggableScroll/DraggableScroll";


class PersonalOrderItemMaterial extends Component {


    async componentDidMount() {
        if (this.props.order) {
            let materials = this.props.order.materials.map(material => material.materialId)
            await this.props.fetchMaterials(materials)
        }
    }


    renderPopup(img) {
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            imageUrl: img,
            animation: false,
            showConfirmButton: false,
            showCloseButton: true,
            backdrop: `rgba(135,100,218,.9)`,
        })
    }

    render() {

        return (<Fragment>
                <DraggableScroll>
                    <div className="PersonalOrderItemMaterial pt-4">
                        <table>
                            <thead>
                            <tr>
                                {
                                    this.props.materialTableHead.map(head => {
                                        return (
                                            <th colSpan={head.col} key={head.id}>
                                                <div className="p-2 font-weight-normal">{head.name}</div>
                                            </th>
                                        )
                                    })
                                }
                            </tr>
                            </thead>

                            {
                                this.props.order
                                    ?
                                    <tbody>
                                    {
                                        this.props.order.materials.map(material => {
                                            // let orderMaterialInfo = this.getOrderMaterial(material.id)
                                            console.log('material', material)

                                            let img = material.pictures[0] ? material.pictures[0] : empty
                                            return (
                                                <tr key={material.id}>
                                                    <td>
                                                        <div className="d-flex flex-nowrap">
                                                            <div className="p-2">
                                                                <img className="PersonalOrderItemMaterial_goodImg"
                                                                     onClick={() => this.renderPopup(img)}
                                                                     src={img}
                                                                     alt={material.name}/>
                                                            </div>
                                                            <div className="p-2">{material.name}</div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div
                                                            className="d-flex flex-column p-2 text-main-1-2 font-weight-normal">
                                                            {parse(material.fullName)}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex flex-column p-2">
                                                            {parse(material.unit)}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="p-2">
                                                            {material.quantity}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="p-2">
                                                            {new Intl.NumberFormat('ru-RU').format(material.price)}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="p-2">
                                                            {new Intl.NumberFormat('ru-RU').format(material.price * material.quantity)}
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }


                                    </tbody>
                                    : null
                            }


                        </table>
                    </div>
                </DraggableScroll>

            </Fragment>
        )
    }

}

function mapStateToProps(state) {
    return {
        materialTableHead: state.orderList.materialTableHead
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchMaterials: materialsId => dispatch(fetchMaterials(materialsId))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(PersonalOrderItemMaterial)