import {
    CHANGE_PAYMENT_SYSTEM,
    FETCH_ORDER_DETAIL_START,
    FETCH_ORDER_DETAIL_SUCCESS,
    FETCH_ORDER_LIST_MATERIALS_ERROR,
    FETCH_ORDER_LIST_MATERIALS_START, FETCH_ORDER_LIST_MATERIALS_SUCCESS, FETCH_ORDER_LIST_START,
    FETCH_ORDER_LIST_SUCCESS, FETCH_PAYMENT_LIST, FETCH_PAYMENT_URL, ORDER_LIST_CANCEL_ORDER,
    ORDER_LIST_ITEM_SWITCH_TAB, ORDER_LIST_ITEM_SWITCH_TAB_SMALL,
    SET_ORDER_LIST_PROPERTY,
} from "../actions/actionTypes";

const initialState = {
    orders: [],
    fetchOrders: false,
    status: 999,
    currentTab: 1,
    currentTabSmall: 1,
    paymentUrl: '',
    paymentSystems: [],
    order: {},
    tabs: [
        {
            'id': 1,
            'name': 'Заказ',
        },
        {
            'id': 2,
            'name': 'ГРАФИК ВЫПОЛНЕНИЯ И ОПЛАТ',
        }
    ],
    tabsSmall: [
        {
            'id': 1,
            'name': 'Работы',
        },
        {
            'id': 2,
            'name': 'Материалы',
        }
    ],
    statuses: [
        {
            id: 1,
            name: 'Принят, ожидается оплата'
        },
        {
            id: 2,
            name: 'Оплачен'
        },
        {
            id: 3,
            name: 'Назначение специалиста'
        },
        {
            id: 4,
            name: 'В работе'
        },
        {
            id: 5,
            name: 'Завершен'
        },
        {
            id: 999,
            name: 'Все'
        },
    ],
    servicesTableHead: [
        // {
        //     id: 1,
        //     name: '№'
        //
        // },
        {
            id: 2,
            name: 'Работы',
            col: 3
        },
        {
            id: 3,
            name: 'Статус заказа'
        },
        {
            id: 4,
            name: 'Ед. Изм'
        },
        {
            id: 10,
            name: 'Цена, р.'
        },
        {
            id: 5,
            name: 'Объем выполнения',
        },
        {
            id: 11,
            name: 'Сумма, р.'
        },
        {
            id: 6,
            name: 'Начало работ'

        },
        {
            id: 7,
            name: 'Дата завершения'
        },
        {
            id: 8,
            name: 'Кол-во мастеров'
        },
        {
            id: 9,
            name: 'Уровень мастеров'
        }

    ],
    materialTableHead: [
        // {
        //     id: 1,
        //     name: '№'
        //
        // },
        {
            id: 2,
            name: 'Название',
        },
        {
            id: 3,
            name: 'Описание'
        },
        {
            id: 4,
            name: 'Ед. Изм'
        },
        {
            id: 5,
            name: 'Количество',
        },
        {
            id: 6,
            name: 'Цена (р.)'

        },
        {
            id: 7,
            name: 'Сумма (р.)'
        },

    ],
    materials: [],
    tranches: [],
    loading: false,
    error: null
}


export default function orderListReducer(state = initialState, action) {
    switch (action.type) {

        case FETCH_ORDER_LIST_START:
            return {
                ...state,
                fetchOrders: true,
                loading: true
            }
        case FETCH_ORDER_LIST_SUCCESS:
            // console.log(action)
            return {
                ...state,
                orders: action.orders,
                loading: false
            }


        case SET_ORDER_LIST_PROPERTY:
            return {
                ...state,
                [action.propertyName]: action.value
            }


        case ORDER_LIST_ITEM_SWITCH_TAB:
            return {
                ...state,
                currentTab: action.payload
            }
        case ORDER_LIST_ITEM_SWITCH_TAB_SMALL:
            return {
                ...state,
                currentTabSmall: action.payload
            }


        case FETCH_ORDER_LIST_MATERIALS_START:
            return {
                ...state,
                loading: true
            }
        case FETCH_ORDER_LIST_MATERIALS_SUCCESS:
            return {
                ...state,
                materials: action.materials,
                loading: false
            }
        case FETCH_ORDER_LIST_MATERIALS_ERROR:
            return {
                ...state,
                error: action.error
            }

        case FETCH_ORDER_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                order: action.order
            }
        case FETCH_ORDER_DETAIL_START:
            return {
                ...state, loading: true
            }
        case FETCH_PAYMENT_URL:
            return {
                ...state,
                paymentUrl: action.payload.paymentUrl,
                loading: false,
                error: null
            }
        case FETCH_PAYMENT_LIST:
            return {
                ...state,
                paymentSystems: action.payload.paymentSystems,
                loading: false
            };
        case CHANGE_PAYMENT_SYSTEM:
            return {
                ...state,
                loading: false
            }
        case ORDER_LIST_CANCEL_ORDER:
            return {
                ...state,
                loading: false,
            }
        default:
            return state
    }
}