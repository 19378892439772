import React, {useEffect, useState} from 'react';
import * as moment from 'moment';
import {TableRow, TableCell, Box, Typography, Table, Paper, Collapse, TableBody, IconButton} from "@material-ui/core";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const AdminOrderListDetailScoreboardThreeRow = ({
		idx,
		task,
		order,
		currentDay,
		tableVisible,
		setTableVisible,
		worker,
		workerOpen,
		setWorkerOpen,
	}) => {
		const [count, setCount] = useState(0);
		const [items, setItems] = useState([]);
		const [open, setOpen] = useState(false);

		useEffect(() => {
			if (!!items.length) {
				const count = items.map((item) => item.acceptedValue).reduce((acc, val) => acc + val, 0);
				if (!!count && !tableVisible) {
					setTableVisible(true);
				}
				setCount(count)
			}
		}, [items, tableVisible, currentDay])

		useEffect(() => {
			const items = task.items && task.items.filter(item => {
				const day = moment(item.lastInspectDate, "DD.MM.YYYY hh:mm:ss")
				return moment(currentDay, "DD.MM.YYYY").diff(moment(day), 'days') >= 0;
			}).filter((item) => {
				let res = true;
				order.classifierTypes.forEach((type) => {
					const classifiers = order.classifiers.filter((classifier) => !!classifier.value && classifier.zoneId === item.id)
					res = res && classifiers.length === order.classifierTypes.length;
				})
				return res;
			}).filter((item) => item.acceptedValue > 0);
			setItems(items);
		}, [task, currentDay])

		useEffect(() => {

			if (!!count && !workerOpen[worker.id]) {
				const workerOpenNew = {...workerOpen}
				workerOpenNew[worker.id] = true;
				setWorkerOpen(workerOpenNew)
			}

		}, [count, workerOpen])

		return (
			<>
				{
					tableVisible && !!count
					&& <>
						<TableRow onClick={() => setOpen(!open)} className="AdminOrderList__row">
							<TableCell className="AdminOrderList__cell-row"><Typography
								align='center'>{idx + 1}</Typography></TableCell>

							<TableCell className="AdminOrderList__cell-row" align="left"><Typography
								align='left'>{task.id}</Typography></TableCell>

							<TableCell className="AdminOrderList__cell-row" align="left"><Typography
								align='left'>{task.name}</Typography>

							</TableCell>

							<TableCell className="AdminOrderList__cell-row" align="left"><Typography
								align='left'>{order.unit}</Typography></TableCell>

							<TableCell className="AdminOrderList__cell-row" align="left"><Typography
								align='left'>{count}</Typography></TableCell>


							<TableCell className="AdminOrderList__cell-row" align="left"><Typography
								align='left'>{order.unitPrice}</Typography>
							</TableCell>

							<TableCell className="AdminOrderList__cell-row" align="left"><Typography
								align='left'>{(order.unitPrice * count).toFixed(2)}</Typography>
							</TableCell>

							<TableCell className="AdminOrderList__cell-row" align="center">
								<IconButton aria-label="expand row" size="small">
									{open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
								</IconButton>
							</TableCell>


						</TableRow>
						<TableRow className="AdminOrderList__detail-modal-row">
							<TableCell style={{padding: 0}} colSpan={12} className="border-0 w-100">
								<Collapse in={open} timeout="auto" unmountOnExit>
									<Box margin={0} className="AdminOrderList__detail-modal-box">
										<Typography variant="button" gutterBottom component="div" className="p-2 m-0">
											Зоны работ
										</Typography>
										<Table className="w-100">
											<Paper component={'thead'} className="AdminOrderList__hidden">
												<TableRow className="AdminOrderList__table-head">
													<TableCell className="AdminOrderList__cell border-right">
														№ п/п
													</TableCell>
													<TableCell className="AdminOrderList__cell border-right">
														№ ТЗ
													</TableCell>
													<TableCell align="left"
															   className="AdminOrderList__cell large border-right">
														Наименование работ
													</TableCell>
													<TableCell align="left"
															   className="AdminOrderList__cell  border-right">
														Ед. изм.
													</TableCell>
													<TableCell align="left"
															   className="AdminOrderList__cell  border-right">
														Кол-во
													</TableCell>
													<TableCell align="left"
															   className="AdminOrderList__cell  border-right">
														Цена
													</TableCell>
													<TableCell align="left"
															   className="AdminOrderList__cell  border-right">
														Сумма, руб.
													</TableCell>
													<TableCell className="AdminOrderList__cell"/>
												</TableRow>
											</Paper>

											<TableBody>
												{task.items && task.items.map((item, idx) => (
													<TableRow key={item.id} className="AdminOrderList__detail-modal-link"
													>
														<TableCell className="AdminOrderList__cell px-3 py-2">
															<Typography align='center'>{idx + 1}</Typography>
														</TableCell>
														<TableCell/>
														<TableCell className="p-2"
																   align="left">
															<Typography
																align='left'>{item.place}</Typography>
														</TableCell>
														<TableCell/>
														<TableCell className="p-2"
																   align="left">
															<Typography
																align='left'>{item.acceptedValue}</Typography>
														</TableCell>
														<TableCell/><TableCell/><TableCell/>

													</TableRow>
												))}
											</TableBody>
										</Table>
									</Box>
								</Collapse>
							</TableCell>
						</TableRow>
					</>

				}
			</>
		);
	}
;

export default AdminOrderListDetailScoreboardThreeRow;