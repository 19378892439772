import React from 'react';
import parse from 'html-react-parser';
import NumberFormat from 'react-number-format';

const IndividualWorksTop = ({picture, name = '', children, price = null, service}) => {
	return (
		<div className="IndividualWorks__top">
			{
				!!name
				&& (<div className="d-flex align-items-center flex-grow-1">
					<img className="IndividualWorks__top-img" src={picture} alt=""/>
					<div className="IndividualWorks__top-name">
						{name}
					</div>
				</div>)
			}
			<div className="d-flex align-items-center justify-content-between flex-grow-1">
				{children}
			</div>
			{
				price
				&& <div className="IndividualWorks__top-price">Цена:&nbsp;	<NumberFormat
					value={price}
					displayType={'text'}
					thousandSeparator={" "}/>&nbsp;₽/{parse(service.unit)}</div>
			}
		</div>
	);
};

export default IndividualWorksTop;