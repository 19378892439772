import React, {useEffect, useState} from 'react';


const CountSliderItem = ({num, title}) => {
	const [nums, setNums] = useState([]);
	useEffect(() => {
		if (!!num) {
			setNums(num.toString().split(''))
		}
	}, [num])
	return (
		<div className="CountSlider__item">
			<div className="CountSlider__item-title">
				{title}
			</div>
			<div className="CountSlider__item-num">{nums[nums.length - 4]}</div>
			<div className="CountSlider__item-num">{nums[nums.length - 3]}</div>
			<div className="CountSlider__item-num">{nums[nums.length - 2]}</div>
			<div className="CountSlider__item-num">{nums[nums.length - 1]}</div>
		</div>
	);
};

export default CountSliderItem;