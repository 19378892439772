export const getMaterialPrice = (material) => {
  //Цена на материал за весь объём работ
  let result;
  if (material.goods) {
    result = (material.goods.price / material.goods.pack * material.goods.package_coefficient) * material.quanity
  } else {
    result = material.price * material.quanity;
  }
  return result
};


const getMaterialPricePerOner = (material) => {
  let result;
  if (material.goods) {
    result = (material.goods.price / material.goods.pack * material.goods.package_coefficient) * material.norms
  } else {
    result = material.price * material.norms;
  }
  return result
};

export const getServicePrice = (service, workType, options) => {
  //Цена за единицу работы
  let price = 0;
  let percent = 0;
  Object.values(options)
    .filter((o) => o.conditional)
    .forEach((option) => {
      if (typeof option.value === "string") {
        percent += !!option.options.find((o) => o.id === option.value).percent ? option.options.find((o) => o.id === option.value).percent : 0;
      } else if (typeof option.value === "object") {
        option.value.forEach((v) => {
          percent += option.options.find((o) => o.id === v).percent;
        });
      }
    });
  let customerCoefficient =
    workType === "premium"
      ? service.customerCoefficient.premium
      : service.customerCoefficient.standard;

  if (!!service.items?.length) {
    service.items.forEach((item) => {
      item.norms.forEach((norm) => {
        price +=
          norm.cc *
          norm.norm *
          (workType === "premium"
            ? norm.costHourPremium
            : norm.costHourStandard);
      });
    });
  } else {
    service.norms.forEach((norm) => {
      price +=
        norm.cc *
        norm.norm *
        (workType === "premium" ? norm.costHourPremium : norm.costHourStandard);
    });
  }
  price *= customerCoefficient;
  price *= ((100 + percent) / 100);
  return price;
};

export const getTotalMaterialsPricePerOner = (service) => {
  if (!!service.items?.length) {
    return service.items.reduce((accum, item) => {
      return accum + getMaterialPricePerOner(item);
    }, 0);
  }
  return service.materials.reduce((accum, material) => {
    return accum + getMaterialPricePerOner(material);
  }, 0);
};

export const getTotalMaterialsPrice = (service) => {
  if (!!service.items?.length) {
    return service.items.reduce((accum, item) => {
      return accum + getTotalMaterialsPrice(item);
    }, 0);
  }
  return service.materials.reduce((accum, material) => {
    return accum + getMaterialPrice(material);
  }, 0);
};

export const getTotalCategoryServicesPrice = (services, workType, options) => {
  return services.reduce((accum, service) => {
    return accum + getServicePrice(service, workType, options) * service.count;
  }, 0);
};

export const getTotalCategoryMaterialsPrice = (services) => {
  return services.reduce((accum, service) => {
    return accum + getTotalMaterialsPrice(service);
  }, 0);
};
