import React, { useEffect, useState } from 'react'
import './ServiceConfirm.scss'
import { connect, useSelector } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import * as moment from "moment";
import NumberFormat from 'react-number-format'
import {
    createOrder, setConfirmOrderProperty, setFormControlsValue,
    validateFormControls
} from "../../../../store/actions/confirmOrderActions";
import { renderFormControl, validateControl } from "../../../../form/formFramework";
import BackBtn from "../../../../components/UI/BackBtn/BackBtn";

import AdminSendLoader from "../../../Admin/AdminComponents/AdminUI/AdminSendLoader";
import { CONFIRM_ORDER } from "../../../../config";
import ServiceConfirmTypeTabs from "./components/ServiceConfirmTypeTabs/ServiceConfirmTypeTabs";
import { validatePhone } from "../../../../form/validators";
import { getOrderState } from '../../../../store/selectors';
import { useConditionsContext } from '../../../../app/containers/Calculator/ConditionsContext';
import ServiceConfirmTranches from './components/ServiceConfirmTranches/ServiceConfirmTranches';
import DefaultLayout from '../../../../app/layout/DefaultLayout/DefaultLayout';

const ServiceConfirm = (props) => {

    const {
        currentService, startDate, dueDate, needMaterials,
    } = useSelector(getOrderState);
    const { tabs } = useSelector(state => state.confirmOrder);
    const { servicePrice, totalServicePrice, materialPrice, sum } = useConditionsContext();
    const [isUserLogin, setUserLogin] = useState(false)

    // физ лицо = 1, юр лицо = 2
    const { userType } = useSelector(state => state.confirmOrder);
    const { user } = useSelector(state => state.workerPersonal);
    const {
        formControls, validateFormControls,
        createOrder, loading,
        setFormControlsValue, setConfirmOrderProperty
    } = props;

    const confirmOrder = (evt) => {
        evt.preventDefault();
        formValidHandler();
    };

    const clearErrors = () => {
        Object.keys(formControls).forEach(key => {
            setFormControlsValue(formControls[key].id, 'error', false)
        })
    };

    const clearOrder = () => {
        const controls = ['objectName', 'objectAddress', 'objectPlace', 'requirements', 'tranches'];
        controls.forEach(control => {
            setFormControlsValue(control, 'value', '')
        })
    };

    const userTypeHandler = (id) => {
        clearErrors();
        setConfirmOrderProperty(id, 'userType');
    };

    const formValidHandler = async () => {
        let errorRef = '';
        let formValid = true;
        const generalFields = ['objectName', 'objectAddress', 'customerName', 'phone', 'email']
        const loginFields = ['customerName', 'phone', 'email']

        Object.keys(formControls).forEach((property) => {
            const formControl = formControls[property];
            if (isUserLogin && loginFields.indexOf(formControl.id) === -1) {
                if ((userType === 1 && !formControl.isCompany)
                    || ((userType === 2 && formControl.isCompany) || (userType === 2 && generalFields.indexOf(formControl.id) !== -1))) {

                    const error = validateControl(formControl.value, formControl.validation);
                    const valid = !error;
                    validateFormControls({
                        property,
                        error,
                        value: formControl.value
                    })
                    formValid = valid && formValid
                    if (error && !errorRef) {
                        errorRef = formControl.id
                    }
                }
            }
        });

        if (formValid) {
            await createOrder(servicePrice, materialPrice);
            clearOrder();
        } else {
            if (errorRef) {
                const $errorElement = document.getElementById(errorRef);
                if ($errorElement) {
                    let block = $errorElement.closest('.ConfirmOrder')
                    if (block) {
                        const top = block.offsetTop - 200;
                        window.scrollTo({
                            top,
                            behavior: 'smooth'
                        })
                    }
                }
                errorRef = ''
            }
        }
        // setFormValid(formValid)
    };

    const inputHandler = (value, property) => {

        if (property === 'phone') {
            value = validatePhone(value)
        }
        const error = validateControl(value, formControls[property].validation)
        validateFormControls({
            property,
            error,
            value
        })
    };

    useEffect(() => {
        setUserLogin(user.email && user.name && user.phone)
    }, [user])

    // console.log(user)

    return (
        <DefaultLayout>
            {/*{*/}
            {/*	!!orderId && <Redirect to='/personal'/>*/}
            {/*}*/}
            {
                loading && <AdminSendLoader/>
            }

            {
                currentService && servicePrice
                    ?
                    <>
                        <BackBtn to={'/calculate'}/>

                        <form id={CONFIRM_ORDER} onSubmit={confirmOrder}>
                            {!isUserLogin
                                && (
                                    <div className='ConfirmOrder'>
                                        <div className="row">
                                            <ServiceConfirmTypeTabs
                                                tabs={tabs}
                                                currentTab={userType}
                                                setCurrentTab={userTypeHandler}/>

                                            <div className="col-12 col-sm-4 mt-4">
                                                {renderFormControl(formControls.customerName, inputHandler, { addLabel: "*" })}
                                            </div>
                                            <div className="col-12 col-sm-4 mt-4">
                                                {renderFormControl(formControls.phone, inputHandler, { addLabel: "*" })}
                                            </div>
                                            <div className="col-12 col-sm-4 mt-4">
                                                {renderFormControl(formControls.email, inputHandler, { addLabel: "*" })}
                                            </div>


                                        </div>
                                    </div>
                                )

                            }
                            {
                                userType === 2 &&
                                <div className='ConfirmOrder mt-1'>
                                    <div className="row">
                                        <div className="col-12 col-sm-6 mt-4">
                                            {renderFormControl(formControls.companyName, inputHandler, { addLabel: "*" })}
                                        </div>
                                        <div className="col-12 col-sm-6 mt-4">
                                            {renderFormControl(formControls.companyInn, inputHandler)}
                                        </div>
                                        <div className="col-12 col-sm-6 mt-4">
                                            {renderFormControl(formControls.companyKpp, inputHandler)}
                                        </div>
                                        <div className="col-12 col-sm-6 mt-4">
                                            {renderFormControl(formControls.companyPhone, inputHandler, { addLabel: "*" })}
                                        </div>
                                        <div className="col-12 col-sm-6 mt-4">
                                            {renderFormControl(formControls.companyAddress, inputHandler)}
                                        </div>
                                        <div className="col-12 col-sm-6 mt-4">
                                            {renderFormControl(formControls.companyKorBank, inputHandler)}
                                        </div>
                                        <div className="col-12 col-sm-4 mt-4">
                                            {renderFormControl(formControls.companyBank, inputHandler)}
                                        </div>
                                        <div className="col-12 col-sm-4 mt-4">
                                            {renderFormControl(formControls.companyBik, inputHandler)}
                                        </div>
                                        <div className="col-12 col-sm-4 mt-4">
                                            {renderFormControl(formControls.companyRasBank, inputHandler)}
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className='ConfirmOrder mt-1'>
                                <div className="row">
                                    <div className="col-12 col-sm-6 mt-4">
                                        {renderFormControl(formControls.objectPlace, inputHandler, { addLabel: "*" })}
                                    </div>
                                    <div className="col-12 col-sm-6 mt-4">
                                        {renderFormControl(formControls.objectAddress, inputHandler, { addLabel: "*" })}
                                    </div>
                                    <div className="col-12 col-sm-6 mt-4">
                                        {renderFormControl(formControls.objectName, inputHandler, { addLabel: "*" })}
                                    </div>
                                    <div className="col-12 col-sm-6 mt-4 d-flex flex-column">
                                        <div className="ConfirmOrder_label">
                                            Что сделать
                                        </div>
                                        <div
                                            className='ConfirmOrder_input w-100 fixed border-bottom border-main-3'>
                                            {currentService.name}
                                        </div>
                                    </div>
                                    <div className="col-12 mt-4">
                                        {renderFormControl(formControls.requirements, inputHandler)}
                                    </div>
                                    <div className="col-12 mt-4">
                                        {renderFormControl(formControls.tranches, inputHandler)}
                                    </div>

                                </div>
                                <div className="ConfirmOrder_total-row row mt-3 mt-sm-5">
                                    <div className="col mt-2 mt-sm-0">
                                        <div className="ConfirmOrder_label">
                                            Сумма работ
                                        </div>
                                        <div className="ConfirmOrder_total mt-2">

                                            {
                                                <NumberFormat
                                                    value={totalServicePrice}
                                                    displayType={'text'}
                                                    thousandSeparator={" "}/>
                                            }
                                            &nbsp;руб

                                        </div>
                                    </div>

                                    {
                                        needMaterials === 2
                                        && (
                                            <>
                                                <div className="col mt-2 mt-sm-0">
                                                    <div className="ConfirmOrder_label">
                                                        Сумма материалов
                                                    </div>
                                                    <div className="ConfirmOrder_total mt-0 mt-sm-2">

                                                        <NumberFormat
                                                            value={materialPrice}
                                                            displayType={'text'}
                                                            thousandSeparator={" "}/>

                                                        &nbsp;руб

                                                    </div>
                                                </div>

                                            </>
                                        )

                                    }
                                    <div className="col mt-2 mt-sm-0">
                                        <div className="ConfirmOrder_label">
                                            Итоговая сумма
                                        </div>
                                        <div className="ConfirmOrder_total mt-0 mt-sm-2">
                                            <NumberFormat
                                                value={sum}
                                                displayType={'text'}
                                                thousandSeparator={" "}/>

                                            &nbsp;руб
                                        </div>
                                    </div>


                                    <div className="col mt-2 mt-sm-0">
                                        <div className="ConfirmOrder_label">
                                            Срок начала работ
                                        </div>
                                        <div className="ConfirmOrder_total mt-0 mt-sm-2">
                                            {moment(startDate, "DD.MM.YYYY")
                                                .format("DD.MM.YYYY")}
                                        </div>
                                    </div>
                                    <div className="col mt-2 mt-sm-0">
                                        <div className="ConfirmOrder_label">
                                            Срок завершения работ
                                        </div>
                                        <div className="ConfirmOrder_total mt-0 mt-sm-2">
                                            {moment(startDate, "DD.MM.YYYY")
                                                .add((+dueDate > 0 ? +dueDate - 1 : 1), 'd')
                                                .format("DD.MM.YYYY")}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className='ConfirmOrder container position-relative mt-1'>
                                <ServiceConfirmTranches/>
                            </div>
                            <div className="container mt-3 d-flex justify-content-end align-items-center">
                                <button className="ConfirmOrder_btn" type='submit'>Подтвердить заказ</button>
                            </div>
                        </form>
                    </>
                    :
                    <Redirect to={'/app'}/>
            }
        </DefaultLayout>
    )
}

function mapStateToProps(state) {
    return {
        order: state.order,
        formControls: state.confirmOrder.formControls,
        services: state.serviceSelect.services,
        loading: state.confirmOrder.loading,
        orderId: state.confirmOrder.orderId,
    }
}

const actions = {
    validateFormControls,
    createOrder,
    setFormControlsValue,
    setConfirmOrderProperty
};

export default withRouter(connect(mapStateToProps, actions)(ServiceConfirm))




