import React, {Component} from 'react'
import './DraggableScroll.scss'


export default class DraggableScroll extends Component {

    constructor(props) {
        super(props)
        this.draggableScroll = React.createRef()
    }


    createDraggable() {
        const slider = this.draggableScroll.current;
        let isDown = false;
        let startX;
        let scrollLeft;

        slider.addEventListener('mousedown', (e) => {
            isDown = true;
            slider.classList.add('active');
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        });
        slider.addEventListener('mouseleave', () => {
            isDown = false;
            slider.classList.remove('active');
        });
        slider.addEventListener('mouseup', () => {
            isDown = false;
            slider.classList.remove('active');
        });
        slider.addEventListener('mousemove', (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 1.5; //scroll-fast
            slider.scrollLeft = scrollLeft - walk;
        });
    }

    componentDidMount() {
        this.createDraggable()
    }

    render() {
        return (
            <>
                <div  className="DraggableScroll">
                    <div ref={this.draggableScroll} className="DraggableScroll_container">
                        {this.props.children}
                    </div>
                    <div className="DraggableScroll_arrow DraggableScroll_arrow-left"/>
                    <div className="DraggableScroll_arrow DraggableScroll_arrow-right"/>
                </div>

            </>
        );
    }

}