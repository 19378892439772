import React from 'react';
import ChartsTable from './components/ChartsTable';
import ChartsTableLeft from './components/ChartsTableLeft';
import ChartsTableHeadTitle from './components/ChartsTableHeadTitle';
import { Typography } from '@material-ui/core';
import ChartsTableLeftRow from './components/ChartsTableLeftRow';
import ChartsTableHeadDates from './components/ChartsTableHeadDates';
import ChartsTableRight from './components/ChartsTableRight';
import ChartsWorkerCountRow from './components/ChartsWorkerCountRow';
import styled from 'styled-components';
import { CHART_WORKERS_COUNTS } from './config';

const StyledTitle = styled.div`
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.1;
  letter-spacing: 0.00714em;
`;

const titleCols = [
    {
        id: 1,
        title: '',
        col: 8,
    },
    {
        id: 2,
        title: 'Количество человек',
        col: 4,
    },
];
const maxCount = 10;

const ChartsWorkerCounts = () => {
    return (
        <ChartsTable>
            <ChartsTableLeft>
                <ChartsTableHeadTitle titleCols={titleCols} />
                {
                    Array(maxCount).fill(0).map((item, idx) => (
                        <ChartsTableLeftRow key={idx}>
                            <Typography variant="subtitle2" className="col-12 text-right">
                                <StyledTitle>{maxCount - idx}</StyledTitle>
                            </Typography>
                        </ChartsTableLeftRow>
                    ))
                }
            </ChartsTableLeft>
            <ChartsTableRight table={CHART_WORKERS_COUNTS}>
                <ChartsTableHeadDates  />
                <ChartsWorkerCountRow maxCount={maxCount} />
            </ChartsTableRight>
        </ChartsTable>
    );
};

export default ChartsWorkerCounts;
