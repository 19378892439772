import React  from 'react';
import { Typography } from '@material-ui/core';
import Button from '../../../../../ui/Button/Button';
import styled from 'styled-components';
import { BiCheckCircle } from 'react-icons/bi';

const StyledLink = styled.a`
  color: inherit;
  border-radius: 15px;
  width: fit-content;
  border: 2px solid #57297C;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    text-decoration: none;
    color: #57297C;
  }
`;

const ChartsNotification = ({setPage}) => {

    const confirmComplexHandler = () => {
       setPage(2)
    }

    return (
        <div className="my-5">
            <Typography variant="subtitle1" className="w-75">
                ИСПОЛНИТЕЛЬ приступает к работе по указанному термину и в указанном количестве с целю завршения работ в
                указанный срок.
                ЗАКАЗЧИК предоставляет необходимые условия согласно уровню услуги и авансно оплачивает работу на р/с
                компании.
                Компания только ПОСЛЕ приема работ с стране Заказчика зачытивает оплаченную авансную сумму и отправляет
                исполнителю.
            </Typography>
            <div className="d-flex align-items-center justify-content-between mt-5">
                <div className="d-flex align-items-center">
                    <StyledLink>
                        Ознакомиться с Договором
                    </StyledLink>
                    <div className="d-flex align-items-center ml-5">
                        <BiCheckCircle size={24} />
                        <Typography className="ml-2">Согласен с условиями Договора</Typography>
                    </div>
                </div>
                <div style={{ width: 150 }}  onClick={confirmComplexHandler}>
                    <Button title="Заказать" />
                </div>
            </div>
        </div>
    );
};

export default ChartsNotification;
