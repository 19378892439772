import React, {useEffect, useState} from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {a11yProps} from './functions';
import {useSelector} from 'react-redux';
import {getAdminOrderListOnlineTabsFinance, getAdminOrderListState} from './selectors';
import AdminOrderListDetail from './AdminOrderListDetail/AdminOrderListDetail';
import AdminOrderListDetailTasks from './AdminOrderListDetail/AdminOrderListDetailTasks';
import AdminOrderListDetailReceptionWorks from './AdminOrderListDetail/AdminOrderListDetailReceptionWorks';
import AdminOrderListDetailWorkSubmit from './AdminOrderListDetail/AdminOrderListDetailWorkSubmit';
import {getAdminState} from '../AdminLeftNav/selectors';
import AdminOrderListDetailScoreboard from './AdminOrderListDetail/AdminOrderListDetailScoreboard';

const AdminOrderListOnline = () => {
		const {onlineTabsFinance, order} = useSelector(getAdminOrderListState)
		const {user} = useSelector(getAdminState);
		const [currentTab, setCurrentTab] = useState(null);
		const [openModal, setOpenModal] = useState(false);
		const [title, setTitle] = useState('');
		const [tabs, setTabs] = useState([]);
		const setCurrentTabHandler = (event, newValue) => {
			setCurrentTab(newValue);
			setOpenModal(true)
			document.body.style.overflow = 'hidden';
		};
		const closeHandler = () => {
			setTitle('');
			setOpenModal(false);
			document.body.style.overflow = '';
		}
		useEffect(() => {
			if (!!onlineTabsFinance.length) {
				const tabs = onlineTabsFinance.filter(tab => tab.roles.includes(user.role));
				setCurrentTab(tabs[0].id);
				setTabs(tabs);
			}
		}, [onlineTabsFinance]);

		useEffect(() => {
			if (!!tabs.length) {
				const title = tabs.find((tab) => tab.id === currentTab).name;
				setTitle(title)
			}
		}, [tabs, currentTab]);

		return (
			<>
				<Tabs
					value={currentTab}
					variant="standard"
					indicatorColor="secondary"
					onChange={setCurrentTabHandler}
					scrollButtons={'on'}
					classes={{
						root: "AdminOrderList__finance-links"
					}}
				>
					{
						!!tabs.length && tabs.map((tab) => {
							return <Tab key={tab.id}
										value={tab.id}
										disabled={tab.disabled}
										label={tab.name}
										{...a11yProps(tab.id)}
										classes={{root: 'AdminOrderList__finance-link'}}
							/>
						})
					}
				</Tabs>
				{
					openModal && currentTab &&
					<AdminOrderListDetail closeHandler={closeHandler} title={`${title} по заказу ${order.id}`}>
						{currentTab === 1 && <AdminOrderListDetailTasks order={order}/>}
						{currentTab === 2 && <AdminOrderListDetailReceptionWorks order={order}/>}
						{currentTab === 3 && <AdminOrderListDetailWorkSubmit order={order}/>}
						{currentTab === 4 && <AdminOrderListDetailScoreboard order={order}/>}
					</AdminOrderListDetail>
				}
			</>
		);
	}
;

export default AdminOrderListOnline;