import React, {Fragment} from 'react';
import List from "@material-ui/core/List";

import {useAdmin} from "../../context/adminContext";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {withRouter} from "react-router-dom";
import {useSelector} from 'react-redux';

import AdminLeftNavItem from './AdminLeftNavItem';
import {getAdminState} from './selectors';
import {ROLE_ADMINISTRATORS, ROLE_ADMINS} from '../../../../config';


const AdminLeftNav = (props) => {

	const {compact, compactHandler} = useAdmin();
	const {leftNav, user} = useSelector(getAdminState);

	const navHandler = (tab) => {
		props.history.push('/admin/' + tab)
	};

	const selected = props.match.params.tab;

	return (
		<div className={`a__nav ${compact ? 'compact' : 'full'}`}>
			<div>
				<div onClick={compactHandler} className='a__nav_arrow'>
					<div className='a__nav_compact'>
						{
							compact
								?
								<NavigateNextIcon/>
								:
								<NavigateBeforeIcon/>
						}
					</div>
				</div>
				<div className='a__nav-scroll'>

					{
						leftNav.map((group, idx) => {
							return (
								<Fragment key={idx}>
									<List
										className='a__nav-list py-0'
										component='nav'
									>
										{
											group.map(item => {
												return item.roles.includes(user.role)
													? (<AdminLeftNavItem key={item.id}
																		 code={item.code}
																		 name={item.name}
																		 onClick={navHandler}
																		 selected={selected}/>)
													: null;
											})
										}
									</List>
									{
										[ROLE_ADMINS, ROLE_ADMINISTRATORS].includes(user.role)
										&& <div className='a__nav_divider'/>
									}
								</Fragment>
							)
						})

					}

					{/*<List*/}
					{/*    className='a__nav-list'*/}
					{/*    component='nav'*/}
					{/*>*/}
					{/*    <ListItem button selected={false} onClick={handleClick}>*/}
					{/*        <ListItemIcon>*/}
					{/*            <WorkIcon/>*/}
					{/*        </ListItemIcon>*/}
					{/*        <ListItemText primary="История заказов"/>*/}
					{/*        {open ? <ExpandLess/> : <ExpandMore/>}*/}
					{/*    </ListItem>*/}
					{/*    <Collapse in={open} timeout="auto"*/}
					{/*              unmountOnExit>*/}
					{/*        <List component="div" disablePadding>*/}
					{/*            <ListItem button className='pl-5' selected={selected === 'orders-active'}*/}
					{/*                      onClick={() => navHandler('orders-active')}>*/}
					{/*                <ListItemIcon>*/}
					{/*                    <StarBorder/>*/}
					{/*                </ListItemIcon>*/}
					{/*                <ListItemText primary="Текущие"/>*/}
					{/*            </ListItem>*/}
					{/*            <ListItem button className='pl-5' selected={selected === 'orders-done'}*/}
					{/*                      onClick={() => navHandler('orders-done')}>*/}
					{/*                <ListItemIcon>*/}
					{/*                    <StarBorder/>*/}
					{/*                </ListItemIcon>*/}
					{/*                <ListItemText primary="Завершенные"/>*/}
					{/*            </ListItem>*/}
					{/*        </List>*/}
					{/*    </Collapse>*/}
					{/*</List>*/}


				</div>
			</div>
			{/*<AdminFooter/>*/}
		</div>
	);
};


export default withRouter(AdminLeftNav);