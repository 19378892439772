import React, {useState} from 'react';
import {Paper} from '@material-ui/core';
import AdminBtn from '../../AdminUI/AdminBtn';
import AdminOrderListFinanceModal from './AdminOrderListFinanceModal';
import NumberFormat from 'react-number-format';

const AdminOrderFinancePaysMasters = ({order, pays, reports}) => {

		const [master, setMaster] = useState({})

		const modalShowHandler = (master) => {
			setMaster(master)
			document.querySelector('body').style.overflowY = 'hidden';
		}

		const modalCloseHandler = (evt) => {
			if (!evt || evt.target.classList.contains('js-close')) {
				setMaster({})
				document.querySelector('body').style.overflowY = 'auto';
			}
		}

		return (
			<>
				<div className="mx-0 px-2 pb-2 d-flex flex-column">
					<div className="AdminOrderList__finance-head d-flex align-items-center mt-2 py-2 border-bottom">
						<div className="text-secondary col">#</div>
						<div className="text-secondary col-3">Бригадир</div>
						<div className="text-secondary col-2">
							Осталось для <br/> выплаты по выполнению
						</div>
						<div className="text-secondary col-2">Аванс</div>
						<div className="text-secondary col-2 pl-1">Сумма, руб.</div>
						<div className="text-secondary col-2 pl-1">Дата платежа</div>
						{/*<div className="text-secondary col-2 px-0">Статус</div>*/}
					</div>
					{
						order.workers.map((master, master_idx) => {
							let sum = 0;
							let value = 0;
							const reported = reports.filter((report) => report.masterId === master.id);
							if (!!reported.length) {
								reported.forEach(report => {
									value += report.value
								})
							}
							if (pays[master.id]) {
								pays[master.id].forEach(pay => {
									sum += pay.sum;
								})
							}
							let balance = value * order.masterPrice - sum > 0 ? (value * order.masterPrice - sum).toFixed() : 0;
							let prepayment = sum - value * order.masterPrice > 0 ? (sum - value * order.masterPrice).toFixed() : 0;

							return (
								<Paper key={master.id} className="d-flex flex-column mt-2 py-2 px-3">
									<div className="d-flex py-1">
										<div className="col px-0">{master_idx + 1}</div>
										<div className="col-3 px-0">{master.name}</div>
										<div className="col-2"><NumberFormat
											value={balance}
											displayType={'text'}
											thousandSeparator={" "}/> р.
										</div>
										<div className="col-2"><NumberFormat
											value={prepayment}
											displayType={'text'}
											thousandSeparator={" "}/> р.</div>
										<ul className="col-4 pl-2 m-0">
											{
												pays[master.id] && pays[master.id].map((pay) => (
														<li key={pay.id}
															className="AdminOrderList__finance-li d-flex align-items-center pb-2">
															<div className="col-6 p-0"><NumberFormat
																value={pay.sum}
																displayType={'text'}
																thousandSeparator={" "}/> р.
															</div>
															<div className="col-6 pl-3">
																{pay.date}
																{/*<AdminOrderListFinanceStatus id={pay.status.id}*/}
																{/*							 text={pay.status.value}/>*/}
															</div>
														</li>
													)
												)
											}
										</ul>
									</div>
									<div className="AdminOrderList__finance-item d-flex align-items-center border-top pt-2">
										<div className="col-8 d-flex align-items-center justify-content-between pl-1">
											<AdminBtn type="add" text="Добавить оплату"
													  onClick={() => modalShowHandler(master)}
											/>
											<strong>
												Итого оплачено:
											</strong>
										</div>
										<div className="px-2 font-weight-bold">
											<NumberFormat
												value={sum}
												displayType={'text'}
												thousandSeparator={" "}/> р.
										</div>
									</div>
								</Paper>
							)
						})
					}
				</div>
				{
					!!master.id && <AdminOrderListFinanceModal master={master} orderId={order.id} onClose={modalCloseHandler}/>
				}
			</>
		);
	}
;

export default AdminOrderFinancePaysMasters;