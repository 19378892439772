import React, {useEffect, useState} from 'react';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Accordion, AccordionDetails} from '@material-ui/core';
import AdminOrderListFinancePayRows from './AdminOrderListFinancePayRows';
import AdminOrderListFinanceAccordion from '../AdminOrderListFinanceAccordion';

const AdminOrderListFinancePayed = ({order, sum}) => {
	const [pays, setPays] = useState([])

	useEffect(() => {
		if (!!order.tranches.length) {
			setPays(order.tranches.filter(tranche => tranche.isPaid))
		}
	}, [order])

	return (
		<div className="px-1">
			<AdminOrderListFinanceAccordion
				name="Оплачено"
				sum={sum}>
				<AdminOrderListFinancePayRows pays={pays}/>
			</AdminOrderListFinanceAccordion>
		</div>
	);
};

export default AdminOrderListFinancePayed;