import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAdminProfessionalListProfessions } from "../../../store/actions/adminProfessionalListActions";
import Slider from "react-slick";
import "./WorkersSlider.scss";
import { NextArrow } from "./WorkersSliderNextBtn";
import { PrevArrow } from "./WorkersSliderPrevBtn";

const getDeclByNum = (names, number) => {
	const cases = [2, 0, 1, 1, 1, 2];
	return names[
		number % 100 > 4 && number % 100 < 20
			? 2
			: cases[number % 10 < 5 ? number % 10 : 5]
	];
};

const WorkersSlider = () => {
	const dispatch = useDispatch();
	const { professions } = useSelector((state) => state.adminProfessionalList);
	const [professionsFiltered, setProfessionsFiltered] = useState([]);

	useEffect(() => {
		dispatch(fetchAdminProfessionalListProfessions());
	}, []);
	useEffect(() => {
		setProfessionsFiltered(professions.filter((p) => p.workersCount > 0));
	}, [professions]);

	const setting = {
		dots: false,
		infinite: true,
		speed: 1000,
		slidesToShow: 5,
		slidesToScroll: 1,
		centerMode: true,
		centerPadding: "0",
		draggable: false,
		autoplay: true,
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
		responsive: [
			{
				breakpoint: 1199,
				settings: {
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 1,
					fade: true,
				},
			},
		],
	};

	return (
		<section className="WorkersSlider">
			<h2 className='WorkersSlider__title'>Собственная база квалифицированных строителей</h2>
			{!!professions.length && (
				<Slider {...setting}>
					{professionsFiltered.map((profession) => (
						<div key={profession.id} className="WorkersSlider__item">
							<div
								className="WorkersSlider__item-inner"
								style={{ backgroundImage: `url(${profession.picture})` }}
							>
								<div className="WorkersSlider__item-inner-text">
									<div className="WorkersSlider__item-name">
										{getDeclByNum(profession.name_2, profession.workersCount+20)}
									</div>{" "}
									{(profession.workersCount+20)
										.toString()
										.split("")
										.map((n, idx) => (
											<span key={idx.toString() + profession.id.toString()} className="WorkersSlider__item-num">{n}</span>
										))}
								</div>
							</div>
						</div>
					))}
				</Slider>
			)}
		</section>
	);
};

export default WorkersSlider;
