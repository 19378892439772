import React, {useEffect, useState} from 'react';
import './AdminGoodsRight.scss'
import {useDispatch, useSelector} from "react-redux";
import AdminGoodsCard from "../AdminGoodsCard/AdminGoodsCard";
import Pagination from "@material-ui/lab/Pagination";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import Typography from "@material-ui/core/Typography";
import {withRouter, useHistory} from "react-router-dom";
import {
	addAdminGoodsCategory,
	addAdminGoodsGood,
	setAdminGoodsCategoryProperty, setAdminGoodsProperty
} from "../../../../../store/actions/adminGoodsActions";
import cuid from 'cuid'
import AdminGoodsCategoryDetail from "../AdminGoodsCategoryDetail/AdminGoodsCategoryDetail";

const AdminGoodsRight = () => {
	const {goods, categories, currentCategory, currentPage} = useSelector(state => state.adminGoods);
	const history = useHistory();
	const dispatch = useDispatch();
	const [page, setPage] = useState(1);
	const [count, setCount] = useState(1);
	const [pageGoods, setPageGoods] = useState([]);
	const [isCategoryEdit, setIsCategoryEdit] = useState(false);
	const [canEdit, setCanEdit] = useState(false);

	const countItemsOnPage = 6;

	const pageHandler = (evt, val) => {
		setPage(val)
		dispatch(setAdminGoodsProperty(val, 'currentPage'))
	};

	const createGood = () => {
		dispatch(addAdminGoodsGood(currentCategory));
		setPage(1);
	};

	const addCategoryHandler = () => {
		const id = cuid();
		dispatch(addAdminGoodsCategory(id));
		// dispatch(setAdminGoodsProperty(id, 'currentCategory'))
	};

	const editCategoryHandler = () => {
		if (currentCategory) {
			dispatch(setAdminGoodsCategoryProperty(currentCategory, true, 'edit'))
		}
	};

	useEffect(() => {
		if (!!goods.length) {
			let currentGoods = goods.filter(good => {
				if (good.section) {
					return good.section.map(section => section.id).includes(currentCategory)
				}
				return false;
			}).filter(good => !good.isNew);
			let pageGoods = [];

			if (page === 1) {
				pageGoods = currentGoods.slice(0, countItemsOnPage)
			} else {
				pageGoods = currentGoods.slice((page - 1) * countItemsOnPage, (page - 1) * countItemsOnPage + countItemsOnPage)
			}
			setPageGoods(pageGoods);
			setCount(Math.ceil(currentGoods.length / countItemsOnPage))
		}
	}, [goods, page, currentCategory]);


	useEffect(() => {
		setPage(currentPage)
	}, [currentPage]);

	useEffect(() => {
		let editCategory = categories.find(category => category.edit);
		setIsCategoryEdit(!!editCategory);
	}, [categories]);

	useEffect(() => {
		let editGoods = goods.find(good => good.isNew);
		if (!!editGoods && editGoods.isNew && editGoods.edit) {
			history.push('/admin/goods/' + editGoods.id);
		}
	}, [goods]);

	useEffect(() => {
		setCanEdit(!!categories.find(category => currentCategory === category.id))
	}, [currentCategory, categories])

	return (
		<div className='AdminGoodsRight col-9'>
			{
				isCategoryEdit
					?
					<AdminGoodsCategoryDetail/>
					:
					<>
						<Paper className="mt-2">
							<div className="d-flex p-3 justify-content-end">
								{
									canEdit
									&& (
										<Button
											className="Admin_btn text mr-2"
											variant="contained"
											color="primary"
											onClick={editCategoryHandler}
										>
											<EditIcon
												className='Admin_btn-icon Admin_btn-icon-create mr-1'/>
											<Typography variant="overline" display="block">
												Редактировать раздел
											</Typography>
										</Button>
									)
								}

								<Button
									className="Admin_btn text mr-2"
									variant="contained"
									color="primary"
									onClick={addCategoryHandler}
								>
									<AddIcon
										className='Admin_btn-icon Admin_btn-icon-create mr-1'/>
									<Typography variant="overline" display="block">
										Добавить раздел
									</Typography>
								</Button>
								<Button
									className="Admin_btn text mr-2"
									variant="contained"
									color="primary"
									onClick={createGood}
								>
									<AddIcon
										className='Admin_btn-icon Admin_btn-icon-create mr-1'/>
									<Typography variant="overline" display="block">
										Добавить товар
									</Typography>
								</Button>
							</div>

						</Paper>

						{
							pageGoods.length
								?
								<div className="row">
									{
										pageGoods.map(good => {
											return (
												(<AdminGoodsCard key={good.id} good={good}/>)
											)
										})
									}
								</div>
								:
								<Paper className='AdminGoodsRight__empty'>
									ТОВАРЫ ОТСУТСТВУЮТ
								</Paper>

						}
						{
							count > 1 &&
							<div className='d-flex justify-content-center'>
								<Pagination
									count={count}
									color="primary"
									page={page}
									onChange={pageHandler}/>
							</div>
						}
					</>
			}
		</div>
	);
};

export default withRouter(AdminGoodsRight);