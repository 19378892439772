import React, {useEffect} from 'react';
import {Paper} from '@material-ui/core';
import {Link} from 'react-router-dom'
import OrderStatus from '../../../../components/UI/OrderStatus/OrderStatus';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {useDispatch, useSelector} from 'react-redux';
import {fetchWorkerPersonalOrders} from '../../../../store/actions/workerPersonalActions';
import {getWorkerPersonalLoading, getWorkerPersonalOrders} from '../../../../store/selectors';
import Loader from '../../../../components/UI/Loader/Loader';

const WorkerPersonalOrdersCurrent = () => {

	const dispatch = useDispatch();
	const orders = useSelector(getWorkerPersonalOrders)
	const loading = useSelector(getWorkerPersonalLoading)

	useEffect(() => {
		dispatch(fetchWorkerPersonalOrders())
	}, [])

	if (loading) {
		return <Loader/>
	}

	return (
		<>
			<h3 className="py-3">Заказы</h3>
			{
				!!orders.length
					?
					<ul className="wp__list p-0">
						{
							orders.map((order) => {
								return (
									<li key={order.id} className="mt-2">
										<Link to={`/worker/personal/orders-current/${order.id}`}>
											<Paper className="d-flex align-items-center p-3">
												<div className="col-2">Заказ № {order.id}</div>
												<div className="col-5">{order.serviceName}</div>
												<div className="col-2">{order.location && order.location.address}</div>
												<div className="col">
													<OrderStatus status={order.status}>
														Дата окончания {order.finishDate}
													</OrderStatus>
												</div>
												{/*<div className="col-2 PersonalOrderItem__cancel font-weight-bold">*/}
												{/*	<HighlightOffIcon className='mr-2'/>*/}
												{/*	Отменить заказ*/}
												{/*</div>*/}
											</Paper>
										</Link>
									</li>
								)
							})
						}
					</ul>
					:
					<div>
						Список заказов пуст
					</div>
			}
		</>
	);
};

export default WorkerPersonalOrdersCurrent;