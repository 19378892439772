import React from 'react';
import { getDayInfo } from '../functions';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import { DATE_WIDTH, ROW_HEIGHT } from '../config';

const StyledRightCol = styled.div`
  display: flex;
  align-items: center;
  height: ${ROW_HEIGHT};
  width: ${DATE_WIDTH};
  min-width: ${DATE_WIDTH};
  flex-direction: column;
  padding: 15px 0 0;
`;

const ChartsTableRightCol = ({ date, value }) => {
    const day = getDayInfo(date);
    return (
        <StyledRightCol>
            {/*{day.weekDay !== 0 && <Typography variant="subtitle1">{value}</Typography>}*/}
            {<Typography variant="subtitle1">{value}</Typography>}
        </StyledRightCol>
    );
};

export default ChartsTableRightCol;

