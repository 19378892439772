import {
    CONFIRM_ORDER_SET_FORM_CONTROL_VALUE,
    CONFIRM_ORDER_START,
    CONFIRM_ORDER_SUCCESS,
    SET_CONFIRM_ORDER_PROPERTY,
    SET_CONFIRM_ORDER_TRANCHE_MAX,
    VALIDATE_FORM_CONTROLS
} from "../actions/actionTypes";

const initialState = {
    userId: '',
    userType: 1,
    orderId: null,
    isLogin: false,
    heading: "Заполните заказ на выполнение работы:",
    objectAddress: '',
    objectPlace: '',
    objectName: '',
    requirements: '',
    customerName: '',
    phone: '',
    email: '',
    error: null,
    tranches: 1,
    trancheData: [],
    trancheDataComplex: [],
    tabs: [
        {
            'id': 1,
            'name': 'Данные заказа',
        },
        {
            'id': 2,
            'name': 'Данные клиента',
        }
    ],
    tabsSmall: [
        {
            'id': 1,
            'name': 'Физ.лицо',
        },
        {
            'id': 2,
            'name': 'Юр. лицо',
        }
    ],
    send: false,
    loading: false,
    formControls: {
        objectName: {
            id: 'objectName',
            label: 'Место работы',
            type: 'text',
            value: '',
            valid: true,
            error: '',
            validation: {
                required: true,
                minLength: 3
            },
            errorMessage: {
                required: 'Это поле необходимо заполнить',
                minLength: `Минимум 3 символа`
            },
        },
        objectAddress: {
            id: 'objectAddress',
            label: 'Адрес объекта',
            type: 'text',
            value: '',
            valid: true,
            error: '',
            validation: {
                required: true,
            },
            errorMessage: {
                required: 'Это поле необходимо заполнить',
            },
        },
        objectPlace: {
            id: 'objectPlace',
            label: 'Объект',
            type: 'text',
            value: '',
            valid: true,
            validation: {
                required: true,
            },
            errorMessage: {
                required: 'Это поле необходимо заполнить',
            },
        },
        requirements: {
            id: 'requirements',
            label: 'Требования',
            type: 'text',
            value: '',
            valid: true,
            validation: {},
            errorMessage: {},
        },
        customerName: {
            id: 'customerName',
            label: 'Контактное лицо',
            type: 'text',
            value: '',
            valid: true,
            validation: {
                required: true,
                minLength: 3
            },
            errorMessage: {
                required: 'Это поле необходимо заполнить',
                minLength: `Минимум 3 символа`
            },
        },
        phone: {
            id: 'phone',
            label: 'Телефон',
            type: 'tel',
            value: '',
            valid: true,
            validation: {
                required: true,
                phone: true
            },
            errorMessage: {
                required: 'Это поле необходимо заполнить',
                phone: `Введите корректный номер телефона`
            },
        },
        email: {
            id: 'email',
            label: 'E-mail',
            type: 'text',
            value: '',
            valid: true,
            validation: {
                required: true,
                email: true
            },
            errorMessage: {
                required: 'Это поле необходимо заполнить',
                email: `Введите корректный email`
            },
        },
        tranches: {
            id: 'tranches',
            label: 'Укажите комфортное вам количество платежей',
            type: 'text',
            value: 1,
            valid: true,
            error: '',
            validation: {
                required: false,
                number: true,
                max: 1
            },
            errorMessage: {
                required: 'Это поле необходимо заполнить',
                number: `Введите число больше 0`,
                max: 'Максимум 1'
            },
        },
        percents: {
            id: 'percents',
            label: 'Укажите процент аванса',
            type: 'text',
            value: 10,
            valid: true,
            error: '',
            validation: {
                required: false,
                number: true,
                max: 100,
                min: 10,
            },
            errorMessage: {
                required: 'Это поле необходимо заполнить',
                number: `Введите число больше 0`,
                max: 'Максимум 100',
                min: 'Минимум 10',
            },
        },
        period: {
            id: 'period',
            label: 'Укажите период расчета',
            type: 'select',
            value: 7,
            valid: true,
            error: '',
            options: {
                size: 7,
                select: [
                    {
                        id: 1,
                        name: 1,
                    },
                    {
                        id: 3,
                        name: 3,
                    },
                    {
                        id: 5,
                        name: 5,
                    },
                    {
                        id: 7,
                        name: 7,
                    },
                    {
                        id: 10,
                        name: 10,
                    },
                    {
                        id: 15,
                        name: 15,
                    },
                    {
                        id: 30,
                        name: 30,
                    },
                ]
            },
            validation: {},
            errorMessage: {},
        },
        companyName: {
            id: 'companyName',
            label: 'Полное наименование',
            isCompany: true,
            type: 'text',
            value: '',
            valid: true,
            validation: {
                required: true,
            },
            errorMessage: {
                required: 'Это поле необходимо заполнить',
            },
        },
        companyInn: {
            id: 'companyInn',
            label: 'ИНН',
            isCompany: true,
            type: 'text',
            value: '',
            valid: true,
            validation: {
                required: false,
            },
            errorMessage: {
                required: 'Это поле необходимо заполнить',
            },
        },
        companyKpp: {
            id: 'companyKpp',
            label: 'КПП',
            isCompany: true,
            type: 'text',
            value: '',
            valid: true,
            validation: {
                required: false,
            },
            errorMessage: {
                required: 'Это поле необходимо заполнить',
            },
        },
        companyPhone: {
            id: 'companyPhone',
            label: 'Телефон',
            isCompany: true,
            type: 'tel',
            value: '',
            valid: true,
            validation: {
                required: true,
                phone: true
            },
            errorMessage: {
                required: 'Это поле необходимо заполнить',
                phone: `Введите корректный номер телефона`
            },
        },
        companyAddress: {
            id: 'companyAddress',
            label: 'Юр. адрес',
            isCompany: true,
            type: 'text',
            value: '',
            valid: true,
            validation: {
                required: false,
            },
            errorMessage: {
                required: 'Это поле необходимо заполнить',
            },
        },
        companyKorBank: {
            id: 'companyKorBank',
            label: 'Кор. счет банка',
            isCompany: true,
            type: 'text',
            value: '',
            valid: true,
            validation: {
                required: false,
            },
            errorMessage: {
                required: 'Это поле необходимо заполнить',
            },
        },
        companyBank: {
            id: 'companyBank',
            label: 'Название банка',
            isCompany: true,
            type: 'text',
            value: '',
            valid: true,
            validation: {
                required: false,
            },
            errorMessage: {
                required: 'Это поле необходимо заполнить',
            },
        },
        companyBik: {
            id: 'companyBik',
            label: 'БИК',
            isCompany: true,
            type: 'text',
            value: '',
            valid: true,
            validation: {
                required: false,
            },
            errorMessage: {
                required: 'Это поле необходимо заполнить',
            },
        },
        companyRasBank: {
            id: 'companyRasBank',
            label: 'Расчетный счет',
            isCompany: true,
            type: 'text',
            value: '',
            valid: true,
            validation: {
                required: false,
            },
            errorMessage: {
                required: 'Это поле необходимо заполнить',
            },
        },
    }
};

export default function (state = initialState, action) {
    let formControls = { ...state.formControls };
    switch (action.type) {

        case SET_CONFIRM_ORDER_PROPERTY:
            return {
                ...state,
                [action.propertyName]: action.value
            };

        case SET_CONFIRM_ORDER_TRANCHE_MAX:
            formControls.tranches.validation.max = action.value;
            formControls.tranches.errorMessage.max = 'Максимум ' + action.value;

            return {
                ...state,
                formControls
            };

        case VALIDATE_FORM_CONTROLS:

            formControls[action.property].value = action.value;
            formControls[action.property].error = action.error;
            formControls[action.property].valid = !action.error;

            return {
                ...state,
                formControls
            };

        case CONFIRM_ORDER_SUCCESS:
            //  console.log(action)
            return {
                ...state,
                orderId: action.orderId,
                isLogin: action.isLogin,
                loading: false
            };

        case CONFIRM_ORDER_START:
            return {
                ...state,
                send: true,
                loading: true
            };
        case CONFIRM_ORDER_SET_FORM_CONTROL_VALUE:
            let formControl = Object.keys(formControls).map(key => formControls[key]).filter(control => control.id === action.payload.id)[0];
            if (formControl) {
                formControl[action.payload.property] = action.payload.value;
            }
            return {
                ...state,
                formControls
            };

        default:
            return state;
    }
}