import React from 'react';


const ErrorStatuses = ({errors, statusesErrors}) => {
    return (
        <div>

            <hr className='border-warning'/>

            <h5 className='text-warning'>Errors:</h5>

            <ul className="pl-5">
                {
                    errors.map(error => <li key={error.id}
                                            className='text-warning'>
                        {error.name}</li>
                    )
                }
            </ul>

            <hr className='border-danger'/>
            <h5 className='text-danger'>Bad statuses:</h5>

            <ul className='pl-5'>
                {statusesErrors.map((status, idx) => {
                    return (
                        <li key={idx} className='text-danger'>
                            {status}
                        </li>
                    )
                })
                }
            </ul>

        </div>
    );
};

export default ErrorStatuses;