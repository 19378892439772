import React, { useState } from 'react';
import classes from './ServiceCalculateMaterialsRows.module.scss';

import NumberFormat from 'react-number-format';
import Modal from 'react-bootstrap/Modal';
import parse from 'html-react-parser';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CountUp from "react-countup";
import { faInfoCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { materialQuantityHandler } from "../../../../../../store/actions/orderActions";
import Td from "../../../../../../components/UI/Td/Td";
import Counter from "../../UI/Counter/Counter";
import { updateServiceCalcProperty } from '../../../../../../store/actions/serviceCalcActions';

const ServiceCalculateMaterialsRows = (props) => {
    const [modalShow, setModalShow] = useState(false);
    const [detailMaterial, setDetailMaterial] = useState(false);
    const { materials, materialQuantityHandler } = props;

    let showModal = material => {
        setDetailMaterial(material);
        setModalShow(true);
    };

    function MyVerticallyCenteredModal(props) {

        let material = detailMaterial.goods;

        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <div className={classes.modalTitleContainer}>
                            {material.name}
                        </div>
                    </Modal.Title>
                </Modal.Header>
                {
                    material.fullName
                        ?
                        <Modal.Body>
                            <div className={classes.modalDescContainer}>
                                {material.fullName}
                            </div>
                        </Modal.Body>
                        : null
                }

                <Modal.Footer>
                    <div className={classes.modalPicContainer}>
                        {material.pictures.length > 0
                            ?
                            material.pictures.map((pic, idx) => {
                                return (
                                    <img className={classes.modalPic} key={idx} src={pic.src} alt={material.name} />
                                );
                            })
                            : null
                        }
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }

    const quantityHandler = (materialId, quantity, inPack) => {
        materialQuantityHandler(materialId, quantity * inPack);
    };

    return (
        <tbody className={classes.ServiceCalcRows}>

        {materials.length > 0
            ?
            materials.map((material, idx) => {
                let name = material.name;
                let pictures = null;
                let description = null;

                const pack = typeof material.goods.pack !== 'undefined'
                    ? material.goods.pack
                    : material.pack;

                let packageCoefficient = typeof material.goods.package_coefficient !== 'undefined'
                    ? material.goods.package_coefficient
                    : material.package_coefficient
                        ? material.package_coefficient
                        : 1;

                let quantity_in_pack = material.goods.quantity_in_pack ?? material.quantity_in_pack ?? 1;
                let packQuantity = Math.ceil(material.quantity / quantity_in_pack);

                if (material.goods.id) {
                    name = material.goods.name;
                    pictures = material.goods.pictures;
                    description = material.goods.description;
                }
                const price = (((material.goods.price || material.price) / (pack > 0 ? pack : 1)) * packageCoefficient).toFixed(2);
                const packPrice = (price * quantity_in_pack).toFixed(2);
                // console.log('price', price)
                // console.log('pack', pack)
                return (
                    <tr key={material.id} data-material={material.id}
                        className={classes.ServiceCalcRow}>
                        <Td colSpan={2} width={220} center={false}>{idx + 1}. {name}</Td>
                        {/*Ед измерения*/}
                        <Td width={80} bg="#EFEBFA">{parse(material.unit)}</Td>
                        {/*Расчетное кол-во*/}
                        <Td width={90} bg="#EFEBFA">
                            <NumberFormat
                                value={(+material.quantity).toFixed(2)}
                                displayType={'text'}
                                thousandSeparator={" "} />
                        </Td>
                        {/*Цена*/}
                        <Td width={90} bg="#EFEBFA">
                            <NumberFormat
                                value={price}
                                displayType={'text'}
                                thousandSeparator={" "} />
                        </Td>
                        {/*Количество в одной упаковке*/}
                        <Td width={90}>
                            <NumberFormat
                                value={quantity_in_pack}
                                displayType={'text'}
                                thousandSeparator={" "} />
                        </Td>
                        {/*Количество упаковок*/}
                        <Td align={"center"}>
                            <Counter
                                materialId={material.id}
                                quantityHandler={quantityHandler}
                                value={packQuantity}
                                inPack={quantity_in_pack}
                            />
                        </Td>
                        {/*Стоимость упаковки*/}
                        <Td align={"center"}>
                            <NumberFormat
                                value={packPrice}
                                displayType={'text'}
                                thousandSeparator={" "} />
                        </Td>

                        {/*Сумма товара*/}
                        <Td width={90}>
                            <CountUp
                                end={+(packPrice * packQuantity).toFixed(2)}
                                duration={.3}
                                formattingFn={(number) => {
                                    return new Intl.NumberFormat('ru-RU').format(number);
                                }}
                            />
                        </Td>
                        {/*Инфо*/}
                        <Td colSpan={1}>
                            {(description || pictures)
                                ?
                                <div className="w-100 d-flex justify-content-center"
                                     onClick={() => showModal(material)}>
                                    <FontAwesomeIcon icon={faInfoCircle} className={'Delete'} size={'2x'} />
                                </div>
                                : null
                            }
                        </Td>
                        {/*Удалить*/}
                        <Td onClickHandler={() => quantityHandler(material.id, 0)}
                            align={"center"}>
                            <FontAwesomeIcon icon={faTimes} className={'Delete'} size={'sm'} />
                        </Td>
                    </tr>
                );
            })
            :
            null
        }

        {detailMaterial
            ?
            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
            : null
        }
        </tbody>
    );
};

const mapState = (state) => {
    return {
        materials: state.order.materials,
    };
};

const actions = {
    materialQuantityHandler,
    updateServiceCalcProperty
};

export default connect(mapState, actions)(ServiceCalculateMaterialsRows);


