import {
    DELIVERY_SWITCH_TAB,
    SET_DELIVERY_DATA,
    SET_DELIVERY_INPUT_VALUE,
    SET_DELIVERY_PRICE
} from "../actions/actionTypes";
// import {setDeliveryPrice} from "../actions/deliveryActions";

const initialState = {
    deliveryData: {},
    deliveryServices: [
        {
            id: 1,
            name: 'В пределах МКАД',
            price: 3000,
            pricePerKm: 0
        },
        {
            id: 2,
            name: 'За пределы МКАД',
            price: 3000,
            pricePerKm: 150
        }
    ],
    deliveryPrice: 0,
    distance: 0,
    deliveryService: 1,
    maxDistance: 1000

}

export default function deliveryReducer(state = initialState, action) {


    switch (action.type) {
        case SET_DELIVERY_DATA:
            return {
                ...state,
                deliveryData: action.deliveryData
            }
        case SET_DELIVERY_PRICE:
            return {
                ...state,
                deliveryPrice: action.deliveryPrice
            }
        case DELIVERY_SWITCH_TAB:

            let distance = action.id === 1 ? 0 : state.distance
            return {
                ...state,
                deliveryService: action.id,
                distance
            }
        case SET_DELIVERY_INPUT_VALUE:
            return {
                ...state,
                [action.deliveryField]: action.value
            }
        default:

            return state
    }
}