import React from "react";
import complexTabs from "./complexTabs";

export default function ComplexHead({ clickHandler, activeTab }) {
    return (
        <div className="complex-head mb-4 mt-2">
            <div className="row no-gutters">
                {Object.values(complexTabs).map(({ id, _, title }, index) => (
                    <div key={id} className={`col-12 col-sm-4`} onClick={() => clickHandler(id)}>
                        <div
                            className={`text-center p-3 complex-head-item ${
                                activeTab === id ? "active" : ""
                            } ${index === 0 ? "first" : ""} ${
                                index === Object.values(complexTabs).length - 1 ? "last" : ""
                            }`}
                        >
                            {title}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
