import React, {Fragment} from 'react';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import {getFormatDate} from '../functions';

const AdminOrderListDetailReceptionWorksRow = ({task, order, idx}) => {

	return (
		<Fragment key={task.id}>
			<Paper component="tr">
				<TableCell align="justify" className="AdminOrderList__row-left w-40 p-0" colSpan={2}>
					<div
						className="d-flex justify-content-between align-items-center">
						<div className="w-20"><Typography align='center'>{idx + 1}</Typography></div>
						<div className="w-80"><Typography className="pl-3">{task.name}</Typography></div>
					</div>
				</TableCell>
				<TableCell align="justify" className="w-60 p-0" colSpan={3}>
					<div className="AdminOrderList__row_list">
						{task.items && task.items.map((item, idx) => (
							<div key={item.id} className="h40 d-flex justify-content-between align-items-center border-left">
								<div className="w-10"><Typography align='center'>{idx + 1}</Typography></div>
								<div className="w-30"><Typography align='left' className="pl-3">{item.place}</Typography></div>
								<div className="w-10"><Typography align='center'>{item.acceptedValue}</Typography></div>
								<div className="w-10"><Typography align='center'>{order.unit}</Typography></div>
								<div className="w-20">
									<Typography align='center'>{getFormatDate(item.lastInspectDate)}</Typography>
								</div>
								<div className="flex-grow-1 d-flex justify-content-center">
									<div className={`AdminOrderList__task-status ${item.status.code}`}>
										{item.status.value}
									</div>
								</div>
							</div>
						))}
					</div>
				</TableCell>
			</Paper>
		</Fragment>
	);
};

export default AdminOrderListDetailReceptionWorksRow;