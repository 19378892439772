import React from 'react'
import './ChatList.scss'
import * as moment from "moment";
import ph from '../../../img/nophoto.jpg'
import {ROLE_ADMINISTRATORS, ROLE_ADMINS, ROLE_MANAGER, ROLE_MASTER} from '../../../config';
// import parse from 'html-react-parser'
// import Tippy from "../ChatImageUploader/ChatImageUploader";

export default ({chats, dialogs, currentChat, onClickHandler, profile}) => {

    // console.log('profile', profile)
    // console.log(chats)

    const getRole = (roleId) => {
        switch (roleId) {
            case ROLE_MASTER:
                return 'Бригадир';
            case ROLE_ADMINS:
            case ROLE_ADMINISTRATORS:
                return 'Администратор';
            case ROLE_MANAGER:
                return 'Менеджер';
            default:
                return '';
        }
    }

    return (
        <ul className="ChatList">
            {
                Object.keys(chats).map(key => {

                    const chat = chats[key]

                    // console.log(Object.values(chat.messages))

                    const opponentMessages = Object.values(chat.messages).filter(message => {
                        // console.log(message)
                        return message.uid !== profile.user.id
                    })

                    const messagesSorted = opponentMessages.sort((a, b) => {
                        return b.timestamp - a.timestamp
                    })

                    // console.log(messagesSorted)

                    const lastMessage = messagesSorted.shift()

                    // const date = moment.unix(lastMessage.timestamp).fromNow()
                    const maxLength = 32
                    let preview = ''
                    if (lastMessage && lastMessage.text) {
                        preview = lastMessage.text.length > maxLength ? lastMessage.text.slice(0, maxLength - 1) + '…' : lastMessage.text;
                    }

                    let unreads = 0;
                    if (!!dialogs[key]) {
                        if (!!dialogs[key]['unreads']) {
                            unreads = (dialogs[key]['unreads']).filter(msg => {
                                return profile.user.id !== msg.uid
                            }).length
                        }
                    }
                    // console.log(unreads)


                    let activeClass = currentChat === key ? 'active' : ''

                    return (
                        <li key={key}
                            className={activeClass}
                            onClick={() => onClickHandler(key)}>
                            <img className="ChatList_profile" src={chat.opponent.photo || ph} alt="Фото"/>
                            <div className="ChatList_content">
                                <div className="ChatList_content-name">
                                    <strong>{chat.opponent.firstName} {chat.opponent.lastName} ({getRole(chat.opponent.role)})
                                    </strong>
                                    <div className="ChatList_content-name-date">
                                        {
                                            !!lastMessage &&
                                            <span>{moment.unix(lastMessage.timestamp).format('HH:mm')}</span>
                                        }
                                        {/*<span>{date}</span>*/}
                                    </div>
                                </div>
                                <div className="ChatList_content-lastMessage">
                                    {
                                       !!lastMessage &&  !!lastMessage.photo
                                            ?
                                            <div className={`ChatList_preview ${activeClass}`}>
                                                <svg enableBackground="new 0 0 488.455 488.455"
                                                     viewBox="0 0 488.455 488.455">
                                                    <path
                                                        d="m287.396 216.317c23.845 23.845 23.845 62.505 0 86.35s-62.505 23.845-86.35 0-23.845-62.505 0-86.35 62.505-23.845 86.35 0"/>
                                                    <path
                                                        d="m427.397 91.581h-42.187l-30.544-61.059h-220.906l-30.515 61.089-42.127.075c-33.585.06-60.925 27.429-60.954 61.029l-.164 244.145c0 33.675 27.384 61.074 61.059 61.074h366.338c33.675 0 61.059-27.384 61.059-61.059v-244.236c-.001-33.674-27.385-61.058-61.059-61.058zm-183.177 290.029c-67.335 0-122.118-54.783-122.118-122.118s54.783-122.118 122.118-122.118 122.118 54.783 122.118 122.118-54.783 122.118-122.118 122.118z"/>
                                                </svg>
                                                <span>Фото</span>
                                            </div>
                                            : null
                                    }

                                    <div className="d-flex justify-content-between align-items-center flex-grow-1">
                                        <span>{preview}</span>
                                        {unreads > 0 ?
                                            <span
                                                className={`ChatList_content-lastMessage-count ${activeClass}`}>{unreads}</span>
                                            : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </li>
                    )
                })
            }
        </ul>
    )
}