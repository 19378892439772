import React, {Fragment} from 'react'
import './BackBtn.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLongArrowAltLeft} from "@fortawesome/free-solid-svg-icons";
import {Link} from 'react-router-dom'

export default ({onClick, to, title = 'Назад', variant='white'}) => {

    return (
        <Fragment>
            {
                to ?

                    <div className={`BackBtn ${variant}`}>
                        <Link to={to}>
                            <div><FontAwesomeIcon className="BackBtn_icon" icon={faLongArrowAltLeft} size={'lg'}/></div>
                            <div className="BackBtn_name">{title}</div>
                        </Link>
                    </div>

                    :
                    <div role={"link"} onClick={onClick} className={`BackBtn ${variant}`}>
                        <div><FontAwesomeIcon className="BackBtn_icon" icon={faLongArrowAltLeft} size={'lg'}/></div>
                        <div className="BackBtn_name">{title}</div>
                    </div>
            }
        </Fragment>

    )
}

