import axios from "../../rest/axios";
import qs from "qs";

import {
    ESTIMATE_CHANGE_CHECKBOX_SELECT,
    ESTIMATE_ADD_SERVICES_TO_ORDER,
    ESTIMATE_NEXT_STEP,
    ESTIMATE_PREV_STEP,
    ESTIMATE_SET_FORM_ITEM_VALUE,
    ESTIMATE_SET_VALUE,
    ESTIMATE_FILTER_SERVICES,
    ESTIMATE_FILTER_CATEGORIES,
    ESTIMATE_FILTER_SUBCATEGORIES,
    ESTIMATE_FETCH_CONDITIONS_SUCCESS,
    ESTIMATE_CHANGE_SERVICE_ANIMATE_ITEMS,
    ESTIMATE_RESET_ORDER,
    ESTIMATE_SET_CALCULATE_DATA,
    ESTIMATE_SET_CHANGED_DATA,
    FETCH_ORDER_CONDITIONS_SUCCESS,
    ESTIMATE_REMOVE_SERVICE_TO_ORDER,
    ESTIMATE_REMOVE_CATEGORY_OF_ORDER,
    ESTIMATE_REMOVE_SUB_CATEGORY_OF_ORDER,
    ESTIMATE_CREATE_COMPLEX_START, ESTIMATE_CREATE_COMPLEX_END
} from "./actionTypes";
import { store } from '../../index';
import { setWorkerPersonalProperty } from "./workerPersonalActions";

export function setChangedData(data) {
    return {
        type: ESTIMATE_SET_CHANGED_DATA,
        payload: {
            data
        },
    };
}

export function setCalculateData(data) {
    return {
        type: ESTIMATE_SET_CALCULATE_DATA,
        payload: {
            data
        },
    };
}

export function setFormItemValue(value, id) {
    return {
        type: ESTIMATE_SET_FORM_ITEM_VALUE,
        payload: {
            id,
            value,
        },
    };
}

export function changeCheckboxSelect(id, value) {
    return {
        type: ESTIMATE_CHANGE_CHECKBOX_SELECT,
        payload: {
            id,
            value,
        },
    };
}

export function setValue(id, value) {
    return {
        type: ESTIMATE_SET_VALUE,
        payload: {
            id,
            value,
        },
    };
}

export function nextStep() {
    return {
        type: ESTIMATE_NEXT_STEP,
    };
}

export function prevStep() {
    return {
        type: ESTIMATE_PREV_STEP,
    };
}

export const addServices = (payload) => ({
    type: ESTIMATE_ADD_SERVICES_TO_ORDER,
    payload,
});

export const removeService = (payload) => ({
    type: ESTIMATE_REMOVE_SERVICE_TO_ORDER,
    payload,
});
export const removeCategory = (payload) => ({
    type: ESTIMATE_REMOVE_CATEGORY_OF_ORDER,
    payload,
});

export const removeSubCategory = (payload) => ({
    type: ESTIMATE_REMOVE_SUB_CATEGORY_OF_ORDER,
    payload,
});

export const setFilterCategories = (activeCategories) => ({
    type: ESTIMATE_FILTER_CATEGORIES,
    payload: {
        activeCategories,
    },
});

export const setFilterSubCategories = (activeSubCategories) => ({
    type: ESTIMATE_FILTER_SUBCATEGORIES,
    payload: {
        activeSubCategories,
    },
});

export const setFilterServices = (activeServices) => ({
    type: ESTIMATE_FILTER_SERVICES,
    payload: {
        activeServices,
    },
});

export const estimateFetchConditions = () => {
    return async dispatch => {
        try {
            const { data } = await axios.get('/admin/orders/conditions/');
            dispatch({ type: ESTIMATE_FETCH_CONDITIONS_SUCCESS, payload: { ...data.data } });
            dispatch({ type: FETCH_ORDER_CONDITIONS_SUCCESS, payload: { data } })
        } catch (e) {
            console.error(e);
        }
    };
};

export const estimateChangeServiceAnimateItems = (item) => ({
    type: ESTIMATE_CHANGE_SERVICE_ANIMATE_ITEMS,
    payload: {
        item
    }
});

export const estimateResetorder = () => {
    localStorage.removeItem('estimate-categories');
    localStorage.removeItem('estimate-subCategories');
    localStorage.removeItem('estimate-services');
    localStorage.removeItem('estimate-startDate');
    localStorage.removeItem('estimate-endDate');
    localStorage.removeItem('estimate-dueDate');
    return {
        type: ESTIMATE_RESET_ORDER,
    }
};
export const estimateCreateComplexStart = () => {
    return {
        type: ESTIMATE_CREATE_COMPLEX_START,
    }
};
export const estimateCreateComplexEnd = (orders) => {
    return {
        type: ESTIMATE_CREATE_COMPLEX_END,
        payload: orders,
    }
};

export const complexOrderCreate = () => {
    const { confirmOrder, estimateOrder, serviceSelect, order } = store.getState();
    const {
        docsTabs, conditions: {
            locationObjectConditions,
            floorConditions,
            instrumentConditions,
            equipmentConditions,
            housingConditions,
            materialPlaceConditions,
        },
    } = serviceSelect;
    const {
        needMaterials,
        locationObject,
        floor,
        withInstrument,
        withEquipment,
        withHousing,
        materialPlace,
        docs,
        classifiers,
    } = order;
    const data = [...estimateOrder.changedData];
    const { userType, trancheDataComplex } = confirmOrder;
    const orderData = {};
    Object.keys(confirmOrder.formControls).forEach(key => {
        orderData[key] = confirmOrder.formControls[key]['value']
    });
    Object.keys(estimateOrder.formControls).forEach(key => {
        orderData[key] = estimateOrder.formControls[key]['value']
    });
    let auth = {
        email: localStorage.getItem('email'),
        token: localStorage.getItem('token')
    }
    return async dispatch => {
        try {
            dispatch(estimateCreateComplexStart());
            const complexData = {
                services: [...data],
                userType,
                ...orderData,
                auth,
                needMaterials,
                classifiers,
                trancheDataComplex,
                locationObject: locationObjectConditions.find(p => p.id === locationObject),
                floor: floorConditions.find(f => f.id === floor),
                withInstrument: instrumentConditions.find(i => i.id === withInstrument),
                withEquipment: equipmentConditions.find(i => i.id === withEquipment),
                withHousing: housingConditions.find(i => i.id === withHousing),
                materialPlace: materialPlaceConditions.find(m => m.id === materialPlace),
                docs: docsTabs.find(d => d.id === docs),
            };
            axios.post('/order/complex/', qs.stringify(complexData)).then((response) => {
                const { orders, result } = response.data.data;
                if (result && result[0]) {
                    const { token, email, userId } = result[0];
                    localStorage.setItem('token', token)
                    localStorage.setItem('userId', userId)
                    localStorage.setItem('email', email)
                    dispatch(setWorkerPersonalProperty(true, 'isLogin'));
                    dispatch(estimateResetorder());
                    dispatch(estimateCreateComplexEnd(orders));
                }
            });

        } catch (e) {

        }
    };
};