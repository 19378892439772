import React, {useEffect, useState} from 'react';
import AdminOrderListFinanceAccordion from '../AdminOrderListFinanceAccordion';
import AdminOrderListFinanceMastersReportsRows from './AdminOrderListFinanceMastersReportsRows';

const AdminOrderListFinanceMastersTotalDone = ({order, masterId, reports}) => {
	const [masterReports, setMasterReports] = useState([]);
	const [sum, setSum] = useState(0);
	useEffect(() => {

		const masterReports = reports.filter(report => report.masterId === masterId);
		const sum = masterReports.reduce((acc, report) => (acc + report.value * order.masterPrice), 0);
		setSum(sum);
		setMasterReports(masterReports);
		// console.log('sum', sum)
		// console.log('masterReports', masterReports)

	}, [order, reports])
	return (
		<div className="px-0">
			<AdminOrderListFinanceAccordion
				name="Всего выполнено"
				sum={sum}>
				<AdminOrderListFinanceMastersReportsRows reports={masterReports} order={order}/>
			</AdminOrderListFinanceAccordion>
		</div>
	);
};

export default AdminOrderListFinanceMastersTotalDone;