import React from 'react';
import NumberFormat from 'react-number-format';
import {getFormatDate} from '../../functions';

const AdminOrderListFinanceMastersPaysRows = ({pays}) => {

	return (
		<div className="w-100">
			<div className="d-flex align-items-center justify-content-between">
				<div className="col-6">Аванс</div>
				<div className="col-3">Дата</div>
				<div className="col-3">Сумма, р.</div>
			</div>
			{
				!!pays && pays.map((pay) => {
					return (
						<div key={pay.id} className="d-flex align-items-center justify-content-between mt-2">
							<div className="col-6 px-2">
								<div className="p-2 pl-3 bg-light">Оплата №{pay.id}</div>
							</div>
							<div className="col-3 px-2">
								<div className="p-2 pl-3 bg-light">{getFormatDate(pay.date)}</div>
							</div>
							<div className="col-3 px-2">
								<div className="p-2 pl-3 bg-light">
									<NumberFormat
										value={pay.sum}
										displayType={'text'}
										thousandSeparator={" "}/>
								</div>
							</div>
						</div>
					)
				})
			}
		</div>
	);
};

export default AdminOrderListFinanceMastersPaysRows;