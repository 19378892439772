import React from "react";
import cn from "../Advantages.module.scss";

const ScheduleAndSupport = (props) => {
	return (
		<section className={cn.main}>
			<div className={`${cn.mainWrapper} ${cn.alignStart} row`}>
				<div className={`${cn.mainRightBlock} col-12 col-lg-7`}>
					<h3 className={cn.mainTitle}>
						<span className={cn.mainTitleNumber}>03 </span>
						<span className={cn.mainTitleText}>Удобная форма и график оплат</span>
					</h3>
					<div className={cn.mainTextBlock}>
						<p className={cn.mainParagraph}>
						Имея регулярную информацию о выполненных работах, вы управляете ее оплатой. Сравнивайте ПЛАН с ФАКТОМ и принимайте решение по текущим платежам. 
						</p>
					</div>
				</div>
				<div className={`${cn.mainRightBlock} col-12 col-lg-5`}>
					<h3 className={cn.mainTitle}>
						<span className={cn.mainTitleNumber}>04 </span>
						<span className={cn.mainTitleText}>Персональная поддержка</span>
					</h3>
					<div className={cn.mainTextBlock}>
						<p className={cn.mainParagraph}>
						Вся внутренняя работа сервиса автоматизирована, но с участием и организацией персонального менеджера для Заказчика, и каждый вопрос, дополнение или изменение в проекте, путем внутреннего чата, вы можете оперативно обсудить с вашим менеджером.
						</p>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ScheduleAndSupport;
