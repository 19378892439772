import React, {Component} from 'react';
import './ActKSTop.scss'
import ActKSTopHead from "./ActKSTopHead/ActKSTopHead";
import ActKSTopLineLittle from "./ActKSTopLineLittle/ActKSTopLineLittle";
import ActKsTopLine from "./ActKSTopLine/ActKSTopLine";

class ActKSTop extends Component {
    render() {

        // console.log(this.props)

        const {order, ks} = this.props


        return (
            <div className="ActKSTop">
                <ActKSTopHead ks={ks}/>
                <ActKSTopLineLittle
                    cell="Код"
                    topCell
                    borderTopWidth
                />
                <ActKSTopLineLittle
                    name="Форма по ОКУД"
                    cell="0322005"
                    topCell
                    borderTopWidth
                />
                <ActKsTopLine
                    name="Инвестор:"
                    text={order.fio}
                    underLine="(организация, адрес, телефон, факс)"
                    groupName="по ОКПО"
                />
                <ActKsTopLine
                    name="Заказчик (Генподрядчик):"
                    underLine="(организация, адрес, телефон, факс)"
                    groupName="по ОКПО"
                    text={order.fio}
                />
                <ActKsTopLine
                    name="Подрядчик (Субподрядчик):"
                    underLine="(организация, адрес, телефон, факс)"
                    groupName="по ОКПО"
                    text="Finish Worker"
                />
                <ActKsTopLine
                    name="Стройка:"
                    underLine="(наименование, адрес)"
                    groupName="по ОКПО"
                    text={order.location.address}
                />
                <ActKsTopLine
                    name="Объект:"
                    underLine="(наименование)"
                    text={`${order.objectName} ${order.objectPlace ? ', ' + order.objectPlace : ''}`}
                />
                <ActKSTopLineLittle
                    name="Вид деятельности ао ОКДП"
                    topCell
                />
                <ActKSTopLineLittle
                    name="Договор подряда (Контракта)"
                    middle="номер"
                    topMiddle
                    topCell
                />
                <ActKSTopLineLittle
                    middle="Дата"
                    topMiddle
                    bottomMiddle
                    topCell
                    bottomCell
                />
                <ActKSTopLineLittle
                    name="Вид операции"
                    bottomCell
                    borderBottomWidth
                />
            </div>
        );
    }
}

export default ActKSTop;