import React from 'react';
import ChartsFinanceRow from './ChartsFinanceRow';
import styled from 'styled-components';

const StyledRowContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

const ChartsFinanceRight = ({ works, materials, total }) => {

    return (
        <StyledRowContainer>
            <ChartsFinanceRow values={works} />
            <ChartsFinanceRow className="mt-3" values={materials} />
            <ChartsFinanceRow className="mt-3" values={total} total />
        </StyledRowContainer>
    );
};

export default ChartsFinanceRight;
