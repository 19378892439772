import React, {Fragment} from 'react';
import List from "@material-ui/core/List";
import {connect, useDispatch, useSelector} from "react-redux";
import {ListItem} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import {setAdminGoodsProperty} from '../../../../../store/actions/adminGoodsActions';

const AdminGoodsLeft = ({categories}) => {
	const {currentCategory} = useSelector(state => state.adminGoods);
	const dispatch = useDispatch();
	const setCurrentCategoryHandler = (id) => {
		dispatch(setAdminGoodsProperty(id, 'currentCategory'))
		dispatch(setAdminGoodsProperty(1, 'currentPage'))
	}

	return (
		<div className='col-3 p-0'>
			{
				!!categories.length &&
				<List>
					{categories.map((category, category_idx) => {
						return (
							<Fragment key={category.id}>
								{
									category.name &&
									<ListItem className="Admin_material-list-item goods-list-item py-1 pl-0"
											  onClick={() => setCurrentCategoryHandler(category.id)}
											  selected={currentCategory === category.id}>
										<Paper className='w-100 h-100 py-1'>
											{
												// category.edit ?
												//     <TextField value={category.name || ''}
												//                variant='outlined'
												//                className='w-100'
												//                autoFocus={true}
												//                onChange={(evt) => dispatch(setAdminGoodsCategoryProperty(category.id, evt.target.value, 'name'))}/>
												//     :


												<div
													className="Admin_material-list-content goods-list-item__content d-flex align-items-center  w-100">
													<Typography variant="subtitle1"
																className="col px-3 m-0">
														{category_idx + 1}. {category.name}
													</Typography>
												</div>

											}
										</Paper>
										{
											currentCategory === category.id &&
											<ArrowRightIcon className='goods-list-item__svg'/>
										}
									</ListItem>
								}
							</Fragment>
						)
					})}
				</List>
			}
		</div>
	);
};

const mapState = state => {
	return {
		categories: state.adminGoods.categories
	}
};

export default connect(mapState)(AdminGoodsLeft);