import React, {useEffect} from 'react';
import {connect, useDispatch, useSelector} from "react-redux";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import PropTypes from 'prop-types';
import is from 'is_js'
import parse from 'html-react-parser'
import iphonelink from '../../../../img/iphonelink.png'
import androidlink from '../../../../img/androidlink.png'
import '../../Worker.scss';

const WorkerSpecialization = ({currentSpecialization, currentSpecializationHandler}) => {
	const {specializations} = useSelector(state => state.specialist)
	return (
		<Paper className='p-3'>
			{
				!!specializations.length &&
				<>	
					<div className='flex'>
						<Typography variant="button" display="block" className='py-3'>
							Специализация
						</Typography>
						<div className='text'>Ссылка на Мобильное приложение для учета и управления строительными работами</div>
						<ul className='worker__downloads'>
							<li className='Footer__downloads-item'>
								<a href="https://apps.apple.com/ru/app/finish-worker/id1499489035" target="_blank">
									<img src={iphonelink}/>
								</a>
							</li>
							<li className='Footer__downloads-item'>
								<a href="https://play.google.com/store/apps/details?id=com.finishworker&hl=ru&gl=US" target='_blank'>
									<img src={androidlink}/>
								</a>
							</li>
						</ul>
					</div>
					<AppBar position="static">
						<Tabs value={currentSpecialization}
							  variant={is.mobile() || is.tablet() ? 'scrollable' : 'fullWidth'}
							  scrollButtons="on"
							  onChange={currentSpecializationHandler}
							  aria-label="wrapped label tabs example">
							{
								specializations.map(item => {
									return (
										<Tab
											key={item.id}
											value={item.code}
											label={item.name}
											wrapped
											{...a11yProps(item.id)}
										/>
									)
								})
							}
						</Tabs>
					</AppBar>
					{
						specializations.map(item => {
							return (
								<TabPanel key={item.id}
										  value={currentSpecialization} index={item.code}>
									{parse(item.description)}
								</TabPanel>
							)
						})
					}
				</>
			}
		</Paper>
	);
};

const mapState = (state) => {
	return {
		specialization: state.specialist.specialization
	}
}


export default connect(mapState)(WorkerSpecialization);

function TabPanel(props) {
	const {children, value, index, ...other} = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`wrapped-tabpanel-${index}`}
			aria-labelledby={`wrapped-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `wrapped-tab-${index}`,
		'aria-controls': `wrapped-tabpanel-${index}`,
	};
}