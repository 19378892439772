import React from 'react';

const IndividualWorksButton = ({title, active = false, onClick, className = '', back = false, forward = false}) => {
	const onClickHandler = () => {
		if (active || back) {
			onClick();
		}
	}
	return (
		<div
			className={`IndividualWorks__button 
			${active ? 'IndividualWorks__button_active' : ''}
			 ${back ? 'IndividualWorks__button_back' : ''}
			 ${forward ? 'IndividualWorks__button_forward' : ''}
			  ${className}`}
			onClick={onClickHandler}>
			<div className="IndividualWorks__button-title">
				{title}
			</div>
			{
				!back && (
					<div className="ml-3">
						<svg width="15" height="13" viewBox="0 0 15 13" fill="none">
							<path d="M8.51111 12.0024L14 6.50244M14 6.50244L8.51111 1.00244M14 6.50244L0.999999 6.50244"
								  stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
						</svg>
					</div>
				)
			}
		</div>
	);
};

export default IndividualWorksButton;