import React from 'react';
import styled from 'styled-components';
import ChartsTableHeadDatesCol from './ChartsTableHeadDatesCol';
import { useChartsContext } from '../ChartsContext';

const StyledHeadDates = styled.div`
  display: flex;
  color: #fff;
`;

const ChartsTableHeadDates = () => {
    const { dates } = useChartsContext();
    return (
        <StyledHeadDates>
            {
                dates.map((date) => <ChartsTableHeadDatesCol key={date} date={date} />)
            }
        </StyledHeadDates>
    );
};

export default ChartsTableHeadDates;
