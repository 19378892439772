import {
	ADD_ADMIN_GOODS_CATEGORY,
	ADD_ADMIN_GOODS_GOOD,
	ADMIN_GOODS_FETCH_CATEGORIES_SUCCESS,
	ADMIN_GOODS_FETCH_ERROR,
	ADMIN_GOODS_FETCH_START,
	ADMIN_GOODS_FETCH_SUCCESS,
	DELETE_ADMIN_GOODS_CATEGORY,
	SET_ADMIN_GOODS_CATEGORY_PROPERTY,
	SET_ADMIN_GOODS_GOOD_PROPERTY, SET_ADMIN_GOODS_PROPERTY
} from "../actions/actionTypes";
import cuid from "cuid";

const initialState = {
	currentCategory: null,
	currentPage: 1,
	goods: [],
	categories: [],
	loading: false,
	error: null
};
const adminGoodsReducer = (state = initialState, {type, payload}) => {
	let goods = [...state.goods]
	let categories = [...state.categories]
	switch (type) {
		case ADMIN_GOODS_FETCH_START:
			return {
				...state,
				loading: true
			};
		case ADMIN_GOODS_FETCH_SUCCESS:
			if (payload.goods) {
				goods = payload.goods
			}
			return {
				...state,
				goods,
				loading: false,
			};
		case ADMIN_GOODS_FETCH_CATEGORIES_SUCCESS:
			if (payload.categories) {
				categories = payload.categories
			}
			return {
				...state, loading: false,
				categories
			}
		case ADMIN_GOODS_FETCH_ERROR:
			return {
				...state,
				error: payload.error,
				loading: false
			};
		case SET_ADMIN_GOODS_GOOD_PROPERTY:
			// console.log(payload)
			let good = goods.find(good => good.id === payload.goodId);
			good[payload.property] = isNaN(parseFloat(payload.value)) ? payload.value : (payload.value).toString()
				.replace(',', '.').replace(' ', '').replace(/[^0-9.]/g, '');
			return {
				...state,
				goods
			}
		case ADD_ADMIN_GOODS_GOOD:

			const section = categories.find(category => category.id === payload.sectionId);
			if (section) {
				const good = {
					id: cuid(),
					name: '',
					active: false,
					pictures: [],
					section: [section],
					unit: 'Кг',
					isNew: true,
					edit: true
				};
				goods = [good, ...goods];
			}
			return {
				...state,
				goods
			};
		case ADD_ADMIN_GOODS_CATEGORY:
			categories = [...categories, payload.category]
			return {
				...state,
				categories
			}
		case SET_ADMIN_GOODS_CATEGORY_PROPERTY:
			let category = categories.find(category => category.id === payload.categoryId);
			category[payload.property] = payload.value;
			return {
				...state,
				categories
			};
		case DELETE_ADMIN_GOODS_CATEGORY:
			let deletedCategory = categories.find(category => category.id === payload.categoryId)
			if (deletedCategory.isNew) {
				categories = categories.filter(category => category.id !== payload.categoryId)
			} else {
				deletedCategory.deleted = true
			}
			return {
				...state,
				categories
			}
		case SET_ADMIN_GOODS_PROPERTY:
			return {
				...state,
				[payload.property]: payload.value
			}
		default:
			return {
				...state
			}
	}
};

export default adminGoodsReducer;
