import React, {Component} from 'react';
import './ActKS.scss'
import ActKSTop from "../ActKSComponents/ActKSTop/ActKSTop";
import ActKSTable from "../ActKSComponents/ActKSTable/ActKSTable";
import ActKsBottom from "../ActKSComponents/ActKSBottom/ActKSBottom";
import ActKSInfo from "../ActKSComponents/ActKSInfo/ActKSInfo";

// export const OrderContext = React.createContext()

class ActKS extends Component {


    render() {

        const {order, period, ks} = this.props

        return (
            <div className="ActKS">
                <ActKSTop order={order} period={period} ks={ks}/>
                <ActKSInfo order={order} period={period}/>
                <ActKSTable order={order} period={period} ks={ks}/>
                <ActKsBottom order={order} period={period}/>
            </div>
        );
    }
}


export default ActKS;