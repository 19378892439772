import React from 'react'
import './PersonalOrderItemTabs.scss'

export default ({order, tabs, currentTab, onSwitchTab}) => {

    let orderId = ''
    if (order) {
        orderId = order.id
    }

    return (
        <div className="PersonalOrderItemTabs">
            {tabs.map(({id, name}) => {
                let ServiceCalcTab = "PersonalOrderItemTabs_tab"
                if (id === currentTab) {
                    ServiceCalcTab += " active"
                }
                let fullName = id === 1 ? name + " №" + orderId : name

                return (
                    <div
                        key={id}
                        className={ServiceCalcTab}
                        onClick={() => onSwitchTab(id)}
                        data-id={id}>
                        {fullName}
                    </div>
                )
            })}
        </div>
    )
}