import React from "react";
import parse from "html-react-parser";

export default function ServiceItem({service, ...props}) {
  return (
    <div
      className={`estimate__sub-categories-service ${
        service.checked ? "active" : ""
      }`}
    >
      <label>
        <span className="estimate__sub-categories-service-name">{service.name}</span>
        <input
          type="checkbox"
          checked={service.checked}
          className="estimate-hidden-input"
          onChange={(e) => props.serviceCheckhandler(service.id, e)}
        />
        <div className="estimate__sub-categories-service-right">
          {service.checked && (
            <>
              <div className="estimate__sub-categories-service-count-text">
                Укажите объём
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#7B53DA"
                >
                  <path
                    d="M14 7.5L8.94444 14V10.3961L1 10.3889L1 4.61111L8.94444 4.61111V1L14 7.5Z"
                    stroke="#7B53DA"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </div>
              <input
                className="estimate__sub-categories-service-count"
                type="text"
                autoFocus
                value={service.count}
                pattern="[0-9]*"
                onChange={(e) => props.serviceCountHandler(service.id, e.target.value)}
                onBlur={(e) => props.countInputBlurHandler(service.id, e.target.value)}
              />
              <span className="estimate__sub-categories-service-unit">
                {parse(service.unit)}
              </span>
            </>
          )}
        </div>
      </label>
    </div>
  );
}
