import {
	CONFIRM_ORDER_ERROR, CONFIRM_ORDER_SET_FORM_CONTROL_VALUE,
	CONFIRM_ORDER_START,
	CONFIRM_ORDER_SUCCESS,
	SET_AUTH_FORM_PROPERTY,
	SET_CONFIRM_ORDER_PROPERTY, SET_CONFIRM_ORDER_TRANCHE_MAX,
	VALIDATE_FORM_CONTROLS
} from "./actionTypes";
import axios from '../../rest/axios'
import {store} from "../../index";
import * as moment from "moment";
import qs from 'qs'
import {setWorkerPersonalProperty} from "./workerPersonalActions";

export const CLEAR_ORDER_FIELDS = 'CLEAR_ORDER_FIELDS';


export function setTrancheMax(value) {
	return {
		type: SET_CONFIRM_ORDER_TRANCHE_MAX,
		value
	}
}

export function setConfirmOrderProperty(value, propertyName) {
	return {
		type: SET_CONFIRM_ORDER_PROPERTY,
		value,
		propertyName
	}
}


export function validateFormControls(formControl = {}) {

	return {
		type: VALIDATE_FORM_CONTROLS,
		property: formControl.property,
		error: formControl.error,
		value: formControl.value
	}
}

export function setFormControlsValue(id, property, value) {
	return {
		type: CONFIRM_ORDER_SET_FORM_CONTROL_VALUE,
		payload: {
			id,
			property,
			value
		}
	}
}


export function createOrder(servicePrice, materialPrice = '') {

	const {confirmOrder, order, serviceSelect, workerSelect: {masters}} = store.getState();
	const {
		docsTabs, conditions: {
			locationObjectConditions,
			floorConditions,
			instrumentConditions,
			equipmentConditions,
			housingConditions,
			materialPlaceConditions,
		},
	} = serviceSelect;
	return async dispatch => {
		try {
			dispatch(fetchStart());

			let orderData = {};
			Object.keys(confirmOrder.formControls).forEach(key => {
				orderData[key] = confirmOrder.formControls[key]['value']
			});
			Object.keys(order.formControls).forEach(key => {
				orderData[key] = order.formControls[key]['value']
			});
			const {
				currentService,
				materials,
				workerCount,
				workType,
				needMaterials,
				locationObject,
				floor,
				withInstrument,
				withEquipment,
				withHousing,
				materialPlace,
				docs,
				classifiers,
				formControls: {startDate, dueDate},
			} = order;
			const {userType, trancheData} = confirmOrder;
			let mainData = {
				servicePrice,
				materialPrice,
				service: currentService,
				materials,
				workerCount,
				workType,
				trancheData,
				needMaterials,
				userType,
				masters,
				classifiers,
				locationObject: locationObjectConditions.find(p => p.id === locationObject),
				floor: floorConditions.find(f => f.id === floor),
				withInstrument: instrumentConditions.find(i => i.id === withInstrument),
				withEquipment: equipmentConditions.find(i => i.id === withEquipment),
				withHousing: housingConditions.find(i => i.id === withHousing),
				materialPlace: materialPlaceConditions.find(m => m.id === materialPlace),
				docs: docsTabs.find(d => d.id === docs),
				finishDate: moment(startDate.value, "DD.MM.YYYY")
					.add((+dueDate.value > 0 ? +dueDate.value - 1 : 1), 'd')
					.format("DD.MM.YYYY")
			}
			let auth = {
				email: localStorage.getItem('email'),
				token: localStorage.getItem('token')
			}
			let data = {
				...mainData,
				...orderData,
				auth
			};

			axios.post('/order/create/', qs.stringify(data)).then((response) => {

				const {data: {token, email, orderId, userId}} = response.data
				dispatch(fetchSuccess(orderId, !!token));

				if (token) {
					localStorage.setItem('token', token)
					localStorage.setItem('userId', userId)
					localStorage.setItem('email', email)
					// dispatch(workerPersonalLogin(token))
					setWorkerPersonalProperty(true, 'isLogin')
				}
				dispatch({type: CLEAR_ORDER_FIELDS})

				return null
			}).catch(() => {
				dispatch(setConfirmOrderProperty(false, 'loading'));
				return null
			});


		} catch (e) {
			console.log(e)
			dispatch(fetchError(e))
		}
	}
}

export function setAuthFormProperty(value, propertyName) {
	return {
		type: SET_AUTH_FORM_PROPERTY,
		value,
		propertyName
	}
}


function fetchStart() {
	return {
		type: CONFIRM_ORDER_START
	}
}

function fetchSuccess(orderId, isLogin) {
	return {
		type: CONFIRM_ORDER_SUCCESS,
		orderId,
		isLogin
	}
}

function fetchError(error) {
	return {
		type: CONFIRM_ORDER_ERROR,
		error
	}
}


