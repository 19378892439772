import React, {Fragment, useEffect, useState} from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import parse from 'html-react-parser';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import {useDispatch, useSelector} from 'react-redux';
import {setMaterialNorm} from '../../../../store/actions/adminServiceActions';
import {Autocomplete} from '@material-ui/lab';
import {TextField} from '@material-ui/core';

const AdminMaterialsGoodsList = ({service, item = null, first = false, setItemsTotalSum}) => {
	const [totalSum, setTotalSum] = useState(0)
	const dispatch = useDispatch();
	const {goods} = useSelector(state => state.adminService);
	const currentService = item ? item : service;
	const height = '70px';
	const addGoodsHandler = (materialId) => {
		//const service = services.find(s => s.id === service.id);
		if (item) {
			const activeGoods = [];
			service.materials.forEach(material => {
				if (material.goods.id) {
					activeGoods.push(material.goods.id)
				}
			});
			const freeGoods = goods.filter(good => {
				return activeGoods.indexOf(good.id) < 0
			});
			if (!!freeGoods.length) {
				const good = {...freeGoods[0], edit: true};
				dispatch(setMaterialNorm(service.id, materialId, good, 'goods', item.id));

			}
		} else {
			const activeGoods = [];
			service.materials.forEach(material => {
				if (material.goods.id) {
					activeGoods.push(material.goods.id)
				}
			});
			const freeGoods = goods.filter(good => {
				return activeGoods.indexOf(good.id) < 0
			});
			if (!!freeGoods.length) {
				const good = {...freeGoods[0], edit: true}
				dispatch(setMaterialNorm(service.id, materialId, good, 'goods'))
			}
		}
	};

	const editMaterialGoodsHandler = (materialId, id, evt, value, reason) => {
		console.log(materialId, id, evt, value, reason)
		const good = goods.find(good => good.id === id);
		if (item) {
			dispatch(setMaterialNorm(service.id, materialId, {...good, edit: true}, 'goods', item.id))
		} else {
			dispatch(setMaterialNorm(service.id, materialId, {...good, edit: true}, 'goods'))
		}
	};

	const setMaterialGoodsHandler = (materialId, goods, edit) => {
		if (item) {
			dispatch(setMaterialNorm(service.id, materialId, {...goods, edit}, 'goods', item.id))
		} else {
			dispatch(setMaterialNorm(service.id, materialId, {...goods, edit}, 'goods'))
		}
	};

	const deleteMaterialGoodsHandler = (materialId) => {
		if (item) {
			dispatch(setMaterialNorm(service.id, materialId, {}, 'goods', item.id))
		} else {
			dispatch(setMaterialNorm(service.id, materialId, {}, 'goods'))
		}
	}
	useEffect(() => {
		let total = 0;
		currentService.materials.map((materialItem) => {
			const quantity = materialItem.norms * materialItem.overNorms;
			const pack = typeof materialItem.goods.pack !== 'undefined'
				? materialItem.goods.pack
				: materialItem.pack;

			let packageCoefficient = typeof materialItem.goods.pack !== 'undefined'
				? materialItem.goods.package_coefficient
				: materialItem.package_coefficient
					? materialItem.package_coefficient
					: 1;

			if (pack > 0) {
				const price = ((materialItem.goods.price || materialItem.price) / pack) * packageCoefficient;
				total += price * quantity;
			}
		});
		setTotalSum((+total).toFixed(2))
		if (item) {
			setItemsTotalSum((+total).toFixed(2))
		}
	}, [currentService])


	return (
		<List className='p-0 m-0 w-100'>
			{
				!service.items.length
					?
					(
						<ListItem
							className={`py-0 overflow-hidden align-items-center border-gray d-flex justify-content-end ${first && item || !item ? 'border-top' : ''}`}
							// style={{height: '50px'}}
						>
							<div className="w-100 d-flex align-items-center justify-content-between">
								<Typography variant="h6">
									{service.name}
									{
										service.unit && <span className="ml-5 pl-3">{parse(service.unit)}</span>
									}
								</Typography>

								<Typography
									variant="h6">Итого стоимость материалов в работе, руб.:{totalSum}</Typography>
							</div>
						</ListItem>
					)
					:
					<ListItem
						className={`py-2 overflow-hidden align-items-center border-gray d-flex justify-content-end ${first && item || !item ? 'border-top' : ''}`}
						// style={{height: '50px'}}
					>
						<div className="w-100 d-flex align-items-center justify-content-between">
							<Typography variant="h6">
								{item.name}
								{
									item.unit && <span className="ml-5 pl-3">{parse(item.unit)}</span>
								}
							</Typography>

							<Typography
								variant="h6">Итого стоимость материалов в подработе, руб.:{totalSum}</Typography>
						</div>
					</ListItem>
			}

			{
				currentService.materials.map((materialItem, materialItem_idx) => {
					// расчетное количество
					const quantity = materialItem.norms * materialItem.overNorms;
					// Измерение товара в ед.изм. нормы
					const pack = typeof materialItem.goods.pack !== 'undefined' ? materialItem.goods.pack : materialItem.pack;
					// Поправочный коэффициент цены
					let packageCoefficient = typeof materialItem.goods.pack !== 'undefined' ? materialItem.goods.package_coefficient : materialItem.package_coefficient ? materialItem.package_coefficient : 1;
					// цена за ед. измерения
					const price = ((materialItem.goods.price || materialItem.price) / pack) * packageCoefficient;
					const sum = price * quantity;

					let freeGoods = [];
					if (materialItem.goods.edit) {
						const activeGoods = [];
						service.materials.forEach(material => {
							if (material.goods.id) {
								activeGoods.push(material.goods.id)
							}
						});
						freeGoods = goods.filter(good => {
							return activeGoods.indexOf(good.id) < 0 || good.id === materialItem.goods.id
						})
					}

					return (
						<ListItem key={materialItem.id}
								  className={`p-0 overflow-hidden align-items-center border-top border-gray ${!currentService.materials[materialItem_idx + 1] ? 'border-bottom' : ''}`}
								  style={{height}}>

							<div
								className="col-5 p-0 pl-3 m-0 d-flex align-self-center justify-content-between">
								<Typography variant="subtitle1"
											className="col-10 p-0 m-0"><span
									className="mr-1"> {materialItem_idx + 1}</span> {materialItem.name}
								</Typography>
								<Typography variant="subtitle1"
											className="col-2 p-0 m-0 text-center">{parse(materialItem.unit)}</Typography>
							</div>

							<div
								className="col-4 p-0 m-0 d-flex align-self-center justify-content-between">
								{

									materialItem.goods.edit ?
										<FormControl variant="outlined"
													 className='Admin_select col-9'>
											{/*<Select*/}
											{/*	className="Admin_select-list"*/}
											{/*	size={5}*/}
											{/*	value={materialItem.goods.id}*/}
											{/*	onChange={evt => editMaterialGoodsHandler(materialItem.id, evt.target.value)}*/}
											{/*>*/}
											{/*	{freeGoods.map(good => {*/}
											{/*		return (*/}

											{/*			<MenuItem*/}
											{/*				key={good.id}*/}
											{/*				value={good.id}>*/}
											{/*				{good.name}*/}
											{/*			</MenuItem>*/}
											{/*		)*/}
											{/*	})}*/}

											{/*</Select>*/}
											<Autocomplete
												id="combo-box-demo"
												options={freeGoods}
												noOptionsText="Ничего не найдено"
												getOptionLabel={(good) => good.name}
												onChange={(evt, value) => editMaterialGoodsHandler(materialItem.id, value.id)}
												renderInput={(params) => <TextField {...params} label="Выберите товар"
																					variant="outlined"/>
												}
											/>

										</FormControl>
										:
										<Typography variant="subtitle1"
													className={`col-9 pr-2 ${!materialItem.goods.name ? 'border-bottom border-blue' : ''}`}>
											<div style={{overflowY: 'auto', maxHeight: 70}}>
												{materialItem.goods.name}
											</div>
										</Typography>
								}
								<div
									className='col-3 d-flex px-0 justify-content-end align-items-center align-self-center'>
									{materialItem.goods.id ?
										<Fragment>
											{
												materialItem.goods.edit
													?
													<Button
														className="Admin_btn col-6"
														variant="contained" color="primary"
														onClick={() => setMaterialGoodsHandler(materialItem.id, materialItem.goods, false)}
													>
														<SaveIcon
															className='Admin_btn-icon Admin_btn-icon-create'/>
													</Button>
													:
													<Button
														className="Admin_btn col-6"
														variant="contained" color="primary"
														onClick={() => setMaterialGoodsHandler(materialItem.id, materialItem.goods, true)}
													>
														<EditIcon
															className='Admin_btn-icon Admin_btn-icon-create'/>
													</Button>
											}
											<Button
												className="Admin_btn col-6 ml-1"
												variant="contained" color="secondary"
												onClick={() => deleteMaterialGoodsHandler(materialItem.id)}
											>
												<DeleteIcon
													className='Admin_btn-icon Admin_btn-icon-create'/>
											</Button>
										</Fragment>
										:
										<Button
											className="Admin_btn col-12"
											variant="contained" color="primary"
											onClick={() => addGoodsHandler(materialItem.id)}
										>
											<PlaylistAddIcon
												className='Admin_btn-icon Admin_btn-icon-create'/>
										</Button>
									}

								</div>
							</div>

							<div
								className="col-3 p-0 m-0 d-flex align-self-center justify-content-between">
								<Typography variant="subtitle1"
											className="col-4 p-0 pl-2 m-0 text-center">{!isNaN(quantity) ? (+quantity).toFixed(3) : 0}</Typography>
								<Typography variant="subtitle1"
											className="col-4 p-0 pl-2 m-0 text-center">{price ? (+price).toFixed(2) : 0}</Typography>
								<Typography variant="subtitle1"
											className="col-4 p-0 pl-2 m-0 text-center">{!isNaN(sum) ? (+sum).toFixed(2) : 0}</Typography>
							</div>


						</ListItem>
					)
				})
			}
		</List>
	);
};

export default AdminMaterialsGoodsList;