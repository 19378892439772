import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { addDays, format, parse } from 'date-fns';
import EstimateRadioInput2 from "../../../UI/EstimateRadioInput2";
import EstimateRadioInput from "../../../UI/EstimateRadioInput";
import {
    setFormItemValue,
    nextStep,
    changeCheckboxSelect,
    prevStep,
} from "../../../../../../../store/actions/estimateActions";
import EstimateSwitch from "../../../UI/EstimateSwitch";
import EstimateCheckbox from "../../../UI/EstimateCheckbox";
import EstimateButton from "../../../UI/EstimateButton";
import "./Estimate-Step3.scss";
import EstimateProcess from "../../Process/EstimateProcess";
import { renderFormControl } from '../../../../../../../form/formFramework';
import FWDatePicker from '../../../../../../ui/FWDatePicker/FWDatePicker';
import IndividualWorksChoice from '../../../../IndividualWorks/IndividualWorksChoice';
import { getOrderState, getServiceSelectState } from '../../../../../../../store/selectors';
import { setOrderProperty } from '../../../../../../../store/actions/orderActions';
import RadioTabs from '../../../../../../ui/RadioTabs/RadioTabs';
import SwitchCondition from '../../../../../../ui/SwitchCondition/SwitchCondition';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const Step3 = (props) => {
    const dispatch = useDispatch();
    const {
        setFormItemValue,
        formControls,
        nextStep,
        changeCheckboxSelect,
        prevStep,
    } = props;
    const {
        docsTabs,
        needMaterialsTabs,
        workTypeTabs,
        conditions: {
            locationObjectConditions,
            floorConditions,
            instrumentConditions,
            equipmentConditions,
            housingConditions,
            materialPlaceConditions,
            classifiersConditions,
        }
    } = useSelector(getServiceSelectState);
    const {
        workType,
        needMaterials,
        currentCategory, currentService,
        quantity, startDate, dueDate,
        locationObject, floor, withInstrument, withEquipment, withHousing,
        materialPlace, docs, classifiers,
    } = useSelector(getOrderState);
    const [endMinDate, setEndMinDate] = useState(new Date());

    const radioTabHandler = (value, property) => {
        dispatch(setOrderProperty(value, property));
    };
    const tabHandler = (property) => (value) => {
        dispatch(setOrderProperty(value, property))
    }

    const changeSupplyDocumentationHandler = (value, id) => {
        setFormItemValue("documentation", []);
        setFormItemValue(value, id);
    };

    const addDocsHandler = (evt, id) => {

        const classifier = classifiersConditions.find(c => c.id === id);
        if (classifier) {
            if (evt.target.checked) {
                dispatch(setOrderProperty([classifier, ...classifiers], 'classifiers'));
            } else {
                dispatch(setOrderProperty(classifiers.filter(c => c.id !== classifier.id), 'classifiers'));
            }
        }
    }

    useEffect(() => {
        const startDate = parse(formControls.startDate.value, 'dd.MM.yyyy', new Date());
        setEndMinDate(startDate);
    }, [formControls.startDate]);

    useEffect(() => {
        if (formControls.dueDate.value) {
            const startDate = parse(formControls.startDate.value, 'dd.MM.yyyy', new Date());
            const endDate = addDays(new Date(startDate), formControls.dueDate.value);
            setFormItemValue(format(new Date(endDate), 'dd.MM.yyyy'), 'endDate');
        }
    }, [ formControls.dueDate.value]);

    return (
        <>
            <EstimateProcess />
            <div className="complex-body">
                <div className="row mb-3">
                    <div className="col-12 d-flex justify-content-center">
            <span>
              Уточните условия проведения работ для точного расчета стоимости
              заказа
            </span>
                    </div>
                    <div className="col-12 d-flex justify-content-between"></div>
                </div>

                <div className="row">
                    <div className="col-12 col-lg-4 mt-4 d-flex flex-column">
                        <FWDatePicker formControl={formControls.startDate} onChange={setFormItemValue} />
                    </div>
                    <div className="col-12 col-lg-4 mt-4 d-flex flex-column">
                        <FWDatePicker formControl={formControls.endDate} onChange={setFormItemValue}
                                      options={{ minDate: endMinDate }} />
                    </div>
                    <div className="col-12 col-lg-4 mt-4 d-flex flex-column" style={{ paddingTop: 2 }}>
                        {renderFormControl(formControls.dueDate, setFormItemValue,)}
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-sm-6">
                        <IndividualWorksChoice
                            onClick={radioTabHandler}
                            choices={workTypeTabs}
                            active={workType}
                            property="workType"
                        />
                    </div>
                    <div className="col-12 col-sm-6">
                        <IndividualWorksChoice
                            onClick={radioTabHandler}
                            choices={needMaterialsTabs}
                            active={needMaterials}
                            property="needMaterials"
                        />
                    </div>
                </div>

                <div className="row">
                    {/*<div className="col-12 col-xl-6 mb-4">*/}
                    {/*    <div className="estimate-firststep-bottom-item">*/}
                    {/*        <EstimateRadioInput*/}
                    {/*            element={formControls.workType}*/}
                    {/*            clickHandler={setFormItemValue}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="col-12 col-xl-6 mb-4">*/}
                    {/*    <div className="estimate-firststep-bottom-item">*/}
                    {/*        <EstimateRadioInput*/}
                    {/*            element={formControls.supplyMaterials}*/}
                    {/*            clickHandler={setFormItemValue}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="col-12 d-flex justify-content-between flex-wrap mt-3">
                    {/*<div className="d-flex justify-content-between w-100 flex-column flex-sm-row mt-3 mt-sm-0">*/}
                        {
                            locationObjectConditions[0] && (
                                <RadioTabs title="Расположение объекта" tabs={locationObjectConditions}
                                           active={locationObject || locationObjectConditions[0].id}
                                           onclick={tabHandler('locationObject')}/>
                            )
                        }
                        {
                            floorConditions[0] && (
                                <RadioTabs title="Этажность зоны работ"
                                           className="mt-3 mt-sm-0"
                                           tabs={floorConditions} active={floor || floorConditions[0].id}
                                           onclick={tabHandler('floor')}/>
                            )
                        }

                    </div>

                    {/*<div className="col-12 d-flex justify-content-between flex-wrap">*/}
                    {/*    <EstimateRadioInput2*/}
                    {/*        element={formControls.locationObjectConditions}*/}
                    {/*        clickHandler={setFormItemValue}*/}
                    {/*    />*/}
                    {/*    <EstimateRadioInput2*/}
                    {/*        element={formControls.floorConditions}*/}
                    {/*        clickHandler={setFormItemValue}*/}
                    {/*    />*/}
                    {/*</div>*/}
                </div>

                <div className="IndividualWorks__conditions-switchers mt-3 mt-sm-5">
                    <div className="d-flex flex-column align-items-center col-12 col-sm-4">
                        {
                            instrumentConditions[0] && (
                                <SwitchCondition switchers={instrumentConditions}
                                                 active={withInstrument || instrumentConditions[0].id}
                                                 title="Инструменты"
                                                 onClick={tabHandler('withInstrument')}/>
                            )
                        }
                        {
                            equipmentConditions[1] && (
                                <SwitchCondition switchers={equipmentConditions}
                                                 active={withEquipment || equipmentConditions[1].id}
                                                 title="Оборудование"
                                                 className="mt-4"
                                                 onClick={tabHandler('withEquipment')}/>
                            )
                        }
                        {
                            housingConditions[0] && (
                                <SwitchCondition switchers={housingConditions}
                                                 active={withHousing || housingConditions[0].id}
                                                 title="Жилье"
                                                 className="mt-4"
                                                 onClick={tabHandler('withHousing')}/>
                            )
                        }

                    </div>
                    <div className="IndividualWorks__conditions-docs">
                        <div className="IndividualWorks__conditions-docs-left">
                            {
                                materialPlaceConditions[1] && (
                                    <RadioTabs title="Материал" tabs={materialPlaceConditions}
                                               active={materialPlace || materialPlaceConditions[1].id}
                                               onclick={tabHandler('materialPlace')} variant="small"/>
                                )
                            }
                            <RadioTabs title="Исполнительная документация" tabs={docsTabs} active={docs}
                                       onclick={tabHandler('docs')} variant="small" className="mt-4"/>
                        </div>
                        <div className="IndividualWorks__conditions-docs-right">
                            {
                                !!classifiersConditions.length && classifiersConditions.map((classifier) => {
                                    const {id, title} = classifier;
                                    return <div key={id}>
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={docs !== 2 && classifiers.map(c => c.id).includes(id)}
                                                onChange={(evt) => addDocsHandler(evt, id)}
                                                color="primary"
                                                disabled={docs === 2}
                                            />}
                                            label={title}
                                        />
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>


                {/*<div className="row mt-4">*/}
                {/*    <div className="col-12 col-lg-4 estimate-supply-block">*/}
                {/*        <EstimateSwitch*/}
                {/*            element={formControls.instrumentConditions}*/}
                {/*            clickHandler={setFormItemValue}*/}
                {/*        />*/}
                {/*        <EstimateSwitch*/}
                {/*            element={formControls.equipmentConditions}*/}
                {/*            clickHandler={setFormItemValue}*/}
                {/*        />*/}
                {/*        <EstimateSwitch*/}
                {/*            element={formControls.housingConditions}*/}
                {/*            clickHandler={setFormItemValue}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*    <div className="col-12 col-lg-8">*/}
                {/*        <div className="d-flex justify-content-end estimate-docs">*/}
                {/*            <div className="estimate-docs-left">*/}
                {/*                <EstimateRadioInput2*/}
                {/*                    element={formControls.materialPlaceConditions}*/}
                {/*                    clickHandler={setFormItemValue}*/}
                {/*                    titleClassNames="small"*/}
                {/*                />*/}
                {/*                <EstimateRadioInput2*/}
                {/*                    element={formControls.supplyDocument}*/}
                {/*                    clickHandler={setFormItemValue}*/}
                {/*                    titleClassNames="small"*/}
                {/*                />*/}
                {/*            </div>*/}
                {/*            <div className="estimate-docs-right d-flex flex-column justify-content-between">*/}
                {/*                <EstimateCheckbox*/}
                {/*                    element={formControls.classifiersConditions}*/}
                {/*                    action={changeCheckboxSelect}*/}
                {/*                    disabled={formControls.supplyDocument.value === "customer"}*/}
                {/*                />*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
            <div className="complex-footer mt-4">
                <div className="row">
                    <div className="col-4 col-lg-2 estimate-footer-item pr-0">
                        <EstimateButton
                            className="back"
                            back={true}
                            onClick={prevStep}
                            text="Назад"
                        />
                    </div>
                    <div className="col-8 col-lg-10 estimate-footer-item pl-0">
                        <EstimateButton onClick={nextStep} text="Следующий шаг" />
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    formControls: state.estimateOrder.formControls,
});

const actions = {
    setFormItemValue,
    nextStep,
    changeCheckboxSelect,
    prevStep,
};

export default connect(mapStateToProps, actions)(Step3);
