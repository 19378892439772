import React from 'react';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Typography from "@material-ui/core/Typography";
import {connect} from "react-redux";
import {setMaterialNorm} from "../../../../store/actions/adminServiceActions";
import AdminMaterialsGoodsList from './AdminMaterialsGoodsList';
import {getTotalSum} from '../../admin.functions';
import parse from 'html-react-parser';

class AdminMaterialsPrices extends React.Component {
	state = {
		itemsTotalSum: 0,
	}

	setMaterialGoodsHandler = async (materialId, goods, edit, itemId = null) => {
		await this.props.setMaterialNorm(this.props.serviceId, materialId, {...goods, edit}, 'goods', itemId)
	}

	editMaterialGoodsHandler = async (materialId, id, itemId = null) => {

		const goods = this.props.goods.find(good => good.id === id)
		await this.props.setMaterialNorm(this.props.serviceId, materialId, {...goods, edit: true}, 'goods', itemId)
	}

	deleteMaterialGoodsHandler = async (materialId, itemId = null) => {
		await this.props.setMaterialNorm(this.props.serviceId, materialId, {}, 'goods', itemId)
	};
	getItemsTotalSum = (items) => {
		let sum = 0;
		items.map((item) => {
			sum += Number(getTotalSum(item))
		});
		return sum.toFixed(2);
	};
	setItemsTotalSum = (sum) => {
		this.setState((prevState)=>{
			return {itemsTotalSum: prevState.itemsTotalSum + +sum}
		})
	}

	render() {
		const {services} = this.props
		const service = services.find(service => service.id === this.props.serviceId)
		const height = '70px';
		return (
			<List className="px-0">
				{
					!!service.items.length ?
						<ListItem className='p-0 m-0 align-items-baseline'>
							<List className="p-0 m-0 w-100">
								{/*Блок сверху - заглушка*/}
								<ListItem
									className='py-0 overflow-hidden align-items-center border-top border-gray d-flex justify-content-end'
									style={{height: '50px'}}
								>
									<div className="w-100 d-flex align-items-center justify-content-between">
										<Typography variant="h6">
											{service.name}
											{
												service.unit && <span className="ml-5 pl-3">{parse(service.unit)}</span>
											}
										</Typography>
										<Typography variant="h6">Итого стоимость материалов в
											работе, руб.: {this.state.itemsTotalSum.toFixed(2)}
										</Typography>
									</div>
								</ListItem>
								{
									service.items.map((item, idx) => {
										let first = !service.items[idx - 1];
										return <AdminMaterialsGoodsList key={idx}
																		service={service}
																		item={item}
																		setItemsTotalSum={this.setItemsTotalSum}
																		first={first}/>
									})
								}
							</List>
						</ListItem>
						:
						<ListItem className='p-0 m-0 align-items-baseline '>

							{
								!!service.materials.length ?
									<AdminMaterialsGoodsList service={service}/>
									:
									<List className='w-100 p-0 m-0'>
										<ListItem
											className='w-100 d-flex align-items-center justify-content-between border-top border-bottom border-gray'
											style={{height}}>
											<Typography variant="h6">{service.name}</Typography>
											<Button
												className="Admin_btn align-self-center"
												variant="contained" color="primary"
												onClick={(e) => this.props.serviceTabHandler(e, 2)}
											>
												<AddCircleOutlineIcon
													className='Admin_btn-icon Admin_btn-icon-create'/>
											</Button>
										</ListItem>
									</List>
							}

						</ListItem>


				}


			</List>
		);
	}

}

const mapState = (state) => {
	return {
		services: state.adminService.services,
		goods: state.adminService.goods,
	}
}
const actions = {
	setMaterialNorm
}
export default connect(mapState, actions)(AdminMaterialsPrices);