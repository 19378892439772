import React, {useState} from 'react';
import WorkerPersonalAvatar from "../WorkerPersonalAvatar/WorkerPersonalAvatar";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import HowToRegIcon from '@material-ui/icons/HowToReg';
import ForumIcon from '@material-ui/icons/Forum';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import GroupIcon from '@material-ui/icons/Group';
import AssignmentIcon from '@material-ui/icons/Assignment';
import WorkIcon from '@material-ui/icons/Work';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ScheduleIcon from '@material-ui/icons/Schedule';
import {ExpandLess, ExpandMore, StarBorder} from "@material-ui/icons";
import {withRouter} from "react-router-dom";
import Badge from "@material-ui/core/Badge";
import {WorkerFooter} from "../../WorkerComponents";
import {useSelector} from "react-redux";
import is from 'is_js'

const WorkerPersonalNav = (props) => {

    const [open, setOpen] = useState(false);
    const user = useSelector(state => state.workerPersonal.user);

    const handleClick = () => {
        setOpen(!open);
    };

    const navHandler = (tab) => {
        props.history.push('/worker/personal/' + tab)
    };

    const requestsCount = user.requestWorkers ? user.requestWorkers.length : null;

    const selected = props.match.params.tab;

    const isMobile = is.mobile() || is.tablet();

    return (
        <div className='wp__nav'>

            {
                !!user &&  <> <div>
                    {
                        !isMobile && <WorkerPersonalAvatar user={user}/>
                    }
                    <List
                        className='wp__nav-list'
                        component='nav'
                    >
                        <ListItem button selected={selected === 'data'} onClick={() => navHandler('data')}>
                            <ListItemIcon>
                                <HowToRegIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Личные данные"/>
                        </ListItem>
                        {
                            user && (user.role === 5 || user.role === 7 || user.role === 10) &&
                            <ListItem button
                                      selected={selected === 'team'}
                                      disabled={!user.workers.length}
                                      onClick={() => navHandler('team')}>
                                <ListItemIcon>
                                    <GroupIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Состав бригады"/>
                            </ListItem>
                        }
                        {
                            user && (user.role === 5) &&
                            <ListItem button selected={selected === 'request'} onClick={() => navHandler('request')}>
                                <ListItemIcon>
                                    <GroupAddIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Запросы в бригаду"/>
                                <Badge color="secondary" className='mr-4 pb-3' badgeContent={requestsCount}/>
                            </ListItem>
                        }
                        {
                            user && (user.role === 7 || user.role === 10) && !user.master &&
                            <>
                                <ListItem button selected={selected === 'request-masters'}
                                          onClick={() => navHandler('request-masters')}>
                                    <ListItemIcon>
                                        <GroupAddIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Запросы к бригадирам"/>
                                    <Badge color="secondary" className='mr-4 pb-3'
                                           badgeContent={user.requestMasters.length}/>
                                </ListItem>
                            </>
                        }
                        <ListItem button selected={selected === 'settings'}
                                  onClick={() => navHandler('settings')}>
                            <ListItemIcon>
                                <SettingsIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Настройки"/>
                        </ListItem>
                        {
                            user && (user.role === 5 || user.role === 6) &&
                            <>
                                <ListItem button selected={false} onClick={handleClick}>
                                    <ListItemIcon>
                                        <WorkIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="История заказов"/>
                                    {open ? <ExpandLess/> : <ExpandMore/>}
                                </ListItem>
                                <Collapse in={open} timeout="auto"
                                          unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItem button className='pl-5' selected={selected === 'orders-current'}
                                                  onClick={() => navHandler('orders-current')}>
                                            <ListItemIcon>
                                                <StarBorder/>
                                            </ListItemIcon>
                                            <ListItemText primary="Текущие"/>
                                        </ListItem>
                                        <ListItem button className='pl-5' selected={selected === 'orders-done'} disabled
                                                  onClick={() => navHandler('orders-done')}>
                                            <ListItemIcon>
                                                <StarBorder/>
                                            </ListItemIcon>
                                            <ListItemText primary="Завершенные"/>
                                        </ListItem>
                                    </List>
                                </Collapse>
                            </>
                        }
                        {
                            user && (user.role === 5 || user.role === 6 || user.role === 11) &&
                            <>
                                <ListItem button selected={selected === 'reports'}
                                          onClick={() => navHandler('reports')}>
                                    <ListItemIcon>
                                        <AssignmentIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Отчеты"/>
                                </ListItem>
                                <ListItem button selected={selected === 'dialogs'} disabled
                                          onClick={() => navHandler('dialogs')}>
                                    <ListItemIcon>
                                        <ForumIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Диалоги"/>
                                </ListItem>
                                <ListItem button selected={selected === 'stat'} disabled
                                          onClick={() => navHandler('stat')}>
                                    <ListItemIcon>
                                        <InsertChartIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Статистика"/>
                                </ListItem>
                            </>
                        }
                        {
                            user && user.moderation.id === 1 && !user.dateInstruction && (user.role === 5 || user.role === 6 || user.role === 11) &&
                            <ListItem button selected={selected === 'dates'} onClick={() => navHandler('dates')}>
                                <ListItemIcon>
                                    <ScheduleIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Дата инструктажа"/>
                            </ListItem>
                        }
                        <ListItem button selected={selected === 'payment'} disabled
                                  onClick={() => navHandler('payment')}>
                            <ListItemIcon>
                                <AccountBalanceWalletIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Оплаты"/>
                        </ListItem>
                    </List>
                </div>
                    {
                        !isMobile && <WorkerFooter/>
                    }</>
            }



        </div>
    );
};


export default withRouter(WorkerPersonalNav);