import React, { useEffect } from 'react';
import './App.css';
import './App.scss';

import { useDispatch, useSelector } from "react-redux";
import Routes from "./routes";
import { workerPersonalFetch, workerPersonalLogin } from "./store/actions/workerPersonalActions";
import {
    getIsLogin, getOrderId,
    getUserId, getUserRole, getUserToken
} from "./store/selectors";
import { useHistory, useLocation } from 'react-router-dom';
import { fetchOnlineStatistic } from './store/actions/contactsActions';
import { ROLE_MASTER } from './config';


const App = () => {
    const role = useSelector(getUserRole);
    const id = useSelector(getUserId);
    const isLogin = useSelector(getIsLogin);
    const orderId = useSelector(getOrderId);
    const token = useSelector(getUserToken);
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();


    useEffect(() => {
        if (!isLogin) {
            dispatch(workerPersonalLogin());
        }
    }, [isLogin, dispatch]);

    useEffect(() => {
        if (isLogin && token) {
            dispatch(workerPersonalFetch(token));
        }
    }, [isLogin, token, dispatch]);

    useEffect(() => {

        if (id && !location.pathname.startsWith('/api/docs')) {
            switch (role) {
                case ROLE_MASTER:
                    history.push('/worker');
                    break;
                // case ROLE_CLIENT:
                //     history.push('/personal/orders');
                //     break;
            }
        }
    }, [role, history, id]);

    useEffect(() => {
        if (orderId) {
            history.push('/personal/orders');
        }

    }, [orderId, history]);

    useEffect(() => {
        dispatch(fetchOnlineStatistic());
    }, []);

    return (
        <Routes />
    );
};


export default App;
