import {
    ADD_DATES_DAY, DELETE_ADDRESS, DELETE_DATES_DAY,
    FETCH_DATES_ERROR,
    FETCH_DATES_START,
    FETCH_DATES_SUCCESS,
    SAVE_ADMIN_DATES_ERROR,
    SAVE_ADMIN_DATES_NOTHING,
    SAVE_ADMIN_DATES_START,
    SAVE_ADMIN_DATES_SUCCESS, SET_ADDRESS, SET_DATE_ADDRESS, SET_DATES_ADDRESS_PROPERTY,
    SET_DATES_DAY, SET_DATES_PROPERTY
} from "../actions/actionTypes";

const initialState = {
    dates: [],
    addresses: [],
    loading: false,
    saveInProcess: false,
    saveResult: {
        title: null,
        description: null,
        list: []
    },
    error: null
}

const adminDatesReducer = (state = initialState, {type, payload}) => {
    let dates = [...state.dates]
    let dayIdx = null
    if (payload && payload.id) {
        dayIdx = dates.findIndex(day => day.id === payload.id)
    }
    let addresses = [...state.addresses];

    switch (type) {
        case FETCH_DATES_START:
            return {
                ...state,
                loading: true
            }
        case FETCH_DATES_SUCCESS:
            return {
                ...state,
                dates: payload.dates,
                addresses: payload.addresses,
                loading: false
            }
        case FETCH_DATES_ERROR:
            return {
                ...state,
                loading: false,
                error: payload.error
            }
        case SET_DATES_DAY:
            if (dayIdx >= 0) {
                dates = [
                    ...dates.slice(0, dayIdx),
                    payload.day,
                    ...dates.slice(dayIdx + 1)
                ]

            }
            return {
                ...state,
                dates
            }
        case ADD_DATES_DAY:
            dates = [...dates, payload.day]
            return {
                ...state,
                dates
            }

        case SAVE_ADMIN_DATES_START:
            return {
                ...state,
                saveInProcess: true,
            }

        case SAVE_ADMIN_DATES_SUCCESS:

            dates = [...dates.map(date => {
                date.edited = false
                return date
            })]

            return {
                ...state,
                saveResult: {
                    title: "Отправлено!",
                    description: "Результат сохранения:",
                    list: payload.saveResult
                },
                dates,
                saveInProcess: false
            }

        case SAVE_ADMIN_DATES_NOTHING:
            return {
                ...state,
                saveResult: {title: "!", description: "Нечего сохранять!", list: []},
                saveInProcess: false
            }

        case SAVE_ADMIN_DATES_ERROR:
            return {
                ...state,
                error: payload.error,
                saveInProcess: false
            }

        case SET_DATES_PROPERTY:
            return {
                ...state,
                [payload.property]: payload.value
            }
        case DELETE_DATES_DAY:

            if (dayIdx >= 0) {
                dates = [
                    ...dates.slice(0, dayIdx),
                    ...dates.slice(dayIdx + 1)
                ]
            }
            return {
                ...state,
                dates
            }
        case SET_DATES_ADDRESS_PROPERTY:
            const address = addresses.find(address => address.id === payload.id);
            address[payload.property] = payload.value;
            return {
                ...state,
                addresses
            };
        case SET_ADDRESS:
            addresses = [...addresses, payload.address]
            return {
                ...state,
                addresses
            }
        case DELETE_ADDRESS:
            addresses = addresses.filter(address => address.id !== payload.id);
            return {
                ...state,
                addresses
            }
        case SET_DATE_ADDRESS:
            let date = dates.find(date => date.id === payload.dateId);
            if (date) {
                let address = addresses.find(address => address.id === payload.addressId)
                if (address) {
                    date.address = address
                    date.edited = true
                }
            }
            return {
                ...state,
                dates
            }
        default:
            return state

    }
}
export default adminDatesReducer;