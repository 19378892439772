import React, { useEffect, useState } from 'react';
import ChartsTable from './components/ChartsTable';
import ChartsTableLeft from './components/ChartsTableLeft';
import ChartsTableHeadTitle from './components/ChartsTableHeadTitle';
import ChartsTableRight from './components/ChartsTableRight';
import ChartsTableHeadDates from './components/ChartsTableHeadDates';
import ChartsFinanceRight from './components/ChartsFinanceRight';
import ChartsFinanceLeft from './components/ChartsFinanceLeft';
import ChartsFinanceColumns from './components/ChartsFinanceColumns';
import { useChartsContext } from './ChartsContext';

const titleCols = [];

const ChartsFinance = () => {
    const { works, materials, total } = useChartsContext();
    const [worksCount, setWorksCount] = useState(0);
    const [materialCount, setMaterialCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);


    useEffect(() => {
        const worksCount = works.reduce((acc, num) => acc + +num, 0);
        setWorksCount(worksCount);
        const materialCount = materials.reduce((acc, num) => acc + +num, 0);
        setMaterialCount(materialCount);
        const totalCount = total.reduce((acc, num) => acc + +num, 0);
        setTotalCount(totalCount);
    }, [works, materials, total]);

    return (
        <ChartsTable>
            <ChartsTableLeft>
                <ChartsTableHeadTitle titleCols={titleCols} />
                <ChartsFinanceLeft
                    worksCount={worksCount}
                    materialCount={materialCount}
                    totalCount={totalCount}
                />
            </ChartsTableLeft>
            <ChartsTableRight>
                <ChartsTableHeadDates />
                <ChartsFinanceColumns total={total} />
                <ChartsFinanceRight
                    works={works}
                    materials={materials}
                    total={total}
                />
            </ChartsTableRight>
        </ChartsTable>
    );
};

export default ChartsFinance;
