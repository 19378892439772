import React, { useState } from 'react';
import ChartsTable from './components/ChartsTable';
import ChartsTableLeft from './components/ChartsTableLeft';
import ChartsTableHeadTitle from './components/ChartsTableHeadTitle';
import ChartsTableRight from './components/ChartsTableRight';
import ChartsTableHeadDates from './components/ChartsTableHeadDates';
import { CHART_RESOURCES, TITLE_COLS } from './config';
import ChartsTableLeftServices from './components/ChartsTableLeftServices';
import ChartsTableWorkers from './components/ChartsTableWorkers';


const ChartsResources = () => {
    const [serviceOpen, setServiceOpen] = useState(null);
    return (
        <ChartsTable>
            <ChartsTableLeft>
                <ChartsTableHeadTitle titleCols={TITLE_COLS} />
                <ChartsTableLeftServices
                    serviceOpen={serviceOpen}
                    setServiceOpen={setServiceOpen}
                />
            </ChartsTableLeft>
            <ChartsTableRight table={CHART_RESOURCES}>
                <ChartsTableHeadDates />
                <ChartsTableWorkers
                    serviceOpen={serviceOpen}
                />
            </ChartsTableRight>
        </ChartsTable>
    );
};

export default ChartsResources;
