import React, {useEffect, useState} from 'react';
import {makeStyles, Paper, TextField, Typography} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {
	editClassifier,
	fetchClassifierRoles,
	fetchClassifiers,
	updateClassifier
} from '../../../../store/actions/adminClassifiersActions';
import AdminBtn from '../AdminUI/AdminBtn';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import AdminSendLoader from '../AdminUI/AdminSendLoader';


const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
}));


const AdminClassifiers = () => {
	const dispatch = useDispatch();
	const {classifiers, roles, loading} = useSelector(state => state.adminClassifiers)
	const [edit, setEdit] = useState(false)
	const [classifierName, setClassifierName] = useState('')
	const [classifierDesc, setClassifierDesc] = useState('')
	const [role, setRole] = useState({})
	const [currentClassifier, setCurrentClassifier] = useState({})
	const classifierNameHandler = (e) => {
		setClassifierName(e.target.value)
	}
	const classifierDescHandler = (e) => {
		setClassifierDesc(e.target.value)
	}
	const saveHandler = (classifierName, classifierDesc, role, id = null) => {
		dispatch(updateClassifier(classifierName, classifierDesc, role, id))
		setEdit(false)
	}
	const deleteHandler = (id) => {
		dispatch(updateClassifier(classifierName, classifierDesc, role, id, true))
	}

	const editHandler = (id, value = null, property = null) => {
		dispatch(editClassifier(id, value, property))
	}

	const setCurrentClassifierHandler = (evt) => {
		const classifier = classifiers.find(c => c.id === +evt.target.id);
		setCurrentClassifier(classifier)
	}
	const setCurrentRoleHandler = (evt) => {
		setRole(roles.find(r => r.id === +evt.target.value))
	}
	useEffect(() => {
		dispatch(fetchClassifiers())
	}, [dispatch])

	useEffect(() => {
		dispatch(fetchClassifierRoles())
	}, [dispatch])

	useEffect(() => {
		if (!!classifiers.length) {
			setCurrentClassifier(classifiers[0])
		}
	}, [classifiers])

	useEffect(() => {
		const role = roles.find(r => {
			return r.id === currentClassifier.role
		})
		if (role) {
			setRole(role)
		}
	}, [currentClassifier, roles])


	return (
		<>
			{
				loading && <AdminSendLoader/>
			}
			<h3 className='text-center'>Классификаторы</h3>
			<Paper className={`px-4 ${edit ? 'pb-4 pt-2' : 'p-4'}`} style={{marginTop: 25}}>
				{
					edit
						? <>
							<div className="d-flex flex-column align-items-center justify-content-between">
								<div className="d-flex align-items-center w-100">
									<TextField
										className="w-30 pr-3"
										label="Название классификатора"
										onChange={classifierNameHandler}
										value={classifierName}
									/>
									<TextField
										className="w-40 pr-3"
										label="Описание"
										onChange={classifierDescHandler}
										value={classifierDesc}
									/>
									{
										!!roles.length
										&& (
											<>
												<select
													className="w-40 pr-3 align-self-end"
													//	size={5}
													value={role.id || roles[0]['id']}
													onChange={setCurrentRoleHandler}
													style={{height: '30px', borderRadius: '3px'}}
												>
													{roles.map(role => {
														return (
															<option
																key={role.id}
																value={role.id}
																title={role.description}
															>
																{role.name}
															</option>
														)
													})}

												</select>
											</>
										)
									}

								</div>
								<div className="d-flex mt-3 pl-0 align-self-start">
									<AdminBtn
										text="Сохранить"
										type="save"
										className="ml-0"
										onClick={() => saveHandler(classifierName, classifierDesc, role)}
									/>
									<AdminBtn
										text="Отменить"
										type="clear"
										onClick={() => setEdit(false)}
									/>
								</div>
							</div>
						</>
						: (
							<>
								{
									!!classifiers.length
									&& (
										<>
											<Paper>

												<Table style={{width: '100%', tableLayout: 'fixed'}}>
													<Paper component={'thead'}>
														<TableRow className="AdminOrderList__table-head">
															<TableCell
																style={{width: '50px'}}
																className="AdminOrderList__cell px-3 py-2 border-right">
																<Typography align='center'>№ п/п</Typography>
															</TableCell>
															<TableCell align="left"
																	   style={{width: '20%'}}
																	   className="AdminOrderList__cell p-3 border-right">
																<Typography>Наименование</Typography>
															</TableCell>
															<TableCell align="left"
																	   style={{width: '20%'}}
																	   className="AdminOrderList__cell p-3 border-right">
																<Typography>Описание</Typography>
															</TableCell>
															<TableCell align="left"
																	   style={{width: '30%'}}
																	   className="AdminOrderList__cell p-3 border-right">
																<Typography>Группа</Typography>
															</TableCell>
															<TableCell className="AdminOrderList__cell p-3"
																	   style={{width: '120px'}}/>
														</TableRow>
													</Paper>
													<TableBody>
														{classifiers.map((classifier, idx) => {
															const role = roles.find(r => {
																return r.id === classifier.role
															})
															return (

																<TableRow key={classifier.id}
																		  className="border-bottom">
																	<TableCell align="center"
																			   className="p-2"
																	>
																		<Typography>{idx + 1}.</Typography>
																	</TableCell>
																	<TableCell align="justify"
																			   className="p-2"
																	>
																		{
																			classifier.edit
																				? <TextField
																					onChange={(e) => editHandler(classifier.id, e.target.value, 'name')}
																					value={classifier.name}
																				/>
																				: <Typography>{classifier.name}</Typography>
																		}
																	</TableCell>
																	<TableCell align="justify"
																			   className="p-2"
																	>{
																		classifier.edit
																			? <TextField
																				onChange={(e) => editHandler(classifier.id, e.target.value, 'description')}
																				value={classifier.description}
																			/>
																			:
																			<Typography>{classifier.description}</Typography>
																	}
																	</TableCell>
																	<TableCell align="justify"
																			   className="p-2"
																	>
																		{
																			classifier.edit
																			&& (
																				<>
																					{
																						!!roles.length
																						&& (
																							<>
																								<select
																									className="w-40 pr-3 align-self-end"
																									value={classifier.role || roles[0]['id']}
																									onChange={(e) => editHandler(classifier.id, e.target.value, 'role')}
																									style={{
																										height: '30px',
																										borderRadius: '3px'
																									}}
																								>
																									{roles.map(role => {
																										return (
																											<option
																												key={role.id}
																												value={role.id}
																												title={role.description}
																											>
																												{role.name}
																											</option>
																										)
																									})}

																								</select>
																							</>
																						)
																					}
																				</>
																			)
																		}
																		{
																			!!role && !classifier.edit && (
																				<Typography>
																					{role.name}
																					{role.description && <> ({role.description})</>}
																				</Typography>
																			)
																		}

																	</TableCell>
																	<TableCell align="justify"
																			   className=" p-2 d-flex justify-content-end border-0"
																	>
																		<Typography>
																			{
																				classifier.edit
																					? <AdminBtn type="save"
																								tip="Сохранить"
																								onClick={() => saveHandler(classifier.name, classifier.description, classifier.role, classifier.id)}/>
																					: <AdminBtn type="edit"
																								tip="Редактировать"
																								onClick={() => editHandler(classifier.id)}/>
																			}

																		</Typography>
																		<Typography>
																			<AdminBtn type="delete" tip="Удалить"
																					  onClick={() => deleteHandler(classifier.id)}/>
																		</Typography>
																	</TableCell>
																</TableRow>
															)
														})}
													</TableBody>
												</Table>
											</Paper>
										</>
									)
								}


							</>
						)
				}
				{
					!edit && (
						<div className="d-flex justify-content-end mt-3">
							<AdminBtn
								text="Добавить классификатор"
								type="add"
								onClick={() => setEdit(true)}
							/>
						</div>
					)
				}

			</Paper>


		</>
	);
};

export default AdminClassifiers;

