import {
	FETCH_SERVICE_ERROR,
	FETCH_SERVICE_START,
	FETCH_SERVICE_SUCCESS,
	SWITCH_TAB_CALC
} from "../actions/actionTypes";

const initialState = {
	heading: "Расчет стоимости",
	tabs: [
		{
			'id': 1,
			'name': 'Работы',
		},
		{
			'id': 2,
			'name': 'Материалы',
		}
	],
	servicesTableHead: [
		// {
		//     id: 1,
		//     name: '№'
		//
		// },
		{
			id: 2,
			name: 'Работы',
			col: 3
		},
		{
			id: 3,
			name: 'Ед. изм.'
		},
		{
			id: 4,
			name: 'Количество'
		},
		{
			id: 5,
			name: 'Цена, &#8381;',
		},
		{
			id: 6,
			name: 'Сумма, &#8381;'

		},
		{
			id: 7,
			name: 'Начало работ'
		},
		{
			id: 8,
			name: 'Окончание работ'
		},
		{
			id: 9,
			name: `Количество мастеров`
		},
		// {
		//     id: 10,
		//     name: 'Удалить'
		// },
	],
	materialTableHead: [
		// {
		//     id: 1,
		//     name: '№'
		//
		// },
		{
			id: 2,
			name: 'Материал (с учетом транспортных расходов) ',
			col: 2
		},
		{
			id: 3,
			name: 'Ед.изм.'
		},
		{
			id: 4,
			name: `Расчетное <br/>количество`
		},
		{
			id: 5,
			name: 'Цена, &#8381;'
		},
		{
			id: 6,
			name: `Количество <br/> в одной упаковке`,
		},
		{
			id: 7,
			name: `Количество <br/> упаковок`,
		},
		{
			id: 11,
			name: 'Цена упаковки, &#8381;'
		},
		{
			id: 8,
			name: 'Сумма товара, &#8381;'
		},
		{
			id: 9,
			name: `Информация<br/> о материале`,
			col: 1
		},
		{
			id: 10,
			name: 'Удалить'
		},
	],
	service: {
		id: '',
		name: '',
		unit: '',
		norm: '',
		price: '',
		materials: []
	},
	totalMaterialPrice: 0,
	loading: false,
	error: null
}

export default function serviceCalcReducer(state = initialState, action) {
	switch (action.type) {
		case SWITCH_TAB_CALC:
			return {
				...state,
				currentTab: action.payload
			}
		case FETCH_SERVICE_START:
			return {
				...state,
				loading: true
			}
		case FETCH_SERVICE_ERROR:
			return {
				...state,
				error: action.error,
				loading: false
			}
		case FETCH_SERVICE_SUCCESS:
			// console.log(action.service)
			if (action.service) {
				let priceStandard = 0
				let pricePremium = 0
				let norm = 0
				action.service.items.forEach(item => {
					// console.log(item)
					item.prices.forEach(price => {

						// console.log(price)

						if (price.id === 1) {
							priceStandard += price.value
							// console.log(price.value)
						}
						if (price.id === 2) {
							pricePremium += price.value
							// console.log(price.value)
						}
						// console.log('priceStandard', priceStandard)
						// console.log('pricePremium', pricePremium)
					})

					norm += item.norm

				})
				action.service.priceStandard = priceStandard
				action.service.pricePremium = pricePremium
				action.service.norm = norm
			}

			return {
				...state,
				service: action.service,
				loading: false
			}
		default:
			return state

	}
}
