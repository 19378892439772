import React from 'react';

const IndividualWorksSteps = ({currentStep}) => {
	const steps = [
		{id: 1, title: 'Выберите услугу', range: 3},
		{id: 2, title: 'уточните объем и сроки'},
		{id: 3, title: 'укажите условия исполнения', range: 2},
		{id: 4, title: 'подтвердите данные'},
	];
	return (
		<div className="IndividualWorks__steps">
			{
				steps.map(({id, title}) => {
					return (
						<div key={id}
							 className={`IndividualWorks__steps-step ${currentStep === id ? 'IndividualWorks__steps-step_active' : ''} ${currentStep > id ? 'IndividualWorks__steps-step_done' : ''}`}>
							<div className="IndividualWorks__steps-step-title">
								{title}
							</div>
							<div className={`IndividualWorks__steps-step-success ${currentStep > id ? 'IndividualWorks__steps-step-success_done' : ''}`}>
								<svg width="14" height="14" viewBox="0 0 14 14" fill="none">
									<path d="M1 7.66667L6.33333 13L13 1" stroke="#C9D6E1" strokeWidth="2"
										  strokeLinecap="round" strokeLinejoin="round"/>
								</svg>
							</div>
						</div>
					)
				})
			}
				</div>
				);
			};

export default IndividualWorksSteps;