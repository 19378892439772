import React, { useState } from 'react';
import { connect } from 'react-redux';
import GreenButton from '../../../components/UI/GreenButton/GreenButton';
import './Feedback.scss';
import { modalOpenModal } from '../../../store/reducers/modalReducer';
import yandexGoal from '../../../utils/yandexGoal';

const Feedback = ({modalOpenModal}) => {
	const [callBtn, setCallBtn] = useState(1);
	const openModal = () => {
		// yandexGoal('QUESTION_BUTTON')
		// modalOpenModal({
		// 	title: 'Задать вопрос',
		// 	buttonText: 'Отправить', 
		// 	activeFields: ['name', 'phone', 'email', 'question'],
		// 	goal: 'QUESTION_FORM',
		// })

		setCallBtn(callBtn + 1);
		if (callBtn === 2) {
			window.location.href='tel:8-800-700-38-77';
			setCallBtn(1);
		}
	}

	const buttonCall = {
		1: 'Заказать звонок',
		2: '8-800-700-38-77'
	}

	return (
		<div className="Feedback">
			<div className="Feedback__title">
				Остались вопросы? Мы ответим!
			</div>
			<div className="d-flex justify-content-center mt-5">
				<GreenButton className='Feedback__button' onClick={openModal}>{buttonCall[callBtn]}</GreenButton>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	
})
const mapDispatchToprops = {
	modalOpenModal
}
export default connect(mapStateToProps, mapDispatchToprops)(Feedback)