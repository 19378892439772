import React, {useState} from 'react';

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {renderFormControl} from "../../../../form/formFramework";
import Button from '@material-ui/core/Button';

const WorkerExperience = ({controls, formControlHandler, professions}) => {
	const [addPro, setAddPro] = useState({});
	const addProHandler = () => {
		const pro = {...addPro};
		if (pro.addExperience) {
			pro.addExperience_2 = true
		}
		pro.addExperience = true
		setAddPro(pro)
	}
	return (

		<Paper className='p-3 mt-2'>
			<Typography variant="button" display="block" className='py-3'>
				Специальность
			</Typography>
			<div className="row">
				<div className="col-12 col-sm-6 mt-3">
					{renderFormControl(controls.mainExperience, formControlHandler, {
						select: professions,
						addLabel: controls.mainExperience.validation.required ? ' *' : ''
					})}
				</div>
				<div className="col-12 col-sm-6 mt-3">
					{renderFormControl(controls.dateStartMainExperience, formControlHandler, {
						select: professions,
						addLabel: controls.dateStartMainExperience.validation.required ? ' *' : ''
					})}
				</div>
				{
					!!addPro.addExperience
					&& (
						<>
							<div className="col-12 col-sm-6 mt-3">
								{renderFormControl(controls.addExperience, formControlHandler, {
									select: professions,
									addLabel: controls.addExperience.validation.required ? ' *' : ''
								})}
							</div>
							<div className="col-12 col-sm-6 mt-3">
								{renderFormControl(controls.dateStartAddExperience, formControlHandler, {
									select: professions,
									addLabel: controls.dateStartAddExperience.validation.required ? ' *' : ''
								})}
							</div>
						</>
					)
				}
				{
					!!addPro.addExperience_2
					&& (
						<>
							<div className="col-12 col-sm-6 mt-3">
								{renderFormControl(controls.addExperience_2, formControlHandler, {
									select: professions,
									addLabel: controls.addExperience_2.validation.required ? ' *' : ''
								})}
							</div>
							<div className="col-12 col-sm-6 mt-3">
								{renderFormControl(controls.dateStartAddExperience_2, formControlHandler, {
									select: professions,
									addLabel: controls.dateStartAddExperience_2.validation.required ? ' *' : ''
								})}
							</div>
						</>
					)
				}
				{/*{*/}
				{/*	Object.keys(controls).map(property => {*/}
				{/*		const control = controls[property];*/}

				{/*		return (*/}
				{/*			<div key={control.id} className="col-12 col-sm-6 mt-3">*/}
				{/*				{renderFormControl(control, formControlHandler, {*/}
				{/*					select: professions,*/}
				{/*					addLabel: control.validation.required ? ' *' : ''*/}
				{/*				})}*/}
				{/*			</div>*/}
				{/*		)*/}
				{/*	})*/}
				{/*}*/}
			</div>
			{
				!addPro.addExperience_2
				&& (
					<div className="mt-2 d-flex">

						<Button
							variant="contained"
							color='primary'
							component='button'
							onClick={addProHandler}
							className=''>
							<div>Добавить специальность</div>
						</Button>

					</div>
				)
			}

		</Paper>
	);
};

export default WorkerExperience;