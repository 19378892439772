import React, {Component} from 'react'
import './PersonalOrderItemPhotoReports.scss'

import * as moment from "moment";
import 'moment/locale/ru';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import DraggableScroll from "../../../../../components/UI/DraggableScroll/DraggableScroll";
import {LazyLoadImage} from 'react-lazy-load-image-component';
// import HorizontalScroll from 'react-scroll-horizontal'
// import ScrollMenu from 'react-horizontal-scrolling-menu';


// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
var keys = {37: 1, 38: 1, 39: 1, 40: 1};

function preventDefault(e) {
	e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
	if (keys[e.keyCode]) {
		preventDefault(e);
		return false;
	}
}

// modern Chrome requires { passive: false } when adding event
var supportsPassive = false;
try {
	window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
		get: function () {
			supportsPassive = true;
			return true
		}
	}));
} catch (e) {
}

var wheelOpt = supportsPassive ? {passive: false} : false;
var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

// call this to Disable
function disableScroll() {
	window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
	window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
	window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
	window.addEventListener('keydown', preventDefaultForScrollKeys, false);
}

// call this to Enable
function enableScroll() {
	window.removeEventListener('DOMMouseScroll', preventDefault, false);
	window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
	window.removeEventListener('touchmove', preventDefault, wheelOpt);
	window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
}


export default class PersonalOrderItemPhotoReports extends Component {


	state = {
		photoReports: [],
		currentPhotoReports: [],
		currentDay: moment(this.props.order.startDate, 'DD.MM.YYYY').format('DD.MM.YYYY'),
		reportedDates: [],
		gallery: [],
		loaded: true
	}

	ReportsDates = React.createRef()


	renderReports = () => {
		let startDate = moment(this.props.order.startDate, 'DD.MM.YYYY')

		let currentDate = moment()

		let days = currentDate.diff(startDate, 'days')

		let table = []

		for (let i = 0; i <= days; i++) {
			let day = moment(startDate, "DD.MM.YYYY").add(i, 'd').format("DD.MM.YYYY")

			let isReported = this.state.reportedDates.indexOf(day) !== -1

			table.push(
				<div
					key={i}
					className={`PersonalOrderItemPhotoReports_reports-date`}
					data-day={day}
					onClick={() => {
						this.setGallery(day)
					}}
				>

					<div className={`${isReported ? 'reported' : ''} ${day === this.state.currentDay ? 'active' : ''}`}>
						<strong>{day}</strong>
						<span
							className="text-capitalize">{moment(startDate, "DD.MM.YYYY").add(i, 'd')
							.format("dd")}</span>
						{/*<span>{i + 1} день</span>*/}
						{/*{*/}
						{/*isReported ?*/}
						{/*<div className={`reported-label accepted`}/>*/}
						{/*: null*/}
						{/*}*/}
					</div>
				</div>
			)
		}

		return table
	}


	setGallery = (currentDay) => {

		let currentPhotoReports = this.state.photoReports[currentDay] ? this.state.photoReports[currentDay] : []

		this.setState({currentPhotoReports, currentDay})

	}

	renderPopup = (img) => {
		const MySwal = withReactContent(Swal)
		MySwal.fire({
			imageUrl: img,
			animation: false,
			showConfirmButton: false,
			showCloseButton: true,
			backdrop: `rgba(135, 100, 218, .9)`,
			customClass: {
				popup: 'PersonalOrderItemPhotoReports_popup'
			}
		})
	}

	renderGallery = () => {
		let photoReports = this.state.currentPhotoReports
		return <>
			{
				photoReports.length
					?
					photoReports.map((photo, idx) => {
						return (
							<div className="PersonalOrderItemPhotoReports_photos" key={idx}
								 onClick={() => this.renderPopup(photo)}>
								{/*<img key={idx} src={photo} alt=''/>*/}
								<LazyLoadImage
									alt={'фото отчета'}
									effect="blur"
									src={photo} />
								{/*<svg viewBox="0 0 448 448">*/}
								{/*<path*/}
								{/*d="m408 184h-136c-4.417969 0-8-3.582031-8-8v-136c0-22.089844-17.910156-40-40-40s-40 17.910156-40 40v136c0 4.417969-3.582031 8-8 8h-136c-22.089844 0-40 17.910156-40 40s17.910156 40 40 40h136c4.417969 0 8 3.582031 8 8v136c0 22.089844 17.910156 40 40 40s40-17.910156 40-40v-136c0-4.417969 3.582031-8 8-8h136c22.089844 0 40-17.910156 40-40s-17.910156-40-40-40zm0 0"/>*/}
								{/*</svg>*/}
							</div>
						)
					})
					: <div className="p-3">
						В этот день фотографии не были загружены
					</div>

			}
		</>
	}

	getLastReport = (reportedDates) => {

		return reportedDates.reduce((max, current) => {
			let date1 = moment(max, "DD.MM.YYYY")
			let date2 = moment(current, "DD.MM.YYYY")
			return moment.max(date1, date2).format("DD.MM.YYYY")
		})

	}

	componentDidMount = async () => {

		//   console.log(this.props.order.reports)

		let startDate = moment(this.props.order.startDate, 'DD.MM.YYYY')
		let currentDate = moment()
		let days = currentDate.diff(startDate, 'days')

		let photoReports = []

		for (let i = 0; i <= days; i++) {
			let day = moment(startDate, "DD.MM.YYYY").add(i, 'd').format("DD.MM.YYYY")

			let dayReports = this.props.order.reports.filter(report => report.date === day)

			let gallery = []
			dayReports.forEach(dayReport => {
				if (dayReport.photos) {
					dayReport.photos.forEach(photo => {
						gallery.push(photo)
					})
				}
			})

			if (gallery.length > 0) {
				photoReports[day] = gallery
			}
		}

		let reportedDays = this.props.order.reports.map(report => report.date)

		let reportedDates = reportedDays.filter((date, index, self) => {
			return self.indexOf(date) === index;
		})
		let currentDay = !!reportedDates.length ? this.getLastReport(reportedDates) : ''
		await this.setState({reportedDates, currentDay, photoReports})
		this.setGallery(currentDay)
		if (this.ReportsDates) {

			let dates = [...this.ReportsDates.current.children]
			let $currentDate = dates.filter(date => {
				return date.dataset.day === currentDay
			})[0]
			if (typeof $currentDate !== 'undefined') {
				$currentDate.scrollIntoView()
			}

			this.ReportsDates.current.addEventListener('mouseover', e => {
				disableScroll()
			})
			this.ReportsDates.current.addEventListener('mouseleave', e => {
				enableScroll()
			})
		}

	}

	render() {
		// console.log(this.props.order)
		// console.log('state', this.state)
		// TODO scroll to select

		return (
			<div className="PersonalOrderItemPhotoReports">
				<div className="PersonalOrderItemPhotoReports_currentDay">Дата отчета: {this.state.currentDay}</div>
				<DraggableScroll>
					<div ref={this.ReportsDates} className="PersonalOrderItemPhotoReports_reports">
						{this.renderReports()}
					</div>
				</DraggableScroll>
				{/*<div ref={this.ReportsDates} className="PersonalOrderItemPhotoReports_scroll-container">*/}
				{/*<div ref={this.ReportsDates}>*/}

				{/*<HorizontalScroll className="PersonalOrderItemPhotoReports_reports">*/}
				{/*<ScrollMenu*/}
				{/**/}
				{/*alignCenter={false}*/}
				{/*transition={1}*/}
				{/*data={this.renderReports()}*/}
				{/**/}
				{/*/>*/}
				{/**/}
				{/*</HorizontalScroll>*/}
				{/*</div>*/}
				{/*</div>*/}
				<DraggableScroll>
					<div className="PersonalOrderItemPhotoReports_gallery">
						{this.renderGallery()}
					</div>
				</DraggableScroll>
			</div>
		)
	}
}