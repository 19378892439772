import React from 'react'
import Main from '../../../hoc/Main/Main'
import HomeSlider from '../../content/HomeSlider/HomeSlider'
import Footer from '../../layout/Footer/Footer'
import GanttContent from '../../content/Gantt/GanttContent'

const GanttPage = ({ works, times }) => {

  return (
    <Main title='Рассчёт'>
      <div className="Home">
        <HomeSlider/>
        <GanttContent/>
        <Footer/>
      </div>
    </Main>
  )
}

export default GanttPage;
