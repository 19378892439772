import React from "react";
import "./About.scss";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import BgBlot from "../../ui/BgBlot/BgBlot";
import blot from '../../../img/Vector-56.png'
import yandexGoal from "../../../utils/yandexGoal";

const About = () => {
	const {
		about: { video },
	} = useSelector((state) => state.homePageAbout);

	const startPlay = () => {
		yandexGoal('VIDEO_CLICK')
	}

	return (
		<>
			<BgBlot image={blot} left={0} top={0} width='500px' height='500px'/>
			<div id="about" className="About">
				<div className='About__inner row'>
				<div className="col-12 col-lg-7 About__item">
					<div className="About__title">
						<p>Выполняем любые работы со своими опытными бригадами.</p>
						<p>Быстрый старт. Работаем качественно и в срок!</p>
						<p>Имеем собственный сервис с онлайн отчетами о ходе строительства.</p>
					</div>
				</div>
				<div className="col-12 col-lg-5 About__item About__item-video">
						<div className="About__video">
							<ReactPlayer
								url={`https://www.youtube.com/watch?v=${video}`}
								controls
								height="100%"
								width="100%"
								onStart={startPlay}
							/>
					</div>
					<span className='About__video-title'>Видеопрезентация</span>
				</div>
				</div>
			</div>
		</>
	);
};
export default About;
