import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useChartsContext } from '../Сharts/ChartsContext';
import * as moment from 'moment';
import { setConfirmOrderProperty, setTrancheMax } from '../../../../../store/actions/confirmOrderActions';

const PaysTranches = ({ inputHandler }) => {
    const dispatch = useDispatch();
    const { formControls: { startDate, dueDate } } = useSelector(state => state.estimateOrder);
    const { formControls: { tranches } } = useSelector(state => state.confirmOrder);
    const tranchesCount = tranches.value || 1;
    const tranchesCountMax = tranches.validation.max;
    const { total, counts } = useChartsContext();
    const [trancheIndexes, setTrancheIndexes] = useState([]);
    const [trancheData, setTrancheData] = useState([]);

    useEffect(() => {
        if (counts && Object.values(counts).length) {
            const max = Math.floor(Object.values(counts).length / 3);
            dispatch(setTrancheMax(max));
        }
    }, [counts]);

    useEffect(() => {
        if (counts && Object.values(counts).length) {
            if (+tranchesCount > +tranchesCountMax) {
                inputHandler(tranchesCountMax, 'tranches');
            }
        }
    }, [counts, tranchesCountMax, tranchesCount]);

    useEffect(() => {
        // периоды между траншами
        let periods = Math.ceil(dueDate.value / tranchesCount);
        let trancheIndex = 0;
        // индекс транша
        let trancheIndexes = [trancheIndex];
        while (trancheIndex < dueDate.value - 1) {
            trancheIndex += periods;
            if (trancheIndexes.length === +tranchesCount) {
                break;
            } else {
                trancheIndexes.push(trancheIndex);
            }
        }
        if (counts) {
            const trancheData = [];
            Object.keys(counts).forEach((key, idx) => {
                if (trancheIndexes.includes(idx)) {
                    trancheData.push(key);
                }
            });
            dispatch(setConfirmOrderProperty(trancheData, 'trancheData'));
        }
        setTrancheIndexes(trancheIndexes);
    }, [tranchesCount, counts]);

    useEffect(() => {
        const trancheData = [];
        let trancheIdx = 1;
        Object.values(counts).forEach((count, i) => {
            let quantityDaySum = 0;
            let costDaySum = 0;
            if (+tranchesCount === 1) {
                quantityDaySum = Object.values(counts).reduce((acc, count) => acc + count.value, 0);
                costDaySum = total.reduce((acc, num) => acc + num, 0);
            } else {
                if (trancheIndexes.indexOf(i) !== -1) {
                    // индекс текущего транша
                    const currentIndex = trancheIndexes.indexOf(i);
                    // значение следующего транша
                    const nextTrancheValue = trancheIndexes[currentIndex + 1];
                    if (nextTrancheValue) {
                        for (let j = i; j < nextTrancheValue; j++) {
                            const dayCount = Object.values(counts)[j];
                            if (dayCount) {
                                quantityDaySum += Object.values(counts)[j].value;
                                costDaySum += Object.values(counts)[j].workPrice;
                            }
                        }
                    } else {
                        for (let j = i; j < dueDate.value; j++) {
                            const dayCount = Object.values(counts)[j];
                            if (dayCount) {
                                quantityDaySum += Object.values(counts)[j].value;
                                costDaySum += Object.values(counts)[j].workPrice;
                            }
                        }
                    }
                } else {
                    quantityDaySum += count.value;
                    costDaySum += count.workPrice;
                }
            }

            let day = moment(startDate.value, "DD.MM.YYYY").add(i, 'd');
            let isTrancheInfo = trancheIndexes.indexOf(i) !== -1;
            if (isTrancheInfo) {
                ++trancheIdx;
            }
            trancheData.push({
                isTrancheInfo,
                weekDay: day.format("dddd"),
                dayFormat: day.format("DD.MM.YYYY"),
                costDay: count.workPrice,
                quantityDay: count.value,
                costDaySum,
                quantityDaySum,
                trancheIdx: isTrancheInfo ? trancheIdx : null,
            });

        });
        const trancheDataComplex = trancheData.filter((data) => data.isTrancheInfo).map(data => ({
            day: data.dayFormat,
            value: data.costDaySum,
        }));
        dispatch(setConfirmOrderProperty(trancheDataComplex, 'trancheDataComplex'));
        setTrancheData(trancheData);
    }, [dispatch, tranchesCount, counts, startDate, trancheIndexes]);


    return (
        <>
            {
                trancheData.map(({
                                     isTrancheInfo,
                                     dayFormat,
                                     weekDay,
                                     quantityDay,
                                     costDay,
                                     costDaySum,
                                     quantityDaySum,
                                     trancheIdx
                                 }, i) => (
                    <React.Fragment key={dayFormat}>
                        <div className={`ConfirmOrderTranches_RightBlock__item ${isTrancheInfo ? 'active' : ''}`}>
                            {/*шапка*/}
                            <div
                                className={`ConfirmOrderTranches_RightBlock__item_head`}>
                                <div>
                                    <strong>{dayFormat}</strong>
                                    <span>{weekDay}</span>
                                    <span>{i + 1} день</span>
                                </div>
                            </div>
                            <div
                                className={`px-3 ${isTrancheInfo ? 'bg-tranche-1 border-tranche-1' : 'bg-white border-main-1-3'} border-bottom d-flex align-items-center justify-content-center h50`}>
                                {quantityDay}
                            </div>
                            <div
                                className={`px-3 ${isTrancheInfo ? 'bg-tranche-1 border-tranche-2' : 'bg-white border-main-2-1'} border-bottom border-main-1-3 d-flex align-items-center justify-content-center h50`}>
                                {costDay}
                            </div>
                            <div
                                className={`px-3 ${isTrancheInfo ? 'bg-tranche-1 border-tranche-1' : 'bg-white border-main-1-3'} border-bottom d-flex align-items-center justify-content-center h50`}>
                                {isTrancheInfo
                                    ?
                                    <strong>{parseFloat(quantityDaySum).toFixed(2)}</strong>
                                    : null}
                            </div>
                            <div
                                className={`px-3  ${isTrancheInfo ? 'bg-tranche-1 border-tranche-1' : 'bg-white border-main-1-3'} border-bottom d-flex align-items-center justify-content-center h50`}>
                                {isTrancheInfo
                                    ? <strong>{parseFloat(costDaySum).toFixed(2)}</strong>
                                    : null
                                }
                            </div>


                            {isTrancheInfo
                                ? <div
                                    className="ConfirmOrderTranches_RightBlock__item_head-label bg-tranche-1 d-flex align-items-center justify-content-center">Транш&nbsp;
                                    {trancheIdx}
                                </div>
                                : null}
                        </div>

                    </React.Fragment>
                ))
            }
        </>
    );
};

export default PaysTranches;
