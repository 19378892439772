import React from "react";
import "./EstimateUI.scss";

export default function EstimateButton({
  className,
  onClick,
  disabled,
  text,
    noIcon = false,
  back = false,
}) {
  return back ? (
    <button
      className={`estimate-button ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {
        !noIcon && (
              <svg
                  style={{ transform: "rotate(180deg)" }}
                  width="15"
                  height="13"
                  viewBox="0 0 15 13"
                  fill="none"
              >
                <path
                    d="M8.51111 12.0024L14 6.50244M14 6.50244L8.51111 1.00244M14 6.50244L0.999999 6.50244"
                    stroke="#8594a2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
              </svg>
          )
      }
      {text}
    </button>
  ) : (
    <button
      className={`estimate-button ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
      {
        !noIcon && ( <svg width="15" height="13" viewBox="0 0 15 13" fill="none">
            <path
                d="M8.51111 12.0024L14 6.50244M14 6.50244L8.51111 1.00244M14 6.50244L0.999999 6.50244"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
          </svg>)
      }

    </button>
  );
}
