import React from 'react'
import classes from './Total.module.scss'

import CountUp from "react-countup";

export default ({title, sum}) => {
    return (
        <div className={`${classes.Total} p-2`}>
            <span>{title}:&nbsp;</span>
            {/*<span className="text-sum">*/}
            <span>
                {/*{new Intl.NumberFormat('ru-RU').format(+sum)}" ₽"*/}
                <strong className={classes.Sum}>
                    <CountUp
                        end={sum || 0}
                        duration={.3}
                        formattingFn={number => {
                            return new Intl.NumberFormat('ru-RU').format(number)
                        }}
                    />
                    &nbsp;р.
             </strong>
             </span>
        </div>
    )
}