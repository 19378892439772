import React, {useState} from 'react';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
// import {useSelector} from 'react-redux';
// import {getAdminOrderListState} from '../../selectors';
// import {a11yProps} from '../../functions';
import AdminOrderListFinanceMasters from './AdminOrderListFinanceMasters';
import AdminOrderListFinanceCompanies from './AdminOrderListFinanceCompanies';
import {Typography} from '@material-ui/core';

const AdminOrderListFinanceTwo = ({order, reports}) => {
	// const {employersTabsFinance} = useSelector(getAdminOrderListState);
	const [currentTab, setCurrentTab] = useState(1);
	// const setCurrentTabHandler = (event, newValue) => {
	// 	setCurrentTab(newValue)
	// };

	return (
		<div className="row mx-0 p-2 d-flex flex-column">
			{/*<Tabs*/}
			{/*	value={currentTab}*/}
			{/*	variant="fullWidth"*/}
			{/*	indicatorColor="secondary"*/}
			{/*	onChange={setCurrentTabHandler}*/}
			{/*	scrollButtons={'on'}*/}
			{/*	classes={{*/}
			{/*		root: "AdminOrderList__finance-tabs"*/}
			{/*	}}*/}
			{/*>*/}
			{/*	{*/}
			{/*		employersTabsFinance.map((tab) => <Tab key={tab.id} value={tab.id}*/}
			{/*											   disabled={tab.disabled}*/}
			{/*											   label={tab.name} {...a11yProps(tab.id)} />)*/}
			{/*	}*/}
			{/*</Tabs>*/}
			<Typography variant="button" className="text-center pt-3 pb-1 border-bottom">Бригадиры</Typography>
			{
				currentTab === 1 &&
				<AdminOrderListFinanceMasters order={order} reports={reports}/>
			}
			{
				currentTab === 2 &&
				<AdminOrderListFinanceCompanies order={order}/>
			}
		</div>
	);
};

export default AdminOrderListFinanceTwo;