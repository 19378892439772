import React, {useEffect, useState} from 'react';

const OnlineTabloDigits = ({variant = '', num, title}) => {
	const [nums, setNums] = useState([]);
	useEffect(() => {
		if (!!num) {
			setNums(num.toString().split(''))
		}
	}, [num])
	return (
		<div className={`OnlineTablo__digits ${variant}`}>
			<div className="OnlineTablo__digits-cell">{title}</div>
			<div className="OnlineTablo__digits-cell OnlineTablo__digits-num">{nums[nums.length - 4]}</div>
			<div className="OnlineTablo__digits-cell OnlineTablo__digits-num">{nums[nums.length - 3]}</div>
			<div className="OnlineTablo__digits-cell OnlineTablo__digits-num">{nums[nums.length - 2]}</div>
			<div className="OnlineTablo__digits-cell OnlineTablo__digits-num">{nums[nums.length - 1]}</div>
		</div>
	);
};

export default OnlineTabloDigits;