import React from 'react';
import styled from 'styled-components';

const StiledTitle = styled.div`
  color: #7B53DA;
  font-size: 22px;
  font-weight: 600;
  padding: 30px 0 0;
`;

const ChartsSectionTitle = ({ children }) => {
    return (
        <StiledTitle>
            {children}
        </StiledTitle>
    );
};

export default ChartsSectionTitle;
