import React, { useState, useEffect } from "react";
import parse from 'html-react-parser';
import { animateInput, getBuildersCount, getRandBetween } from "./instanteCompleteFunctions";
import cn from '../Advantages.module.scss'

const AdvantagesServiceForm = ({service, setWorkLoad, setTermOfWork, workLoad, termOfWork}) => {
	const [countOfBuilders, setCountOfBuilders] = useState(0)

	useEffect(() => {
		setWorkLoad(getRandBetween(100, 1000));
		setTermOfWork(1)
		async function animateInputsValue() {
			let workLoadArr = [];
			let termOfWorkArr = [];
			for (let i = 0; i < 3; i++) {
				workLoadArr.push(getRandBetween(1000, 3000));
				termOfWorkArr.push(getRandBetween(50, 150));
			}
			await animateInput(workLoadArr, setWorkLoad, 300)
			await animateInput(termOfWorkArr, setTermOfWork, 300)

      animateInputsValue()
		}
    animateInputsValue()
	}, []);

	useEffect(() => {
		setCountOfBuilders(getBuildersCount(service, workLoad, termOfWork))
	}, [service, workLoad, termOfWork])

	return (
		<div className={cn.formWrapper}>
			<div className={cn.formInputsWrapper}>
				<div className={cn.formLabel}>Объём работы {parse(service.unit)}</div>
				<input type="text" readOnly className={cn.formInput} value={workLoad || ''} />
				<div className={cn.formLabel}>Сроки выполнения (дней)</div>
				<input type="text" readOnly className={cn.formInput} value={termOfWork || ''} />
			</div>
			<div className={cn.formLabel}><b>Расчётное количество строителей</b></div>
			<div className={cn.formResultWrapper}>
				{countOfBuilders.toString().split('').map(i => <div key={Math.random()} className={cn.formResultItem}>{i}</div>)}
			</div>
		</div>
	);
};

export default AdvantagesServiceForm