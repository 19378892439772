import React from 'react';
import AdminOrderListFinanceAccordion from '../AdminOrderListFinanceAccordion';
import AdminOrderListFinanceActRows from './AdminOrderListFinanceActRows';

const AdminOrderListFinanceActs = ({order, sum, reports}) => {
	return (
		<div className="px-1 mt-2">
			<AdminOrderListFinanceAccordion name="Актами ОФОРМЛЕНО выполнение"
											sum={sum}>
				<AdminOrderListFinanceActRows acts={reports} order={order}/>
			</AdminOrderListFinanceAccordion>

		</div>
	);
};

export default AdminOrderListFinanceActs;