import React, { useCallback, useMemo, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { COLORS, CRITICAL, DATE_WIDTH_NUM, ROW_HEIGHT, ROW_HEIGHT_NUM } from '../config';
import { addDays, differenceInDays, format, parse } from 'date-fns';
import Slider from 'rc-slider';
import { useChartsContext } from '../ChartsContext';
import { Tooltip } from 'react-tippy';
import { getReserveDays } from '../functions';
import { useDispatch, useSelector } from 'react-redux';
import { setChangedData } from '../../../../../../store/actions/estimateActions';

const StyledWorkerRow = styled.div`
  position: relative;
  width: ${({ width }) => width}px;
  min-width: ${({ width }) => width}px;
  border-bottom: 1px solid #E2E4F1;
  background: #f3f3f8;
  height: ${({ height }) => height}px;
  transition: height 500ms;
`;

const StyledRangeContainer = styled.div`
  position: relative;
  left: ${({ offset }) => offset * DATE_WIDTH_NUM}px;
  background: #fff;
  width: ${({ width }) => width}px;
  min-width: ${({ width }) => width}px;
  border-left: 1px solid #E2E4F1;
  border-right: 1px solid #E2E4F1;
`;

const StyledWorkerContainer = styled.div`
  position: relative;
  left: ${({ offset }) => offset * DATE_WIDTH_NUM}px;
  background: #fff;
  width: ${({ width }) => width}px;
  min-width: ${({ width }) => width}px;
  border-left: 1px solid #E2E4F1;
  border-right: 1px solid #E2E4F1;
  height: ${ROW_HEIGHT_NUM - 6}px;
  top: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  padding: 0 5px;
  margin-bottom: 6px;
  border-radius: 5px;

  span {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    pointer-events: none;
    color: #fff;
  }

  ${({ isSub }) => !isSub && css`
      //background: ${({ color }) => color};
  `}


  ${({ isSub }) => isSub && css`
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: -4px;
      width: ${({ offset, width }) => offset * DATE_WIDTH_NUM + width}px;
      height: 1px;
      background: #E2E4F1;
      right: 0;
    }

    opacity: .85;
    color: #fff;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #000;
    }
  `}
`;

const ChartsTableWorker = ({ service, color, serviceOpen }) => {
    const dispatch = useDispatch();
    const { dates } = useChartsContext();
    const { dayNorm } = useSelector(state => state.adminService);

    const { changedData } = useSelector(state => state.estimateOrder);

    const { daysCount, norms, offset, initialDaysCount, daysReserve, value } = service;
    //  const offsetC = differenceInDays(parse(service.startDate, 'dd.MM.yyyy', new Date()), parse(service.globalStartDate, 'dd.MM.yyyy', new Date()));


    const worker = !!norms.length
        ? norms[0].name
        : 'Все вместе';

    const itemChangeHandler = useCallback((value, service, id) => {
        // console.log(value);
        const data = [...changedData];
        const {globalStartDate, offset, count} = service;
        const serviceIndex = data.findIndex((s) => s.id === service.id);
        const { items } = changedData[serviceIndex];
        const itemIndex = items.findIndex(item => item.id === id);
        const item = items[itemIndex];
        const serviceStartDate = format(new Date(addDays(new Date(parse(globalStartDate, 'dd.MM.yyyy', new Date())), value[0] + offset)), 'dd.MM.yyyy');
        const serviceEndDate = format(new Date(addDays(new Date(parse(globalStartDate, 'dd.MM.yyyy', new Date())), value[1] - 1 + offset)), 'dd.MM.yyyy');
        const daysCount = value[1] - value[0];
        let workerNorm = 0;
            item.norms.forEach(norm => {
                workerNorm += norm.norm * norm.cc;
            });

        const workersCount = Math.ceil(count * workerNorm / (daysCount * dayNorm));

        data[serviceIndex].items[itemIndex] = {
            ...item,
            value,
            startDate: serviceStartDate,
            endDate: serviceEndDate,
            daysCount,
            workersCount,
        };
        dispatch(setChangedData(data));
    }, [changedData]);


    const changeHandler = useCallback((value) => {

        const data = [...changedData];
        const { globalStartDate, items, norms, count, id, offset } = service;
        const serviceStartDate = format(new Date(addDays(new Date(parse(globalStartDate, 'dd.MM.yyyy', new Date())), value[0] + offset)), 'dd.MM.yyyy');
        const serviceEndDate = format(new Date(addDays(new Date(parse(globalStartDate, 'dd.MM.yyyy', new Date())), value[1] - 1 + offset)), 'dd.MM.yyyy');
        const daysCount = value[1] - value[0];

        let workerNorm = 0;
        if (!!items && !!items.length) {
            items.forEach(item => {
                item.norms.forEach(norm => {
                    workerNorm += norm.norm * norm.cc;
                });
            });
        } else {
            norms.forEach(norm => {
                workerNorm += norm.norm * norm.cc;
            });
        }
        const workersCount = Math.ceil(count * workerNorm / (daysCount * dayNorm));
        const servicePosition = data.findIndex(item => item.id === id);
        data[servicePosition] = {
            ...service,
            startDate: serviceStartDate,
            endDate: serviceEndDate,
            daysCount,
            workersCount,
            value,
        };
        dispatch(setChangedData(data));
    }, [service, changedData]);


    const criticalDaysCount = initialDaysCount + daysReserve;

    const height = serviceOpen === service.id
        ? ROW_HEIGHT_NUM * (service.items.length + 1)
        : ROW_HEIGHT_NUM;

    if (service.id === 2826) {
        // console.log('initialDaysCount', initialDaysCount);
        // console.log('daysReserve', daysReserve);
        // console.log(value);
    }

    return (
        <StyledWorkerRow
            width={dates.length * DATE_WIDTH_NUM}
            height={height}
        >
            <StyledRangeContainer
                offset={offset}
                // width={daysCount * DATE_WIDTH_NUM}
                width={criticalDaysCount * DATE_WIDTH_NUM}
                // color={color}
            >
                <Tooltip
                    title={worker}
                    position="top"
                    interactive
                >
                    {/*    <span>{worker}</span>*/}


                    <Slider
                        key={service.id}
                        range
                        min={0}
                        max={criticalDaysCount}
                        // defaultValue={[0, daysCount]}
                        value={!!value ? value : [0, daysCount]}
                        step={1}
                        onChange={changeHandler}
                        trackStyle={{
                            backgroundColor: color,
                            height: ROW_HEIGHT_NUM - 2,
                            borderRadius: 5,
                        }}
                        railStyle={{
                            borderRadius: 0,
                            height: ROW_HEIGHT_NUM - 2,
                            border: '1px solid rgb(169 173 179)',
                        }}
                        handleStyle={{
                            top: `${ROW_HEIGHT_NUM / 2 - 1}px`,
                        }}
                        pushable
                        draggableTrack
                        style={{
                            height: ROW_HEIGHT,
                            padding: 0,
                            cursor: 'ew-resize'
                        }}

                    />
                    {/*<span>{worker}</span>*/}
                </Tooltip>

            </StyledRangeContainer>
            {
                !!service.items.length &&
                service.items.map((item, idx) => {
                    const { id, norms, workPrice, value } = item;
                    // console.log(value);
                    const subWorker = !!norms.length
                        ? norms[0].name
                        : 'Мастер';
                    const subColor = COLORS[idx % 10];
                    const subDaysCount = Math.ceil(daysCount * (workPrice / service.workPrice));
                    return (
                        <StyledWorkerContainer
                            offset={offset}
                            width={daysCount * DATE_WIDTH_NUM}
                            color={subColor}
                            key={id}
                            isSub
                        >
                            <Slider
                                range
                                min={0}
                                max={daysCount}
                            //    defaultValue={[0, subDaysCount]}
                                step={1}
                                value={value || [0, subDaysCount]}
                                onChange={(value) => itemChangeHandler(value, service, id)}
                                trackStyle={{
                                    backgroundColor: subColor,
                                    height: ROW_HEIGHT_NUM - 6,
                                    borderRadius: 5,
                                    position: 'relative',
                                    top: 3
                                }}
                                railStyle={{
                                    backgroundColor: subColor,
                                    opacity: 0.2,
                                    borderRadius: 0,
                                    height: ROW_HEIGHT
                                }}
                                handleStyle={{
                                    top: `${ROW_HEIGHT_NUM / 2 - 1}px`,
                                }}
                                pushable
                                draggableTrack
                                style={{
                                    height: ROW_HEIGHT,
                                    padding: 0,
                                    cursor: 'ew-resize'
                                }}

                            />

                            <span>{subWorker}</span>

                        </StyledWorkerContainer>
                    );
                })
            }
        </StyledWorkerRow>
    );
};

export default ChartsTableWorker;
