import React from 'react';
import SliderHomePage from '../../content/HomeSlider/HomeSlider';
import ServiceSelectWrapper from '../../../hoc/ServiceSelectWrapper/ServiceSelectWrapper';
import Footer from '../Footer/Footer';

const DefaultLayout = ({ children, noWrap = false }) => {
    return (
        <>
            <div className="my-3">
                <SliderHomePage />
            </div>
            <div className="mt-5">
                {
                    noWrap
                        ? <>{children}</>
                        : (
                            <ServiceSelectWrapper>
                                {children}
                            </ServiceSelectWrapper>
                        )
                }

            </div>
            <div className="mt-3 pb-3">
                <Footer light />
            </div>
        </>
    );
};

export default DefaultLayout;